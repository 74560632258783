import { NgModule } from '@angular/core';
import { FileUploadModule } from 'ng2-file-upload';
import { CustomerAccountComponent } from './customer-account/customer-account.component';
import { CustomerProfileComponent } from './customer-profile/customer-profile.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CustomerRoutingModule } from './customer-routing.module';

@NgModule({
  declarations: [CustomerAccountComponent, CustomerProfileComponent],
  imports: [
    CustomerRoutingModule,
    SharedModule,
    FileUploadModule,
    RouterModule,
  ],
})
export class CustomerModule {}
