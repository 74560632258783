import { Component, Inject, OnInit } from '@angular/core';
import {
  Router,
  NavigationEnd,
  NavigationStart,
  UrlTree,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { EnvironmentService } from './shared/services';
import { AuthService } from './auth/auth.service';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as AuthActions from '@auth/store/auth.actions';

@Component({
  selector: 'voyant-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  url = this.router.url;
  constructor(
    private router: Router,
    private env: EnvironmentService,
    private _authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private store: Store<fromApp.AppState>
  ) {
    // used to track url (used to set header presence for some views)
    router.events
      .pipe(filter(($event) => $event instanceof NavigationStart))
      .subscribe(($event: NavigationStart) => {
        this.store.dispatch(AuthActions.checkAuthentication());
        const urlTree: UrlTree = this.router.parseUrl($event.url);
        if (urlTree.queryParams?.code) {
          this._authService
            .authenticateSso(urlTree.queryParams?.code)
            .subscribe((res) => {
              this._authService.userRoleRedirect(res.data);
              this.store.dispatch(
                AuthActions.updateAuthUser({ user: res.data })
              );
            });
        }
      });

    router.events
      .pipe(filter(($event) => $event instanceof NavigationEnd))
      .subscribe(($event: NavigationEnd) => {
        this.url = $event.url;
      });

    // listener for window propagated logout event. event is environment specific
    window.addEventListener('storage', (event) => {
      if (event.key === 'voyantfax-logout-event-' + this.env.name) {
        this._authService
          .isAuthenticated()
          .subscribe((isAuthenticated: boolean) => {
            if (!isAuthenticated) {
              location.reload();
            }
          });
      }
    });
  }

  ngOnInit(): void {
    const body = this.document.querySelector('body');
    body.classList.remove('mat-typography');
  }
}
