import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import coverPageTemplate from '../../../assets/data/cover-page.pdf-template.json';
import loaPageTemplate from '../../../assets/data/loa-page.pdf-template.json';
@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor() {}

  generateCoverSheet(data: {
    fax_name: string;
    first_name: string;
    last_name: string;
    account_name: string;
    send_from_label: string;
    send_to: string;
    send_from: string;
    date: string;
    message?: string;
  }) {
    let templateString = JSON.stringify(coverPageTemplate);

    Object.keys(data).forEach((key) => {
      const value = data.hasOwnProperty(key) && data[key] ? data[key] : 'N/A';

      if (key === 'send_from_label' && data[key].includes('Direct Line')) {
        templateString = templateString.replace(`via $send_from_label`, '');
      } else if (key === 'message' && !data[key].length) {
        const tpl = JSON.parse(templateString);
        tpl.content.splice(-2, 2);
        templateString = JSON.stringify(tpl);
      }
      templateString = templateString.replace(`$${key}`, value);
    });

    return pdfMake.createPdf(JSON.parse(templateString));
  }

  generateLoa(data: {
    name_of_business: string;
    name_on_account: string;
    billing_address1: string;
    billing_address2: string;
    contact_email: string;
    billing_city: string;
    billing_state: string;
    billing_zip: string;
    contact_number: string;
    requested_for: string;
    port_numbers: string[];
    signature: string;
    authorized_signer: string;
    bill_received_at: string;
  }) {
    let templateString = JSON.stringify(loaPageTemplate);

    Object.keys(data).forEach((key) => {
      if (key !== 'port_numbers') {
        const value = data.hasOwnProperty(key) && data[key] ? data[key] : 'N/A';
        templateString = templateString.replace(`$${key}`, value);
      }
    });

    const templateJson = JSON.parse(templateString);
    let portNumbersSectionId = templateJson.content.findIndex(
      (obj: any) => obj.hasOwnProperty('id') && obj.id === 'portNumbers'
    );

    templateJson.content[portNumbersSectionId].columns.push({
      stack: data.port_numbers,
    });

    return pdfMake.createPdf(templateJson);
  }
}
