import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PhaxioResponse } from '@shared/models';
import { ToastrService } from '@shared/services';
import { VarProfile } from '../var.model';
import { VarService } from '../var.service';

@Component({
  selector: 'voyant-var-profile',
  templateUrl: './var-profile.component.html',
  styleUrls: ['./var-profile.component.scss'],
})
export class VarProfileComponent implements OnInit {
  isReady = false;
  profile: FormGroup;

  constructor(
    private _varService: VarService,
    private _toastrService: ToastrService,
    private fb: FormBuilder
  ) {
    this.profile = this.fb.group({
      name: ['', [Validators.required]],
      domain: ['', [Validators.required]],
      contact_first_name: ['', [Validators.required]],
      contact_last_name: ['', [Validators.required]],
      contact_email: ['', [Validators.required]],
      header_background_color: ['', [Validators.required]],
      header_text_theme: ['light', [Validators.required]],
      primary_color: ['', [Validators.required]],
      secondary_color: ['', [Validators.required]],
      tertiary_color: ['', [Validators.required]],
      logo_url: [''],
      logo: [''],
    });
  }

  get p() {
    return this.profile.controls;
  }

  ngOnInit() {
    this._varService.varProfile.subscribe((profile: VarProfile) => {
      if (profile) {
        if (!profile.header_text_theme) {
          profile.header_text_theme = 'light';
        }
        Object.keys(profile).forEach((key: string) => {
          if (profile[key]) {
            this.profile.get(key).patchValue(profile[key]);
          }
        });
        this.isReady = true;
      }
    });
  }

  onSave(): void {
    this._varService
      .updateProfile(this.profile.value)
      .subscribe((res: PhaxioResponse) => {
        if (res.success) {
          this._toastrService.notify('success', res.message);
        }
      });
  }
}
