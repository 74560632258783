import { Injectable } from '@angular/core';
import { UrlTree, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalService } from '../shared/services';
import { VarProfileComponent } from './var-profile/var-profile.component';
import { VarService } from './var.service';

@Injectable({
  providedIn: 'root'
})
export class VarSetGuard implements CanDeactivate<VarProfileComponent> {

  constructor(private _varService: VarService, private _modalService: ModalService){
  }

  canDeactivate(
    component: VarProfileComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (nextState.url.includes('help') || nextState.url.includes('customer')){
      return true;
    }
    if (this._varService.isNewAccount()){
        this._modalService.openConfirmModal({
          modalType: 'warning',
          title: 'Missing Information',
          altTitle: `Profile must be completed`,
          message: `All profile details and visual elements should be
           filled in before a new company is created so that correct
           branding is implemented. <br><br>
           Please address the following: <br> ${this.createMissingValuesList()}`,
          confirmText: 'okay',
          withoutCancel: true
        });
      }

    return !this._varService.isNewAccount();
  }

  createMissingValuesList(){
    const missingFields = [];
    Object.keys(this._varService.isNewAccountValues).forEach((key: string) => {
      if (this._varService.isNewAccountValues[key] === null){
        switch (key){
          case 'logo_url':
            missingFields.push('<strong>LOGO</strong>');
            break;
          case 'primary_color':
            missingFields.push('<strong>BUTTON COLOR</strong>');
            break;
            case 'header_background_color':
            missingFields.push('<strong>HEADER BACKGROUND COLOR</strong>');
            break;
            case 'header_text_theme':
            missingFields.push('<strong>HEADER TEXT COLOR THEME</strong>');
            break;
        }
      }
    });
    return missingFields.join(', <br>');
  }

}
