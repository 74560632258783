import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { User, UserService } from 'src/app/user';

@Component({
  selector: 'voyant-biller-selection',
  templateUrl: './biller-selection.component.html',
  styleUrls: ['./biller-selection.component.scss'],
})
export class BillerSelectionComponent implements OnInit {
  @Input() submitted;
  @Input() parentForm: FormGroup;

  users: any[];
  exclude: any[] = [];

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private _userService: UserService) {}

  get controls() {
    return this.parentForm.controls;
  }

  ngOnInit(): void {
    const users: User[] = Array.from(this._userService.fetchAll().values());
    this.users = users.sort(this._userService.sortUsers);
    const { billing_name, billing_email } = this.parentForm.value;

    this.controls.billing_name.setValue(billing_name);
    this.controls.billing_email.setValue(billing_email);

    this.setBillerView();

    this.controls.manager_id.valueChanges.subscribe(
      (val: string) => (this.exclude = [val])
    );
  }

  setBillerView() {
    // if not new department
    if (this.controls.billing_name.value) {
      const users = Array.from(this._userService.fetchAll().values());
      const billers = users.filter(
        (user: User) => user.email === this.controls.billing_email.value
      );
      if (billers.length === 0) {
        this.controls.selectBiller.setValue(true);
      }
    }
  }

  onClearBiller() {
    this.controls.billing_name.setValue(null);
    this.controls.billing_email.setValue(null);
  }

  onSetBiller(userId: string) {
    if (userId) {
      const user: User = this._userService.fetchAll()[userId];
      this.controls.billing_name.setValue(
        `${user.first_name} ${user.last_name}`
      );
      this.controls.billing_email.setValue(user.email);
    }
  }
}
