import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, fieldNames: string[]): any[] {
    // return empty array if array is falsy
    if (!items) {
      return [];
    }

    // return the original array if search text is empty
    if (!searchText) {
      return items;
    }

    // convert the searchText to lower case
    searchText = searchText.toLowerCase();

    // retrun the filtered array
    return items.filter(item => {
      let returnVal = false;
      if (item) {
        for (const key of fieldNames) {
          let prop = item[key];
          if (typeof prop === 'number') {
            prop = prop.toString();
          }

          returnVal = prop ? prop.toLowerCase().includes(searchText) : false;

          if (returnVal) {
            break;
          }
        }
      }
      return returnVal;
    });
  }
}
