import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NumberService, PhoneNumber } from '../../../../number';

@Component({
  selector: 'voyant-group-info',
  templateUrl: './group-info.component.html',
  styles: [`
  voyant-get-fax-number {
    display: block;
  }`],
})
export class GroupInfoComponent implements OnInit {
  @Input() data;
  @Output() next = new EventEmitter();
  groupForm: FormGroup;
  numberType = 'group';

  hippa: string = null;
  purchaseNumber = false;
  numbers: PhoneNumber[] = [];

  faxNumberSelected: PhoneNumber;
  faxNumberAssign: string;
  submitted = false;

  constructor(private _numberService: NumberService, private fb: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
    this.setGroupFormValidators();
    this._numberService
      .getNumbers()
      .subscribe((res: any) => (this.numbers = res.data));
  }

  get groupFormControls() {
    return this.groupForm.controls;
  }

  setValidation(numberProvisioning, targetState) {
    return numberProvisioning === targetState ? [Validators.required] : null;
  }

  buildForm() {
    if (this.data) {
      const groupInfo = { ...this.data.groupInfo };
      const numberFormConfig = { ...this.data.numberFormConfig };
      const {
        selectedNumber,
        numberProvisioning,
        areaCode,
        hipaa,
        selectedPlan,
      } = numberFormConfig;

      this.groupForm = this.fb.group({
        name: [null, [Validators.required]],
        selectedNumber: [
          null,
          this.setValidation(numberProvisioning, 'numberInventory'),
        ],
        areaCode: [
          null,
          this.setValidation(numberProvisioning, 'purchaseNumber'),
        ],
        selectedPlan: [null],
        hipaa: [null, [Validators.required]],
        numberProvisioning: [null],
      });

      this.groupForm.setValue({
        name: groupInfo.name,
        selectedNumber,
        areaCode,
        selectedPlan,
        hipaa,
        numberProvisioning,
      });
    }
  }

  setGroupFormValidators() {
    if (this.groupForm) {
      const selectedNumber = this.groupForm.get('selectedNumber');
      const areaCode = this.groupForm.get('areaCode');
      const hipaa = this.groupForm.get('hipaa');
      this.groupForm
        .get('numberProvisioning')
        .valueChanges.subscribe((numberProvisioning) => {
          if (numberProvisioning === 'numberInventory') {
            selectedNumber.setValidators([Validators.required]);
            areaCode.setValidators(null);
          } else {
            selectedNumber.setValidators(null);
            areaCode.setValidators([Validators.required]);
          }

          selectedNumber.updateValueAndValidity();
          areaCode.updateValueAndValidity();
          hipaa.updateValueAndValidity();
        });
    }
  }

  onNext() {
    this.submitted = true;
    if (this.groupForm.valid) {
      const {
        name,
        selectedNumber,
        areaCode,
        hipaa,
        numberProvisioning,
        selectedPlan,
      } = this.groupForm.value;

      const groupInfo: {
        name: string;
        phoneNumber?: {
          is_hipaa: boolean;
          phone_number?: string;
          area_code?: string;
          plan?: string;
        };
      } = { name };

      if (numberProvisioning === 'numberInventory') {
        groupInfo.phoneNumber = {
          phone_number: selectedNumber,
          plan: selectedPlan,
          is_hipaa: hipaa === 'yes',
        };
      } else {
        groupInfo.phoneNumber = {
          area_code: areaCode,
          plan: selectedPlan,
          is_hipaa: hipaa === 'yes',
        };
      }
      this.next.emit({
        groupInfo,
        numberFormConfig: { ...this.groupForm.value },
      });
    }
  }
}
