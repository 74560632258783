import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthUser } from '@auth/store/auth.model';
import * as fromApp from '@app-store/app.reducer';
import * as NumberActions from '@number/store/number.actions';
import * as numberSelectors from '@number/store/number.selectors';

import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { PhoneNumberMetrics } from '@number/store/number.model';

@Component({
  selector: 'voyant-numbers-page',
  templateUrl: './numbers-page.component.html',
  styleUrls: ['./numbers-page.component.scss'],
})
export class NumbersPageComponent implements OnInit {
  authUser$: Observable<AuthUser>;
  metrics$: Observable<PhoneNumberMetrics>;

  currentRange = {
    start: new Date(),
    end: new Date(),
    daysRemaining: 0,
  };

  constructor(private store: Store<fromApp.AppState>) {
    this.store.dispatch(NumberActions.getNumbersMetrics());

    this.authUser$ = this.store.select('auth').pipe(map((s) => s.loggedInUser));
    this.metrics$ = this.store
      .select(numberSelectors.getState)
      .pipe(map((s) => s.metrics.data));
  }

  ngOnInit() {
    this.setCurrentRange();
  }

  private setCurrentRange() {
    const today = new Date();

    if (today.getDate() > 25) {
      this.currentRange.end.setMonth(today.getMonth() + 1);
    }

    this.currentRange.end.setDate(25);
    this.currentRange.start.setMonth(this.currentRange.end.getMonth() - 1);
    this.currentRange.start.setDate(26);

    this.currentRange.daysRemaining = Math.round(
      (this.currentRange.end.getTime() - today.getTime()) / (1000 * 3600 * 24)
    );
  }
}
