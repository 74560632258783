import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { Ata } from '../../store/ata.model';

import * as fromApp from '@app-store/app.reducer';
import * as fromAta from '../../store/ata.reducer';
import * as AtaActions from '../../store/ata.actions';

import { PhoneNumber } from '@number/store/number.model';
import * as numberSelectors from '@number/store/number.selectors';

import { AddFaxLinePersonalComponent } from '@user/modals/add-fax-line-personal/add-fax-line-personal.component';
import { ModalService } from '@shared/services';
import { AddUsersComponent } from '@shared/components/modal';
import { User } from 'src/app/user';
import { Observable } from 'rxjs';
import { MacAddressPipe } from '@shared/pipes';

@Component({
  selector: 'voyant-ata-edit',
  templateUrl: './ata-edit.component.html',
  styles: [`
    .preview__content {
        margin: 0 72px;
      }

      .deactivated {
        opacity: 0.5;
      }
  `]
})
export class AtaEditComponent implements OnInit {
  isEdit = false;
  editAta: FormGroup;
  numbers$: Observable<PhoneNumber[]>;
  selectedNumber: PhoneNumber;

  hashPassword = true;

  config = {
    saving: false,
    initialValues: { ...this.ata },
    saveDisabled: true,
  };

  copied = {
    username: false,
    password: false,
    domain: false,
  };

  constructor(
    private _modalService: ModalService,
    private macPipe: MacAddressPipe,
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>,
    @Inject(MAT_DIALOG_DATA) public ata: Ata
  ) {
    this.config.initialValues = { ...ata };

    this.editAta = this.fb.group({
      name: [this.ata.name, [Validators.required]],
      description: [this.ata.description, [Validators.required]],
      username: [this.ata.username, [Validators.required]],
      password: [this.ata.password, [Validators.required]],
      domain: [this.ata.domain],
      phone_number: [
        this.ata.phone_number ? this.ata.phone_number.phone_number : null,
      ],
      mac_address: [this.macPipe.transform(this.ata.mac_address)],
    });
  }

  get a() {
    return this.editAta.controls;
  }

  ngOnInit(): void {
    this.store
      .select(fromAta.selectEntity(this.ata.id))
      .subscribe((ata: Ata) => {
        this.ata = { ...ata };
        this.config.initialValues = { ...ata };
      });

    this.numbers$ = this.store.select(numberSelectors.selectAvailable);
  }

  onSaveAta(): void {
    const form = this.editAta.value;
    const macAddress = form.mac_address
      ? form.mac_address.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
      : null;

    const phoneNumber = {
      ...this.ata.phone_number,
      phone_number: form.phone_number,
    };

    if (this.editAta.valid) {
      this.ata = {
        ...this.ata,
        ...form,
        mac_address: macAddress,
        phone_number: phoneNumber,
      };

      this.store.dispatch(
        AtaActions.updateAta({
          id: this.ata.id,
          compare: this.config.initialValues,
          ata: this.ata,
        })
      );
    }
  }

  onUpdateMac(mac: string) {
    this.editAta.patchValue({
      mac_address: this.macPipe.transform(mac),
    });
  }

  onCancel(): void {
    this.editAta.patchValue({
      ...this.config.initialValues,
      phone_number: this.config.initialValues.phone_number
        ? this.config.initialValues.phone_number.phone_number
        : null,
    });
    this.isEdit = false;
  }

  onHashPassword(password: string): string {
    return password.replace(/[a-zA-Z0-9]/g, '*');
  }

  onRefreshAta(): void {
    this.store.dispatch(AtaActions.loadAtaStatusDetails({ id: this.ata.id }));
  }

  onOpenAddFaxLines(): void {
    const dialogRef = this._modalService.openModal(null, 'center', {
      component: AddFaxLinePersonalComponent,
      title: 'Set Fax Line',
    });

    dialogRef.afterClosed().subscribe(({ is_hipaa, phone_number }) => {
      if (phone_number) {
        this.ata = {
          ...this.ata,
          phone_number: this.ata.phone_number
            ? {
                ...this.ata.phone_number,
                phone_number,
                is_hipaa,
              }
            : {
                phone_number,
                is_hipaa,
              },
        };
      }
    });
  }

  onAddUsers(): void {
    const dialogRef = this._modalService.openModal(null, 'center', {
      component: AddUsersComponent,
      title: 'Add Members',
    });

    dialogRef.afterClosed().subscribe(({ users }) => {
      if (users && users.length > 0) {
        const usersToAdd = users.filter(
          (u: User) => !this.ata.users.map((u) => u.id).includes(u.id)
        );

        this.ata.users = [...this.ata.users, ...usersToAdd];
      }
    });
  }

  onRemoveUser({ index }): void {
    const newAtaUsers = [...this.ata.users];
    newAtaUsers.splice(index, 1);
    this.ata = {
      ...this.ata,
      users: [...newAtaUsers],
    };
  }

  onDeactivateAta(): void {
    const { name, id } = this.ata;

    const dialogRef = this._modalService.openConfirmModal({
      modalType: 'danger',
      title: `Deactivate Ata`,
      altTitle: `Are you sure you want to deactivate [${name}] ?`,
      message: 'Once deactivated, this action cannot be undone.',
      confirmText: 'deactivate',
    });

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.store.dispatch(AtaActions.deactivateAta({ id }));
      }
    });
  }

  onDeleteAta(): void {
    const { id, name } = this.ata;

    const dialogRef = this._modalService.openConfirmModal({
      modalType: 'danger',
      title: `Delete Ata`,
      altTitle: `Are you sure you want to delete [${name}] ?`,
      message: 'Once deleted, this action cannot be undone.',
      confirmText: 'delete',
    });

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.store.dispatch(AtaActions.deleteAta({ id }));
      }
    });
  }

  onCopy(field: string): void {
    this.copied[field] = true;
    setTimeout(() => (this.copied[field] = false), 750);
  }
}
