import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from '@shared/services';
import { NumberService } from '@number/store/number.service';
import { PhoneNumber } from '@number/store/number.model';

import { User } from 'src/app/user/store/user.model';

@Component({
  selector: 'voyant-add-fax-line-personal',
  templateUrl: './add-fax-line-personal.component.html',
})
export class AddFaxLinePersonalComponent implements OnInit {
  faxLineForm: FormGroup;
  submitted = false;
  isSubmitting = false;

  constructor(
    private _numberService: NumberService,
    public dialogRef: MatDialogRef<AddFaxLinePersonalComponent>,
    private fb: FormBuilder,
    private _toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      user: User;
      numbers: PhoneNumber[];
    }
  ) {}

  get f() {
    return this.faxLineForm.controls;
  }

  ngOnInit() {
    this.buildForm();
    this.setFormValidators();
  }

  buildForm() {
    this.faxLineForm = this.fb.group({
      selectedNumber: ['', [Validators.required]],
      selectedPlan: [''],
      areaCode: [''],
      hipaa: ['', [Validators.required]],
      numberProvisioning: ['numberInventory'],
    });
  }

  setFormValidators() {
    this.f.numberProvisioning.valueChanges.subscribe((numberProvisioning) => {
      if (numberProvisioning === 'numberInventory') {
        this.f.selectedNumber.setValidators([Validators.required]);
        this.f.areaCode.setValidators(null);
      } else {
        this.f.selectedNumber.setValidators(null);
        this.f.areaCode.setValidators([Validators.required]);
      }

      this.f.selectedNumber.updateValueAndValidity();
      this.f.areaCode.updateValueAndValidity();
      this.f.hipaa.updateValueAndValidity();
    });
  }
  handleSuccess(num: {
    phone_number: string;
    is_hipaa: boolean;
    user_id: string;
  }) {
    this._toastrService.notify('success', 'Line successfully added.');
    this.dialogRef.close(num);
  }

  handleError({ error }) {
    this._toastrService.notify('error', error.message);
  }

  submitFaxLineForm() {
    this.submitted = true;
    if (this.faxLineForm.valid) {
      this.isSubmitting = true;
      const {
        numberProvisioning,
        selectedNumber,
        selectedPlan,
        areaCode,
        hipaa,
      } = this.faxLineForm.value;

      // assisign a selected number to the user
      if (numberProvisioning === 'numberInventory') {
        this.dialogRef.close({
          phone_number: selectedNumber,
          plan: selectedPlan,
          is_hipaa: hipaa === 'yes',
        });
      } else {
        // privison number and assing it to the user
        this._numberService
          .provisionNumber({
            area_code: areaCode,
            plan: selectedPlan,
            is_hipaa: hipaa === 'yes',
          })
          .subscribe((res: { data: PhoneNumber }) => {
            this.dialogRef.close(res.data);
          });
      }
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
