import { AdminLogComponent } from './admin-log/admin-log.component';
import { RoleGuard } from '../auth/role.guard';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'admin',
        canActivate: [RoleGuard],
        children: [{ path: 'log', component: AdminLogComponent }],
        data: {
          expectedRoles: ['admin', 'manager'],
        },
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
