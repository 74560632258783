import * as NumberActions from './number.actions';
import { PhoneNumber, PhoneNumberMetrics } from './number.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { createReducer, on } from '@ngrx/store';

import { Paging, pagingDefault } from '@shared/models';

export interface State extends EntityState<PhoneNumber> {
  loading: boolean;
  paging: Paging;
  numbersBuyLoading: boolean;
  numbersBought: PhoneNumber[];
  numbersFailed: number;
  selected: Map<string, PhoneNumber>;
  searchCache: PhoneNumber[];
  metrics: {
    loading: boolean;
    data: PhoneNumberMetrics;
  };
}

export interface ReadState extends State {
  items: PhoneNumber[];
}

export function selectPhoneId(a: PhoneNumber): string {
  return a.phone_number;
}

export const adapter: EntityAdapter<PhoneNumber> =
  createEntityAdapter<PhoneNumber>({
    selectId: selectPhoneId,
    sortComparer: false,
  });

export const initialState: State = adapter.getInitialState({
  loading: false,
  paging: pagingDefault,
  numbersBuyLoading: false,
  numbersBought: [],
  numbersFailed: 0,
  selected: new Map(),
  searchCache: [],
  metrics: {
    loading: false,
    data: {
      assigned_numbers: 0,
      current_overage: 0,
      local_numbers: 0,
      toll_free_numbers: 0,
    },
  },
});

export const numberReducer = createReducer(
  initialState,

  on(NumberActions.loadPhoneNumbers, (state) => {
    return { ...state, loading: true };
  }),

  on(NumberActions.setPhoneNumbers, (state, { paging, numbers }) => {
    return adapter.setAll(
      numbers.map((n) => ({
        ...n,
        selected: state.selected.has(n.phone_number),
      })),
      { ...state, paging, loading: false }
    );
  }),

  on(NumberActions.updateSearchCache, (state, { numbers }) => {
    return {
      ...state,
      searchCache: numbers,
    };
  }),

  on(NumberActions.addPhoneNumberSuccess, (state, { payload, gettingMany }) => {
    let newState = state;
    if (gettingMany) {
      newState = {
        ...state,
        numbersBought: [...state.numbersBought, payload],
      };
    }
    return adapter.addOne(payload, newState);
  }),

  on(NumberActions.addPhoneNumberFail, (state) => {
    return { ...state, numbersFailed: state.numbersFailed + 1 };
  }),

  on(NumberActions.provisionManyPhoneNumbers, (state) => {
    return { ...state, numbersBuyLoading: true };
  }),

  on(NumberActions.updatePhoneNumberSuccess, (state, { payload }) => {
    return adapter.updateOne(
      {
        id: payload.phone_number,
        changes: {
          ...payload,
        },
      },
      state
    );
  }),

  on(NumberActions.deleteSuccess, (state, { numbers }) => {
    return adapter.removeMany(numbers, { ...state, selected: new Map() });
  }),

  on(NumberActions.provisionManyPhoneNumbersFinished, (state) => {
    return { ...state, numbersBuyLoading: false };
  }),

  on(NumberActions.provisionManyPhoneNumbersDone, (state) => {
    return { ...state, numbersBought: [] };
  }),

  on(NumberActions.selectPhoneNumber, (state, { number }) => {
    state.selected.set(number.phone_number, number);
    return adapter.updateOne(
      {
        id: number.phone_number,
        changes: {
          selected: true,
        },
      },
      state
    );
  }),

  on(NumberActions.unselectPhoneNumber, (state, { number }) => {
    state.selected.delete(number.phone_number);
    return adapter.updateOne(
      {
        id: number.phone_number,
        changes: {
          selected: false,
        },
      },
      state
    );
  }),

  on(NumberActions.selectAllPhoneNumbers, (state) => {
    const updates = Object.values(state.entities).map((n) => {
      state.selected.set(n.phone_number, n);
      return {
        id: n.phone_number,
        changes: {
          selected: true,
        },
      };
    });

    return adapter.updateMany(updates, state);
  }),

  on(NumberActions.unselectAllPhoneNumbers, (state) => {
    const updates = Object.values(state.entities).map((n) => {
      state.selected.delete(n.phone_number);
      return {
        id: n.phone_number,
        changes: {
          selected: false,
        },
      };
    });

    return adapter.updateMany(updates, state);
  }),

  on(NumberActions.getNumbersMetrics, (state) => {
    return {
      ...state,
      metrics: {
        loading: true,
        data: {
          ...state.metrics.data,
        },
      },
    };
  }),
  on(NumberActions.getNumbersMetricsSuccess, (state, { data }) => {
    return {
      ...state,
      metrics: {
        loading: false,
        data,
      },
    };
  })
);
