import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  catchError,
} from 'rxjs/operators';
import { VarService } from '../var.service';
import {
  VarPhoneNumberResponse,
  VarPhoneNumber,
  VarAccount,
} from '../var.model';

interface Config {
  type: string;
  value: string;
  showRecent: boolean;
}

@Component({
  selector: 'voyant-var-omni-search',
  templateUrl: './var-omni-search.component.html',
  styleUrls: ['./var-omni-search.component.scss'],
})
export class VarOmniSearchComponent implements OnDestroy {
  @ViewChild('searchValue', { static: true }) searchValue;
  @Output() setCompany = new EventEmitter<VarAccount>();

  config: Config = {
    type: 'number',
    value: null,
    showRecent: false,
  };

  numbers$: Observable<VarPhoneNumber[]>;

  recentSearches: any[] = JSON.parse(localStorage.getItem('varRecentSearches'));

  selectOptions = ['number'];

  searchQueryChanged: Subject<string> = new Subject<string>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const list = event.target.classList.value;
    if (list && !list.includes('form__control')) {
      this.config.showRecent = false;
    }
  }

  constructor(private _varService: VarService) {
    this.searchQueryChanged
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((val) => {
        if (val && val.length) {
          switch (this.config.type) {
            case 'number':
              this.findPhoneNumber();
              break;
          }
        } else {
          this.numbers$ = of(null);
        }
      });

    if (!localStorage.getItem('varRecentSearches')) {
      localStorage.setItem('varRecentSearches', JSON.stringify([]));
    }
  }

  onClearConfig() {
    this.config.value = null;
    setTimeout(() => this.searchValue.nativeElement.focus(), 150);
  }

  onSearchChange(val) {
    this.searchQueryChanged.next(val);
  }

  onSetCompany(obj: { account; phone_number }) {
    if (this.recentSearches === null) {
      this.recentSearches = [];
    }
    const index = this.recentSearches
      .map((search) => search.phone_number)
      .indexOf(obj.phone_number);
    if (index === -1) {
      if (this.recentSearches.length < 5) {
        this.recentSearches = [...this.recentSearches, obj];
      } else {
        this.recentSearches = [...this.recentSearches.slice(1)];
        this.recentSearches = [...this.recentSearches, obj];
      }
    }
    this.setCompany.emit(obj.account);
    this.config.showRecent = false;
  }

  private findPhoneNumber() {
    this.numbers$ = this._varService.phoneNumberLookup(this.config.value).pipe(
      map((res: VarPhoneNumberResponse) => res.data),
      catchError(() => of([]))
    );
  }

  onSetShowRecent(val: boolean) {
    this.config = {
      ...this.config,
      showRecent: val,
    };
  }

  ngOnDestroy() {
    localStorage.setItem(
      'varRecentSearches',
      JSON.stringify(this.recentSearches)
    );
  }
}
