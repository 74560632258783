import {
  Component,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  SimpleChange,
} from '@angular/core';
import { Ata } from '@ata/store';

@Component({
  selector: 'voyant-ata-status',
  templateUrl: './ata-status.component.html',
  styleUrls: ['./ata-status.component.scss'],
})
export class AtaStatusComponent implements OnChanges {
  @Input() size: 'sm' | 'md' = 'sm';
  @Input() ata: Ata;
  @Input() details = false;
  @Output() refresh: EventEmitter<number> = new EventEmitter();

  isOnline = false;

  constructor() {}

  ngOnChanges(changes: { ata: SimpleChange; details: SimpleChange }) {
    if (changes.ata.currentValue) {
      const ata: Ata = changes.ata.currentValue;
      if (ata.expiry_time) {
        const exparation = new Date(ata.expiry_time);
        const today = new Date();
        this.isOnline = exparation.getTime() > today.getTime();
      }
    }
  }

  onRefresh() {
    this.refresh.emit();
  }
}
