import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedModule } from '../shared/shared.module';
import { GroupsTableComponent } from './groups-table/groups-table.component';

import { UserService } from '../user';
import { NumberService } from '../number';

import {
  EditGroupComponent,
  NewGroupComponent,
  GroupInfoComponent,
  ReviewCreateGroupComponent,
} from './modals';

@NgModule({
    declarations: [
        NewGroupComponent,
        GroupsTableComponent,
        GroupInfoComponent,
        ReviewCreateGroupComponent,
        EditGroupComponent,
    ],
    imports: [SharedModule, ReactiveFormsModule, MatTooltipModule],
    providers: [UserService, NumberService],
    exports: [NewGroupComponent, GroupsTableComponent]
})
export class GroupModule {}
