import { Component, OnInit } from '@angular/core';
import { SearchService, ModalService } from '@shared/services';
import { UniversalSearchControlls } from '@shared/models';
import { GroupService } from 'src/app/group';
import { NewGroupComponent } from 'src/app/group/modals';
import { NewUserComponent } from 'src/app/user/modals';
import { UserService, User } from 'src/app/user';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import { AtaNewComponent } from '@ata/modals/ata-new/ata-new.component';
import { ActivatedRoute, RouterStateSnapshot, Router } from '@angular/router';

@Component({
  selector: 'voyant-manage-all',
  templateUrl: './manage-all.component.html',
  styleUrls: ['./manage-all.component.scss'],
})
export class ManageAllComponent implements OnInit {
  activePage: string;
  loggedInUser: User;

  searchControls: UniversalSearchControlls = {
    search: true,
  };

  constructor(
    private _searchService: SearchService,
    private _modalService: ModalService,
    private _groupService: GroupService,
    private _userService: UserService,
    private router: Router,
    private store: Store<fromApp.AppState>
  ) {
    this.onSetActivePage(this.router.url.replace('/manage/', ''));
  }

  ngOnInit(): void {
    this.store.select('auth').subscribe((authState) => {
      if (authState.loggedInUser) {
        this.loggedInUser = { ...authState.loggedInUser };
      }
    });
  }

  onFilterResults(searchObj: any): void {
    this._searchService.term.next(searchObj);
  }

  openNewGroupModal(): void {
    const dialogRef = this._modalService.openModal(
      NewGroupComponent,
      'center',
      {
        title: 'Create New Group',
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._groupService.getGroups(null).subscribe();
      }
    });
  }

  openNewUserModal(): void {
    const dialogRef = this._modalService.openModal(NewUserComponent, 'center', {
      isStepper: true,
      title:
        'Create New ' +
        (this.loggedInUser.role === 'admin' ? 'Account' : 'User'),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._userService.getUsers(null).subscribe();
      }
    });
  }

  openNewAtaModal(): void {
    this._modalService.openModal(AtaNewComponent, 'center', {
      isStepper: true,
      title: 'Create New Device',
    });
  }

  onSetActivePage(pageName: string) {
    // this.activePage = pageName;
    // if (this.activePage === 'atas') {
    //   this.searchControls = {
    //     search: false,
    //   };
    // } else {
    //   this.searchControls = {
    //     search: true,
    //   };
    // }
  }
}
