import { Component, Input } from '@angular/core';
import { pagingDefault, Paging } from '@shared/models';
import { VarAccount } from '../../var.model';
import { DashPhonePipe } from '@shared/pipes/dash-phone/dash-phone.pipe';

interface BillingItem {
  account_id: number;
  created_at: string;
  description: string;
  event_type:
    | 'user_added'
    | 'user_removed'
    | 'phone_number_added'
    | 'phone_number_removed'
    | 'pages_sent'
    | 'pages_received';
  id: number;
  payload: {
    klass: string;
    id: number;
    number: string;
    caller_id?: string;
    recipient_numbers?: string;
    email?: string;
  };
  qty: number;
  updated_at: string;
}

@Component({
  selector: 'voyant-var-dashboard-billing-events',
  templateUrl: './var-dashboard-billing-events.component.html',
  styleUrls: ['./var-dashboard-billing-events.component.scss'],
})
export class VarDashboardBillingEventsComponent {
  invoices = [];
  @Input() accounts: VarAccount[] = [];
  @Input() isAllCompanies = false;

  @Input() billingData: {
    data: BillingItem[];
    paging: Paging;
  } = {
    data: [],
    paging: {
      ...pagingDefault,
      per_page: 6,
    },
  };

  constructor(private dashPhonePipe: DashPhonePipe) {}

  getCompanyName(accountId: number): string {
    if (this.accounts.length) {
      return (
        this.accounts.filter((acc: any) => acc.id === accountId)[0].name ||
        'N/A'
      );
    } else {
      return 'N/A';
    }
  }

  toText(pascalStr: string): string {
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    return pascalStr
      .split('_')
      .map((chunk) => capitalize(chunk))
      .join(' ');
  }

  getDescription(obj: BillingItem): string {
    let str = null;
    switch (obj.event_type) {
      case 'user_added':
        str = `User ${obj.payload.email} added`;
        break;
      case 'user_removed':
        str = `User ${obj.payload.email} removed`;
        break;
      case 'phone_number_added':
        str = `Phone number ${this.dashPhonePipe.transform(
          obj.payload.number
        )} added`;
        break;
      case 'phone_number_removed':
        str = `Phone number ${this.dashPhonePipe.transform(
          obj.payload.number
        )} removed`;
        break;
      case 'pages_received':
        str = `Fax ${obj.payload.id} Received`;
        break;
      case 'pages_sent':
        str = `Fax ${obj.payload.id} Sent`;
        break;
      default:
        str = 'N/A';
        break;
    }
    return str;
  }

  trackEvent(index: number, item: BillingItem): number {
    return item.id;
  }
}
