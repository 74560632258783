export enum UserActionTypes {
  LOAD_USERS = '[USER] Load Users',
  SET_USERS = '[USER] Set Users',

  LOAD_USERS_DETAILS = '[USER] Load Users Details',
  UPDATE_USERS_DETAILS = '[USER] Update Users Details',

  CREATE_USER = '[USER] Create User',
  CREATE_USER_SUCCESS = '[USER] Create User Success',

  ADD_USER = '[USER] Add User',
  SEND_INVITE_EMAIL = '[USER] Send Invite Email',

  UPDATE_USER = '[USER] Update User',
  UPDATE_EDIT_USER = '[USER] Update Edit User',
  UPDATE_USER_STATUS = '[USER] Update User Status',
  UPDATE_USER_SUCCESS = '[USER] Update User Success',

  DELETE_USER = '[USER] Delete User',
  DELETE_USER_SUCCESS = '[USER] Delete User Success',

  SET_SELECTED_USER = '[USER] Set Selected User',
  SET_EDIT = '[USER] Set Edit User',
  END_SELECTED_USER = '[USER] End Selected User',

  REPORT_ERROR = '[USER] Report Error',
  REPORT_SUCCESS = '[USER] Report Success',
}
