import { Component, OnInit, EventEmitter, Output, Input, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NewCompany } from '../new-company.models';

@Component({
  selector: 'voyant-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
  @Input() data: NewCompany;
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  @ViewChild('form', {static: true}) contactInfoRef: NgForm;

  contactsForm: FormGroup;
  isSubmitting = false;
  sameAsAdmin = true;

  constructor(
    private fb: FormBuilder
  ) {
  }

  get billingControls(){
    const billing: FormGroup = this.contactsForm.get('billing') as FormGroup;
    return billing.controls;
  }

  get adminControls(){
    const admin: FormGroup = this.contactsForm.get('admin') as FormGroup;
    return admin.controls;
  }

  ngOnInit(): void {
    const { admin , billing } = this.data;
    this.contactsForm = this.fb.group({
      admin: this.fb.group({
        firstName: [admin.firstName, [Validators.required]],
        lastName: [admin.firstName, [Validators.required]],
        email: [admin.email, [Validators.required, Validators.email]],
      }),
      billing: this.fb.group({
        firstName: [billing.firstName, [Validators.required]],
        lastName: [billing.firstName, [Validators.required]],
        email: [billing.email, [Validators.required, Validators.email]]
      })
    });
  }

  onNext(){
    if (this.sameAsAdmin){
        const { firstName, lastName, email } = this.contactsForm.get('admin').value;
        this.contactsForm.get('billing').patchValue({
          firstName,
          lastName,
          email
        });
    }
    if (this.contactInfoRef.valid){
      this.isSubmitting = true;
      this.next.emit(this.contactsForm.value);
    }
  }

  onBack(){
    this.back.emit(true);
  }

  onSetBiller(setBiller: boolean){
    if (setBiller){
      const { firstName, lastName, email } = this.contactsForm.get('admin').value;
      this.contactsForm.get('billing').patchValue({
        firstName,
        lastName,
        email
      });
    } else {
      this.contactsForm.get('billing').patchValue({
        firstName: null,
        lastName: null,
        email: null,
      });
    }
  }
}
