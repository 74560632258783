import { Component, OnInit } from '@angular/core';
import { User, UserService } from 'src/app/user';
import { Department } from '../../department.model';
import { DepartmentService } from '../../department.service';
import { NewDepartment } from './new-department.model';

@Component({
  selector: 'voyant-new-department',
  templateUrl: './new-department.component.html',
  styleUrls: ['./new-department.component.scss'],
})
export class NewDepartmentComponent implements OnInit {
  usersAssignedToDepartments: string[] = [];
  steps = [
    {
      label: 'Enter Department Info',
      complete: false,
      valid: false,
      active: true,
    },
    { label: 'Add Members', complete: false, valid: false, active: false },
    { label: 'Review', complete: false, valid: false, active: false },
  ];

  data: NewDepartment = {
    departmentInfo: {
      name: null,
      billing_name: null,
      billing_email: null,
      manager_ids: [],
    },
    user_ids: [],
  };

  constructor(
    private _departmentService: DepartmentService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    const users: User[] = Array.from(this._userService.fetchAll().values());
    const adminStaff = users
      .filter((user: User) => user.role !== 'user')
      .map((user: User) => user.id);
    const usersAlreadyAssigned: any = Array.from(
      this._departmentService.depMap.value.values()
    ).map((department: Department) =>
      department.users.map((user: User) => user.id)
    );

    this.usersAssignedToDepartments = [...adminStaff, ...usersAlreadyAssigned];
  }

  setActiveStep(stepObj: any) {
    this.steps.forEach((step) => (step.active = false));
    stepObj.active = true;
  }

  getActiveStep() {
    let index = 0;
    let activeStep = null;
    for (const step of this.steps) {
      if (step.active) {
        activeStep = step;
        break;
      }
      index++;
    }
    return { step: activeStep, index };
  }

  onNext(data: any) {
    this.data = Object.assign(this.data, data);
    const activeStepObj = this.getActiveStep();
    activeStepObj.step.active = false;
    if (activeStepObj.index !== this.steps.length) {
      const nextStepIndex = activeStepObj.index + 1;
      this.steps[nextStepIndex].active = true;
    }

    if (this.data.departmentInfo.manager_ids) {
      this.usersAssignedToDepartments = [
        ...this.usersAssignedToDepartments,
        ...this.data.departmentInfo.manager_ids,
      ];
    }
  }

  onAssignUsers(res: { users: User[] }) {
    this.onNext({ user_ids: res.users.map((user: User) => user.id) });
  }

  onBack() {
    const activeStepObj = this.getActiveStep();
    activeStepObj.step.active = false;
    if (activeStepObj.index !== 0) {
      const nextStepIndex = activeStepObj.index - 1;
      this.steps[nextStepIndex].active = true;
    }
  }
}
