import { RoleGuard } from '../auth/role.guard';
import { VarDashboardComponent } from './var-dashboard/var-dashboard.component';
import { VarProfileComponent } from './var-profile/var-profile.component';
import { VarSetGuard } from './var-set.guard';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        canActivate: [RoleGuard],
        children: [
          { path: 'dashboard', component: VarDashboardComponent },
          {
            path: 'profile',
            component: VarProfileComponent,
            canDeactivate: [VarSetGuard],
          },
        ],
        data: {
          expectedRoles: ['var_admin'],
        },
      },
    ]),
  ],
  exports: [RouterModule],
})
export class VarRoutingModule {}
