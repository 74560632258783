import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromOps from '@ops/store/ops.reducer';
import * as OpsActions from '@ops/store/ops.actions';

import { Observable } from 'rxjs';

@Component({
  selector: 'voyant-impersonation-popup',
  templateUrl: './impersonation-popup.component.html',
  styleUrls: ['./impersonation-popup.component.scss'],
})
export class ImpersonationPopupComponent implements OnInit {
  state$: Observable<fromOps.State>;

  constructor(private store: Store<fromOps.State>) {
    this.store.dispatch(OpsActions.checkImpersonation());
  }

  ngOnInit(): void {
    this.state$ = this.store.select(fromOps.getState);
  }

  unimpersonate() {
    this.store.dispatch(OpsActions.unimpersonate());
  }
}
