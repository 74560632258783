import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'voyant-ata-info',
  templateUrl: './ata-info.component.html',
  styleUrls: ['./ata-info.component.scss'],
})
export class AtaInfoComponent implements OnInit {
  @Input() data: {
    name: string;
    description: string;
  };
  @Output() next = new EventEmitter();
  ataInfo: FormGroup;

  constructor(private fb: FormBuilder) {
    this.ataInfo = this.fb.group({
      name: [null, [Validators.required]],
      description: [null, [Validators.required]],
    });
  }

  get a() {
    return this.ataInfo.controls;
  }

  ngOnInit(): void {
    this.ataInfo.patchValue(this.data);
  }

  onNext() {
    if (this.ataInfo.valid) {
      this.next.emit({ ...this.ataInfo.value });
    }
  }
}
