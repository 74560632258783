import { Component, OnInit, OnDestroy } from '@angular/core';
import { Port } from '../store/port.model';
import * as fromApp from '@app-store/app.reducer';
import * as fromPort from '../store/port.reducer';
import * as PortActions from '../store/port.actions';
import { Store } from '@ngrx/store';
import { SearchService, ModalService } from '@shared/services';
import {
  Paging,
  pagingDefault,
  SearchParams,
  UniversalSearchValues,
} from '@shared/models';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PortEditComponent } from '@port/modals/port-edit/port-edit.component';

@Component({
  selector: 'voyant-ports-table',
  templateUrl: './ports-table.component.html',
  styleUrls: ['./ports-table.component.scss'],
})
export class PortsTableComponent implements OnInit, OnDestroy {
  search: string;
  isSearch = false;

  state$: Observable<fromPort.State>;

  paginationConfig: Paging = pagingDefault;
  searchParams: SearchParams = this._searchService.initSearchParams('id');

  searchSubscription: Subscription;

  constructor(
    private _searchService: SearchService,
    private _modalService: ModalService,
    private store: Store<fromApp.AppState>
  ) {
    this.store.dispatch(PortActions.loadPortsMetrics());
  }

  ngOnInit(): void {
    this.searchSubscription = this._searchService.term.subscribe(
      (params: UniversalSearchValues) => {
        this.search = params.search;
        this.searchParams = { ...this.searchParams, page: 1 };

        if (params.search && params.search.length) {
          this.searchParams.search = params.search;
          this.isSearch = true;
        } else if (this.searchParams.hasOwnProperty('search')) {
          delete this.searchParams.search;
          this.isSearch = false;
        }
        this.store.dispatch(
          PortActions.loadPorts({ payload: this.searchParams })
        );
      }
    );

    this.state$ = this.store.select(fromPort.getState);
  }

  onPageChanged(paginationConfig) {
    this.searchParams = { ...this.searchParams, ...paginationConfig };
    delete this.searchParams.total;

    this.store.dispatch(PortActions.loadPorts({ payload: this.searchParams }));
  }

  onSortChanged(sortOn: string) {
    this.searchParams = this._searchService.updateSort(
      sortOn,
      this.searchParams
    );

    this.store.dispatch(PortActions.loadPorts({ payload: this.searchParams }));
  }

  onViewPort(port: Port) {
    const dialogRef = this._modalService.openModal(
      PortEditComponent,
      'right',
      port
    );

    dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(PortActions.endSelectedPort());
    });
  }

  identify(index, item: Port) {
    return item.id;
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
    this._searchService.term.next({
      search: null,
    });
  }
}
