import { Component, OnInit, Input } from '@angular/core';
import { Fax } from '@fax/store';

@Component({
  selector: 'voyant-fax-from',
  templateUrl: './fax-from.component.html',
  styleUrls: ['./fax-from.component.scss'],
})
export class FaxFromComponent implements OnInit {
  @Input() search: string;
  @Input() fax: Fax;
  @Input() position: string;
  constructor() {}

  ngOnInit(): void {}
  reformatPhoneNumber(phoneNum: string) {
    if (phoneNum) {
      const numsOnly = phoneNum.replace(/\D/g, '');

      if (numsOnly.length) {
        return `+1${numsOnly}`;
      }
    }

    return phoneNum;
  }
}
