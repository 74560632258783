import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable, Subject, concat, of } from 'rxjs';
import {
  debounceTime,
  switchMap,
  map,
  catchError,
  share,
  finalize,
} from 'rxjs/operators';
import { NumberService } from '@number/store/number.service';
import { PhoneNumber } from '@number/store/number.model';

@Component({
  selector: 'voyant-phone-number-select',
  templateUrl: './phone-number-select.component.html',
  styleUrls: ['./phone-number-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberSelectComponent),
      multi: true,
    },
  ],
})
export class PhoneNumberSelectComponent implements ControlValueAccessor {
  @Input() multiple = false;
  @Input() clearable = true;
  @Input() disabled = false;
  @Input() name = 'phoneNumberSelect';
  @Input() selectedNumbers: PhoneNumber[] = [];
  @Input() value: string = null;
  @Output() phoneNumber: EventEmitter<PhoneNumber> = new EventEmitter();

  numCache: PhoneNumber[];

  searchResults$: Observable<PhoneNumber[] | any[]>;
  searchInput$: Subject<string> = new Subject<string>();

  loading = false;

  constructor(private _numberService: NumberService) {
    this.searchResults$ = concat(
      of([]),
      this.searchInput$.pipe(
        debounceTime(400),
        switchMap((term) => {
          this.loading = true;
          if (term && term.length) {
            term = term.replace(/[-_,.!@#$%^&*()]/g, '');
            const params = {
              search: term,
              page: 1,
              per_page: 50,
            };

            return this._numberService.getNumbers(params).pipe(
              map((res) => {
                this.numCache = [...res.data];

                return res.data.filter((n) =>
                  this._numberService.numberIsAvailable(n)
                );
              }),
              catchError(() => of([])),
              finalize(() => (this.loading = false))
            );
          } else {
            this.loading = false;
            return of([]);
          }
        }),
        share()
      )
    );
  }

  onChange = (_) => {};
  onBlur = (_) => {};

  writeValue(val: string): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChanged($event) {
    this.phoneNumber.emit(this.numCache.find((n) => n.phone_number === $event));
    this.value = $event;
    this.onChange(this.value);
  }
}
