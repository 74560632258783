import { Group } from '@group/';
import { PhoneNumber } from '../../../number';

export class CreateUserData {
  userInfo: {
    email: string;
    first_name: string;
    last_name: string;
    role: string;
  };
  lines?: {
    numbers: PhoneNumber[];
    groups: Group[];
  };
}

export const newUserDefault = {
  userInfo: {
    email: null,
    first_name: null,
    last_name: null,
    role: null,
  },
  lines: {
    numbers: [],
    groups: [],
  },
};
