import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CreateUserData } from '../new-user.model';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as UserActions from '@user/store/user.actions';
import * as fromUser from '@user/store/user.reducer';
import { Observable } from 'rxjs';

@Component({
  selector: 'voyant-review-create-account',
  templateUrl: './review-create-account.component.html',
  styles: [
    `
      .modal__container {
        [class*='col-'] {
          padding-left: 24px;
          padding-right: 24px;
        }
      }
      @media screen and (min-width: 990px) {
        .br-1 {
          border-right: 1px solid #e6eaee;
        }
      }
    `,
  ],
})
export class ReviewCreateAccountComponent {
  @Input() stepperData: CreateUserData;
  @Output() back = new EventEmitter();
  state$: Observable<fromUser.State>;

  constructor(private store: Store<fromApp.AppState>) {
    this.state$ = this.store.select(fromUser.getState);
  }

  onCreateUser() {
    const { userInfo, lines } = this.stepperData;
    const { numbers, groups } = lines;

    this.store.dispatch(
      UserActions.createUser({
        payload: {
          ...userInfo,
          phone_numbers: numbers,
          groups: groups.map((group) => group.id),
        },
      })
    );
  }

  onBack() {
    this.back.emit('');
  }
}
