import { Component, OnInit } from '@angular/core';
import { SearchParams, Paging, pagingDefault } from '@shared/models';
import { SearchService } from '@shared/services';

@Component({
  selector: 'voyant-customer-account',
  templateUrl: './customer-account.component.html',
  styleUrls: ['./customer-account.component.scss'],
})
export class CustomerAccountComponent implements OnInit {
  invoices = [
    { id: 435703, date: new Date(), due: new Date(), status: 'outstanding' },
    { id: 435703, date: new Date(), due: new Date(), status: 'paid' },
    { id: 435703, date: new Date(), due: new Date(), status: 'paid' },
  ];

  paginationConfig: Paging = pagingDefault;
  searchParams: SearchParams = this._searchService.initSearchParams('name');

  constructor(private _searchService: SearchService) {}

  ngOnInit() {}

  onSortChanged(sortOn: string) {
    this.searchParams = this._searchService.updateSort(
      sortOn,
      this.searchParams
    );
  }
}
