import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { debounceTime } from 'rxjs/operators';
import {
  SearchParamsSortDir,
  UniversalSearchControlls,
  UniversalSearchValues,
} from '../../models';
import { SearchService } from '../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'voyant-universal-search',
  templateUrl: './universal-search.component.html',
  styleUrls: ['./universal-search.component.scss'],
})
export class UniversalSearchComponent implements OnDestroy {
  @Output() searchUpdated = new EventEmitter();
  params: FormGroup;

  @Input('controls') searchControls: UniversalSearchControlls = {
    sort_dir: false,
    sort_on: false,
    date: false,
    search: false,
  };

  datePickerOpen = false;

  controls: any = [];

  options = {
    sort: [
      { value: SearchParamsSortDir.ASC, label: 'Oldest to Newest' },
      { value: SearchParamsSortDir.DESC, label: 'Newest to Oldest' },
    ],
    filter: [
      { value: 'date', label: 'Date' },
      { value: 'name', label: 'Full Name' },
    ],
  };

  isActiveField = false;
  dateSelectorIsWired = false;
  sortingIsWierd = true;
  searchSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private _searchService: SearchService,
    private datePipe: DatePipe
  ) {
    this.params = this.fb.group({
      sort_dir: [SearchParamsSortDir.DESC, [Validators.required]],
      filter: [null, { validators: [Validators.required], updateOn: 'blur' }],
      search: [null, [Validators.required]],
      date: [null, [Validators.required]],
    });

    this.params.valueChanges.pipe(debounceTime(350)).subscribe((value: any) => {
      // do not emit any properties that were not chosen during init
      Object.keys(value).forEach((key) => {
        if (
          !this.searchControls.hasOwnProperty(key) ||
          !this.searchControls[key]
        ) {
          delete value[key];
        }
      });

      if (value.hasOwnProperty('search') && value.search !== null) {
        value.search = value.search.replace(/[^a-zA-Z0-9@ ]/g, '').trim();
      }

      this.searchUpdated.emit(value);
    });

    Object.keys(this.params.value).forEach((key) => {
      this.controls[key] = {
        active: false,
      };
    });

    // this.searchSubscription = this._searchService.term.subscribe(
    //   (v: UniversalSearchValues) => {
    //     this.params.patchValue({
    //       search: null,
    //     });
    //   }
    // );
  }

  onDatesSelected(dates: { fromDate: Date; toDate: Date }) {
    let dateStr = '';
    if (dates.fromDate) {
      dateStr = this.datePipe.transform(dates.fromDate, 'MM/dd/yyyy');
    }

    if (dates.toDate) {
      dateStr += '-' + this.datePipe.transform(dates.toDate, 'MM/dd/yyyy');
    }

    this.params.get('date').setValue(dateStr);
    this.datePickerOpen = false;
  }

  onDatePickerClickOutside(e) {
    if (this.datePickerOpen && !e.target.className.includes('date-picker')) {
      this.datePickerOpen = false;
    }
  }

  onToggleDatePicker() {
    setTimeout(() => {
      this.datePickerOpen = !this.datePickerOpen;
    }, 50);
    this.params.get('date').setValue(null);
  }

  ngOnDestroy() {
    // this.searchSubscription.unsubscribe();
  }
}
