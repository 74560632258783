import { SearchParams, PhaxioResponse } from '@shared/models';

export interface FaxSearchParams extends SearchParams {
  direction?: string;
  is_read?: boolean;
  admin_mode?: boolean;
  status?: string;
}

export enum FaxStatus {
  queued = 'queued',
  success = 'success',
  partialsuccess = 'partialsuccess',
  failure = 'failure',
  error = 'error',
}

export interface Fax {
  id: number;
  name: string;
  direction: string;
  status: FaxStatus;
  caller_id: string;
  caller_name: string;
  num_pages: number;
  created_at: string;
  user_id: string;
  group_id?: string;
  recipients: [
    {
      error_id: any;
      error_message: string;
      error_type: string;
      status: string;
      phone_number: string;
      name: string;
    }
  ];
  from?: string;
  to?: string;
  is_read?: boolean;
  re_sent?: boolean;
}

export interface FaxResponse extends PhaxioResponse {
  data: Fax[];
}
