import { Injectable, Inject } from '@angular/core';
import { VarService } from 'src/app/var/var.service';
import { map, catchError } from 'rxjs/operators';
import { PhaxioResponse } from '../models';
import { AuthService } from '@auth/auth.service';
import { Title } from '@angular/platform-browser';
import { UtilityService } from './utility.service';
import { DOCUMENT } from '@angular/common';
import { of } from 'rxjs';
import { hexToCSSFilter } from 'hex-to-css-filter';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private _varService: VarService,
    private _authService: AuthService,
    private _utilityService: UtilityService,
    private title: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._varService.varProfile.subscribe(
      (profile) => this.setVarGlobals(profile),
      (errRes) => console.log('init', errRes)
    );
  }

  init(): Promise<any> {
    if (window.location.hostname.includes('voyant')) {
      window.location.href = window.location.href.replace(
        'voyant',
        'inteliquent'
      );
      return new Promise((resolve) => resolve(true));
    }
    
    if (this._authService.isVar) {
      return this._varService
        .getPublicProfile(window.location.host)
        .pipe(
          map((res: PhaxioResponse) => {
            if (res.success) {
              this.setVarGlobals(res.data);
            }
            return res;
          }),
          catchError((errRes) => of(errRes.error))
        )
        .toPromise();
    } else {
      this.title.setTitle('Inteliquent Fax');
      return new Promise((resolve) => resolve(true));
    }
  }

  setVarGlobals(profile): void {
    if (profile && this._authService.isVar) {
      this.title.setTitle(profile.name);
      const hexToRgb = this._utilityService.hexToRgb;
      const { primary_color, header_background_color } = profile;

      if (primary_color && header_background_color) {
        const primaryDarken = this._utilityService.shadeColor(
          primary_color,
          -20
        );
        const cssFilter = hexToCSSFilter(primary_color).filter;

        const bodyStyle = this.document.querySelector('body').style;

        bodyStyle.setProperty(
          '--brand-primary',
          `rgb(${hexToRgb(primary_color)})`
        );
        bodyStyle.setProperty(
          '--brand-primary-darken',
          `rgb(${hexToRgb(primaryDarken)})`
        );
        bodyStyle.setProperty(
          '--brand-primary-lighten',
          `rgba(${hexToRgb(primary_color)}, 0.1)`
        );
        bodyStyle.setProperty(
          '--brand-secondary',
          `rgb(${hexToRgb(primary_color)})`
        );
        bodyStyle.setProperty(
          '--brand-base',
          `rgb(${hexToRgb(header_background_color)})`
        );
        bodyStyle.setProperty('--brand-filter', cssFilter.replace(';', ''));
      }

      if (profile.logo_url) {
        document
          .getElementById('appIcon')
          .setAttribute('href', profile.logo_url);
      }
    }
  }
}
