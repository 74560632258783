import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'voyant-fax-to',
  templateUrl: './fax-to.component.html',
  styleUrls: ['./fax-to.component.scss'],
})
export class FaxToComponent implements OnInit {
  @Input() search: string;
  @Input() recipients: { status: string; phone_number: string; name: string }[];
  @Input() position: string;
  constructor() {}

  ngOnInit(): void {}
}
