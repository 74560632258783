import { Component, OnInit } from '@angular/core';
import { Fax, FaxService, FaxSearchParams, FaxResponse } from '@fax/store';
import { UserService } from '../../user/store/user.service';
import { User } from '../../user/store/user.model';
import { GroupService, Group } from '../../group';
import { PusherService, SearchService, UtilityService } from '@shared/services';
import {
  pagingDefault,
  Paging,
  UniversalSearchControlls,
  UniversalSearchValues,
  SearchParamsSortDir,
} from '@shared/models';
import { debounceTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as fromGroup from '../../group/store/group.reducer';
import * as fromUser from '../../user/store/user.reducer';

@Component({
  selector: 'voyant-fax-log',
  templateUrl: './fax-log.component.html',
  styleUrls: ['./fax-log.component.scss'],
})
export class FaxLogComponent implements OnInit {
  loading = true;
  faxes: any[];
  users: User[];
  groups: Group[];
  data: {
    icon: string;
    label: string;
    value: number | string;
  }[];

  searchControls: UniversalSearchControlls = {
    search: true,
    date: true,
  };

  search = {
    text: null,
  };

  paginationConfig: Paging = pagingDefault;

  searchParams: FaxSearchParams = {
    direction: null,
    is_read: null,
    admin_mode: true,
    ...this._searchService.initSearchParams('created_at'),
  };

  searchFields = ['name', 'id'];

  constructor(
    private _faxService: FaxService,
    private _userService: UserService,
    private _groupsService: GroupService,
    private _pusherService: PusherService,
    private _searchService: SearchService,
    private _utilityService: UtilityService,
    private store: Store<fromApp.AppState>
  ) {
    this.data = [
      { icon: 'person-team-default', label: 'Active Users', value: null },
      { icon: 'number', label: 'Active Groups', value: null },
      { icon: 'money', label: 'Amount Spent', value: '--' },
    ];
  }

  ngOnInit() {
    this.store.select(fromUser.selectAll).subscribe((userArr: User[]) => {
      this.data[0] = {
        ...this.data[0],
        value: userArr.filter((u: User) => u.status === 'active').length,
      };
    });

    this.store.select(fromGroup.selectTotal).subscribe((t: number) => {
      this.data[1] = {
        ...this.data[1],
        value: t,
      };
    });

    this.searchParams.sort_dir = SearchParamsSortDir.DESC;

    this._faxService.getFaxes(this.searchParams).subscribe(
      (faxRes: FaxResponse) => {
        this.faxes = faxRes.data;
        this.paginationConfig = { ...faxRes.paging };
      },
      (errRes) => (this.faxes = []),
      () => (this.loading = false)
    );

    this.searchParams.sort_dir = SearchParamsSortDir.DESC;

    this._faxService.getFaxes(this.searchParams).subscribe(
      (faxRes: FaxResponse) => {
        this.faxes = faxRes.data;
        this.paginationConfig = { ...faxRes.paging };
      },
      (errRes) => (this.faxes = []),
      () => (this.loading = false)
    );

    this._pusherService.channel.bind('update', (data: Fax) => {
      if (data && typeof data === 'object' && data.hasOwnProperty('id')) {
        data.created_at = this._utilityService.utcToIsosDate(data.created_at);
        const index = this.findFaxIndex(data.id, this.faxes);
        if (index !== null) {
          this.faxes[index] = { ...this.faxes[index], ...data };
        }
      }
    });
  }

  findFaxIndex(id: number, arr: any[]) {
    let found = null;
    let index = 0;
    if (typeof arr === 'object' && arr.length > 0) {
      for (const fax of arr) {
        if (fax.id === id) {
          found = true;
          break;
        }
        index++;
      }
    }
    return found ? index : null;
  }

  updateResults(paginationConfig) {
    // this.loading = true;
    this.searchParams = { ...this.searchParams, ...paginationConfig };
    this._faxService.getFaxes(this.searchParams).subscribe(
      (res: FaxResponse) => {
        this.faxes = res.data;
        this.paginationConfig = { ...res.paging };
      },
      (errRes) => (this.faxes = []),
      () => (this.loading = false)
    );
  }

  onFilterResults(params: UniversalSearchValues) {
    // this.loading = true;
    this.searchParams = { ...this.searchParams, ...params, page: 1 };
    this.searchParams = this._searchService.sanitizeParams(this.searchParams);

    this._faxService
      .getFaxes(this.searchParams)
      .pipe(debounceTime(350))
      .subscribe(
        (res: FaxResponse) => {
          this.faxes = res.data;
          this.paginationConfig = { ...res.paging };
        },
        (errRes) => (this.faxes = []),
        () => (this.loading = false)
      );
  }

  onSortChanged(sortOn: string) {
    this.loading = true;
    this.searchParams = this._searchService.updateSort(
      sortOn,
      this.searchParams
    );

    this._faxService.getFaxes(this.searchParams).subscribe(
      (res: FaxResponse) => {
        this.faxes = res.data;
        this.paginationConfig = { ...res.paging };
      },
      (errRes) => (this.faxes = []),
      () => (this.loading = false)
    );
  }

  getUserName(userId: string) {
    return this._userService.getUserName(userId);
  }

  getGroupName(groupId: string) {
    return this._groupsService.getGroupName(groupId);
  }
}
