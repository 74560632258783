import { Component, OnInit } from '@angular/core';
import { User } from '../../../user';

@Component({
  selector: 'voyant-new-group',
  templateUrl: './new-group.component.html',
  styles: [
    `
      section {
        min-height: 341px;
        > * {
          display: block;
        }
      }
    `,
  ],
})
export class NewGroupComponent implements OnInit {
  data: {
    users: User[];
    groupInfo: any;
    numberFormConfig: any;
  };

  steps = [
    { label: 'Enter Group Info', complete: false, valid: false, active: true },
    { label: 'Add Members', complete: false, valid: false, active: false },
    { label: 'Review', complete: false, valid: false, active: false },
  ];

  constructor() {
    this.data = {
      users: [],
      groupInfo: {
        name: '',
        phoneNumber: '',
      },
      numberFormConfig: {
        selectedNumber: '',
        selectedPlan: '',
        areaCode: '',
        hipaa: '',
        numberProvisioning: 'numberInventory',
      },
    };
  }

  ngOnInit() {}

  setActiveStep(stepObj: any) {
    this.steps.forEach((step) => (step.active = false));
    stepObj.active = true;
  }

  getActiveStep() {
    let index = 0;
    let activeStep = null;
    for (const step of this.steps) {
      if (step.active) {
        activeStep = step;
        break;
      }
      index++;
    }
    return { step: activeStep, index };
  }

  onNext(data: any) {
    this.data = { ...this.data, ...data };
    const activeStepObj = this.getActiveStep();
    activeStepObj.step.active = false;
    if (activeStepObj.index !== this.steps.length) {
      const nextStepIndex = activeStepObj.index + 1;
      this.steps[nextStepIndex].active = true;
    }
  }

  onBack(data?: any) {
    if (data) {
      this.data = { ...this.data, ...data };
    }
    const activeStepObj = this.getActiveStep();
    activeStepObj.step.active = false;
    if (activeStepObj.index !== 0) {
      const nextStepIndex = activeStepObj.index - 1;
      this.steps[nextStepIndex].active = true;
    }
  }
}
