import { Component, Input } from '@angular/core';

@Component({
  selector: 'voyant-has-error',
  templateUrl: './has-error.component.html',
  styleUrls: ['./has-error.component.scss'],
})
export class HasErrorComponent {
  @Input() value: any;
  @Input() submitted: boolean;

  constructor() {}

  fieldsToString(fields: string[]) {
    const capitalize = (s): string => s.charAt(0).toUpperCase() + s.slice(1);
    const f = fields.map((s) =>
      capitalize(s)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    );
    return f.join(' & ');
  }

  onHasError(val: any) {
    if (val) {
      if (this.submitted) {
        return val.errors && Object.keys(val.errors).length > 0;
      } else {
        return val.errors && (val.touched || val.dirty);
      }
    }
  }
}
