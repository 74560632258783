import { Component, OnInit } from '@angular/core';
import { AdminService } from '@admin/store/admin.service';
import { AuthService } from '@auth/auth.service';
import {
  Paging,
  pagingDefault,
  UniversalSearchControlls,
  UniversalSearchValues,
  SearchParamsSortDir,
  SearchParams,
} from '@shared/models';

import { OpsUser, OpsResponse } from '@ops/store/ops.model';
import { SearchService, ModalService } from '@shared/services';
import { OpsService } from '@ops/store/ops.service';
import { UploadUsersCsvComponent } from '../modals/upload-users-csv/upload-users-csv.component';
import { debounceTime } from 'rxjs/operators';

import * as fromOps from '@ops/store/ops.reducer';
import * as OpsActions from '@ops/store/ops.actions';
import { Store } from '@ngrx/store';

interface Config {
  loading: boolean;
  searchFields: string[];
  accounts: OpsUser[];
  counts: { total: number; label: string }[];
  searchControls: UniversalSearchControlls;
  paginationConfig: Paging;
  searchParams: SearchParams;
  file: File;
}

@Component({
  selector: 'voyant-ops-account-management',
  templateUrl: './ops-account-management.component.html',
  styleUrls: ['./ops-account-management.component.scss'],
})
export class OpsAccountManagementComponent implements OnInit {
  config: Config = {
    loading: false,
    searchFields: ['full_name'],
    accounts: [],
    paginationConfig: pagingDefault,
    searchControls: {
      search: true,
    },
    searchParams: this._searchService.initSearchParams('first_name'),
    file: null,
    counts: [
      {
        total: 10,
        label: 'Accounts',
      },
      {
        total: 10,
        label: 'Organizations',
      },
      {
        total: 10,
        label: 'Fax Numbers',
      },
    ],
  };

  constructor(
    private _adminService: AdminService,
    private _authService: AuthService,
    private _impersonateService: OpsService,
    private _searchService: SearchService,
    private _modalService: ModalService,
    private store: Store<fromOps.State>
  ) {}

  ngOnInit(): void {
    const { searchParams } = this.config;
    this._adminService
      .getOpsUsers(searchParams)
      .subscribe(this.handleAccountsData());
  }

  impersonateUser(user: any): void {
    this.store.dispatch(OpsActions.impersonate({ userId: user.id }));
  }

  onPageChanged(paginationConfig) {
    this.config = {
      ...this.config,
      loading: true,
      searchParams: { ...this.config.searchParams, ...paginationConfig },
    };

    delete this.config.searchParams.total;

    this._adminService
      .getOpsUsers(this.config.searchParams)
      .subscribe(this.handleAccountsData());
  }

  onFilterResults(params: UniversalSearchValues) {
    this.config = {
      ...this.config,
      loading: true,
      searchParams: this._searchService.sanitizeParams({
        ...this.config.searchParams,
        ...params,
        page: 1,
        sort_dir: SearchParamsSortDir.DESC,
        sort_on: 'first_name',
      }),
    };

    this._adminService
      .getOpsUsers(this.config.searchParams)
      .pipe(debounceTime(350))
      .subscribe(this.handleAccountsData());
  }

  onSortChanged(sortOn: string) {
    this.config = {
      ...this.config,
      loading: true,
      searchParams: this._searchService.updateSort(
        sortOn,
        this.config.searchParams
      ),
    };

    this._adminService
      .getOpsUsers(this.config.searchParams)
      .subscribe(this.handleAccountsData());
  }

  onOpenUploadCsvModal() {
    this._modalService.openModal(null, 'center', {
      title: 'Upload Csv',
      component: UploadUsersCsvComponent,
    });
  }

  private handleAccountsData() {
    return {
      next: (res: OpsResponse) => {
        this.config = {
          ...this.config,
          accounts: res.data,
          paginationConfig: res.paging,
        };
      },
      error: () => {
        this.config = {
          ...this.config,
          accounts: [],
        };
      },
      complete: () => {
        this.config = {
          ...this.config,
          loading: false,
        };
      },
    };
  }
}
