import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'tableDate',
})
export class TableDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: string, ...args: unknown[]): unknown {
    if (value) {
      const date = this.datePipe.transform(value, 'MM/d/yy');
      const time = this.datePipe.transform(value, 'shortTime');
      return `${date} at ${time}`;
    } else {
      return ' ';
    }
  }
}
