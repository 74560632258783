import { Component, Input } from '@angular/core';

@Component({
  selector: 'voyant-copy-string',
  styles: [
    `
      .deactivated {
        opacity: 0.5;
      }
    `,
  ],
  templateUrl: './copy-string.component.html',
})
export class CopyStringComponent {
  @Input() value: string;
  @Input() copied: boolean;
  @Input() masked: boolean = false;
  @Input() deactivated: boolean = false;

  onMaskString(str: string): string {
    return str.replace(/[a-zA-Z0-9]/g, '*');
  }
}
