import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'voyant-no-data-table',
  templateUrl: './no-data-table.component.html',
  styleUrls: ['./no-data-table.component.scss'],
})
export class NoDataTableComponent implements OnInit {
  @Input() title: string = 'No data to display';
  @Input() message: string = null;
  constructor() {}

  ngOnInit(): void {}
}
