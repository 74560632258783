import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'voyant-compliance',
  templateUrl: './compliance.component.html',
})
export class ComplianceComponent implements OnInit, OnChanges {
  @Input() isHipaa: boolean;
  config = {
    icon: null,
    label: null,
  };

  constructor() {}

  ngOnInit(): void {
    this.config = { ...this.updateConfig(this.isHipaa) };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.isHipaa.currentValue !== undefined) {
      this.config = { ...this.updateConfig(changes.isHipaa.currentValue) };
    }
  }

  private updateConfig(val: boolean) {
    return val
      ? { icon: 'lock', label: 'Compliant' }
      : { icon: null, label: null };
  }
}
