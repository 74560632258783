import { ActionReducerMap } from '@ngrx/store';
import * as fromAuth from '@auth/store/auth.reducer';
import * as fromOps from '@ops/store/ops.reducer';
import * as fromUser from '@user/store/user.reducer';
import * as fromPort from '@port/store/port.reducer';
import * as fromAta from '@ata/store/ata.reducer';
import * as fromNumber from '@number/store/number.reducer';
import * as fromGroup from '@group/store/group.reducer';
import * as fromFax from '@fax/store/fax.reducer';
import * as fromAdmin from '@admin/store/admin.reducer';

export interface AppState {
  auth: fromAuth.State;
  admin: fromAdmin.State;
  ops: fromOps.State;
  user: fromUser.State;
  port: fromPort.State;
  ata: fromAta.State;
  number: fromNumber.State;
  group: fromGroup.State;
  fax: fromFax.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  admin: fromAdmin.adminReducer,
  ops: fromOps.opsReducer,
  user: fromUser.userReducer,
  port: fromPort.portReducer,
  ata: fromAta.ataReducer,
  number: fromNumber.numberReducer,
  group: fromGroup.groupReducer,
  fax: fromFax.faxReducer,
};
