import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
} from '@angular/core';
import { VarPhoneNumber, VarAccount } from 'src/app/var/var.model';
interface SearchResult {
  account: VarAccount;
  phone_number: string;
}
@Component({
  selector: 'voyant-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
})
export class NumberComponent {
  @Output() selectedCompany = new EventEmitter<SearchResult>();
  @Input() numbers: VarPhoneNumber[];
  @Input() recentSearches: SearchResult[];
  @Input() showRecent: boolean;

  constructor() {}

  onSelectNumber(num: VarPhoneNumber) {
    const { account, phone_number } = num;
    this.selectedCompany.emit({
      account,
      phone_number,
    });
    this.numbers = null;
  }
}
