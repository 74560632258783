import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PhoneNumber } from '@number/store/number.model';
import { NumberService } from '@number/store/number.service';
import { UserService } from '@user/store/user.service';
import { GroupService } from '@group/store/group.service';
import { AtaService } from '@ata/store/ata.service';
import { Observable, of } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

type IdTypes = 'user_id' | 'group_id' | 'ata_id';

@Component({
  selector: 'voyant-number-assignee',
  templateUrl: './number-assignee.component.html',
})
export class NumberAssigneeComponent implements OnInit {
  private icons = ['person-default', 'person-team-default', 'fax-default'];

  @Input() number: PhoneNumber;
  @Output() assigned: EventEmitter<{
    type: IdTypes;
    name: string;
  }> = new EventEmitter();

  config$: Observable<{ icon: string; name: string }>;

  constructor(
    private _numberService: NumberService,
    private _userService: UserService,
    private _groupService: GroupService,
    private _ataService: AtaService
  ) {}

  ngOnInit(): void {
    this.setUp();
  }

  private setUp() {
    if (this._numberService.numberIsAvailable(this.number)) {
      this.config$ = of({
        icon: null,
        name: null,
      });
      return;
    } else {
      const activeProperty = this.getSetProperty();
      this.setConfig(activeProperty);
    }
  }

  private setConfig(type: IdTypes): void {
    switch (type) {
      case 'user_id':
        this.config$ = this._userService.getUser(this.number.user_id).pipe(
          take(1),
          shareReplay(1),
          map((u) => {
            const name = u.first_name + ' ' + u.last_name;
            this.assigned.emit({ type, name });
            return {
              icon: this.icons[0],
              name,
            };
          })
        );
        break;
      case 'group_id':
        this.config$ = this._groupService.getGroup(this.number.group_id).pipe(
          take(1),
          shareReplay(1),
          map((res: any) => {
            this.assigned.emit({ type, name: res.data.name });
            return {
              icon: this.icons[1],
              name: res.data.name,
            };
          })
        );
        break;
      case 'ata_id':
        this.config$ = this._ataService.getAta(this.number.ata_id).pipe(
          take(1),
          shareReplay(1),
          map((res: any) => {
            this.assigned.emit({ type, name: res.data.name });
            return {
              icon: this.icons[2],
              name: res.data.name,
            };
          })
        );
        break;
    }
  }

  private getSetProperty(): IdTypes | null {
    let field = null;
    const fields = ['user_id', 'group_id', 'ata_id'];
    for (let prop of fields) {
      if (this.number.hasOwnProperty(prop) && this.number[prop]) {
        field = prop;
        break;
      }
    }
    return field;
  }
}
