import { Component, OnInit } from '@angular/core';
import { NewCompany } from './new-company.models';
@Component({
  selector: 'voyant-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.scss'],
})
export class NewCompanyComponent implements OnInit {
  data: NewCompany = {
    street_address: null,
    street_address_2: null,
    city: null,
    name: null,
    state: null,
    zip: null,
    admin: {
      firstName: null,
      lastName: null,
      email: null,
    },
    billing: {
      firstName: null,
      lastName: null,
      email: null,
    },
  };

  steps = [
    {
      label: 'Enter Company Info',
      complete: false,
      valid: false,
      active: true,
    },
    { label: 'Add Contact Info', complete: false, valid: false, active: false },
    { label: 'Review', complete: false, valid: false, active: false },
  ];

  constructor() {}

  ngOnInit(): void {}

  setActiveStep(stepObj: any) {
    this.steps.forEach((step) => (step.active = false));
    stepObj.active = true;
  }

  getActiveStep() {
    let index = 0;
    let activeStep = null;
    for (const step of this.steps) {
      if (step.active) {
        activeStep = step;
        break;
      }
      index++;
    }
    return { step: activeStep, index };
  }

  onNext(data: any) {
    this.data = Object.assign(this.data, data);
    const activeStepObj = this.getActiveStep();
    activeStepObj.step.active = false;
    if (activeStepObj.index !== this.steps.length) {
      const nextStepIndex = activeStepObj.index + 1;
      this.steps[nextStepIndex].active = true;
    }
  }

  onBack() {
    const activeStepObj = this.getActiveStep();
    activeStepObj.step.active = false;
    if (activeStepObj.index !== 0) {
      const nextStepIndex = activeStepObj.index - 1;
      this.steps[nextStepIndex].active = true;
    }
  }
}
