import { Component, Input } from '@angular/core';

@Component({
  selector: 'voyant-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent {
  @Input() type = 'button';
  @Input() btnClass = 'btn__primary';
  @Input() btnText = 'submit';
  @Input() isSubmitting = false;

  constructor(){
  }
}
