import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap, take } from 'rxjs/operators';
import { ToastrService } from '../../shared/services';
import { PhaxioResponse } from '@shared/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from './must-match';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'voyant-auth-activate',
  templateUrl: './auth-activate.component.html',
  styleUrls: ['./auth-activate.component.scss'],
})
export class AuthActivateComponent implements OnInit {
  config = {
    isReady: false,
    isSsoUser: false,
    loginUrl: null,
  };

  params = {
    id: null,
    activation_token: null,
    email: null,
  };

  setPassword: FormGroup = this.fb.group(
    {
      password: [null, [Validators.required, Validators.minLength(8)]],
      confirm: [null, [Validators.required]],
    },
    { validator: MustMatch('password', 'confirm') }
  );

  constructor(
    private _authService: AuthService,
    private _toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  get p() {
    return this.setPassword.controls;
  }

  ngOnInit() {
    const queryParams = this.activatedRoute.snapshot.queryParamMap;

    Object.keys(this.params).forEach((key: string) => {
      if (queryParams.get(key)) {
        this.params[key] = queryParams.get(key).replace(' ', '+');
      }
    });

    if (this.params.email) {
      this._authService
        .checkSSO(this.params.email)
        .pipe(take(1))
        .subscribe((res: PhaxioResponse) => {
          this.config = {
            isReady: true,
            isSsoUser: res.success,
            loginUrl: res.data,
          };
        });
    } else {
      this.config = {
        ...this.config,
        isReady: true,
      };
    }
  }

  onActivateUser() {
    const { value, valid } = this.setPassword;
    const { isSsoUser, loginUrl } = this.config;
    const { password } = value;
    if (valid || isSsoUser) {
      const { activation_token, id, email } = this.params;

      const reqObj: any = {
        activation_token,
      };

      if (!isSsoUser) {
        reqObj.password = password;
      }

      this._authService
        .activateUser(id, reqObj)
        .pipe(
          switchMap((res: any) => {
            if (password) {
              return this._authService.login(email, password);
            } else if (isSsoUser && loginUrl) {
              res.message = 'Activation successful. Redirecting to login page';
              setTimeout(() => {
                window.location.href = loginUrl;
              }, 1000);
            }
            return res;
          })
        )
        .subscribe(
          (res: any) => {
            if (res.hasOwnProperty('data')) {
              this._authService.userRoleRedirect(res.data);
            }
          },
          (errorRes: HttpErrorResponse) =>
            this._toastrService.setErrorFlash(errorRes.error)
        );
    }
  }
}
