import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'voyant-table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.scss'],
})
export class TableLoaderComponent implements OnInit {
  rows = Array(10).fill('row');
  constructor() {}

  ngOnInit(): void {}
}
