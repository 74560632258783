import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@shared/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OpsService {
  private API_URL = this.env.apiUrls.faxApi;
  private IMPERSONATE_URL = `${this.API_URL}users/`;

  constructor(private http: HttpClient, private env: EnvironmentService) {}

  impersonateUser(userId: string): Observable<any> {
    const url = `${this.IMPERSONATE_URL}${userId}/impersonate`;
    return this.http.post(url, {}).pipe(map((res: any) => res));
  }

  unimpersonate(): Observable<any> {
    const url = `${this.IMPERSONATE_URL}unimpersonate`;
    return this.http.post(url, {}).pipe(map((res: any) => res));
  }

  checkImpersonation(): Observable<any> {
    const url = `${this.IMPERSONATE_URL}impersonation_status`;
    return this.http.get(url).pipe(map((res: any) => res));
  }
}
