import { AtaEffects } from '@ata/store/ata.effects';
import { FaxEffects } from '@fax/store/fax.effects';
import { PortEffects } from '@port/store/port.effects';
import { NumberEffects } from '@number/store/number.effects';
import { GroupEffects } from '@group/store/group.effects';
import { UserEffects } from '@user/store/user.effects';
import { AdminEffects } from '@admin/store/admin.effects';
import { AuthEffects } from '@auth/store/auth.effects';
import { OpsEffects } from '@ops/store/ops.effects';

export const AppEffects: any = [
  AtaEffects,
  AdminEffects,
  FaxEffects,
  PortEffects,
  NumberEffects,
  GroupEffects,
  UserEffects,
  AuthEffects,
  OpsEffects,
];
