export interface Paging {
  page: number;
  per_page: number;
  total?: number;
}

export const pagingDefault: Paging = {
  page: 1,
  per_page: 10,
  total: 0,
};
