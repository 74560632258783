import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { take, map, debounceTime, shareReplay } from 'rxjs/operators';

import { pagingDefault, PhaxioResponse } from '@shared/models';

import * as fromApp from '@app-store/app.reducer';

import { PhoneNumber } from '@number/store/number.model';
import * as NumberActions from '@number/store/number.actions';

import { UtilityService, EnvironmentService } from '@shared/services';
import { Dictionary } from '@ngrx/entity';
import * as fromUser from '@user/store/user.reducer';
import * as UserActions from '@user/store/user.actions';
import {
  User,
  UserResponse,
  SingleUserResponse,
  NewUser,
  UserStatus,
} from '@user/store/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private USERS_URL: string = this.env.apiUrls.faxApi + 'users';

  constructor(
    private env: EnvironmentService,
    private http: HttpClient,
    private _utilityService: UtilityService,
    private store: Store<fromApp.AppState>
  ) {}

  fetchAll(): User[] {
    let users: User[];
    this.store
      .select(fromUser.selectAll)
      .pipe(take(1))
      .subscribe((s) => (users = s));
    return users;
  }

  getMap(): Dictionary<User> {
    let users: Dictionary<User>;
    this.store
      .select(fromUser.selectEntities)
      .pipe(take(1))
      .subscribe((s) => (users = s));
    return users;
  }

  getUsers(urlParams?: any): Observable<UserResponse> {
    const url = this.USERS_URL;
    let params: any = { with_inactive: 'true' };

    params =
      urlParams && Object.keys(urlParams).length > 0
        ? { ...params, ...urlParams }
        : { ...params, ...pagingDefault };

    return this.http
      .get(url, {
        params,
      })
      .pipe(
        debounceTime(350),
        take(1),
        map((res: UserResponse) => {
          res.data.map((user: User) => {
            user.created_at = this._utilityService.utcToIsosDate(
              user.created_at
            );
            return user;
          });

          return res;
        })
      );
  }

  getUser(userId: string): Observable<User> {
    const url = `${this.USERS_URL}/${userId}`;
    return this.http.get(url).pipe(
      map((res: SingleUserResponse) => {
        this.store.dispatch(UserActions.addUser({ payload: res.data }));
        return res.data;
      })
    );
  }

  getUserName(userId: string): string {
    const users = this.getMap();
    if (users[userId]) {
      const { first_name, last_name } = users[userId];
      return `${first_name} ${last_name}`;
    }
    return '';
  }

  createUser(userData: NewUser) {
    const url = this.USERS_URL;
    return this.http.post(url, userData).pipe(
      map((res: any) => {
        this.store.dispatch(UserActions.addUser({ payload: res.data }));
        return res;
      })
    );
  }

  updateUser(userId: string, userObj: Partial<User>) {
    const url = `${this.USERS_URL}/${userId}`;
    return this.http.put(url, userObj).pipe(
      map((res: any) => {
        const user = res.data;
        this.store.dispatch(
          UserActions.updateUser({
            id: user.id,
            payload: user,
          })
        );
        return res;
      })
    );
  }

  addPhoneNumbersToUser(userId: string, phoneNumbers: PhoneNumber[]) {
    const url = `${this.USERS_URL}/${userId}/phone_numbers`;
    return this.http.post(url, { phone_numbers: phoneNumbers }).pipe(
      map((res: any) => {
        phoneNumbers.forEach((n: PhoneNumber) => {
          const { phone_number, is_hipaa, plan } = n;
          this.store.dispatch(
            NumberActions.updatePhoneNumberSuccess({
              payload: {
                phone_number,
                is_hipaa,
                plan,
                user_id: userId,
              },
            })
          );
        });
        return res;
      })
    );
  }

  removePhoneNumberFromUser(userId: string, phoneNumber: string) {
    const url = `${this.USERS_URL}/${userId}/phone_numbers/${phoneNumber}`;
    return this.http.delete(url);
  }

  listUserPhoneNumbers(userId: string): Observable<PhaxioResponse> {
    const url = `${this.USERS_URL}/${userId}/phone_numbers`;
    return this.http.get(url).pipe(
      map((res: PhaxioResponse) => {
        res.data.forEach((n: PhoneNumber) =>
          this.store.dispatch(
            NumberActions.updatePhoneNumberSuccess({ payload: n })
          )
        );
        return res;
      })
    );
  }

  changePassword({ currentPassword, newPassword, confirmPassword }) {
    const url = `${this.USERS_URL}/update_password`;
    const passwordBody = {
      old_password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: confirmPassword,
    };
    return this.http.post(url, passwordBody);
  }

  sortUsers(a: User, b: User) {
    const uOne = {
      first: a.first_name.toLowerCase(),
      last: a.last_name.toLowerCase(),
    };
    const uTwo = {
      first: b.first_name.toLowerCase(),
      last: b.last_name.toLowerCase(),
    };
    const firstName = `${uOne.first} ${uOne.last}`;
    const secondName = `${uTwo.first} ${uTwo.last}`;
    return firstName > secondName ? 1 : -1;
  }

  deleteUser(userId: string): Observable<PhaxioResponse> {
    const url = `${this.USERS_URL}/${userId}`;
    return this.http.delete(url).pipe(map((res: PhaxioResponse) => res));
  }

  updateUserStatus(
    userId: string,
    status: UserStatus
  ): Observable<SingleUserResponse> {
    const url = `${this.USERS_URL}/${userId}/update_status?status=${status}`;
    return this.http.put(url, {}).pipe(map((res: PhaxioResponse) => res));
  }

  sendInviteEmail(userId: string): Observable<PhaxioResponse> {
    const url = `${this.USERS_URL}/${userId}/invite`;
    return this.http.post(url, {}).pipe(map((res: PhaxioResponse) => res));
  }

  getUsersNameString(usersArr: Partial<User>[]): string {
    return usersArr
      .map((u: Partial<User>) => `${u.first_name} ${u.last_name}`)
      .join(', ');
  }
}
