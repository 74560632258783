import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Group } from '@group/store/group.model';
import { PhoneNumber } from '@number/store/number.model';

@Component({
  selector: 'voyant-fax-lines-list',
  templateUrl: './fax-lines-list.component.html',
  styleUrls: ['./fax-lines-list.component.scss'],
})
export class FaxLinesListComponent {
  @Input() groups: Group[] = [];
  @Input() numbers: PhoneNumber[] = [];
  @Input() withDelete = true;
  @Output() delete: EventEmitter<{ type: string; index: number }> =
    new EventEmitter();

  onDelete(type: string, index: number) {
    this.delete.emit({ type, index });
  }
}
