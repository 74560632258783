import toastr from 'toastr';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  constructor() {
    toastr.options = {
      closeButton: true,
      positionClass: 'toast-top-center',
      timeOut: 3000,
    };
  }

  notify(type: 'success' | 'warning' | 'error', message, timeout = null) {
    toastr.options.timeOut = timeout ? timeout : 3000;
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    message = capitalize(message.replace(/</g, '&lt;').replace(/>/g, '&gt;'));

    return toastr[type](message);
  }

  setErrorFlash(data: any) {
    let errorDetail = 'Unknown error response';
    if (data) {
      if (data.detail || data.message) {
        // if data.detail gets passed back, that gets assigned, if not, then data.message gets assigned
        errorDetail = data.detail ? data.detail : data.message;
      }
    }
    this.notify('error', errorDetail);
  }
}
