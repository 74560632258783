import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromAuth from '@auth/store/auth.reducer';
import { Observable } from 'rxjs';

@Component({
  selector: 'voyant-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent {
  state$: Observable<fromAuth.State>;

  constructor(private store: Store<fromAuth.State>) {
    this.state$ = this.store.select(fromAuth.getState);
  }
}
