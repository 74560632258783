import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as numberSelectors from '@number/store/number.selectors';
import * as NumberActions from '../store/number.actions';

import { SearchParams, UniversalSearchValues } from '@shared/models';

import { SearchService, ModalService } from '@shared/services';
import { Observable, Subscription } from 'rxjs';
import { PhoneNumber } from '../store/number.model';
import { map } from 'rxjs/operators';
import { NumberEditComponent } from '@number/modals/number-edit/number-edit.component';

import { AuthUser } from '@auth/store/auth.model';
import * as fromAuth from '@auth/store/auth.reducer';

@Component({
  selector: 'voyant-numbers-table',
  templateUrl: './numbers-table.component.html',
  styleUrls: ['./numbers-table.component.scss'],
})
export class NumbersTableComponent implements OnInit, OnDestroy {
  auth$: Observable<AuthUser>;
  state$: Observable<any>;
  numbers$: Observable<PhoneNumber[]>;

  search: string;
  selectAll = false;

  searchParams: SearchParams = this._searchService.initSearchParams('status');
  searchSubscription: Subscription;
  assignedData = new Map();

  constructor(
    private _searchService: SearchService,
    private _modalService: ModalService,
    private store: Store<fromApp.AppState>
  ) {
    this.state$ = this.store.select(numberSelectors.getState);

    this.numbers$ = this.store.select(numberSelectors.selectAll).pipe(
      map((numbers) => {
        this.selectAll = numbers.every((n) => n.selected);
        return numbers;
      })
    );
  }

  ngOnInit(): void {
    this.auth$ = this.store
      .select('auth')
      .pipe(map((s: fromAuth.State) => s.loggedInUser));

    this.searchSubscription = this._searchService.term.subscribe(
      (params: UniversalSearchValues) => {
        this.searchParams = { ...this.searchParams, page: 1 };

        if (params.search && params.search.length) {
          this.searchParams.search = params.search;
        } else if (this.searchParams.hasOwnProperty('search')) {
          delete this.searchParams.search;
        }

        this.store.dispatch(
          NumberActions.loadPhoneNumbers({ payload: this.searchParams })
        );
      }
    );
  }

  onViewNumber(num: PhoneNumber): void {
    this._modalService.openModal(NumberEditComponent, 'right', {
      number: num,
      assignee: this.assignedData.get(num.phone_number),
    });
  }

  onPageChanged(paginationConfig) {
    this.searchParams = { ...this.searchParams, ...paginationConfig };
    delete this.searchParams.total;

    this.selectAll = false;
    this.store.dispatch(
      NumberActions.loadPhoneNumbers({ payload: this.searchParams })
    );
  }

  onSortChanged(sortOn: string) {
    this.searchParams = this._searchService.updateSort(
      sortOn,
      this.searchParams
    );
    this.selectAll = false;
    this.store.dispatch(
      NumberActions.loadPhoneNumbers({ payload: this.searchParams })
    );
  }

  onSelectNumber(num: PhoneNumber) {
    const action = !num.selected
      ? NumberActions.selectPhoneNumber({ number: num })
      : NumberActions.unselectPhoneNumber({ number: num });

    this.store.dispatch(action);
  }

  onSelectAll(selectAll) {
    const action = !selectAll
      ? NumberActions.selectAllPhoneNumbers()
      : NumberActions.unselectAllPhoneNumbers();

    this.store.dispatch(action);
  }

  onDeleteSelected() {
    const dialogRef = this._modalService.openConfirmModal({
      modalType: 'danger',
      title: `Delete Numbers`,
      altTitle: 'Are you sure you want to delete these numbers ?',
      message:
        'Any assigned number will be automatically removed from its account or group. Once deleted, this action cannot be undone.',
      confirmText: 'Delete',
    });

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.store.dispatch(NumberActions.deletePhoneNumbers());
      }
    });
  }

  onAssignedData(data: any, num: PhoneNumber) {
    this.assignedData.set(num.phone_number, data);
  }

  identify(index, item: PhoneNumber) {
    return item.phone_number + '_' + index;
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
    this._searchService.term.next({
      search: null,
    });
  }
}
