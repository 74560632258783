import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from 'src/app/user';
import { Group } from 'src/app/group';
import { PhoneNumber } from 'libphonenumber-js';

@Component({
  selector: 'voyant-ata-new',
  templateUrl: './ata-new.component.html',
  styleUrls: ['./ata-new.component.scss'],
})
export class AtaNewComponent implements OnInit {
  phoneNum: FormGroup;
  data: {
    name: string;
    description: string;
    mac_address: string;
    lines: {
      groups: Group[];
      numbers: PhoneNumber[];
    };
    users: User[];
  };

  steps = [
    { label: 'Enter Ata Info', active: true, required: true },
    { label: 'Add Number', active: false, required: false },
    { label: 'Add Users', active: false, required: false },
    { label: 'Connect', active: false, required: false },
    { label: 'Review', active: false, required: true },
  ];

  constructor(private fb: FormBuilder) {
    this.phoneNum = this.fb.group({
      selectedNumber: [null],
      areaCode: [null],
      hipaa: [null],
      numberProvisioning: [null],
    });

    this.data = {
      name: null,
      description: null,
      mac_address: null,
      lines: {
        groups: [],
        numbers: [],
      },
      users: [],
    };
  }

  ngOnInit() {}

  setActiveStep(stepObj: any) {
    this.steps.forEach((step) => (step.active = false));
    stepObj.active = true;
  }

  getActiveStep() {
    let index = 0;
    let activeStep = null;
    for (const step of this.steps) {
      if (step.active) {
        activeStep = step;
        break;
      }
      index++;
    }
    return { step: activeStep, index };
  }

  onNext(data: any) {
    this.data = { ...this.data, ...data };
    const activeStepObj = this.getActiveStep();
    activeStepObj.step.active = false;
    if (activeStepObj.index !== this.steps.length) {
      const nextStepIndex = activeStepObj.index + 1;
      this.steps[nextStepIndex].active = true;
    }
  }

  onBack(data?: any) {
    if (data) {
      this.data = { ...this.data, ...data };
    }
    const activeStepObj = this.getActiveStep();
    activeStepObj.step.active = false;

    if (activeStepObj.index !== 0) {
      const nextStepIndex = activeStepObj.index - 1;
      this.steps[nextStepIndex].active = true;
    }
  }

  onAssignUsers({ users }) {
    this.onNext({ users });
  }
}
