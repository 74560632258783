import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { NumberService, PhoneNumber } from '../../../../number';
import { FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';

import { Observable, Subject } from 'rxjs';
import * as fromAuth from '@auth/store/auth.reducer';

@Component({
  selector: 'voyant-get-fax-number',
  templateUrl: './get-fax-number.component.html',
  styleUrls: ['./get-fax-number.component.scss'],
})
export class GetFaxNumberComponent implements OnInit {
  @ViewChild('numberSelection', { static: true }) numberSelection: ElementRef;
  searchResults$: Observable<PhoneNumber[] | any[]>;
  searchInput$: Subject<string> = new Subject<string>();

  @Input() submitted;
  @Input() parentForm: FormGroup;
  @Input() form: NgForm;
  @Input() selectedNumbers: any[] = [];

  auth$: Observable<fromAuth.State>;

  purchaseNumber = false;

  onChange = () => {};
  onTouched = () => {};

  constructor(
    private _numberService: NumberService,
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>
  ) {
    this.auth$ = this.store.select('auth');

    this.parentForm = this.fb.group({
      selectedNumber: [null],
      selectedPlan: [null],
      areaCode: [null, [], this._numberService.areaCodeIsAvailableValidator()],
      hipaa: [null],
      numberProvisioning: [null],
    });
  }

  get c() {
    return this.parentForm.controls;
  }

  isPurchaseNumber(numberProvisioning) {
    return numberProvisioning.value === 'purchaseNumber';
  }

  ngOnInit() {
    this.parentForm.patchValue({
      ...this.parentForm.value,
    });
  }

  onSetPlan(num: PhoneNumber) {
    if (this.c.numberProvisioning.value !== 'purchaseNumber') {
      this.c.selectedPlan.setValue(num.plan);
    }
  }

  toggleNumberProvisioning(numberProvisioning: string) {
    this.parentForm.patchValue({
      numberProvisioning,
    });
  }
}
