import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AtaService } from './ata.service';
import { Ata } from './ata.model';
import { NumberService } from 'src/app/number';
import { User } from 'src/app/user';

@Injectable({ providedIn: 'root' })
export class AtaHelper {
  constructor(
    private _ataService: AtaService,
    private _numberService: NumberService
  ) {}

  getCreateChain(ataId: string, newAta: Ata): Observable<any>[] {
    const createAta: Observable<any>[] = [];
    // update manin params of ata;
    const mainParams = ['name', 'description', 'password', 'username'];

    const { name, description, password, username } = newAta;

    for (const key of mainParams) {
      if (newAta[key]) {
        createAta.push(
          this._ataService.updateAta(ataId, {
            name,
            description,
            password,
            username,
          })
        );
        break;
      }
    }

    const numberExists =
      newAta.phone_number && newAta.phone_number.phone_number;

    // add new number and update it's compliance
    if (numberExists) {
      createAta.push(
        this._ataService.addNumberToAta(ataId, newAta.phone_number.phone_number)
      );

      createAta.push(
        this._numberService.updateNumber(newAta.phone_number.phone_number, {
          is_hipaa: newAta.phone_number.is_hipaa,
        })
      );
    }

    const newUsers: string[] = newAta.users
      ? newAta.users.map((u: User) => u.id)
      : [];

    newUsers.forEach((u) => {
      createAta.push(this._ataService.addUserToAta(ataId, u));
    });
    return createAta;
  }

  getUpdateChain(ataId: string, oldAta: Ata, newAta: Ata): Observable<any>[] {
    const updateAta: Observable<any>[] = [];
    // update manin params of ata;
    const mainParams = [
      'name',
      'description',
      'password',
      'username',
      'mac_address',
      'domain',
    ];

    for (const key of mainParams) {
      if (oldAta[key] !== newAta[key]) {
        const { name, description, password, username, mac_address, domain } =
          newAta;

        updateAta.push(
          this._ataService.updateAta(ataId, {
            name,
            description,
            password,
            username,
            mac_address,
            domain,
          })
        );
        break;
      }
    }
    const numberUpdated =
      oldAta.phone_number &&
      newAta.phone_number &&
      oldAta.phone_number.phone_number !== newAta.phone_number.phone_number;

    // if number exists remove it
    if (numberUpdated) {
      updateAta.push(
        this._ataService.removeNumberFromAta(
          ataId,
          oldAta.phone_number.phone_number
        )
      );
    }

    // add new number and update it's compliance
    if (!oldAta.phone_number || numberUpdated) {
      updateAta.push(
        this._ataService.addNumberToAta(ataId, newAta.phone_number.phone_number)
      );

      updateAta.push(
        this._numberService.updateNumber(newAta.phone_number.phone_number, {
          is_hipaa: newAta.phone_number.is_hipaa,
        })
      );
    }

    const oldUsers: string[] = oldAta.users.map((u: User) => u.id);
    const newUsers: string[] = newAta.users.map((u: User) => u.id);

    oldUsers.forEach((u) => {
      if (!newUsers.includes(u)) {
        updateAta.push(this._ataService.removeUserToAta(ataId, u));
      }
    });

    newUsers.forEach((u) => {
      if (!oldUsers.includes(u)) {
        updateAta.push(this._ataService.addUserToAta(ataId, u));
      }
    });

    return updateAta;
  }
}
