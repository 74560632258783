import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NewDepartment } from '../new-department.model';
import { User, UserService } from 'src/app/user';
import { DepartmentService } from 'src/app/department/department.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from '@shared/services';
import { pagingDefault } from '@shared/models';
import { Department } from 'src/app/department/department.model';
import { forkJoin } from 'rxjs';
import { timeout, delay, map } from 'rxjs/operators';

@Component({
  selector: 'voyant-review-create-department',
  templateUrl: './review-create-department.component.html',
  styleUrls: ['./review-create-department.component.scss'],
})
export class ReviewCreateDepartmentComponent implements OnInit {
  @Input() stepper: NewDepartment;
  @Output() back = new EventEmitter();
  isSubmitting = false;
  displayUsers: { admins: User[]; users: User[] } = {
    admins: [],
    users: [],
  };
  billerFromList = false;
  constructor(
    private _userService: UserService,
    private _departmentService: DepartmentService,
    private _toastrService: ToastrService,
    public dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {
    const users: User[] = Array.from(this._userService.fetchAll().values());
    const biller = users.filter(
      (user: User) => user.email === this.stepper.departmentInfo.billing_email
    );
    if (biller.length > 0) {
      this.billerFromList = true;
    }
  }

  onGetUserName(userId: string) {
    return this._userService.getUserName(userId);
  }

  onCreateDepartment() {
    const { name, billing_name, billing_email, manager_ids } =
      this.stepper.departmentInfo;

    const req: any = {
      name,
      billing_name,
      billing_email,
      manager_ids,
      user_ids: [...this.stepper.user_ids],
    };
    this._departmentService
      .create(req)
      .pipe(
        delay(1000),
        map((res) => {
          this._departmentService.get({ ...pagingDefault }, true).subscribe();
          return res;
        })
      )
      .subscribe((res: any) => {
        this._toastrService.notify(
          'success',
          `[${this.stepper.departmentInfo.name}] department was successfully created`
        );
        this.dialogRef.close(res);
      });
  }

  onBack() {
    this.back.emit('true');
  }
}
