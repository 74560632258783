import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashPhone',
})
export class DashPhonePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    let phone = value.replace(/\D/g, '').trim();
    if (phone.length > 0) {
      if (phone.charAt(0) === '1') {
        phone = phone.substring(1);
      }
      phone =
        phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
      return phone;
    } else {
      return value;
    }
  }
}
