import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthService } from '../../auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as fromAuth from '../../auth/store/auth.reducer';
import { VarService } from 'src/app/var/var.service';
import { VarProfile } from 'src/app/var/var.model';

import * as fromOps from '@ops/store/ops.reducer';
import * as OpsActions from '@ops/store/ops.actions';

interface Config {
  logo: string;
  headerClass: string;
  name: string;
  isOps: boolean;
  navIsVisible: boolean;
  activeLink: string;
}

@Component({
  selector: 'voyant-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  authS$: Observable<fromAuth.State>;
  opsS$: Observable<fromOps.State>;
  varProfile$: Observable<VarProfile>;
  @ViewChild('menu', { static: true }) menu: MatMenuTrigger;

  config: Config = {
    logo: null,
    headerClass: null,
    name: 'Inteliquent Fax',
    isOps: false,
    navIsVisible: false,
    activeLink: null,
  };

  private links = {
    accountMng: ['/account-management'],
    dashboard: ['/dashboard'],
    admin: [
      '/manage-users',
      '/departments',
      '/admin/log',
      '/fax/log',
      '/numbers',
    ],
    profile: [
      '/customer/profile',
      '/help/faq',
      '/help/terms-and-conditions',
      '/help/privacy-policy',
      '/help/contact',
    ],
  };

  constructor(
    private _authService: AuthService,
    private _varService: VarService,
    private router: Router,
    private store: Store<fromApp.AppState>
  ) {
    this.opsS$ = this.store.select(fromOps.getState);
    this.authS$ = this.store.select(fromAuth.getState);
    this.varProfile$ = this.store.select(fromAuth.varProfile);
  }

  ngOnInit() {
    this.setActiveLink(this.router.url);

    combineLatest([
      this.store.select(fromAuth.getState),
      this.store.select(fromOps.getState),
    ]).subscribe(([authS, opsS]) => {
      if (opsS.impersonating) {
        this.config = {
          ...this.config,
          headerClass: 'header',
          logo: '/assets/images/inteliquent-icon.svg',
          isOps: false,
        };
      }

      if (!opsS.impersonating && authS.loggedInUser) {
        this.updateCompanyInfoState(authS);
      }

      if (authS.isVar) {
        this._varService.varProfile.subscribe((p) =>
          this.updateCompanyInfoVar(p)
        );
        this._varService.varProfilePublic.subscribe((p) =>
          this.updateCompanyInfoVar(p)
        );
      }
    });

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.setActiveLink(e.urlAfterRedirects);
      }
    });
  }

  onLogout(e) {
    e.preventDefault();
    this._authService.logout().subscribe();
  }

  onToggleNav(show: boolean) {
    this.config = { ...this.config, navIsVisible: show };
  }

  setActiveLink(url) {
    let activeLink = null;

    if (this.links.dashboard.includes(url)) {
      activeLink = 'dashboard';
    } else if (this.links.admin.includes(url)) {
      activeLink = 'admin';
    } else if (this.links.profile.includes(url)) {
      activeLink = 'profile';
    } else if (this.links.accountMng.includes(url)) {
      activeLink = 'accountMng';
    }
    this.config = {
      ...this.config,
      activeLink,
    };
  }

  private updateCompanyInfoState(s: fromAuth.State) {
    if (!s.isVar) {
      setTimeout(() => {
        if (s.loggedInUser && s.loggedInUser.role === 'ops') {
          this.config = {
            ...this.config,
            headerClass: 'header header--ops',
            logo: '/assets/images/inteliquent-icon-grey.svg',
            isOps: true,
          };
        } else {
          this.config = {
            ...this.config,
            headerClass: 'header',
            logo: '/assets/images/inteliquent-icon.svg',
            isOps: false,
          };
        }
      });
    }
  }

  private updateCompanyInfoVar(profile: VarProfile) {
    if (profile) {
      this.config = {
        ...this.config,
        name: profile.name,
        logo: profile.logo_url + `?v=${Date.now()}`,
      };
    }
  }
}
