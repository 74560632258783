import { Component, Input, EventEmitter, Output } from '@angular/core';

interface Config {
  showDatePicker: boolean
  dateRangeLabel: string;
}

@Component({
  selector: 'voyant-var-statistics',
  templateUrl: './var-statistics.component.html',
  styleUrls: ['./var-statistics.component.scss'],
})
export class VarStatisticsComponent {
  @Input() accounts = 0;
  @Output() rangeChange = new EventEmitter<{ fromDate: Date; toDate: Date }>();
  @Input() showAll = false;

  @Input() dateRange: {
    fromDate: Date;
    toDate: Date;
  };

  @Input() data: {
    sent_pages_count: number;
    received_pages_count: number;
    users_count: number;
    phone_numbers_count: number;
  };

  config: Config = {
    showDatePicker: false,
    dateRangeLabel: 'Last 30 days'
  };

  constructor() {}

  onShowDatePicker() {
    this.config = {
      ...this.config,
      showDatePicker: !this.config.showDatePicker
    }
  }

  onDatePickerClickOutside(e) {
    let show = this.config.showDatePicker;
    if (show && !e.target.className.includes('date-picker')) {
      show = false;
    }
  }

  onDatesSelected(e: { fromDate: Date; toDate: Date }) {
    this.dateRange = { ...e };
    this.rangeChange.emit(e);

    this.config = {
      ...this.config,
      showDatePicker: false
    };
  }

  onUpdateDateRangeLabel(e: string) {
    this.config.dateRangeLabel = e;
  }
}
