import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private http: HttpClient) {}

  uniqueArr(arr: any[], key: string) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }
  addWorkDays(startDate, days): Date {
    if (isNaN(days)) {
      console.log('Value provided for "days" was not a number');
      return;
    }
    if (!(startDate instanceof Date)) {
      console.log('Value provided for "startDate" was not a Date object');
      return;
    }
    // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
    var dow = startDate.getDay();
    var daysToAdd = parseInt(days);
    // If the current day is Sunday add one day
    if (dow == 0) daysToAdd++;
    // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
    if (dow + daysToAdd >= 6) {
      //Subtract days in current working week from work days
      var remainingWorkDays = daysToAdd - (5 - dow);
      //Add current working week's weekend
      daysToAdd += 2;
      if (remainingWorkDays > 5) {
        //Add two days for each working week by calculating how many weeks are included
        daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
        //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
        if (remainingWorkDays % 5 == 0) daysToAdd -= 2;
      }
    }
    startDate.setDate(startDate.getDate() + daysToAdd);
    return startDate;
  }

  utcToIsosDate(date: string) {
    return date.replace(' UTC', 'Z').replace(' ', 'T');
  }

  catchErrWrapper(call: any) {
    return call.pipe(catchError((res: HttpErrorResponse) => of(res.error)));
  }

  getFile(url: string) {
    return this.http.get(url, { responseType: 'blob' });
  }

  shadeColor(color: string, percent: number): string {
    let R: number = Math.floor(
      (parseInt(color.substring(1, 3), 16) * (100 + percent)) / 100
    );
    let G: number = Math.floor(
      (parseInt(color.substring(3, 5), 16) * (100 + percent)) / 100
    );
    let B: number = Math.floor(
      (parseInt(color.substring(5, 7), 16) * (100 + percent)) / 100
    );

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    const RR =
      R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
  }

  hexToRgb(hex: string): string {
    const result = /^#?([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})$/i.exec(hex);
    const resultConversion = (val) => {
      return val.length > 1 ? val : val + val;
    };
    if (result) {
      const o = {
        r: parseInt(resultConversion(result[1]), 16),
        g: parseInt(resultConversion(result[2]), 16),
        b: parseInt(resultConversion(result[3]), 16),
      };
      const { r, g, b } = o;
      return `${r}, ${g}, ${b}`;
    } else {
      return hex;
    }
  }

  getStates() {
    return [
      { abbr: 'AL', name: 'Alabama' },
      { abbr: 'AK', name: 'Alaska' },
      { abbr: 'AZ', name: 'Arizona' },
      { abbr: 'AR', name: 'Arkansas' },
      { abbr: 'CA', name: 'California' },
      { abbr: 'CO', name: 'Colorado' },
      { abbr: 'CT', name: 'Connecticut' },
      { abbr: 'DE', name: 'Delaware' },
      { abbr: 'DC', name: 'District Of Columbia' },
      { abbr: 'FL', name: 'Florida' },
      { abbr: 'GA', name: 'Georgia' },
      { abbr: 'HI', name: 'Hawaii' },
      { abbr: 'ID', name: 'Idaho' },
      { abbr: 'IL', name: 'Illinois' },
      { abbr: 'IN', name: 'Indiana' },
      { abbr: 'IA', name: 'Iowa' },
      { abbr: 'KS', name: 'Kansas' },
      { abbr: 'KY', name: 'Kentucky' },
      { abbr: 'LA', name: 'Louisiana' },
      { abbr: 'ME', name: 'Maine' },
      { abbr: 'MD', name: 'Maryland' },
      { abbr: 'MA', name: 'Massachusetts' },
      { abbr: 'MI', name: 'Michigan' },
      { abbr: 'MN', name: 'Minnesota' },
      { abbr: 'MS', name: 'Mississippi' },
      { abbr: 'MO', name: 'Missouri' },
      { abbr: 'MT', name: 'Montana' },
      { abbr: 'NE', name: 'Nebraska' },
      { abbr: 'NV', name: 'Nevada' },
      { abbr: 'NH', name: 'New Hampshire' },
      { abbr: 'NJ', name: 'New Jersey' },
      { abbr: 'NM', name: 'New Mexico' },
      { abbr: 'NY', name: 'New York' },
      { abbr: 'NC', name: 'North Carolina' },
      { abbr: 'ND', name: 'North Dakota' },
      { abbr: 'OH', name: 'Ohio' },
      { abbr: 'OK', name: 'Oklahoma' },
      { abbr: 'OR', name: 'Oregon' },
      { abbr: 'PA', name: 'Pennsylvania' },
      { abbr: 'RI', name: 'Rhode Island' },
      { abbr: 'SC', name: 'South Carolina' },
      { abbr: 'SD', name: 'South Dakota' },
      { abbr: 'TN', name: 'Tennessee' },
      { abbr: 'TX', name: 'Texas' },
      { abbr: 'UT', name: 'Utah' },
      { abbr: 'VT', name: 'Vermont' },
      { abbr: 'VA', name: 'Virginia' },
      { abbr: 'WA', name: 'Washington' },
      { abbr: 'WV', name: 'West Virginia' },
      { abbr: 'WI', name: 'Wisconsin' },
      { abbr: 'WY', name: 'Wyoming' },
    ];
  }
}
