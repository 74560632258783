export enum NumberTypes {
  LOAD_NUMBERS = '[NUMBER] Load Phone Numbers',
  SET_NUMBERS = '[NUMBER] Set Phone Numbers',

  PROVISION_NUMBER = '[NUMBER] Provision Phone Number',
  PROVISION_MANY_NUMBERS = '[NUMBER] Provision Many Phone Numbers',
  PROVISION_MANY_NUMBERS_FINISHED = '[NUMBER] Provision Many Phone Numbers Finished',
  PROVISION_MANY_NUMBERS_DONE = '[NUMBER] Provision Many Phone Numbers Done',

  UPDATE_SEARCH_CACHE = '[NUMBER] Update Phone Number Search Caches',

  UPDATE_NUMBER = '[NUMBER] Update Number',
  UPDATE_NUMBER_SUCCESS = '[NUMBER] Update Number Success',

  DELETE_NUMBERS = '[NUMBER] Delete Phone Numbers',
  DELETE_NUMBER = '[NUMBER] Delete Phone Number',
  DELETE_SUCCESS = '[NUMBER] Delete Phone Number(s) Success',

  ADD_NUMBER_SUCCESS = '[NUMBER] Add Phone Number',
  ADD_NUMBER_FAIL = '[NUMBER] Add Phone Number Fail',

  GET_NUMBERS_METRICS = '[NUMBER] Get Phone Numbers Metrics',
  GET_NUMBERS_METRICS_SUCCESS = '[NUMBER] Get Phone Numbers Metrics Success',

  SELECT_NUMBER = '[NUMBER] Select Phone Number',
  UNSELECT_NUMBER = '[NUMBER] Unselect Phone Number',
  SELECT_ALL_NUMBERS = '[NUMBER] Select All Phone Number',
  UNSELECT_ALL_NUMBERS = '[NUMBER] Unselect All Phone Number',
}
