import { RoleGuard } from '../auth/role.guard';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OpsAccountManagementComponent } from './ops-account-management/ops-account-management.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'account-management',
        canActivate: [RoleGuard],
        component: OpsAccountManagementComponent,
        data: {
          expectedRoles: ['ops'],
        },
      },
    ]),
  ],
  exports: [RouterModule],
})
export class OpsngRoutingModule {}
