import { createAction, props } from '@ngrx/store';
import { AuthUser } from '../../auth/store/auth.model';

const label = '[OPS]';

export const impersonate = createAction(
  `${label} Impersonate`,
  props<{ userId: string }>()
);

export const impersonateSuccess = createAction(
  `${label} Impersonate Success`,
  props<{ user: AuthUser }>()
);

export const unimpersonate = createAction(`${label} Unimpersonate`);
export const unimpersonateSuccess = createAction(
  `${label} Unimpersonate Success`
);

export const checkImpersonation = createAction(`${label} Check Impersonation`);
