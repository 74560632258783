import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[voyantConfirmEqualValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ConfirmEqualValidatorDirective,
      multi: true
    }
  ]
})
export class ConfirmEqualValidatorDirective implements Validator {
  @Input() voyantConfirmEqualValidator: string;
  validate(control: AbstractControl): ValidationErrors | null {
    const controlToCompare = control.parent.get(this.voyantConfirmEqualValidator);
    if (controlToCompare) {
      const ctrlOne = controlToCompare.value;
      const ctrlTwo = control.value;
      if (ctrlOne.length && ctrlTwo.length) {
        if (ctrlOne !== ctrlTwo) {
          return { notEqual: true };
        }
      }
    }
    return null;
  }
}
