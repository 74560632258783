import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { UtilityService } from '@shared/services';
import { NewCompany } from '../new-company.models';
import { VarAccount } from '../../../var.model';

//   name: string;
//   admin_contact_email: string;
//   admin_contact_first_name: string;
//   admin_contact_last_name: string;
//   billing_contact_email: string;
//   billing_contact_first_name: string;
//   billing_contact_last_name: string;
//   city: string;
//   state: string;
//   street_address: string;
//   street_address_2: string;
//   zip: string;

@Component({
  selector: 'voyant-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
})
export class CompanyInfoComponent implements OnInit {
  @Input() data: NewCompany;
  @ViewChild('form', { static: true }) companyInfoForm: NgForm;
  @Output() next: EventEmitter<any> = new EventEmitter();
  isSubmitting = false;
  states: { abbr: string; name: string }[] = [];

  companyInfo: FormGroup;
  constructor(
    private _utilityService: UtilityService,
    private fb: FormBuilder
  ) {}

  get c() {
    return this.companyInfo.controls;
  }

  ngOnInit(): void {
    this.states = this._utilityService.getStates();

    this.companyInfo = this.fb.group({
      name: [this.data.name, [Validators.required]],
      street_address: [this.data.street_address, [Validators.required]],
      street_address_2: [this.data.street_address_2],
      city: [this.data.city, [Validators.required]],
      state: [this.data.state, [Validators.required]],
      zip: [this.data.zip, [Validators.required]],
    });
  }

  onNext() {
    if (this.companyInfoForm.valid) {
      this.isSubmitting = true;
      this.next.emit(this.companyInfo.value);
    }
  }
}
