import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService, User } from 'src/app/user';
import { combineLatest } from 'rxjs';
import { NewDepartment } from '../new-department.model';
import { DepartmentService } from 'src/app/department/department.service';

@Component({
  selector: 'voyant-department-info',
  templateUrl: './department-info.component.html',
  styleUrls: ['./department-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DepartmentInfoComponent implements OnInit {
  @Input() stepper: NewDepartment;
  @Output() next = new EventEmitter();

  departmentForm: FormGroup;
  submitted = false;
  users: User[] = [];

  constructor(
    private _userService: UserService,
    private _departmentService: DepartmentService,
    private fb: FormBuilder
  ) {
    this._userService.getUsers().subscribe();
  }

  get c() {
    return this.departmentForm.controls;
  }

  ngOnInit(): void {
    this.onInit();
  }

  onInit() {
    const users: User[] = Array.from(this._userService.fetchAll().values());
    this.users = users.sort(this._userService.sortUsers);

    const { name, manager_ids, billing_email, billing_name } =
      this.stepper.departmentInfo;

    let billerId = null;
    const biller: User[] = users.filter(
      (user: User) => billing_email === user.email
    );

    if (biller && biller.length) {
      billerId = biller[0].id;
    }

    this.departmentForm = this.fb.group({
      name: [name, [Validators.required]],
      manager_id: [manager_ids[0], [Validators.required]],
      billing_email: [billing_email, [Validators.required, Validators.email]],
      billing_name: [billing_name, [Validators.required]],
      billing_id: [billerId],
      selectBiller: [false],
    });
  }

  userFilter(users: User[]) {
    const notAssignable: string[] =
      this._departmentService.getNonAssignableUsers();
    return users.filter((user: User) => !notAssignable.includes(user.id));
  }

  onNext() {
    this.submitted = true;
    if (this.departmentForm.valid) {
      const data: any = {
        ...this.departmentForm.value,
      };
      data.manager_ids = [data.manager];
      delete data.manager;
      delete data.selectBiller;
      this.next.emit({ departmentInfo: data });
    }
  }
}
