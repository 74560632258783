import { NgModule } from '@angular/core';
import { AtasTableComponent } from './atas-table/atas-table.component';
import { SharedModule } from '../shared/shared.module';
import { AtaEditComponent } from './modals/ata-edit/ata-edit.component';
import { AtaNewComponent } from './modals/ata-new/ata-new.component';
import { AtaInfoComponent } from './modals/ata-new/ata-info/ata-info.component';
import { AtaConnectComponent } from './modals/ata-new/ata-connect/ata-connect.component';
import { AtaReviewComponent } from './modals/ata-new/ata-review/ata-review.component';
import { AtaStatusComponent } from './ata-status/ata-status.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AtaProvisionUrlComponent } from './modals/ata-provision-url/ata-provision-url.component';

@NgModule({
    declarations: [
        AtasTableComponent,
        AtaEditComponent,
        AtaNewComponent,
        AtaInfoComponent,
        AtaConnectComponent,
        AtaReviewComponent,
        AtaStatusComponent,
        AtaProvisionUrlComponent,
    ],
    imports: [SharedModule, MatTooltipModule],
    exports: [AtasTableComponent]
})
export class AtaModule {}
