import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Group } from 'src/app/group';
import { Store } from '@ngrx/store';
import * as fromGroup from '@group/store/group.reducer';
import { Observable } from 'rxjs';

@Component({
  selector: 'voyant-groups-panel',
  templateUrl: './groups-panel.component.html',
  styleUrls: ['./groups-panel.component.scss'],
})
export class GroupsPanelComponent implements OnInit {
  @Input() parentName: string;
  @Input() userGroups: Group[];
  @Input() isEdit: boolean;
  @Output() addGroup: EventEmitter<Group> = new EventEmitter();

  groups$: Observable<Group[]>;

  constructor(private store: Store<fromGroup.State>) {}

  ngOnInit(): void {
    this.groups$ = this.store.select(fromGroup.selectAll);
  }

  isAssignedGroup(group: Group, arr: Group[] = this.userGroups): number {
    let groupFound = -1;
    let index = 0;
    for (const userGroup of arr) {
      if (userGroup.id === group.id) {
        groupFound = index;
        break;
      }
      index++;
    }
    return groupFound;
  }

  onAddGroup(group: Group): void {
    this.addGroup.emit(group);
  }

  identify(index, item: Group) {
    return item.id;
  }
}
