import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FileUploadModule } from 'ng2-file-upload';

import { SharedModule } from '../shared/shared.module';
import { ModalService } from '../shared/services';

import { FaxService } from './store';

import {
  FaxActivityComponent,
  FaxActivityRowComponent,
  FaxActivityActionsComponent,
} from './fax-activity';

import { FaxViewComponent, FaxSendComponent } from './modals';
import { FaxLogComponent } from './fax-log/fax-log.component';
import { NoFaxesComponent } from './no-faxes/no-faxes.component';
import { FaxPreviewComponent } from './fax-preview/fax-preview.component';
import { FaxStatusComponent } from './fax-status/fax-status.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FaxFromComponent } from './fax-from/fax-from.component';
import { FaxToComponent } from './fax-to/fax-to.component';
import { FaxTitlePipe } from './filters/fax-title.pipe';
import { FaxActivityLoaderComponent } from './fax-activity-loader/fax-activity-loader.component';
import { FaxRoutingModule } from './fax-routing.module';

@NgModule({
    imports: [
        FaxRoutingModule,
        MatTooltipModule,
        SharedModule,
        FileUploadModule,
        RouterModule,
    ],
    declarations: [
        FaxActivityComponent,
        FaxActivityRowComponent,
        FaxActivityActionsComponent,
        FaxViewComponent,
        FaxSendComponent,
        FaxLogComponent,
        NoFaxesComponent,
        FaxPreviewComponent,
        FaxStatusComponent,
        FaxFromComponent,
        FaxToComponent,
        FaxTitlePipe,
        FaxActivityLoaderComponent,
    ],
    providers: [FaxService, ModalService],
    exports: [FaxActivityComponent]
})
export class FaxModule {}
