import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VarService } from '../../var.service';
import { ToastrService } from '@shared/services';

@Component({
  selector: 'voyant-var-profile-main',
  templateUrl: './var-profile-main.component.html',
  styleUrls: ['./var-profile-main.component.scss'],
})
export class VarProfileMainComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Output() save: EventEmitter<any> = new EventEmitter();

  config = {
    initialValues: {},
    saveDisabled: true,
  };

  domainConfig = {
    initialValues: {},
    saveDisabled: true,
  };

  profile: FormGroup;
  domain: FormGroup;

  copied = {
    cNameOne: false,
    cDataOne: false,
    cNameTwo: false,
    cDataTwo: false,
  };

  errors = {
    domainError: false,
  };

  get p() {
    return this.profile.controls;
  }

  get d() {
    return this.domain.controls;
  }

  constructor(
    private _varService: VarService,
    private _toastrService: ToastrService,
    private fb: FormBuilder
  ) {
    this.profile = this.fb.group({
      name: ['', [Validators.required]],
      domain: ['', [Validators.required]],
      contact_first_name: ['', [Validators.required]],
      contact_last_name: ['', [Validators.required]],
      contact_email: ['', [Validators.required]],
    });

    this.domain = this.fb.group({
      domain: ['', [Validators.required]],
      cNameOne: ['_acme-challenge'],
      cDataOne: [''],
      cNameTwo: ['www'],
      cDataTwo: ['whitelabel.faxingportal.com'],
    });
  }

  onSave(type: string): void {
    if (type === 'profile') {
      this.form.patchValue(this.profile.value);
      this.config.saveDisabled = true;
      this.config.initialValues = { ...this.profile.value };
    }

    if (type === 'domain') {
      this.form.patchValue({ domain: this.domain.value.domain });
      this.domainConfig.saveDisabled = true;
      this.domainConfig.initialValues = { ...this.domain.value };
    }

    this.save.emit(true);
  }

  ngOnInit(): void {
    this.profile.valueChanges.subscribe((changes) => {
      for (const prop in changes) {
        if (changes[prop] === this.config.initialValues[prop]) {
          this.profile.get(prop).markAsPristine();
        }
      }
      this.config.saveDisabled = !this.profile.dirty;
    });

    this.domain.valueChanges.subscribe((changes) => {
      for (const prop in changes) {
        if (changes[prop] === this.domainConfig.initialValues[prop]) {
          this.domain.get(prop).markAsPristine();
        }
      }
      this.domainConfig.saveDisabled = !this.domain.dirty;
    });
  }

  onDomainData(): void {
    const val = this.d.domain.value;
    const data = {
      cDataOne: '',
      cNameOne: '',
      cNameTwo: '',
    };
    if (val.length) {
      data.cDataOne = `_acme-challenge.${val}.whitelabel.faxingportal.com`;
      data.cNameOne = `_acme-challenge.${val}`;
      data.cNameTwo = `${val}`;
    }

    this.domain.patchValue(data);
  }

  onCopied(fieldName: string): void {
    this.copied[fieldName] = true;
    setTimeout(() => (this.copied[fieldName] = false), 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    const form = changes.form.currentValue;
    this.profile.patchValue(form.value);
    this.domain.patchValue({ domain: form.value.domain });

    this.config.initialValues = { ...form.value };
    this.domainConfig.initialValues = { ...form.value };

    this.onDomainData();
  }

  onVerifyDomain() {
    this._varService.activateDomain().subscribe(
      (res: any) => this._toastrService.notify('success', res.message),
      (errRes) => this._toastrService.notify('error', errRes.error.message)
    );
  }
}
