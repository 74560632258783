import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'voyant-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent {
  isVar = false;

  @HostBinding('class') className = 'app-container';

  constructor(private _authService: AuthService) {
    this.isVar = this._authService.isVar;
  }
}
