import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { UserService } from '@user/store/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from './must-match';
import { TimezoneService, ToastrService } from '@shared/services';
import { Department } from 'src/app/department/department.model';
import { DepartmentService } from 'src/app/department/department.service';
import { combineLatest, Observable } from 'rxjs';
import { Timezone } from '@shared/models';

import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as AuthActions from '@auth/store/auth.actions';
import { AuthUser } from '@auth/store/auth.model';

import * as fromAuth from '@auth/store/auth.reducer';
import { map } from 'rxjs/operators';

const INITIAL_CONFIG = {
  loading: false,
  initialValues: {},
  saveDisabled: true,
};

@Component({
  selector: 'voyant-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss'],
})
export class CustomerProfileComponent implements OnInit {
  authUser$: Observable<AuthUser>;

  faxLinesReady = false;

  user: AuthUser;
  uploader: FileUploader;
  userDepartments: Department[] = [];

  userForm: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    first_name: [null, [Validators.required]],
    last_name: [null, [Validators.required]],
    timezone: [null],
  });

  userFormConfig = { ...INITIAL_CONFIG };

  passwordForm: FormGroup = this.fb.group(
    {
      currentPassword: [null, [Validators.required]],
      newPassword: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(64),
        ],
      ],
      confirmPassword: [null, [Validators.required]],
    },
    { validator: MustMatch('newPassword', 'confirmPassword') }
  );

  passwordFormConfig = {
    loading: false,
  };

  notificationForm: FormGroup = this.fb.group({
    notify_on_received_personal: [null],
    notify_on_received_group: [null],
    notify_on_sent: [null],
  });

  notificationFormConfig = { ...INITIAL_CONFIG };

  timezones: Timezone[] = this._timezoneService.getList();

  constructor(
    private _userService: UserService,
    private _toastrService: ToastrService,
    private _departmentService: DepartmentService,
    private _timezoneService: TimezoneService,
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>
  ) {
    this.uploader = new FileUploader({
      method: 'get',
      autoUpload: true,
      disableMultipart: true,
      allowedFileType: ['image', 'pdf'],
      queueLimit: 11, // max number of files (setting to 11 di allow displaying of error message)
      maxFileSize: 30 * 1024 * 1024, // 30MB - this is per file though, not per queue
    });

    this.authUser$ = this.store.select(fromAuth.authUser).pipe(
      map((user) => {
        this.user = { ...user };
        this.initUserForm(user);
        this.initNotificationsFrom(user);
        return user;
      })
    );

    combineLatest([
      this.store.select(fromAuth.authUser),
      this._departmentService.serviceIsReady,
    ]).subscribe(([authUser, isReady]) => {
      if (isReady && authUser) {
        this.userDepartments = this._departmentService.getUserDepartments(
          authUser.id
        );
        this.faxLinesReady = true;
      }
    });
  }

  // User (Profile) Form
  get userFormControl() {
    return this.userForm.controls;
  }

  get notificationFormControl() {
    return this.notificationForm.controls;
  }

  ngOnInit() {}

  handleUserFormSuccess(user: AuthUser): void {
    this.store.dispatch(AuthActions.updateAuthUser({ user }));
    this._toastrService.notify('success', 'Profile successfully updated.');
    this.userFormConfig.loading = false;
  }

  handleUserFormError(error): void {
    this._toastrService.notify('error', error.message);
    this.userFormConfig.loading = false;
    this.notificationFormConfig.loading = false;
  }

  onUserFormSubmit() {
    if (this.userFormConfig.loading) {
      return;
    }
    if (this.userForm.valid) {
      this.userFormConfig.loading = true;
      this._userService.updateUser(this.user.id, this.userForm.value).subscribe(
        (res: any) => this.handleUserFormSuccess(res.data),
        (error) => this.handleUserFormError(error)
      );
    }
  }

  onNotificationsSubmit() {
    if (this.notificationFormConfig.loading) {
      return;
    }
    if (this.notificationForm.valid) {
      this.notificationFormConfig.loading = true;

      const {
        notify_on_received_personal,
        notify_on_received_group,
        notify_on_sent,
      } = this.notificationForm.value;

      const req = {
        notify_on_received_personal,
        notify_on_received_group,
        notify_on_sent: notify_on_sent === 'on',
      };

      this._userService
        .updateUser(this.user.id, { ...this.userForm.value, ...req })
        .subscribe(
          (res: any) => this.handleUserFormSuccess(res.data),
          (error) => this.handleUserFormError(error)
        );
    }
  }

  // Password Form
  get passwordFormControl() {
    return this.passwordForm.controls;
  }

  handlePasswordFormSuccess() {
    this._toastrService.notify('success', 'Password successfully updated');
    this.passwordForm.reset();
    this.passwordFormConfig = {
      loading: false,
    };
  }

  handlePasswordFormError({ error }) {
    const errorMessage = error.data.errors
      ? error.data.errors[0]
      : 'Something went wrong. Please try again.';
    this._toastrService.notify('error', errorMessage);
    this.passwordFormConfig.loading = false;
  }

  onPasswordFormSubmit() {
    if (this.passwordFormConfig.loading) {
      return;
    }
    if (this.passwordForm.valid) {
      this.passwordFormConfig.loading = true;
      this._userService.changePassword(this.passwordForm.value).subscribe(
        (res: any) => this.handlePasswordFormSuccess(),
        (error) => this.handlePasswordFormError(error)
      );
    }
  }

  private initNotificationsFrom(user: AuthUser) {
    const {
      notify_on_received_personal,
      notify_on_received_group,
      notify_on_sent,
    } = user;

    this.notificationFormConfig.initialValues = {
      ...this.notificationFormConfig.initialValues,
      notify_on_received_personal,
      notify_on_received_group,
      notify_on_sent: notify_on_sent ? 'on' : 'off',
    };

    this.notificationForm.patchValue(this.notificationFormConfig.initialValues);

    this.notificationForm.valueChanges.subscribe((changes) => {
      let dirty = false;
      for (const prop in changes) {
        if (changes[prop] === this.notificationFormConfig.initialValues[prop]) {
          this.notificationForm.get(prop).markAsPristine();
        } else {
          dirty = true;
        }
      }

      this.notificationFormConfig.saveDisabled = !dirty;
    });
  }

  private initUserForm(user: AuthUser) {
    // Edit Profile Form Setup
    if (user) {
      const { email, first_name, last_name } = user;
      this.userFormConfig.initialValues = {
        ...this.userFormConfig.initialValues,
        email,
        first_name,
        last_name,
      };
      this.userForm.patchValue({ ...this.userFormConfig.initialValues });

      // Change listener for user form
      this.userForm.valueChanges.subscribe((changes) => {
        for (const prop in changes) {
          if (changes[prop] === this.userFormConfig.initialValues[prop]) {
            this.userForm.get(prop).markAsPristine();
          }
        }
        this.userFormConfig.saveDisabled = !this.userForm.dirty;
      });
    }
  }
}
