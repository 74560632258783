import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@user/store/user.model';
import { PhoneNumber } from '@number/store/number.model';
import { GroupService } from '@group/store/group.service';
import { Group } from '@group/store/group.model';
import { ModalService } from '@shared/services';
import { AddFaxLinePersonalComponent } from '../add-fax-line-personal/add-fax-line-personal.component';
import { DashPhonePipe } from '@shared/pipes';
import { AuthService } from '@auth/auth.service';
import { DepartmentService } from 'src/app/department/department.service';
import { Department } from 'src/app/department/department.model';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as UserActions from '@user/store/user.actions';
import * as fromUser from '@user/store/user.reducer';
import { AuthUser } from '@auth/store/auth.model';

@Component({
  selector: 'voyant-edit-user',
  templateUrl: './edit-user.component.html',
  styles: [
    `
      .badge {
        text-transform: uppercase;
      }

      .deactivated {
        opacity: 0.5;
      }
    `,
  ],
})
export class EditUserComponent implements OnInit {
  saving = false;
  editUser: FormGroup;
  authUser: AuthUser;

  groups: Group[];

  cache: {
    user: User;
    numbers: PhoneNumber[];
    groups: Group[];
  };

  roleOptions = [{ value: 'user' }, { value: 'manager' }, { value: 'admin' }];

  userNumbers: PhoneNumber[];
  userGroups: Group[];

  constructor(
    private _authService: AuthService,
    private _groupService: GroupService,
    private _modalService: ModalService,
    private _departmentService: DepartmentService,
    private dashPhone: DashPhonePipe,
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) {
    if (!user.phone_numbers) {
      user.phone_numbers = [];
    }
    this.authUser = this._authService.getLoggedInUser();

    this.cache = {
      user: { ...this.user },
      numbers: user.phone_numbers,
      groups: [...this._groupService.getUserGroups(this.user.id)],
    };

    this.userNumbers = [...this.cache.numbers];
    this.userGroups = [...this.cache.groups];

    const { first_name, last_name, role, email } = user;

    this.editUser = this.fb.group({
      first_name: [first_name, [Validators.required]],
      last_name: [last_name, [Validators.required]],
      role: [role, [Validators.required]],
      email: [email, [Validators.required, Validators.email]],
    });
  }

  get e() {
    return this.editUser.controls;
  }

  ngOnInit() {
    this.store.select(fromUser.getState).subscribe((s: fromUser.State) => {
      this.user = { ...s.selectedUser };
      this.cache = {
        ...this.cache,
        user: { ...s.selectedUser },
      };
    });
  }

  onOpenAddFaxLines(): void {
    const dialogRef = this._modalService.openModal(null, 'center', {
      component: AddFaxLinePersonalComponent,
      title: 'Add Fax Line',
      user: this.user,
    });

    dialogRef.afterClosed().subscribe((res: PhoneNumber | null) => {
      if (res) {
        const { is_hipaa, phone_number, plan } = res;
        const num: PhoneNumber = {
          is_hipaa,
          phone_number,
          plan,
        };
        this.userNumbers = [...this.userNumbers, num];
      }
    });
  }

  onAddGroup(group: Group): void {
    const groupIsAssigned = this.isAssignedGroup(group);
    const groupsCopy = [...this.userGroups];
    if (groupIsAssigned === -1) {
      groupsCopy.push(group);
    } else {
      groupsCopy.splice(groupIsAssigned, 1);
    }
    this.userGroups = [...groupsCopy];
  }

  onRemoveNumber(e: { phoneNum: PhoneNumber; index: number }): void {
    const dialogRef = this._modalService.openConfirmModal({
      modalType: 'danger',
      title: `Remove Phone Number`,
      altTitle: `Are you sure you want to remove [${this.dashPhone.transform(
        e.phoneNum.phone_number
      )}] ?`,
      message: 'The number will be returned to your inventory.',
      confirmText: 'remove',
    });

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        const nums = [...this.userNumbers];
        nums.splice(e.index, 1);
        this.userNumbers = [...nums];
      }
    });
  }

  onSaveUser(): void {
    if (this.editUser.valid) {
      const { first_name, last_name, role, email } = this.editUser.value;

      this.store.dispatch(
        UserActions.updateUserEdit({
          compare: this.cache,
          payload: {
            user: {
              ...this.user,
              first_name,
              last_name,
              role,
              email,
            },
            numbers: this.userNumbers,
            groups: this.userGroups,
          },
        })
      );
    }
  }

  onEdit() {
    this.store.dispatch(
      UserActions.setEdit({
        payload: true,
      })
    );
  }

  onCancel(): void {
    this.store.dispatch(
      UserActions.setEdit({
        payload: false,
      })
    );
    this.resetForm(this.user);
    this.userGroups = [...this.cache.groups];
    this.userNumbers = [...this.cache.numbers];
  }

  isAssignedGroup(group: Group, arr: Group[] = this.userGroups): number {
    let groupFound = -1;
    let index = 0;
    for (const userGroup of arr) {
      if (userGroup.id === group.id) {
        groupFound = index;
        break;
      }
      index++;
    }
    return groupFound;
  }

  canEdit(): boolean {
    if (this.authUser.role === 'admin') {
      return true;
    }

    if (this.authUser.role === 'manager') {
      const departments: Department[] =
        this._departmentService.getUserDepartments(this.authUser.id);
      let canEdit = false;
      if (departments.length > 0) {
        const userIds = departments[0].users.map((user: User) => user.id);
        canEdit = userIds.includes(this.user.id);
      }
      return departments.length !== 0 && canEdit;
    }
  }

  onDeactivateUser() {
    const dialogRef = this._modalService.openConfirmModal({
      modalType: 'primary',
      title: `Deactivate Account`,
      altTitle: `Are you sure you want to deactivate this account?`,
      message:
        'The account will not be accessible, but nothing will be deleted. The associated faxes will be maintained.',
      confirmText: 'deactivate',
    });

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.store.dispatch(
          UserActions.updateUserStatus({
            id: this.user.id,
            status: 'deactivated',
          })
        );
      }
    });
  }

  onReactivateUser() {
    this.store.dispatch(
      UserActions.updateUserStatus({
        id: this.user.id,
        status: 'active',
      })
    );
  }

  onDeleteUser() {
    const dialogRef = this._modalService.openConfirmModal({
      modalType: 'danger',
      title: `Delete Account`,
      altTitle: `Are you sure you want to delete this account?`,
      message:
        'All of the faxes will be lost. Once deleted, this action cannot be undone. If you wish to keep the associated faxes, deactivate the account instead.',
      confirmText: 'delete',
    });

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.store.dispatch(UserActions.deleteUser({ payload: this.user.id }));
      }
    });
  }

  onInviteUser() {
    this.store.dispatch(UserActions.sendInviteEmail({ id: this.user.id }));
  }

  private resetForm({ first_name, last_name, role, email }): void {
    this.editUser.patchValue({
      first_name,
      last_name,
      role,
      email,
    });
  }
}
