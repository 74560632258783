import { NgModule } from '@angular/core';
import { ManageAllComponent } from './manage-all/manage-all.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ManageRoutingModule } from './manage-routing.module';

@NgModule({
  declarations: [ManageAllComponent],
  imports: [ManageRoutingModule, RouterModule, SharedModule],
})
export class ManageModule {}
