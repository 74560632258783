import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  Input,
} from '@angular/core';
import { Group } from '@group/';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PhoneNumber } from '../../../../number';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalService } from '@shared/services';
import { Store } from '@ngrx/store';
import * as fromGroup from '@group/store/group.reducer';
import { Observable } from 'rxjs';

@Component({
  selector: 'voyant-add-fax-lines',
  templateUrl: './add-fax-lines.component.html',
  styleUrls: ['./add-fax-lines.component.scss'],
})
export class AddFaxLinesComponent implements OnInit {
  @Input() single = false;
  @Input() numbers: { numbers: any[]; groups: any[] };
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();

  faxLineForm: FormGroup;
  isEdit = false;

  editNumberIndex = 0;
  addFaxLine = false;
  numInfo: PhoneNumber = null;

  selectedNumbers: any = {
    numbers: [],
    groups: [],
  };

  groups$: Observable<Group[]>;

  lineTypes = [
    { value: 'group', label: 'Group Line' },
    { value: 'personal', label: 'Direct Line' },
  ];

  constructor(
    private _modal: ModalService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddFaxLinesComponent>,
    private store: Store<fromGroup.State>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.faxLineForm = this.fb.group({
      numberType: [''],
      selectedGroup: [''],
      selectedNumber: [''],
      selectedPlan: [''],
      areaCode: [''],
      hipaa: [''],
      numberProvisioning: ['numberInventory'],
    });
  }

  get f() {
    return this.faxLineForm.controls;
  }

  ngOnInit(): void {
    if (this.numbers) {
      this.selectedNumbers = this.numbers;
    }

    const { numbers, groups } = this.selectedNumbers;
    const numTypeValidator =
      numbers.length || groups.length ? null : [Validators.required];

    this.f.numberType.setValidators(numTypeValidator);

    this.groups$ = this.store.select(fromGroup.selectAll);

    this.f.numberProvisioning.valueChanges.subscribe((numberProvisioning) => {
      if (this.f.numberType.value === 'personal') {
        const fields = ['selectedNumber', 'areaCode', 'hipaa'];

        if (numberProvisioning === 'numberInventory') {
          this.f.selectedNumber.setValidators([Validators.required]);
          this.f.areaCode.setValidators(null);
        } else {
          this.f.selectedNumber.setValidators(null);
          this.f.areaCode.setValidators([Validators.required]);
        }

        for (const key of fields) {
          this.f[key].updateValueAndValidity();
        }
      }
    });

    this.f.numberType.valueChanges.subscribe((val: string) => {
      if (val) {
        setTimeout(() => {
          this.isEdit = true;
          const validator = val === 'group' ? [Validators.required] : null;
          this.f.selectedGroup.setValidators(validator);
          this.setPersonalLineValidators(val);
        });
      }
    });

    if (this.single) {
      this.faxLineForm.patchValue({
        numberType: 'personal',
      });
    }
  }

  onSkip(): void {
    this.next.emit({});
  }

  onClearSelected(): void {
    this.isEdit = false;
    this.faxLineForm.patchValue({
      selectedNumber: '',
      selectedGroup: '',
      areaCode: '',
      hipaa: '',
      numberType: '',
      selectedPlan: '',
      numberProvisioning: 'numberInventory',
    });

    for (const key in this.f) {
      if (this.f[key]) {
        this.f[key].setValidators(null);
        this.f[key].updateValueAndValidity();
      }
    }

    const { numbers, groups } = this.selectedNumbers;
    if (numbers.length || groups.length) {
      this.f.numberType.setValidators(null);
    }
    this.f.numberType.updateValueAndValidity();
  }

  onAddNumber(event): void {
    event.preventDefault();
    if (!this.faxLineForm.valid) {
      return;
    }
    this.onPopulateResults(this.f.numberType.value);
    this.onClearSelected();
  }

  onNext(): void {
    if (this.faxLineForm.valid) {
      this.onPopulateResults(this.f.numberType.value);
      const obj = { lines: this.selectedNumbers };
      this.next.emit(obj);
    }
  }

  onPopulateResults(numType: string): void {
    if (numType === 'group') {
      if (this.f.selectedGroup.value) {
        this.assignGroup(this.f.selectedGroup.value);
      }
    }

    if (numType === 'personal') {
      const phoneNumber = this.constructPhoneNumber();
      this.assignNumber(phoneNumber);
    }
  }

  onBack(): void {
    this.back.emit('true');
  }

  onDeleteNumber({ type, index }): void {
    const typeStr = type.substr(0, type.length - 1).toUpperCase();
    const dialogRef = this._modal.openConfirmModal({
      modalType: 'danger',
      title: `Remove ${typeStr}`,
      altTitle: `Are you sure you want to remove this ${typeStr} ?`,
      confirmText: 'remove',
    });

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.selectedNumbers[type].splice(index, 1);
        this.selectedNumbers[type] = [...this.selectedNumbers[type]];
        const { numbers, groups } = this.selectedNumbers;
        if (numbers.length || groups.length) {
          return;
        }

        // if all numbers are deleted, reset validation
        this.f.numberType.setValidators([Validators.required]);
        this.f.numberType.updateValueAndValidity();
      }
    });
  }

  private setPersonalLineValidators(numberType): void {
    const fields = ['selectedNumber', 'areaCode', 'hipaa'];

    const updateValidity = () => {
      for (const key of fields) {
        this.f[key].updateValueAndValidity();
      }
    };

    const clearValidators = () => {
      for (const key of fields) {
        this.f[key].setValidators(null);
      }
    };

    if (numberType === 'personal') {
      this.f.selectedNumber.setValidators([Validators.required]);
      this.f.hipaa.setValidators([Validators.required]);
      this.f.areaCode.setValidators(null);
    } else {
      clearValidators();
    }
    updateValidity();
  }

  private assignGroup(obj: Group): void {
    let groupExist = false;
    let index = 0;
    for (const group of this.selectedNumbers.groups) {
      if (group.id === obj.id) {
        groupExist = true;
        break;
      }
      index++;
    }

    if (!groupExist) {
      this.selectedNumbers.groups.push(obj);
    } else {
      this.selectedNumbers.groups[index] = obj;
    }
  }

  private assignNumber(obj): void {
    if (obj.hasOwnProperty('phone_number')) {
      let numberExists = false;
      let index = 0;
      for (const num of this.selectedNumbers.numbers) {
        if (obj.phone_number === num.phone_number) {
          numberExists = true;
          break;
        }
        index++;
      }

      if (!numberExists) {
        this.selectedNumbers.numbers.push(obj);
      } else {
        this.selectedNumbers.numbers[index] = obj;
      }
    } else {
      this.selectedNumbers.numbers.push(obj);
    }

    this.faxLineForm.patchValue({
      selectedNumber: '',
      areaCode: '',
      hipaa: '',
    });
  }

  private constructPhoneNumber(): Partial<PhoneNumber> {
    const {
      selectedNumber,
      areaCode,
      hipaa,
      numberProvisioning,
      selectedPlan,
    } = this.faxLineForm.value;

    const num: Partial<PhoneNumber> = {
      is_hipaa: hipaa === 'yes',
      plan: selectedPlan,
    };

    if (numberProvisioning === 'numberInventory') {
      num.phone_number = selectedNumber;
    } else {
      num.area_code = areaCode;
    }

    return num;
  }
}
