import { Injectable } from '@angular/core';

interface PriceCategory {
  number_of_pages: number;
  price: number;
}

@Injectable({
  providedIn: 'root',
})
export abstract class EnvironmentService {
  abstract readonly name: string;
  abstract readonly production: boolean;
  abstract readonly apiUrls: {
    readonly faxApi?: string;
  };
  abstract readonly s3: {
    accessKeyId: string;
    secretAccessKey: string;
  };
  abstract readonly pusher: {
    key: string;
    cluster: string;
  };
  abstract readonly DOMAIN: string;
  abstract readonly pricing: {
    work_os_domain_price: number;
    work_os_directory_price: number;
    whitelabel_fee: number;
    price_per_overage_page: number;

    usage: {
      price_per_page: number;
      price_per_phone_number: number;
      price_per_active_user: number;
    };

    wholesale: {
      basic: PriceCategory;
      standard: PriceCategory;
      premium: PriceCategory;
      deluxe: PriceCategory;
    };
  } | null;
}
