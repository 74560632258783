import { Component, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Ata } from '../store/ata.model';

import * as fromApp from '@app-store/app.reducer';
import * as fromAta from '../store/ata.reducer';
import * as AtaActions from '../store/ata.actions';
import * as NumberActions from '../../number/store/number.actions';

import { Store } from '@ngrx/store';
import { SearchService, ModalService } from '@shared/services';
import {
  Paging,
  pagingDefault,
  SearchParams,
  UniversalSearchValues,
} from '@shared/models';

import { AtaEditComponent } from '../modals/ata-edit/ata-edit.component';
import { map } from 'rxjs/operators';
import { AtaProvisionUrlComponent } from '@ata/modals/ata-provision-url/ata-provision-url.component';
import { AddFaxLinePersonalComponent } from '@user/modals/add-fax-line-personal/add-fax-line-personal.component';
import { AtaNewComponent } from '@ata/modals/ata-new/ata-new.component';

interface Config {
  isSearch: boolean;
  loading: boolean;
  search: string;
  cache: any;
}

@Component({
  selector: 'voyant-atas-table',
  templateUrl: './atas-table.component.html',
  styleUrls: ['./atas-table.component.scss'],
})
export class AtasTableComponent implements OnInit {
  config: Config = {
    isSearch: false,
    loading: false,
    search: null,
    cache: {},
  };

  state$: Observable<fromAta.State>;
  atas$: Observable<Ata[]>;

  paginationConfig: Paging = pagingDefault;
  searchParams: SearchParams = this._searchService.initSearchParams('name');
  searchSubscription: Subscription;

  constructor(
    private _searchService: SearchService,
    private _modalService: ModalService,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit(): void {
    this.searchSubscription = this._searchService.term.subscribe(
      (params: UniversalSearchValues) => {
        const { search } = params;
        this.config = {
          ...this.config,
          search,
          isSearch: search && search.length > 0,
        };

        this.searchParams = { ...this.searchParams, page: 1 };

        if (this.config.isSearch) {
          this.searchParams.search = this.config.search;
        } else if (this.searchParams.hasOwnProperty('search')) {
          delete this.searchParams.search;
        }

        this.store.dispatch(
          AtaActions.loadAtas({ payload: this.searchParams })
        );
      }
    );

    this.state$ = this.store.select(fromAta.getState).pipe(
      map((s: fromAta.State) => {
        this.paginationConfig = { ...s.paging };
        return s;
      })
    );

    this.atas$ = this.store.select(fromAta.selectAll).pipe(
      map((atas: Ata[]) => {
        return atas.filter((a, i) => i < this.paginationConfig.per_page);
      })
    );
  }

  onPageChanged(paginationConfig) {
    this.searchParams = { ...this.searchParams, ...paginationConfig };
    delete this.searchParams.total;

    this.store.dispatch(AtaActions.loadAtas({ payload: this.searchParams }));
  }

  onSortChanged(sortOn: string) {
    this.searchParams = this._searchService.updateSort(
      sortOn,
      this.searchParams
    );

    this.store.dispatch(AtaActions.loadAtas({ payload: this.searchParams }));
  }

  identify(index, item: Ata) {
    return item.id;
  }

  onViewAta(ata: Ata): void {
    this._modalService.openModal(AtaEditComponent, 'right', ata);
  }

  onOpenProvisionUrlModal(): void {
    this._modalService.openModal(AtaProvisionUrlComponent, 'center', {
      title: 'Provisioning URLs',
    });
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
    this._searchService.term.next({
      search: null,
    });
  }
}
