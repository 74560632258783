import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PhaxioResponse } from '@shared/models';
import { ToastrService } from '@shared/services';
import { VarAccount } from 'src/app/var/var.model';
import { VarService } from 'src/app/var/var.service';
import { NewCompany } from '../new-company.models';

@Component({
  selector: 'voyant-company-review',
  templateUrl: './company-review.component.html',
  styleUrls: ['./company-review.component.scss'],
})
export class CompanyReviewComponent implements OnInit {
  @Input() data: NewCompany;
  @Output() back: EventEmitter<any> = new EventEmitter();
  isSubmitting = false;

  constructor(
    private _varService: VarService,
    private _toastrService: ToastrService,
    public dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {}

  onBack() {
    this.back.emit({ hi: true });
  }

  onSubmit() {
    const { name, street_address, street_address_2, city, state, zip } =
      this.data;

    const req: VarAccount = {
      admin_contact_email: this.data.admin.email,
      admin_contact_first_name: this.data.admin.firstName,
      admin_contact_last_name: this.data.admin.lastName,
      billing_contact_email: this.data.billing.email,
      billing_contact_first_name: this.data.billing.firstName,
      billing_contact_last_name: this.data.billing.lastName,
      name,
      street_address,
      street_address_2,
      city,
      state,
      zip,
    };

    this._varService.createAccount(req).subscribe(
      (res: PhaxioResponse) => {
        if (res.success) {
          this._toastrService.notify('success', res.message);
          this.dialogRef.close(res.data);
        }
      },
      (res: HttpErrorResponse) => {
        this._toastrService.notify('error', res.error.data.errors.toString());
      }
    );
  }
}
