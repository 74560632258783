import { Injectable } from '@angular/core';

interface DateRange {
  start: Date;
  end: Date;
}

@Injectable({
  providedIn: 'root',
})
export class DateRangeService {
  constructor() {}

  private getToday() {
    const today = new Date();
    return new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    );
  }

  daysInMonth(month, year): number {
    return new Date(year, month, 0).getDate();
  }

  getTodayRange(): DateRange {
    const today = this.getToday();
    const start = new Date(today.setHours(0, 0, 0));
    const end = new Date(today.setHours(23, 59, 59));
    return { start, end };
  }

  getDateRange(date): DateRange {
    const locDate = new Date(date);
    const start = new Date(locDate.setHours(0, 0, 0));
    const end = new Date(locDate.setHours(23, 59, 59));
    return { start, end };
  }

  getCurrentWeekRange(): DateRange {
    const curr = new Date();
    const first = curr.getDate() - curr.getDay() + 1;
    const last = first + 6;

    const start = new Date(curr.setDate(first));
    const end = new Date(curr.setDate(last));
    return { start, end };
  }

  getCurrentMonthRange(): DateRange {
    const curr = new Date();
    const start = new Date(curr.getFullYear(), curr.getMonth(), 1);
    const end = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);
    return { start, end };
  }

  getDaysSinceToday(num: number) {
    const start = new Date();
    start.setDate(start.getDate() - num);
    start.setHours(0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59, 59);
    return { start, end };
  }
}
