import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[voyantDomainValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DomainValidatorDirective,
      multi: true
    }
  ]
})
export class DomainValidatorDirective implements Validator {
  @Input() voyantDomainValidator: string;
  validate(control: AbstractControl): ValidationErrors | null {
    const regex = RegExp(/\./g);
    const res = control.value.replace('www.', '').match(regex);
    if (!res || res.length < 2){
      return {notDomain: true};
    }
    return null;
  }
}
