import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';

// angular material
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { FileUploadModule } from 'ng2-file-upload';

import { sharedComponents } from './components';

// components / modal
import {
  ModalComponent,
  AddFaxLinesComponent,
  AddUsersComponent,
  ConfirmationComponent,
  FaxLinesPanelComponent,
  GroupsPanelComponent,
  MembersPanelComponent,
  GetFaxNumberComponent,
} from './components/modal';

// layout
import {
  NavigationComponent,
  MobileNavigationComponent,
  HeaderComponent,
  FooterComponent,
  LayoutComponent,
  ImpersonationPopupComponent,
  PrivacyPopupComponent,
} from '../layout';

// pipes
import { DashPhonePipe, MacAddressPipe, sharedPipes } from './pipes';

// directives
import { sharedDirectives } from './directives';

// validators
import {
  ConfirmEqualValidatorDirective,
  DomainValidatorDirective,
  PhoneNumberValidatorDirective,
} from './validators';

import { MatTooltipModule } from '@angular/material/tooltip';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatMenuModule,
  MatCheckboxModule,
  NgSelectModule,
  MatDialogModule,
  RouterModule,
  FileUploadModule,
  MatTooltipModule,
];

const modals = [
  ModalComponent,
  AddFaxLinesComponent,
  AddUsersComponent,
  ConfirmationComponent,
  FaxLinesPanelComponent,
  GroupsPanelComponent,
  MembersPanelComponent,
  GetFaxNumberComponent,
];

const declarations = [
  ...sharedComponents,
  FooterComponent,
  HeaderComponent,
  NavigationComponent,
  MobileNavigationComponent,
  HeaderComponent,
  FooterComponent,
  LayoutComponent,
  ImpersonationPopupComponent,
  PrivacyPopupComponent,

  ConfirmEqualValidatorDirective,
  DomainValidatorDirective,
  PhoneNumberValidatorDirective,
  ...sharedDirectives,
  ...modals,
  ...sharedPipes,
];

@NgModule({
    imports: [PdfViewerModule, ...modules],
    declarations: [...declarations],
    providers: [
        NgDialogAnimationService,
        DashPhonePipe,
        MacAddressPipe,
        DatePipe,
    ],
    exports: [...modules, ...sharedPipes, ...declarations]
})
export class SharedModule {}
