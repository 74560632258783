import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateUserData } from '../new-user.model';
import { User } from 'src/app/user/store/user.model';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as fromAuth from '@auth/store/auth.reducer';
import * as fromOps from '@ops/store/ops.reducer';
import { combineLatest, Observable } from 'rxjs';
import { AuthUser } from '@auth/store/auth.model';

@Component({
  selector: 'voyant-profile-info',
  templateUrl: './profile-info.component.html',
  styles: [
    `
      .voyant-radio {
        display: inline-block;
      }
    `,
  ],
})
export class ProfileInfoComponent implements OnInit {
  @ViewChild('userForm', { static: true }) userForm;
  @Input() stepper: CreateUserData;
  @Output() next = new EventEmitter();

  authUser$: Observable<AuthUser>;
  impersonating$: Observable<User>;

  userInfoForm: FormGroup;

  submitted = false;
  addFaxLine = false;
  userType: string = null;

  constructor(private fb: FormBuilder, private store: Store<fromApp.AppState>) {
    this.impersonating$ = this.store.select(fromOps.impersonating);
    this.authUser$ = this.store.select(fromAuth.authUser);
  }

  get UIF() {
    return this.userInfoForm.controls;
  }

  ngOnInit() {
    if (this.stepper) {
      this.userInfoForm = this.fb.group({
        email: [
          this.stepper.userInfo.email,
          [Validators.required, Validators.email],
        ],
        first_name: [this.stepper.userInfo.first_name, [Validators.required]],
        last_name: [this.stepper.userInfo.last_name, [Validators.required]],
        role: [this.stepper.userInfo.role, [Validators.required]],
      });

      combineLatest([this.impersonating$, this.authUser$]).subscribe(
        ([impersonating, authUser]) => {
          const user = impersonating || authUser;
          if (user?.role === 'manager') {
            this.stepper.userInfo.role = 'user';

            this.userInfoForm.patchValue({
              role: 'user',
            });
          }
          return user;
        }
      );
    }
  }

  onNext() {
    this.submitted = true;
    if (this.userInfoForm.valid) {
      this.next.emit({ userInfo: this.userInfoForm.value });
    }
  }
}
