import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as OpsActions from '@ops/store/ops.actions';

import { AuthService } from '@auth/auth.service';
import * as AuthActions from '@auth/store/auth.actions';
import * as fromApp from '@app-store/app.reducer';
import { delay, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { OpsService } from '@ops/store/ops.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable()
export class OpsEffects {
  checkImpersonation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpsActions.checkImpersonation),
      exhaustMap(() =>
        this._opsService
          .checkImpersonation()
          .pipe(map((res) => OpsActions.impersonateSuccess({ user: res.data })))
      )
    )
  );

  impersonate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpsActions.impersonate),
      exhaustMap(({ userId }) =>
        this._opsService.impersonateUser(userId).pipe(
          delay(500),
          switchMap(() => this._opsService.checkImpersonation()),
          map((res) => {
            this._authService.userRoleRedirect(res.data);
            return OpsActions.impersonateSuccess({ user: res.data });
          })
        )
      )
    )
  );

  unimpersonate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpsActions.unimpersonate),
      exhaustMap(() =>
        this._opsService.unimpersonate().pipe(
          map(() => {
            this.store.dispatch(AuthActions.checkAuthentication());
            return OpsActions.unimpersonateSuccess();
          })
        )
      )
    )
  );

  unimpersonateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpsActions.unimpersonateSuccess),
        tap(() => {
          setTimeout(() => {
            this.router.navigate(['account-management']);
          }, 200);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private _opsService: OpsService,
    private _authService: AuthService,
    private store: Store<fromApp.AppState>
  ) {}
}
