import { NgModule } from '@angular/core';
import { NumbersAllComponent } from './numbers-all/numbers-all.component';
import { SharedModule } from '../shared/shared.module';
import { UserService } from '../user';
import { BuyNumberComponent } from './modals/buy-number/buy-number.component';
import { NumbersTableComponent } from './numbers-table/numbers-table.component';
import { NumberStatisticsComponent } from './number-statistics/number-statistics.component';
import { PortModule } from '../port/port.module';
import { RouterModule } from '@angular/router';
import { NumberRoutingModule } from './numbers-routing.module';
import { NumbersPageComponent } from './numbers-page/numbers-page.component';
import { NumberAssigneeComponent } from './number-assignee/number-assignee.component';
import { NumberEditComponent } from './modals/number-edit/number-edit.component';

@NgModule({
  declarations: [
    NumbersAllComponent,
    BuyNumberComponent,
    NumbersTableComponent,
    NumberStatisticsComponent,
    NumbersPageComponent,
    NumberAssigneeComponent,
    NumberEditComponent,
  ],
  imports: [NumberRoutingModule, SharedModule, RouterModule, PortModule],
  providers: [UserService],
})
export class NumberModule {}
