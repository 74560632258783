import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Paging, pagingDefault } from '../../models';

@Component({
  selector: 'voyant-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() paginationConfig: Paging = pagingDefault;
  @Output() paramsChanged: EventEmitter<Paging> = new EventEmitter();
  options = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ];
  pages = [1];
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.hasOwnProperty('paginationConfig') &&
      changes.paginationConfig.currentValue
    ) {
      const base = 10;
      let pagConfig: { page: any; per_page: any; total: any } =
        changes.paginationConfig.currentValue;

      if (typeof pagConfig.page === 'string') {
        pagConfig = {
          ...pagConfig,
          page: parseInt(pagConfig.page, base),
        };
      }

      if (typeof pagConfig.per_page === 'string') {
        pagConfig = {
          ...pagConfig,
          per_page: parseInt(pagConfig.per_page, base),
        };
      }

      if (typeof pagConfig.total === 'string') {
        pagConfig = {
          ...pagConfig,
          total: parseInt(pagConfig.total, base),
        };
      }

      // converts api response fields to a number (page and per_page were returning strings)
      this.paginationConfig = { ...pagConfig };

      this.setPages(pagConfig.total, pagConfig.per_page);
    }
  }

  // per page dropdown selection
  perPageChanged(option) {
    if (option) {
      this.paramsChanged.emit({ page: 1, per_page: option.value });
    }
  }

  pageNumberChanged(page: number) {
    this.paramsChanged.emit({ ...this.paginationConfig, page });
  }

  nextPage(page: number) {
    this.pageNumberChanged(page + 1);
  }

  previousPage(page: number) {
    if (page > 1) {
      this.pageNumberChanged(page - 1);
    }
  }

  setPages(total: number, perPage: number) {
    if (!!total && !!perPage) {
      // get the total amount of pages, convert to array, and add 1 to each so that the page matches instead of the index
      this.pages = [...Array(Math.ceil(total / perPage)).keys()].map(
        (page) => page + 1
      );
    }
  }
}
