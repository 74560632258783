import { Injectable } from '@angular/core';
import { Group, GroupResponse } from './group.model';
import { HttpClient } from '@angular/common/http';
import { map, take, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../shared/services/environment.service';
import { PhoneNumber } from '../../number';
import {
  SearchParams,
  PhaxioResponse,
  pagingDefault,
} from '../../shared/models';
import { SearchService } from '../../shared/services';
import { Store } from '@ngrx/store';
import * as fromGroup from './group.reducer';
import * as GroupActions from './group.actions';
import { Dictionary } from '@ngrx/entity';
import { UserService, User } from 'src/app/user';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  groupUrl: string = this.env.apiUrls.faxApi + 'groups';
  defaultSearch: SearchParams = this._searchService.initSearchParams('name');

  constructor(
    private _searchService: SearchService,
    private _userService: UserService,
    private env: EnvironmentService,
    private http: HttpClient,
    private store: Store<fromGroup.State>
  ) {}

  fetchAll() {
    let phoneNumbers: Group[];
    this.store
      .select(fromGroup.selectAll)
      .pipe(take(1))
      .subscribe((s) => (phoneNumbers = s));
    return phoneNumbers;
  }

  getMap() {
    let phoneNumbers: Dictionary<Group>;
    this.store
      .select(fromGroup.selectEntities)
      .pipe(take(1))
      .subscribe((s) => (phoneNumbers = s));
    return phoneNumbers;
  }

  getGroups(
    urlParams: Partial<SearchParams> = this.defaultSearch
  ): Observable<GroupResponse> {
    const url = this.groupUrl;
    const params: any =
      urlParams && Object.keys(urlParams).length > 0
        ? { ...urlParams }
        : { ...this.defaultSearch };

    return this.http.get(url, { params }).pipe(
      debounceTime(350),
      take(1),
      map((response: GroupResponse) => {
        return response;
      })
    );
  }

  getGroup(groupId: string): Observable<PhaxioResponse> {
    const url = `${this.groupUrl}/${groupId}`;
    return this.http.get(url).pipe(
      map((res: PhaxioResponse) => {
        this.store.dispatch(GroupActions.addGroup({ payload: res.data }));
        return res;
      })
    );
  }

  getGroupNumbers(groupId: string) {
    const url = `${this.groupUrl}/${groupId}/phone_numbers`;
    return this.http.get(url).pipe(
      map((res: PhaxioResponse) => {
        const g: Group = { ...this.getMap()[groupId] };
        this.store.dispatch(
          GroupActions.updateGroup({
            group: {
              ...g,
              phone_numbers: res.data,
            },
          })
        );
        return res;
      })
    );
  }

  getGroupUsers(groupId: string) {
    const url = `${this.groupUrl}/${groupId}/users`;
    return this.http.get(url).pipe(
      map((res: PhaxioResponse) => {
        const g: Group = { ...this.getMap()[groupId] };
        this.store.dispatch(
          GroupActions.updateGroup({
            group: {
              ...g,
              users: res.data,
            },
          })
        );
        return res;
      })
    );
  }

  createGroup(groupPayload: any) {
    const url = this.groupUrl;
    return this.http.post(url, groupPayload).pipe(
      map((res: any) => {
        this.store.dispatch(GroupActions.addGroup({ payload: res.data }));
        return res;
      })
    );
  }

  updateGroup(
    groupId: string,
    obj: { name: string }
  ): Observable<PhaxioResponse> {
    const url = `${this.groupUrl}/${groupId}`;
    return this.http.put(url, obj).pipe(
      map((res: PhaxioResponse) => res)
    );
  }

  deleteGroup(groupId: string): Observable<PhaxioResponse> {
    const url = `${this.groupUrl}/${groupId}`;
    return this.http.delete(url).pipe(
      map((res: PhaxioResponse) => res)
    );
  }

  addPhoneNumbersToGroup(
    groupId: string,
    phoneNumbers: PhoneNumber[]
  ): Observable<PhaxioResponse> {
    const url = `${this.groupUrl}/${groupId}/phone_numbers`;
    return this.http.post(url, { phone_numbers: phoneNumbers }).pipe(
      map((res: PhaxioResponse) => res)
    );
  }

  removePhoneNumberFromGroup(
    groupId: string,
    phoneNumber: string
  ): Observable<PhaxioResponse> {
    const url = `${this.groupUrl}/${groupId}/phone_numbers/${phoneNumber}`;
    return this.http.delete(url).pipe(
      map((r: PhaxioResponse) => r)
    );
  }

  addUsersToGroup(groupId: string, users: string[]) {
    const url = `${this.groupUrl}/${groupId}/users`;
    return this.http.post(url, { id: users });
  }

  removeUserFromGroup(groupId: string, userId: string) {
    const url = `${this.groupUrl}/${groupId}/users/${userId}`;
    return this.http.delete(url);
  }

  getUserGroups(userId: string): Group[] {
    return this.fetchAll().filter((g: Group) => {
      for (const user of g.users) {
        if (user.id === userId) {
          return g;
        }
      }
    });
  }

  getGroupName(groupId: string): string {
    const g: Group = this.getMap()[groupId];
    return g ? g.name : '';
  }
}
