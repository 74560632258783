import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError, take, debounceTime } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { EnvironmentService } from '@shared/services';
import { FaxSearchParams, Fax, FaxResponse } from './fax.model';
import { pagingDefault, PhaxioResponse } from '@shared/models';
import { UtilityService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class FaxService {
  private faxUrl: string = this.env.apiUrls.faxApi + 'faxes';

  sentFaxesUpdated: Subject<Fax[]> = new Subject();
  receivedFaxesUpdated: Subject<Fax[]> = new Subject();

  constructor(
    private _utilityService: UtilityService,
    private env: EnvironmentService,
    private http: HttpClient
  ) {}

  getFaxes(urlParams: FaxSearchParams): Observable<FaxResponse> {
    let params: any =
      urlParams && Object.keys(urlParams).length > 0
        ? { ...urlParams }
        : { ...pagingDefault };
    // remove all params that do not have a truthy value
    params = [...Object.keys(params)].reduce((acc, key) => {
      if (params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    if (
      params.hasOwnProperty('direction') &&
      params.direction === 'received'
    ) {
      params.status = 'success';
    }

    return this.http.get(this.faxUrl, { params }).pipe(
      debounceTime(350),
      take(1),
      map((res: FaxResponse) => {
        res.data.map((fax: Fax) => {
          fax.created_at = this._utilityService.utcToIsosDate(fax.created_at);
          return fax;
        });
        return res;
      })
    );
  }

  getFax(faxId: string | number) {
    const url = this.faxUrl + `/${faxId}`;
    return this.http.get(url).pipe(
      map((res: PhaxioResponse) => {
        res.data.created_at = this._utilityService.utcToIsosDate(
          res.data.created_at
        );
        return res;
      })
    );
  }

  sendFax(faxObj: { name: string; from: string; to: string[]; file: File[] }) {
    const formData: any = new FormData();
    Object.keys(faxObj).forEach((key) => {
      if (key !== 'file') {
        formData.append(key, faxObj[key]);
      }
    });

    for (const file of faxObj.file) {
      formData.append('file[]', file);
    }
    const url = this.faxUrl;
    return this.http.post(url, formData);
  }

  faxPreview(faxId: number) {
    const url = this.faxUrl + `/${faxId}` + '/file';
    const params = { thumbnail: 'l' };
    return this.http.get(url, { params, responseType: 'blob' }).pipe(
      map((blob: Blob) => blob),
      catchError((e) => {
        // ignore preview errors;
        return of(false);
      })
    );
  }

  faxDownloadUrl(faxId: number, save: boolean = true): Observable<Blob> {
    const url = this.faxUrl + `/${faxId}` + '/file';
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((res: Blob) => {
        if (save) {
          saveAs(res, faxId + `.${res.type.replace('application/', '')}`);
        }
        return res;
      })
    );
  }
}
