import { FaxLogComponent } from './fax-log/fax-log.component';
import { RoleGuard } from '../auth/role.guard';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'log',
        canActivate: [RoleGuard],
        component: FaxLogComponent,
        data: {
          expectedRoles: ['admin', 'manager'],
        },
      },
    ]),
  ],
  exports: [RouterModule],
})
export class FaxRoutingModule {}
