import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, throwError, of } from 'rxjs';
import {
  map,
  mergeMap,
  catchError,
  take,
  switchMap,
  tap,
} from 'rxjs/operators';

import { PortService } from './port.service';
import * as PortActions from './port.actions';
import { PortTypes } from './port.types';
import * as fromPort from './port.reducer';

import { PhaxioResponse, Paging } from '@shared/models';
import { Store } from '@ngrx/store';
import { ToastrService } from '@shared/services';
import { Router } from '@angular/router';

@Injectable()
export class PortEffects {
  loadAllPorts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortActions.loadAllPorts),
      map((action) => action.payload),
      mergeMap((params: Paging) =>
        this._portService.getPorts(params).pipe(
          map((res: PhaxioResponse) => {
            return PortActions.loadAllSucess({ payload: res.data });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadPorts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortActions.loadPorts),
      map((action) => action.payload),
      mergeMap((params: Paging) =>
        this._portService.getPorts(params).pipe(
          map((res: PhaxioResponse) => {
            const loadAll =
              !this.getState().isReady && res.paging.total > res.data.length;

            const action = loadAll
              ? PortActions.loadAllPorts({
                  payload: {
                    per_page: res.paging.total,
                    page: 1,
                  },
                })
              : PortActions.loadAllSucess({ payload: res.data });

            this.store.dispatch(action);
            const { paging, data } = res;
            return PortActions.setPorts({ paging, data });
          }),
          catchError((errRes: any, source) =>
            of(PortActions.reportError({ error: errRes.error.message }))
          )
        )
      )
    )
  );

  loadMetrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortActions.loadPortsMetrics),
      mergeMap(() =>
        this._portService.getPortsMetrics().pipe(
          map((res: PhaxioResponse) =>
            PortActions.setPortsMetrics({ payload: res.data })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createPort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortActions.createPort),
      switchMap((action) =>
        this._portService.createPort(action.payload).pipe(
          map((res: PhaxioResponse) => {
            if (action.hasOwnProperty('loa') && action.loa) {
              return PortActions.updatePort({
                port: res.data,
                payload: action.loa,
              });
            } else {
              return PortActions.addPort({ payload: res.data });
            }
          }),
          catchError((errRes: any, source) =>
            of(PortActions.reportError({ error: errRes.error.message }))
          )
        )
      )
    )
  );

  updatePort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortActions.updatePort),
      switchMap((action) => {
        const loa = new File(
          [action.payload],
          `port_${action.port.id}_${new Date().getTime()}_loa.pdf`
        );

        return this._portService
          .uploadLetterOfAgreement(action.port.id, loa)
          .pipe(
            map((res: PhaxioResponse) => {
              this._toastrService.notify(
                'success',
                'Port request successfully created'
              );
              return PortActions.addPort({ payload: action.port });
            }),
            catchError((errRes: any, source) =>
              of(PortActions.reportError({ error: errRes.error.message }))
            )
          );
      })
    )
  );

  portRequestAdded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PortActions.addPort),
        tap(() => this.router.navigate(['/numbers/ports']))
      ),
    { dispatch: false }
  );

  reportError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PortTypes.REPORT_ERROR),
        map(({ error }) => {
          this._toastrService.notify('error', error);
          return throwError(error);
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromPort.State>,
    private _portService: PortService,
    private _toastrService: ToastrService
  ) {}

  getState() {
    let state: fromPort.State;
    this.store
      .select(fromPort.getState)
      .pipe(take(1))
      .subscribe((s) => (state = s));
    return state;
  }
}
