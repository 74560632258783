import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'macAddress',
})
export class MacAddressPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    const macAddress = value || null;

    if (macAddress !== null) {
      let deviceMac = macAddress.replace(/\W/g, '');

      // Change all letters to upper case
      deviceMac = deviceMac.toUpperCase();

      // Input string should be one less in lenght than a qualified MAC address (17)
      if (deviceMac.length >= 3 && deviceMac.length <= 16) {
        // Remove all but alphanumeric characters
        deviceMac = deviceMac.replace(/\W/gi, '');

        // Append a colon after every two characters
        deviceMac = deviceMac.replace(/(.{2})/g, '$1:');
      }

      deviceMac = deviceMac.substr(0, 17);

      if (deviceMac.charAt(deviceMac.length - 1) === ':') {
        deviceMac = deviceMac.substr(0, deviceMac.length - 1);
      }

      return deviceMac;
    }
  }
}
