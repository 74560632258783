import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PhaxioResponse } from '../shared/models';
import { EnvironmentService, UtilityService } from '../shared/services';
import {
  VarAccount,
  VarAccountsResponse,
  VarProfile,
  VarProfileResponse,
  VarPhoneNumberResponse,
} from './var.model';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import { CustomHttpParamEncoder } from '../shared/custom-http-params-encoder.encoder';
import { AuthService } from '../auth/auth.service';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class VarService {
  isNewAccountValues = {
    logo_url: null,
    // primary_color: null,
    // header_background_color: null,
    // header_text_theme: null
  };
  private FAX_API_URL = this.env.apiUrls.faxApi;
  private VAR_URL = `${this.FAX_API_URL}var`;
  private VARS_URL = `${this.FAX_API_URL}vars`;

  varProfilePublic: Subject<any> = new Subject();
  varProfile: BehaviorSubject<VarProfile | null> = new BehaviorSubject(null);
  varAccounts: BehaviorSubject<VarAccount[] | null> = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private http: HttpClient,
    private env: EnvironmentService,
    private _authService: AuthService,
    private _utilityService: UtilityService,
    private store: Store<fromApp.AppState>,
    private title: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.store.select('auth').subscribe((authState) => {
      const user = authState.loggedInUser;
      if (authState.isVar) {
        if (user && user.role !== 'var_admin') {
          this.getPublicProfile(window.location.hostname).subscribe();
        } else {
          this.getProfile().subscribe();
          this.getAccounts().subscribe();
        }
      } else {
        if (user && user.role === 'var_admin') {
          this.getProfile().subscribe();
          this.getAccounts().subscribe();
        }
      }
    });
  }

  getProfile(): Observable<VarProfileResponse> {
    const url = `${this.VAR_URL}`;
    return this.http.get(url).pipe(
      map((res: VarProfileResponse) => {
        this.patchNewAccount(res.data);
        if (this.isNewAccount()) {
          this.router.navigate(['vars/profile']);
        }
        this.varProfile.next(res.data);
        return res;
      })
    );
  }

  getPublicProfile(domain): Observable<PhaxioResponse> {
    const url = `${this.VAR_URL}/public_show`;
    return this.http.get<PhaxioResponse>(url, { params: { domain } }).pipe(
      map((res: PhaxioResponse) => {
        this.varProfilePublic.next(res.data);
        return res;
      })
    );
  }

  updateProfile(payload: VarProfile): Observable<PhaxioResponse> {
    const formData: any = new FormData();
    Object.keys(payload).forEach((key) => {
      if (key !== 'logo') {
        formData.append(key, payload[key]);
      }
    });
    if (payload.logo) {
      formData.append('logo', payload.logo);
    }

    const url = `${this.VAR_URL}`;
    return this.http.put(url, formData).pipe(
      map((res: PhaxioResponse) => {
        this.patchNewAccount(res.data);
        this.varProfile.next(res.data);
        return res;
      })
    );
  }

  getAccounts(): Observable<VarAccountsResponse> {
    const url = `${this.VARS_URL}/accounts`;
    return this.http.get(url).pipe(
      map((res: VarAccountsResponse) => {
        if (res.success) {
          res.data.sort((a: VarAccount, b: VarAccount) =>
            a.name > b.name ? 1 : -1
          );
          this.varAccounts.next(res.data);
        }
        return res;
      })
    );
  }

  getAccount(accountId: string | number): Observable<PhaxioResponse> {
    const url = `${this.VARS_URL}/accounts/${accountId}`;
    return this.http.get<PhaxioResponse>(url);
  }

  createAccount(payload: VarAccount): Observable<PhaxioResponse> {
    const url = `${this.VARS_URL}/accounts`;
    return this.http.post(url, payload).pipe(
      map((res: PhaxioResponse) => {
        if (res.success) {
          this.getAccounts().subscribe();
        }
        return res;
      })
    );
  }

  updateAccount(
    accountId: string | number,
    payload: VarAccount
  ): Observable<PhaxioResponse> {
    const url = `${this.VARS_URL}/accounts/${accountId}`;
    return this.http.put(url, payload).pipe(
      map((res: PhaxioResponse) => {
        if (res.success) {
          this.getAccounts().subscribe();
        }
        return res;
      })
    );
  }

  deleteAccount(accountId: string | number): Observable<PhaxioResponse> {
    const url = `${this.VARS_URL}/accounts/${accountId}`;
    return this.http.delete(url).pipe(
      map((res: PhaxioResponse) => {
        if (res.success) {
          this.getAccounts().subscribe();
        }
        return res;
      })
    );
  }

  isNewAccount() {
    for (const o in this.isNewAccountValues) {
      if (!this.isNewAccountValues[o]) {
        return true;
      }
    }
    return false;
  }

  patchNewAccount(obj: VarProfile) {
    Object.keys(this.isNewAccountValues).forEach((key: string) => {
      if (obj.hasOwnProperty(key) && obj[key]) {
        this.isNewAccountValues[key] = obj[key];
      }
    });
  }

  activateDomain() {
    const url = `${this.VAR_URL}var/activate_domain`;
    return this.http.post(url, {});
  }

  getBillingData(accId: number | null, d: any): Observable<PhaxioResponse> {
    const { page, per_page, total, fromDate, toDate } = d;
    const params: any = {
      page: page.toString(),
      per_page: per_page.toString(),
      total: total.toString(),
      created_before: toDate.toUTCString(),
      created_after: fromDate.toUTCString(),
    };

    if (accId) {
      params.account_id = accId.toString();
    }

    const url = `${this.VARS_URL}/billing_events`;

    return this.http
      .get(url, { params })
      .pipe(map((res: PhaxioResponse) => res));
  }

  getBillingDataCsv(req: {
    account_id?: string;
    toDate: Date;
    fromDate: Date;
  }): Observable<any> {
    const url = `${this.VARS_URL}/billing_events.csv`;
    const { account_id, toDate, fromDate } = req;

    const params = {};
    if (!!account_id) {
      params['account_id'] = account_id;
    }
    params['created_before'] = toDate.toUTCString();
    params['created_after'] = fromDate.toUTCString();

    return this.http.get(url, { responseType: 'text', params });
  }

  phoneNumberLookup(number: string): Observable<VarPhoneNumberResponse> {
    const url = `${this.VARS_URL}/phone_numbers`;
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    });
    params = params.append('number', number);
    return this.http
      .get(url, { params })
      .pipe(map((res: VarPhoneNumberResponse) => res));
  }
}
