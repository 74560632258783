import { createAction, props } from '@ngrx/store';
import { PhoneNumber, PhoneNumberMetrics } from './number.model';
import { Paging } from '@shared/models';
import { NumberTypes } from './number.types';

export const loadPhoneNumbers = createAction(
  NumberTypes.LOAD_NUMBERS,
  props<{ payload: Partial<Paging> }>()
);

export const setPhoneNumbers = createAction(
  NumberTypes.SET_NUMBERS,
  props<{ paging: Paging; numbers: PhoneNumber[] }>()
);

export const updateSearchCache = createAction(
  NumberTypes.UPDATE_SEARCH_CACHE,
  props<{ numbers: PhoneNumber[] }>()
);

export const provisionPhoneNumber = createAction(
  NumberTypes.PROVISION_NUMBER,
  props<{ payload: Partial<PhoneNumber> }>()
);

export const provisionManyPhoneNumbers = createAction(
  NumberTypes.PROVISION_MANY_NUMBERS,
  props<{
    quantity: number;
    req: { area_code: string; is_hipaa: boolean; plan?: string };
  }>()
);

export const provisionManyPhoneNumbersFinished = createAction(
  NumberTypes.PROVISION_MANY_NUMBERS_FINISHED
);
export const provisionManyPhoneNumbersDone = createAction(
  NumberTypes.PROVISION_MANY_NUMBERS_DONE
);

export const addPhoneNumberSuccess = createAction(
  NumberTypes.ADD_NUMBER_SUCCESS,
  props<{ payload: PhoneNumber; gettingMany: boolean }>()
);

export const addPhoneNumberFail = createAction(NumberTypes.ADD_NUMBER_FAIL);

export const updatePhoneNumber = createAction(
  NumberTypes.UPDATE_NUMBER,
  props<{ payload: PhoneNumber }>()
);

export const updatePhoneNumberSuccess = createAction(
  NumberTypes.UPDATE_NUMBER_SUCCESS,
  props<{ payload: PhoneNumber }>()
);

export const deletePhoneNumbers = createAction(NumberTypes.DELETE_NUMBERS);
export const deletePhoneNumber = createAction(
  NumberTypes.DELETE_NUMBER,
  props<{ number: string }>()
);

export const deleteSuccess = createAction(
  NumberTypes.DELETE_SUCCESS,
  props<{ numbers: string[] }>()
);

export const selectPhoneNumber = createAction(
  NumberTypes.SELECT_NUMBER,
  props<{ number: PhoneNumber }>()
);

export const unselectPhoneNumber = createAction(
  NumberTypes.UNSELECT_NUMBER,
  props<{ number: PhoneNumber }>()
);

export const selectAllPhoneNumbers = createAction(
  NumberTypes.SELECT_ALL_NUMBERS
);

export const unselectAllPhoneNumbers = createAction(
  NumberTypes.UNSELECT_ALL_NUMBERS
);

// Metrics

export const getNumbersMetrics = createAction(NumberTypes.GET_NUMBERS_METRICS);

export const getNumbersMetricsSuccess = createAction(
  NumberTypes.GET_NUMBERS_METRICS_SUCCESS,
  props<{ data: PhoneNumberMetrics }>()
);
