import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { AuthUser } from '@auth/store/auth.model';
import * as OpsActions from './ops.actions';

export interface State {
  impersonating: AuthUser | null;
}

const initialState: State = {
  impersonating: null,
};

export const opsReducer = createReducer(
  initialState,
  on(OpsActions.impersonateSuccess, (state, { user }) => {
    return {
      ...state,
      impersonating: user,
    };
  }),
  on(OpsActions.unimpersonateSuccess, (state) => ({
    ...state,
    impersonating: null,
  }))
);

export const getState = createFeatureSelector<State>('ops');
export const impersonating = createSelector(getState, (s) => s.impersonating);
