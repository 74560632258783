import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'faxTitle',
})
export class FaxTitlePipe implements PipeTransform {
  transform(value: any): any {
    if (!value || typeof value !== 'string') {
      return value;
    }

    value = value.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    const start = value.indexOf('|');
    if (start > -1) {
      const re = new RegExp(value.substr(start + 1), 'gi');
      value = value.replace(re, '<i>$&</i>');
    }
    return value;
  }
}
