import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaxActivityComponent } from './fax/fax-activity/fax-activity.component';
import { LayoutComponent } from './layout/layout.component';

import { AuthGuard } from './auth/auth.guard';
import { RoleGuard } from './auth/role.guard';
import { FaxPreviewComponent } from './fax/fax-preview/fax-preview.component';
import { DepartmentsAllComponent } from './department/departments-all/departments-all.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'dashboard',
        component: FaxActivityComponent,
        canActivate: [RoleGuard],
        children: [{ path: ':id', component: FaxPreviewComponent }],
        data: {
          expectedRoles: ['user', 'manager', 'admin'],
        },
      },
      {
        path: '',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: '',
        loadChildren: () => import('./ops/ops.module').then((m) => m.OpsModule),
      },
      {
        path: 'customer',
        loadChildren: () =>
          import('./customer/customer.module').then((m) => m.CustomerModule),
      },
      {
        path: 'departments',
        component: DepartmentsAllComponent,
        canActivate: [RoleGuard],
        data: {
          expectedRoles: ['admin', 'manager'],
        },
      },
      {
        path: 'fax',
        loadChildren: () => import('./fax/fax.module').then((m) => m.FaxModule),
      },
      {
        path: 'manage',
        loadChildren: () =>
          import('./manage/manage.module').then((m) => m.ManageModule),
      },
      {
        path: 'numbers',
        loadChildren: () =>
          import('./number/number.module').then((m) => m.NumberModule),
      },
      {
        path: 'vars',
        loadChildren: () => import('./var/var.module').then((n) => n.VarModule),
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
