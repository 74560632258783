export interface PortValues {
  business: {
    name_of_business: string;
    account_identifier: string;
    port_out_pin: string;
    billing_address1: string;
    billing_address2: string;
    billing_city: string;
    billing_state: string;
    billing_zip: string;
  };
  contact: {
    name_on_account: string;
    contact_email: string;
    contact_number: string;
    billing_number: string;
  };
  port: {
    provider_name: string;
    requested_for: Date;
    port_type: string;
    port_numbers: string;
    legal_agreement: boolean;
    authorized_signer: string;
    bill_received_at: Date;
    esig: boolean;
  };
}

export const portDefaults: PortValues = {
  business: {
    name_of_business: null,
    account_identifier: null,
    port_out_pin: null,
    billing_address1: null,
    billing_address2: null,
    billing_city: null,
    billing_state: null,
    billing_zip: null,
  },
  contact: {
    name_on_account: null,
    contact_email: null,
    contact_number: null,
    billing_number: null,
  },
  port: {
    provider_name: null,
    requested_for: null,
    port_type: 'residential',
    port_numbers: null,
    legal_agreement: false,
    authorized_signer: null,
    bill_received_at: new Date(),
    esig: false,
  },
};

export interface Config {
  loading: boolean;
  submitted: boolean;
  portType: 'residential' | 'business';
  signature: string;
  initialValues: PortValues;
}
