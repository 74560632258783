import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

interface Range {
  fromDate: Date;
  toDate: Date;
}

@Component({
  selector: 'voyant-csv-date-selector',
  templateUrl: './csv-date-selector.component.html',
  styleUrls: ['./csv-date-selector.component.scss'],
})
export class CsvDateSelectorComponent {
  selectedDates: Range;

  constructor(public dialogRef: MatDialogRef<Range>) {}

  onDatesSelected(e: { fromDate: Date; toDate: Date }) {
    this.selectedDates = { ...e };
  }

  onSubmit() {
    if (this.selectedDates) {
      this.dialogRef.close(this.selectedDates);
    }
  }
}
