import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { VarProfile } from '../../var.model';
import { VarService } from '../../var.service';

@Component({
  selector: 'voyant-var-profile-branding',
  templateUrl: './var-profile-branding.component.html',
  styleUrls: ['./var-profile-branding.component.scss'],
})
export class VarProfileBrandingComponent implements OnInit, OnChanges {
  fontsReady = false;
  btn: any = {
    primary: { hover: false },
    outline: { hover: false },
  };
  @Input() form: FormGroup;
  @Output() save: EventEmitter<any> = new EventEmitter();
  @ViewChild('brandingForm', { static: true }) brandingForm;

  branding: FormGroup;
  textColorOptions = [
    { value: 'light', label: 'Light' },
    { value: 'dark', label: 'Dark' },
  ];

  config = {
    initialValues: {
      logo: '',
      header_text_theme: 'light',
    },
    saveDisabled: true,
  };

  get b() {
    return this.branding.controls;
  }

  constructor(private fb: FormBuilder, private _varService: VarService) {
    this.branding = this.fb.group({
      name: [''],
      header_background_color: [
        '',
        [Validators.required, Validators.minLength(7)],
      ],
      header_text_theme: ['light', [Validators.required]],
      primary_color: ['', [Validators.required, Validators.minLength(7)]],
      logo: [''],
    });
  }

  onUpdateBranding() {
    this.form.patchValue(this.branding.value);
    this.config.saveDisabled = true;
    this.config.initialValues = { ...this.branding.value };
    this.save.emit(this.form.value);
  }

  ngOnInit() {
    this.branding.valueChanges.subscribe({
      next: (changes: any) => {
        for (const prop in changes) {
          if (changes[prop] === this.config.initialValues[prop]) {
            this.branding.get(prop).markAsPristine();
          }
        }
        this.config.saveDisabled = !this.branding.dirty;
      },
    });
    this._varService.varProfile.subscribe((profile: VarProfile) => {
      if (profile) {
        const obj = {};
        Object.keys(profile).forEach((key) =>
          !!profile[key] ? (obj[key] = profile[key]) : null
        );
        this.branding.patchValue(obj);
        this.branding.markAsPristine();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.branding.patchValue(changes.form.currentValue.value);
    this.config.initialValues = {
      ...this.config.initialValues,
      ...changes.form.currentValue.value,
    };
  }
}
