import { createAction, props } from '@ngrx/store';
import { User, UserStatus } from './user.model';
import { Paging } from '@shared/models';
import { UserActionTypes } from './user.types';
import { PhoneNumber } from 'src/app/number';
import { Group } from 'src/app/group';
import { NewUser } from '@user/store/user.model';

export const loadUsers = createAction(
  UserActionTypes.LOAD_USERS,
  props<{ payload: Partial<Paging> }>()
);

export const loadUsersDetails = createAction(
  UserActionTypes.LOAD_USERS_DETAILS,
  props<{ users: User[] }>()
);

export const updateUserDetails = createAction(
  UserActionTypes.UPDATE_USERS_DETAILS,
  props<{ numbers: any }>()
);

export const setUsers = createAction(
  UserActionTypes.SET_USERS,
  props<{ paging: Paging; users: User[] }>()
);

export const createUser = createAction(
  UserActionTypes.CREATE_USER,
  props<{ payload: NewUser }>()
);

export const createUserSuccess = createAction(
  UserActionTypes.CREATE_USER_SUCCESS,
  props<{ message: string }>()
);

export const addUser = createAction(
  UserActionTypes.ADD_USER,
  props<{ payload: User }>()
);

export const sendInviteEmail = createAction(
  UserActionTypes.SEND_INVITE_EMAIL,
  props<{ id: string }>()
);

export const updateUser = createAction(
  UserActionTypes.UPDATE_USER,
  props<{ id: string; payload: Partial<User> }>()
);

export const updateUserStatus = createAction(
  UserActionTypes.UPDATE_USER_STATUS,
  props<{ id: string; status: UserStatus }>()
);

export const updateUserEdit = createAction(
  UserActionTypes.UPDATE_EDIT_USER,
  props<{
    compare: {
      user: User;
      numbers: PhoneNumber[];
      groups: Group[];
    };
    payload: {
      user: User;
      numbers: PhoneNumber[];
      groups: Group[];
    };
  }>()
);

export const updateUserSuccess = createAction(
  UserActionTypes.UPDATE_USER_SUCCESS,
  props<{ payload: User }>()
);

export const deleteUser = createAction(
  UserActionTypes.DELETE_USER,
  props<{ payload: string }>()
);

export const deleteUserSuccess = createAction(
  UserActionTypes.DELETE_USER_SUCCESS,
  props<{ message: string }>()
);

export const setSelectedUser = createAction(
  UserActionTypes.SET_SELECTED_USER,
  props<{ payload: User }>()
);

export const setEdit = createAction(
  UserActionTypes.SET_EDIT,
  props<{ payload: boolean }>()
);

export const endSelectedUser = createAction(UserActionTypes.END_SELECTED_USER);

export const reportError = createAction(
  UserActionTypes.REPORT_ERROR,
  props<{ message: string; data?: any }>()
);

export const reportSuccess = createAction(
  UserActionTypes.REPORT_SUCCESS,
  props<{ message: string }>()
);
