import { Injectable } from '@angular/core';
import { PhoneNumber, PhoneNumberResponse } from './number.model';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, debounceTime, switchMap, take } from 'rxjs/operators';
import { EnvironmentService } from '../../shared/services';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { pagingDefault, PhaxioResponse } from '../../shared/models';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as numberSelectors from '@number/store/number.selectors';
import * as NumberActions from '@number/store/number.actions';
import { Dictionary } from '@ngrx/entity';

@Injectable({
  providedIn: 'root',
})
export class NumberService {
  private API_URL = this.env.apiUrls.faxApi;
  private NUMBER_URL = `${this.API_URL}phone_numbers`;

  constructor(
    private env: EnvironmentService,
    private http: HttpClient,
    private store: Store<fromApp.AppState>
  ) {}

  fetchAll() {
    let phoneNumbers: PhoneNumber[];
    this.store
      .select(numberSelectors.selectAll)
      .pipe(take(1))
      .subscribe((s) => (phoneNumbers = s));
    return phoneNumbers;
  }

  getMap() {
    let phoneNumbers: Dictionary<PhoneNumber>;
    this.store
      .select(numberSelectors.selectEntities)
      .pipe(take(1))
      .subscribe((s) => (phoneNumbers = s));
    return phoneNumbers;
  }

  getNumbers(urlParams?: any): Observable<any> {
    const url = this.NUMBER_URL;
    const params =
      urlParams && Object.keys(urlParams).length > 0
        ? { ...urlParams }
        : { ...pagingDefault };

    return this.http.get(url, { params }).pipe(
      debounceTime(350),
      take(1),
      map((res: PhoneNumberResponse) => {
        this.store.dispatch(
          NumberActions.updateSearchCache({ numbers: res.data })
        );
        return res;
      })
    );
  }

  getNumberFor(
    phonenumberableType: 'User' | 'Group' | 'Ata',
    phonenumberableId: string
  ) {
    const url = this.NUMBER_URL;
    return this.http.get(url, {
      params: {
        phonenumberable_type: phonenumberableType,
        phonenumberable_id: phonenumberableId,
      },
    });
  }

  updateNumber(
    phoneNumber: string,
    obj: { is_hipaa: boolean; revised_plan?: string }
  ): Observable<PhaxioResponse> {
    const url = `${this.NUMBER_URL}/${phoneNumber}`;
    return this.http.put(url, obj).pipe(
      map((res: PhaxioResponse) => {
        return res;
      })
    );
  }

  deleteNumber(phoneNumber: string) {
    const url = `${this.NUMBER_URL}/${phoneNumber}`;
    return this.http.delete(url);
  }

  provisionNumber(num: Partial<PhoneNumber>) {
    const url = this.NUMBER_URL;
    return this.http.post(url, num);
  }

  getNumber(phoneNum: string): PhoneNumber | null {
    const pNumberObj = this.getMap()[phoneNum];
    return pNumberObj ? pNumberObj : null;
  }

  getUserNumbers(userId: string): PhoneNumber[] {
    return this.fetchAll().filter(
      (num) => num.hasOwnProperty('user_id') && num.user_id === userId
    );
  }

  areaCodeIsAvailableValidator(): AsyncValidatorFn {
    const url = `${this.API_URL}area_codes`;
    return (control: AbstractControl) => {
      if (control.value && control.value.length === 3) {
        const areaCode = control.value;
        return this.http.get(url).pipe(
          debounceTime(350),
          take(1),
          switchMap((res: any) => {
            const areaCodeIndex = res.data.indexOf(areaCode);
            return areaCodeIndex > -1 ? of(null) : of({ areaCode: true });
          })
        );
      } else {
        return of(null);
      }
    };
  }

  getPhoneNumbersMetrics() {
    const url = `${this.NUMBER_URL}/metrics`;
    return this.http.get(url);
  }

  numberIsAvailable(num: PhoneNumber) {
    return (
      !num.hasOwnProperty('user_id') &&
      !num.hasOwnProperty('group_id') &&
      !num.hasOwnProperty('ata_id')
    );
  }

  getWholeSalePriceList(): {
    type: string;
    number_of_pages: number;
    price: number;
  }[] {
    return Object.keys(this.env.pricing.wholesale).map((key) => ({
      type: key,
      ...this.env.pricing.wholesale[key],
    }));
  }
}
