import * as AtaActions from './ata.actions';
import { Ata } from './ata.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  createFeatureSelector,
  createReducer,
  on,
  createSelector,
} from '@ngrx/store';
import { Paging, pagingDefault, PhaxioResponse } from '@shared/models';

export interface State extends EntityState<Ata> {
  loading: boolean;
  creating: boolean;
  provision_urls: { brand: string; url: string }[];
  paging: Paging;
  error: string;
}

export const adapter: EntityAdapter<Ata> = createEntityAdapter<Ata>({
  selectId: (a: Ata) => a.id,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  creating: false,
  provision_urls: null,
  paging: pagingDefault,
  error: null,
});

export const ataReducer = createReducer(
  initialState,

  on(AtaActions.loadAtas, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(AtaActions.setCurrentAtas, (state, { paging, data }) => {
    return adapter.setAll(data, {
      ...state,
      paging,
      loading: false,
    });
  }),

  // Create
  on(AtaActions.createAta, (state) => {
    return {
      ...state,
      creating: true,
    };
  }),

  on(AtaActions.createAtaSuccess, (state, { ata }) => {
    return adapter.addOne(ata, { ...state, creating: false });
  }),

  // Update
  on(AtaActions.updateAtaSuccess, (state, { ata }) => {
    return adapter.updateOne(
      {
        id: ata.id,
        changes: ata,
      },
      state
    );
  }),

  on(AtaActions.updateAtasCurrentDetails, (state, { payload }) => {
    const updates = payload.map((res: PhaxioResponse) => {
      const {
        expiry_time,
        group,
        last_registered,
        sip_uri,
        user_agent,
        user_phone_number,
      } = res.data;

      return {
        id: res.message.replace('Ata ', ''),
        changes: {
          expiry_time,
          group,
          last_registered,
          sip_uri,
          user_agent,
          user_phone_number,
        },
      };
    });

    return adapter.updateMany(updates, {
      ...state,
    });
  }),

  on(AtaActions.loadProvisionUrlsSuccess, (state, { payload }) => {
    return {
      ...state,
      provision_urls: [
        ...Object.keys(payload).map((key: string) => {
          return {
            brand: key,
            url: payload.hasOwnProperty(key) ? payload[key] : null,
          };
        }),
      ],
    };
  }),

  on(AtaActions.reportError, (state, { error }) => {
    return {
      ...state,
      error,
      creating: false,
    };
  })
);

export const getState = createFeatureSelector<State>('ata');

export const { selectIds, selectAll, selectEntities, selectTotal } =
  adapter.getSelectors(getState);

export const selectEntity = (id) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectProvisionUrls = createSelector(
  getState,
  (s) => s.provision_urls
);
