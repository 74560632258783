import { createAction, props } from '@ngrx/store';
import { Ata } from './ata.model';
import { Paging, PhaxioResponse, SearchParams } from '@shared/models';

const label = '[ATA]';

export const loadAtasDetails = createAction(
  `${label} Load Atas Details`,
  props<{ atas: Ata[] }>()
);

export const updateAtasCurrentDetails = createAction(
  `${label} Update Atas Details`,
  props<{ payload: PhaxioResponse[] }>()
);

export const loadAtaStatusDetails = createAction(
  `${label} Load Ata Status Details`,
  props<{ id: string }>()
);

export const loadAtas = createAction(
  `${label} Load Atas`,
  props<{ payload: Partial<SearchParams> }>()
);

export const setCurrentAtas = createAction(
  `${label} Set Current Atas`,
  props<{ paging: Paging; data: Ata[] }>()
);

export const createAta = createAction(
  `${label} Create Ata`,
  props<{ ata: Ata }>()
);

export const createAtaSuccess = createAction(
  `${label} Create Ata Success`,
  props<{ ata: Ata }>()
);

export const updateAta = createAction(
  `${label} Update Ata`,
  props<{ id: string; ata: Ata; compare: Ata }>()
);

export const updateAtaSuccess = createAction(
  `${label} Update Ata Success`,
  props<{ ata: Ata }>()
);

export const deleteAta = createAction(
  `${label} Delete Ata`,
  props<{ id: string }>()
);

export const deactivateAta = createAction(
  `${label} Deactivate Ata`,
  props<{ id: string }>()
);

export const reportError = createAction(
  `${label} Report Error`,
  props<{ error: string }>()
);

// Provision Urls
export const loadProvisionUrls = createAction(`${label} Load Provision Urls`);
export const loadProvisionUrlsSuccess = createAction(
  `${label} Load Provision Urls Success`,
  props<{ payload: any }>()
);
