import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import countryCodes from '../../../assets/data/country-codes.json';
import countryFlags from '../../../assets/data/country-flags.json';
import canadaAreaCodes from '../../../assets/data/area-codes-canada.json';

import { CountryCode } from 'libphonenumber-js';

export interface Country {
  name: string;
  dial_code: string;
  code: CountryCode;
  emoji: any;
}

@Injectable({
  providedIn: 'root',
})
export class CountryCodeService {
  constructor(private http: HttpClient) {}

  getCountryByCode(codeStr: string): Country[] {
    codeStr = codeStr.replace(/\s/g, '');

    let search: any[] = countryCodes.filter((c: Country) => {
      if (c.dial_code) {
        return codeStr.includes(c.dial_code.replace(/[^0-9+]/gi, ''));
      }
      return false;
    });

    if (search.length > 1 && codeStr.includes('+1') && codeStr.length > 4) {
      const areaCodes = canadaAreaCodes.map((a: any) =>
        a['area-code'].toString()
      );
      const isCanadianNumber = areaCodes.includes(codeStr.substr(2, 4));
      search = search.filter((c: Country) =>
        isCanadianNumber ? c.code === 'CA' : c.code === 'US'
      );
    }

    if (search && search.length) {
      search.map((c) => {
        const flag: any[] = countryFlags.filter((f) => f.code === c.code);
        c.emoji = flag[0].emoji;
        return c;
      });
    }
    return search;
  }
}
