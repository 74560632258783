import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phonePreview'
})
export class PhonePreviewPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value ? `${value} - xxx - xxxx` : value;
  }
}
