import { Directive } from '@angular/core';
import {
  Validator,
  AbstractControl,
  ValidationErrors,
  NG_VALIDATORS,
} from '@angular/forms';
import { parsePhoneNumber } from 'libphonenumber-js';

@Directive({
  selector: '[voyantPhoneNumberValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneNumberValidatorDirective,
      multi: true,
    },
  ],
})
export class PhoneNumberValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.length >= 10) {
      const parsedNum = parsePhoneNumber(control.value, 'US');
      if (!parsedNum.isValid()) {
        return { phone_number: true };
      }
    } else if (control.value && control.value.length < 10) {
      return { phone_number: true };
    } else {
      return null;
    }
  }
}
