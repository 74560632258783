import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { map, catchError, take } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { User } from '@user/store/user.model';
import { EnvironmentService } from '@shared/services';
import { Store } from '@ngrx/store';

import * as fromApp from '@app-store/app.reducer';
import * as AuthActions from '@auth/store/auth.actions';
import * as authReducer from '@auth/store/auth.reducer';
import * as opsReducer from '@ops/store/ops.reducer';
import { AuthUser } from '@auth/store/auth.model';

import { PhaxioResponse } from '@shared/models';

interface AuthResponse extends PhaxioResponse {
  data: AuthUser;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authChecker: any;
  private API_URL = this.env.apiUrls.faxApi;

  notVarHosts: string[] = [
    'fax.voyant-staging.com',
    'fax.voyant.com',
    'fax.inteliquent.com',
    'staging-fax.inteliquent.com',
    'localhost',
  ];

  isVar = !this.notVarHosts.includes(window.location.hostname);

  settings = {
    utcTime: false,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private env: EnvironmentService,
    private cookieService: CookieService,
    private store: Store<fromApp.AppState>
  ) {
    this.authChecker = setInterval(() => {
      this.isAuthenticated().subscribe((isAuthenticated) => {
        if (!isAuthenticated) {
          this.logout().subscribe();
        }
      });
      // 3 minutes
    }, 1000 * 60 * 3);
  }

  getLoggedInUser(): AuthUser | null {
    let state: authReducer.State;
    this.store
      .select('auth')
      .pipe(take(1))
      .subscribe((s) => (state = s));
    return state.loggedInUser;
  }

  getImpersonatingUser(): User | null {
    let state: opsReducer.State;
    this.store
      .select('ops')
      .pipe(take(1))
      .subscribe((s) => (state = s));
    return state.impersonating;
  }

  login(email, password): Observable<AuthResponse> {
    const url = `${this.API_URL}login`;
    return this.http
      .post(url, { email, password })
      .pipe(map((res: AuthResponse) => res));
  }

  checkSSO(email) {
    const url = `${this.API_URL}sso_authenticate`;
    return this.http.post(url, { email }).pipe(
      map((res: any) => res),
      catchError((res) => of(res.error))
    );
  }

  // loginSSO(code) {
  //   const url = `${this.API_URL}sso_callback`;
  //   return this.http.post(url, { code });
  // }

  logout() {
    const url = `${this.API_URL}logout`;
    return this.http.post(url, {}).pipe(
      map(() => {
        clearInterval(this.authChecker);

        this.cookieService.deleteAll();
        localStorage.clear();
        localStorage.setItem(
          'voyantfax-logout-event-' + this.env.name,
          'logout' + Math.random()
        );
        this.store.dispatch(AuthActions.logout());
        location.reload();
      }),
      catchError((errResponse: any) => {
        return of(errResponse.error);
      })
    );
  }

  activateUser(
    userId: string,
    activateObj: { activation_token: string; password?: string }
  ) {
    const url = `${this.API_URL}users/${userId}/activate`;
    return this.http.post(url, activateObj);
  }

  verifyEmail(id: string, token: string) {
    const url = `${this.API_URL}users/verify_email`;
    return this.http.post(url, {
      id,
      token,
    });
  }

  isAuthenticated(): Observable<boolean> {
    let url = `${this.API_URL}login`;
    return this.http.get(url).pipe(
      take(1),
      map((res: AuthResponse) => this.handleAuthVerification(res)),
      catchError(() => of(false))
    );
  }

  checkAuthentication(): Observable<any> {
    const url = `${this.API_URL}login`;
    return this.http.get(url);
  }

  authenticateSso(code: string): Observable<any> {
    const url = `${this.API_URL}sso_callback`;
    const params = { code };
    return this.http.get(url, { params });
  }

  redirectToLogin(): void {
    this.router.navigate(['auth/login']);
  }

  requestPasswordReset(email: string) {
    const url = `${this.API_URL}users/request_reset_password`;
    return this.http.post(url, { email });
  }

  resetPassword(params: {
    id: string;
    password_reset_token: string;
    password: string;
  }) {
    const url = `${this.API_URL}users/reset_password`;
    return this.http.post(url, params);
  }

  userRoleRedirect(user: AuthUser | null): void {
    if (user) {
      switch (user.role) {
        case 'user':
        case 'admin':
        case 'manager':
          this.router.navigate(['dashboard']);
          break;
        case 'var_admin':
          this.router.navigate(['vars/dashboard']);
          break;
        case 'ops':
          this.router.navigate(['account-management']);
          break;
        default:
          this.redirectToLogin();
          break;
      }
    } else {
      this.redirectToLogin();
    }
  }

  private handleAuthVerification(res: AuthResponse): boolean {
    let isAuth = false;
    if (res.message === 'User is authenticated') {
      this.store.dispatch(AuthActions.updateAuthUser({ user: res.data }));
      isAuth = true;
    }
    return isAuth;
  }
}
