// angular
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EnvironmentService } from './shared/services/environment.service';

// 3rd party
import { CookieService } from 'ngx-cookie-service';

// modules
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { FaxModule } from './fax/fax.module';
import { UsersModule } from './user/user.module';
import { NumberModule } from './number/number.module';
import { CustomerModule } from './customer/customer.module';
import { AdminModule } from './admin/admin.module';
import { AtaModule } from './ata/ata.module';
import { PortModule } from './port/port.module';

// components
import { AppComponent } from './app.component';

import { AuthModule } from './auth/auth.module';
import { environment } from '../environments/environment';
import { CallInterceptorService } from './auth/call-interceptor.service';
import { DepartmentModule } from './department/department.module';
import { VarModule } from './var/var.module';

// store
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import * as fromApp from './store/app.reducer';
import * as fromEffects from './store/app.effects';

import { EffectsModule } from '@ngrx/effects';
import { ManageModule } from './manage/manage.module';
import { FaxRoutingModule } from './fax/fax-routing.module';
import { AppInitService } from './shared/services/app-init.service';
import { OpsModule } from '@ops/ops.module';

export function initializeApp(_appInitService: AppInitService) {
  return (): Promise<any> => {
    return _appInitService.init();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FaxRoutingModule,
    AtaModule,
    PortModule,
    AdminModule,
    SharedModule,
    FaxModule,
    UsersModule,
    NumberModule,
    CustomerModule,
    DepartmentModule,
    AuthModule,
    VarModule,
    ManageModule,
    OpsModule,
    StoreModule.forRoot(fromApp.appReducer),
    EffectsModule.forRoot(fromEffects.AppEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CallInterceptorService,
      multi: true,
    },
    CookieService,
    {
      provide: EnvironmentService,
      useValue: environment,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
