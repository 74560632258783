import {
  Component,
  Input,
  OnInit,
  forwardRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';

import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';

import { Subscription } from 'rxjs';

export interface ImageUploadValue {
  file: File;
}

@Component({
  selector: 'voyant-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true,
    },
  ],
})
export class ImageUploadComponent
  implements ControlValueAccessor, OnInit, OnDestroy, OnChanges
{
  @Input() preview: string = null;
  @Input() branding: any;
  @Input() uploadText = 'Upload Image';

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      logo: [Validators.required],
    });

    this.uploader = new FileUploader({
      disableMultipart: true,
      allowedMimeType: ['image/jpeg', 'image/png'],
      queueLimit: 20, // max number of files (setting to 11 di allow displaying of error message)
      maxFileSize: 75 * 1024 * 1024, // 75MD - this is per file though, not per queue
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe((value) => {
        this.onChange(value.logo);
        this.onTouched();
      })
    );
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  form: FormGroup;
  imgPreview: any = this.preview
    ? this.preview
    : '/assets/images/placeholder-image.svg';
  uploader: FileUploader;

  subscriptions: Subscription[] = [];

  hasBaseDropZoneOver: boolean;
  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    this.uploader.onAfterAddingFile = (file: FileItem) => {
      this.form.patchValue({ logo: file._file });
      const reader = new FileReader();
      reader.onloadend = () => (this.imgPreview = reader.result);
      reader.readAsDataURL(file._file);
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('preview') && changes.preview.currentValue) {
      this.imgPreview = changes.preview.currentValue;
    }

    if (changes.hasOwnProperty('barnding') && changes.branding.currentValue) {
      this.uploadText = changes.uploadText.currentValue;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    } else if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { profile: { valid: false } };
  }

  onFileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  onResetFile() {
    this.uploader.clearQueue();
    this.imgPreview = this.preview;
  }
}
