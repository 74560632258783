import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';

interface Config {
  file: {
    obj: File;
    type: string;
    name: string;
    src: any;
  };
  sending: boolean;
  previewIsReady: boolean;
}

@Component({
  selector: 'voyant-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Output() fileUploaded = new EventEmitter<File[]>();
  @Input() multiple: boolean = false;

  @Input() allowedMimeType: string[] = [
    'image/jpeg',
    'image/png',
    'image/tiff',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'application/vnd.oasis.opendocument.text',
  ];

  config: Config;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;

  constructor() {
    this.config = {
      file: {
        obj: null,
        type: null,
        name: null,
        src: null,
      },
      sending: false,
      previewIsReady: false,
    };
  }

  ngOnInit(): void {
    this.uploader = new FileUploader({
      method: 'GET',
      autoUpload: true,
      disableMultipart: true,
      allowedMimeType: [...this.allowedMimeType],
      queueLimit: 20, // max number of files (setting to 11 di allow displaying of error message)
      maxFileSize: 75 * 1024 * 1024, // 75MD - this is per file though, not per queue
    });

    this.uploader.onAfterAddingFile = async (fileItem: FileItem | any) => {
      const { type } = fileItem._file;

      this.config.file.src = null;
      this.config.previewIsReady = false;

      type.indexOf('image/') > -1
        ? this.setImagePreview(type, fileItem)
        : this.setFilePreview(type, fileItem);
    };

    this.uploader.onAfterAddingAll = async () => {
      this.fileUploaded.emit(
        this.uploader.queue.map((item: FileItem) => item._file)
      );
    };
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  onResetFile(): void {
    this.uploader.clearQueue();
  }

  private setImagePreview(type, fileItem: FileItem): void {
    if (type === 'image/tiff') {
      this.setFilePreview(type, fileItem);
      return;
    }
    this.config.file.type = 'img';
    const reader = new FileReader();
    reader.onload = (e: any) => this.setFileItem(fileItem, e.target.result);
    reader.readAsDataURL(fileItem._file);
  }

  private setFilePreview(type, fileItem: FileItem): void {
    switch (type) {
      case 'image/tiff':
        (this.config.file.type = 'tiff'), this.setFileItem(fileItem);
        break;

      case 'application/pdf':
        const reader = new FileReader();
        this.config.file.type = 'pdf';
        reader.onload = (e: any) =>
          this.setFileItem(fileItem, new Uint8Array(e.target.result));
        reader.readAsArrayBuffer(fileItem._file);
        break;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        this.config.file.type = 'doc';
        this.setFileItem(fileItem);
        break;

      case 'text/csv':
      case 'application/csv':
      case 'application/vnd.ms-excel':
        this.config.file.type = 'csv';
        this.setFileItem(fileItem);
        break;

      case 'text/plain':
        this.config.file.type = 'txt';
        this.setFileItem(fileItem);
        break;

      case 'application/vnd.oasis.opendocument.text':
        this.config.file.type = 'odt';
        this.setFileItem(fileItem);
        break;
    }
  }

  private setFileItem(
    fileItem: FileItem = null,
    perviewPath: any = null
  ): void {
    console.log('setFileItem', {
      fileItem,
      perviewPath,
    });
    if (perviewPath) {
      this.config.file.src = perviewPath;
    }

    if (fileItem) {
      const { _file } = fileItem;

      this.config.file = {
        ...this.config.file,
        name: _file.name,
        obj: _file,
      };

      this.config.previewIsReady = true;
    }
  }
}
