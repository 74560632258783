import { Log } from '@admin/store/admin.model';
import { createAction, props } from '@ngrx/store';
import { Paging, SearchParams } from '@shared/models';
import { User } from '@user/store/user.model';

const label = '[ADMIN]';

export const getLog = createAction(
  `${label} Get Log`,
  props<{ payload: Partial<SearchParams> }>()
);

export const getUsers = createAction(
  `${label} Get User`,
  props<{ logUserMap: Map<string, string> }>()
);

export const getUsersSuccess = createAction(
  `${label} Get User Success`,
  props<{ logUsers: Map<string, User> }>()
);

export const getLogSuccess = createAction(
  `${label} Get Log Success`,
  props<{
    logs: Log[];
    paging: Paging;
  }>()
);

export const reportError = createAction(
  `${label} Report Error`,
  props<{ error: string }>()
);
