import {
  Component,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VarContact } from './var-contact.model';

@Component({
  selector: 'voyant-var-contact',
  templateUrl: './var-contact.component.html',
  styleUrls: ['./var-contact.component.scss'],
})
export class VarContactComponent implements OnInit, OnChanges {
  @Input() headerText: string;
  @Input() info: VarContact;
  @Output() updateContactInfo: EventEmitter<{ data: VarContact }> =
    new EventEmitter();
  form: FormGroup;

  config = {
    initialValues: {},
    saveDisabled: true,
  };

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });
  }

  get c() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((changes) => {
      for (const prop in changes) {
        if (changes[prop] === this.config.initialValues[prop]) {
          this.form.get(prop).markAsPristine();
        }
      }
      this.config.saveDisabled = !this.form.dirty;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.info.currentValue) {
      this.form.patchValue(changes.info.currentValue);
      this.config.initialValues = { ...changes.info.currentValue };
    }
  }

  onUpdateContactInfo() {
    this.config.saveDisabled = true;
    this.config.initialValues = { ...this.form.value };
    this.updateContactInfo.next(this.form.value);
  }
}
