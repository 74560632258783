import { Component } from '@angular/core';
import { CreateUserData, newUserDefault } from './new-user.model';

@Component({
  selector: 'voyant-new-user',
  templateUrl: './new-user.component.html',
})
export class NewUserComponent {
  data: CreateUserData = { ...newUserDefault };

  steps = [
    {
      label: 'Enter Profile Info',
      complete: false,
      valid: false,
      active: true,
    },
    { label: 'Add Fax Lines', complete: false, valid: false, active: false },
    { label: 'Review', complete: false, valid: false, active: false },
  ];

  constructor() {}

  setActiveStep(stepObj: any) {
    this.steps.forEach((step) => (step.active = false));
    stepObj.active = true;
  }

  getActiveStep() {
    let index = 0;
    let activeStep = null;
    for (const step of this.steps) {
      if (step.active) {
        activeStep = step;
        break;
      }
      index++;
    }
    return { step: activeStep, index };
  }

  onNext(data: any) {
    this.data = Object.assign(this.data, data);
    const activeStepObj = this.getActiveStep();
    activeStepObj.step.active = false;
    if (activeStepObj.index !== this.steps.length) {
      const nextStepIndex = activeStepObj.index + 1;
      this.steps[nextStepIndex].active = true;
    }
  }

  onBack() {
    const activeStepObj = this.getActiveStep();
    activeStepObj.step.active = false;
    if (activeStepObj.index !== 0) {
      const nextStepIndex = activeStepObj.index - 1;
      this.steps[nextStepIndex].active = true;
    }
  }
}
