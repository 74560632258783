import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isHipaa',
})
export class IsHipaaPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value === 'boolean') {
      return value ? 'Compliant' : 'Noncompliant';
    }
    return value;
  }
}
