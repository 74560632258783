// wrapper for modal content
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
} from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'voyant-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @ViewChild('content', { static: true, read: ViewContainerRef })
  content: ViewContainerRef;

  constructor(
    private cfr: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data.hasOwnProperty('component')) {
      const nestedComponent = this.cfr.resolveComponentFactory(
        this.data.component
      );
      this.content.createComponent(nestedComponent);
    }
  }

  onCloseModal() {
    this.dialogRef.close(false);
  }
}
