import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { ToastrService } from '@shared/services';

@Component({
  selector: 'voyant-auth-verify-email',
  template: '',
})
export class AuthVerifyEmailComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _authService: AuthService,
    private _toastrService: ToastrService
  ) {
    this.route.queryParams.subscribe(({ id, token }) => {
      if (id && token) {
        this._authService.verifyEmail(id, token).subscribe(
          (res) => {
            this._toastrService.notify('success', 'Email change verified');
            this.router.navigate(['/customer/profile']);
          },
          (errRess) => {
            this._toastrService.notify('error', errRess.error.message);
            this._authService.redirectToLogin();
          }
        );
      } else {
        this._authService.redirectToLogin();
      }
    });
  }
}
