import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';

interface Range {
  fromDate: Date;
  toDate: Date;
}

@Component({
  selector: 'voyant-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @Input() popup = false;
  @Output() updateLabel = new EventEmitter<string>();
  @Output() clickOutside = new EventEmitter();
  @Output() datesSelected = new EventEmitter<Range>();

  label: string = null;
  today: Date = null;
  next: Date = null;

  selectedRange: Range = {
    fromDate: null,
    toDate: null,
  };

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elemRef.nativeElement.contains(event.target)) {
      this.clickOutside.emit(event);
    }
  }

  constructor(private elemRef: ElementRef) {
    this.setInitialDates();
  }

  ngOnInit(): void {}

  onDateSelected(date: Date) {
    this.label = 'Custom';

    if (!this.selectedRange.fromDate || date < this.selectedRange.fromDate) {
      date.setHours(0, 0, 0);
      this.selectedRange.fromDate = date;
    } else if (!this.selectedRange.toDate || date > this.selectedRange.toDate) {
      date.setHours(12, 59, 59);
      this.selectedRange.toDate = date;
    } else {
      this.selectedRange.fromDate = null;
      this.selectedRange.toDate = null;
      this.onDateSelected(date);
    }

    if (!this.popup) {
      this.onApply();
    }
  }

  onPrevMonth() {
    const todayCopy = this.today;
    todayCopy.setMonth(todayCopy.getMonth() - 1);
    this.today = new Date(todayCopy);

    this.setNextMonth(todayCopy);
  }

  onNextMonth() {
    const todayCopy = this.today;
    todayCopy.setMonth(todayCopy.getMonth() + 1);
    this.today = new Date(todayCopy);

    this.setNextMonth(todayCopy);
  }

  setNextMonth(date: Date) {
    const nextCopy = this.next;
    nextCopy.setMonth(date.getMonth() + 1);
    if (nextCopy.getMonth() !== 0) {
      nextCopy.setFullYear(date.getFullYear());
    } else {
      nextCopy.setFullYear(date.getFullYear() + 1);
    }
    this.next = new Date(nextCopy);
  }

  setToday() {
    this.label = 'Today';
    this.setInitialDates();
    this.selectedRange.toDate = new Date(
      this.createTimeString(null, '12:59:59')
    );

    this.selectedRange.fromDate = new Date();
    this.selectedRange.fromDate.setDate(
      this.selectedRange.toDate.getDate() - 1
    );

    if (!this.popup) {
      this.onApply();
    }
  }

  setDayRange(num: number) {
    this.label = `Last ${num + 1} days`;
    this.setInitialDates();
    this.selectedRange.toDate = new Date();
    this.selectedRange.fromDate = new Date();
    this.selectedRange.fromDate.setDate(
      this.selectedRange.toDate.getDate() - num
    );

    if (num === 29) {
      this.today.setMonth(this.today.getMonth() - 1);
      this.next.setMonth(this.next.getMonth() - 1);
    }

    if (!this.popup) {
      this.onApply();
    }
  }

  setInitialDates() {
    this.today = new Date();
    this.next = new Date();
    this.next.setMonth(this.today.getMonth() + 1);
  }

  createTimeString(date = null, timeStr): string {
    const locDate = date ? new Date(date) : new Date();
    return `${locDate.getFullYear()}-${
      locDate.getMonth() + 1
    }-${locDate.getDate()} ${timeStr}`;
  }

  onApply() {
    const label = this.label ? this.label : 'Custom';
    this.updateLabel.emit(label);
    this.datesSelected.emit(this.selectedRange);
  }

  onDateChange(type: string, val: any) {
    const slashes = val.replace(/[0-9]/g, '');
    if (val.length === 10 && slashes === '//') {
      const date = new Date(val);
      if (type === 'from') {
        this.selectedRange.fromDate = date;
      }

      if (type === 'to') {
        date.setHours(12, 59, 59);
        this.selectedRange.toDate = date;
      }
    } else if (val.length === 0) {
      if (type === 'from') {
        this.selectedRange.fromDate = null;
      }
      if (type === 'to') {
        this.selectedRange.toDate = null;
      }
    }
  }

  clearRange() {
    this.selectedRange = {
      fromDate: null,
      toDate: null,
    };

    if (!this.popup) {
      this.onApply();
    }
  }
}
