import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of, throwError } from 'rxjs';
import {
  map,
  mergeMap,
  catchError,
  take,
  switchMap,
  startWith,
  tap,
} from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromAta from './ata.reducer';
import * as AtaActions from './ata.actions';
import { AtaService } from './ata.service';

import { PhaxioResponse, pagingDefault } from '@shared/models';
import { ToastrService } from '@shared/services';
import { AtaHelper } from './ata.helper';
import { MatDialog } from '@angular/material/dialog';
import { Ata, AtaPhone } from './ata.model';
import { NumberService } from '@number/store/number.service';
import * as NumberActions from '@number/store/number.actions';

@Injectable()
export class AtaEffects {
  loadAtas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AtaActions.loadAtas),
      mergeMap(({ payload }) =>
        this._ataService.getAtas(payload).pipe(
          map((res: PhaxioResponse) => {
            const { paging, data } = res;
            return AtaActions.setCurrentAtas({ paging, data });
          }),
          catchError((errRes: any) =>
            of(AtaActions.reportError({ error: errRes.error.message }))
          )
        )
      )
    )
  );

  loadAtaStatusDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AtaActions.loadAtaStatusDetails),
      switchMap(({ id }) => {
        return this._ataService
          .getAta(id)
          .pipe(
            map((res: PhaxioResponse) =>
              AtaActions.updateAtasCurrentDetails({ payload: [res] })
            )
          );
      })
    )
  );

  loadAtasDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AtaActions.setCurrentAtas),
      switchMap(({ data }) => {
        const getAtas = data.map((ata: Ata) => this._ataService.getAta(ata.id));
        return forkJoin(getAtas).pipe(
          map((res: PhaxioResponse[]) =>
            AtaActions.updateAtasCurrentDetails({ payload: res })
          )
        );
      })
    )
  );

  createAta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AtaActions.createAta),
      switchMap((action) =>
        this._ataService.createAta(action.ata).pipe(
          map((res: PhaxioResponse) => {
            return {
              newAta: res.data,
              ata: action.ata,
            };
          })
        )
      ),
      switchMap(({ newAta, ata }) => {
        const ataPhone: AtaPhone = ata.phone_number;
        if (ataPhone && ataPhone.phone_number.length === 3) {
          const { phone_number, is_hipaa, plan } = ataPhone;
          return this._numberService
            .provisionNumber({
              area_code: phone_number,
              is_hipaa,
              plan,
            })
            .pipe(
              map((res: PhaxioResponse) => {
                this.store.dispatch(
                  NumberActions.addPhoneNumberSuccess({
                    payload: res.data,
                    gettingMany: false,
                  })
                );
                return {
                  newAta,
                  ata: {
                    ...ata,
                    phone_number: res.data,
                  },
                };
              })
            );
        } else {
          return of({
            newAta,
            ata,
          });
        }
      }),
      switchMap(({ newAta, ata }) => {
        if (newAta.hasOwnProperty('id')) {
          const updateCalls = this._ataHelper.getCreateChain(newAta.id, {
            ...newAta,
            ...ata,
          });

          return forkJoin(updateCalls).pipe(
            map(() =>
              AtaActions.createAtaSuccess({
                ata: {
                  id: newAta.id,
                  ...ata,
                  ...newAta,
                },
              })
            ),
            catchError((errRes: any) =>
              of(AtaActions.reportError({ error: errRes.error.message }))
            )
          );
        }
      }),
      catchError((errRes: any, source) => {
        return source.pipe(
          startWith(AtaActions.reportError({ error: errRes.error.message }))
        );
      })
    )
  );

  updateAta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AtaActions.updateAta),
      switchMap(({ id, compare, ata }) => {
        const updateCalls = this._ataHelper.getUpdateChain(id, compare, ata);

        return forkJoin(updateCalls).pipe(
          map(() => AtaActions.updateAtaSuccess({ ata })),
          catchError((errRes) =>
            of(AtaActions.reportError({ error: errRes.error.message }))
          )
        );
      })
    )
  );

  deleteAta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AtaActions.deleteAta),
      switchMap((action) => {
        return this._ataService.deleteAta(action.id).pipe(
          map(() => {
            this._toastrService.notify('success', 'ATA deleted successfully');
            this.dialogRef.closeAll();
            return AtaActions.loadAtas({
              payload: pagingDefault,
            });
          }),
          catchError((errRes: any, source) =>
            of(AtaActions.reportError({ error: errRes.error.message.faxes[0] }))
          )
        );
      })
    )
  );

  deactivateAta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AtaActions.deactivateAta),
      switchMap(({ id }) => {
        return this._ataService.deactivateAta(id).pipe(
          map((res: PhaxioResponse) => {
            this._toastrService.notify(
              'success',
              'ATA deactivated successfully'
            );
            this.dialogRef.closeAll();
            return AtaActions.updateAtaSuccess({
              ata: {
                id,
                ...res.data,
              },
            });
          }),
          catchError((errRes: any, source) =>
            of(AtaActions.reportError({ error: errRes.error.message.faxes[0] }))
          )
        );
      })
    )
  );

  loadProvisionUrls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AtaActions.loadProvisionUrls),
      switchMap(() =>
        this._ataService.getProvisioningUrls().pipe(
          map((res: PhaxioResponse) =>
            AtaActions.loadProvisionUrlsSuccess({ payload: res.data })
          ),
          catchError((errRes: any, source) =>
            of(AtaActions.reportError({ error: errRes.error.message }))
          )
        )
      )
    )
  );

  doneEditing$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AtaActions.updateAtaSuccess),
        tap(() => {
          this._toastrService.notify('success', `ATA updated successfully`);
          this.dialogRef.closeAll();
        })
      ),
    {
      dispatch: false,
    }
  );

  doneCreating$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AtaActions.createAtaSuccess),
        map(() => {
          this._toastrService.notify(
            'success',
            'New ATA successfully created.'
          );
          this.dialogRef.closeAll();
          this.store.dispatch(AtaActions.loadAtas({ payload: pagingDefault }));
        })
      ),
    {
      dispatch: false,
    }
  );

  reportError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AtaActions.reportError),
        map(({ error }) => {
          this._toastrService.notify('error', error);
          return throwError(error);
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromAta.State>,
    private _ataService: AtaService,
    private _toastrService: ToastrService,
    private _ataHelper: AtaHelper,
    private _numberService: NumberService,
    private dialogRef: MatDialog
  ) {}

  getState() {
    let state: fromAta.State;
    this.store
      .select(fromAta.getState)
      .pipe(take(1))
      .subscribe((s) => (state = s));
    return state;
  }
}
