import { Directive, Input } from '@angular/core';
import {
  Validator,
  NG_VALIDATORS,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

@Directive({
  selector: '[voyantDomainValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MacValidatorDirective,
      multi: true,
    },
  ],
})
export class MacValidatorDirective implements Validator {
  @Input() voyantDomainValidator: string;
  validate(control: AbstractControl): ValidationErrors | null {
    const regex = RegExp(
      /^[a-fA-F0-9]{2}(?:[:-]?[a-fA-F0-9]{2}){5}(?:,[a-fA-F0-9]{2}(?:[:-]?[a-fA-F0-9]{2}){5})*$/g
    );
    if (regex.test(control.value)) {
      return { mac: true };
    }
    return null;
  }
}
