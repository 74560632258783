import { Component, Input } from '@angular/core';
import { User } from '@user/store/user.model';
import { UserService } from '@user/store/user.service';

@Component({
  selector: 'voyant-members-tooltip',
  templateUrl: './members-tooltip.component.html',
})
export class MembersTooltipComponent {
  @Input() members: Partial<User>[];

  constructor(private _userService: UserService) {}

  getUsersNameString = (usersArr: Partial<User>[]) =>
    this._userService.getUsersNameString(usersArr);
}
