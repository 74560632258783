import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of, throwError } from 'rxjs';
import {
  map,
  mergeMap,
  catchError,
  take,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { GroupResponse } from './group.model';
import { GroupService } from './group.service';
import * as GroupActions from './group.actions';
import * as fromGroup from './group.reducer';

import { pagingDefault, PhaxioResponse } from '@shared/models';
import { Store } from '@ngrx/store';
import { ToastrService } from '@shared/services';
import { MatDialog } from '@angular/material/dialog';
import { GroupHelper } from './group.helper';

@Injectable()
export class GroupEffects {
  loadAllGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.loadAllGroups),
      mergeMap((action) =>
        this._groupService.getGroups(action.payload).pipe(
          map((res: GroupResponse) =>
            GroupActions.loadAllSucess({
              payload: res.data,
              paging: res.paging,
            })
          ),
          catchError((errRes) =>
            of(GroupActions.reportError({ message: errRes.error.message }))
          )
        )
      )
    )
  );

  loadGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.loadGroups),
      mergeMap((action) =>
        this._groupService.getGroups(action.payload).pipe(
          map((res: GroupResponse) => {
            const loadAll =
              !this.getState().isReady && res.paging.total > res.data.length;

            const handle = loadAll
              ? GroupActions.loadAllGroups({
                  payload: {
                    per_page: res.paging.total,
                    page: 1,
                  },
                })
              : GroupActions.loadAllSucess({
                  payload: res.data,
                  paging: res.paging,
                });

            return handle;
          }),
          catchError((errRes) =>
            of(GroupActions.reportError({ message: errRes.error.message }))
          )
        )
      )
    )
  );

  createGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.createGroup),
      mergeMap((action) =>
        this._groupService.createGroup(action.payload).pipe(
          map((res: PhaxioResponse) =>
            GroupActions.addGroup({ payload: res.data })
          ),
          catchError((errRes) =>
            of(GroupActions.reportError({ message: errRes.error.message }))
          )
        )
      )
    )
  );

  updateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.updateGroup),
      map((action) => action.group),
      mergeMap((group) =>
        of(group).pipe(
          withLatestFrom(this.store.select(fromGroup.selectGroup(group.id)))
        )
      ),
      switchMap(([group, oldGroup]) => {
        const calls = this._groupHelper.getUpdateChain(oldGroup, group);
        const { id } = group;
        return forkJoin(calls).pipe(
          map(() => {
            this._toastrService.notify('success', 'Group successfully updated');
            return GroupActions.updateGroupSuccess({
              id,
              payload: group,
            });
          }),
          catchError((errRes) =>
            of(GroupActions.reportError({ message: errRes.error.message }))
          )
        );
      })
    )
  );

  deleteGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.deleteGroup),
      switchMap(({ id }) => {
        return this._groupService.deleteGroup(id).pipe(
          map((res: PhaxioResponse) =>
            GroupActions.deleteGroupSuccess({ id, message: res.message })
          ),
          catchError((errRes: any) =>
            of(GroupActions.reportError({ message: errRes.error.message }))
          )
        );
      })
    )
  );

  closeModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.deleteGroupSuccess),
      switchMap(({ message }) => {
        this.dialogRef.closeAll();
        this.store.dispatch(
          GroupActions.loadGroups({ payload: pagingDefault })
        );
        return of(GroupActions.reportSuccess({ message }));
      })
    )
  );

  reportSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupActions.reportSuccess),
        map(({ message }) => {
          this._toastrService.notify('success', message);
        })
      ),
    {
      dispatch: false,
    }
  );

  reportError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupActions.reportError),
        map(({ message, data }) => {
          const computedMessage = `${message} \r\n ${
            data?.errors?.length ? data.errors.join('\r\n') : ''
          }`;
          this._toastrService.notify('error', computedMessage);
          return throwError(message);
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromGroup.State>,
    private _groupService: GroupService,
    private _groupHelper: GroupHelper,
    private _toastrService: ToastrService,
    private dialogRef: MatDialog
  ) {}

  getState() {
    let state: fromGroup.State;
    this.store
      .select(fromGroup.getState)
      .pipe(take(1))
      .subscribe((s) => (state = s));
    return state;
  }
}
