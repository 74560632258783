import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NumberService } from '@number/store/number.service';

@Component({
  selector: 'voyant-plan-select',
  templateUrl: './plan-select.component.html',
  styleUrls: ['./plan-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlanSelectComponent),
      multi: true,
    },
  ],
})
export class PlanSelectComponent implements ControlValueAccessor {
  @Input() multiple = false;
  @Input() clearable = true;
  @Input() disabled = false;
  @Input() name = 'planSelection';
  @Input() value: string = null;

  loading = false;

  packages = this._numberService.getWholeSalePriceList();

  constructor(private _numberService: NumberService) {}

  onChange = (_) => {};
  onBlur = (_) => {};

  writeValue(val: string): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChanged($event) {
    this.value = $event;
    this.onChange(this.value);
  }
}
