import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ToastrService } from '../../shared/services';
import { MustMatch } from '../../customer/customer-profile/must-match';
import { ActivatedRoute } from '@angular/router';

interface Params {
  id: string;
  password_reset_token: string;
}

@Component({
  selector: 'voyant-auth-reset-password',
  templateUrl: './auth-reset-password.component.html',
  styleUrls: ['./auth-reset-password.component.scss'],
})
export class AuthResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitting = false;
  submitted = false;
  id = '';
  passwordReseToken = '';

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.id = params.id;
      this.passwordReseToken = params.password_reset_token;
    });

    this.resetPasswordForm = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(64),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: MustMatch('password', 'confirmPassword') }
    );
  }

  get password() {
    return this.resetPasswordForm.get('password');
  }
  get confirmPassword() {
    return this.resetPasswordForm.get('confirmPassword');
  }

  handleSuccess() {
    this.toastrService.notify(
      'success',
      'Password successfully updated, return to the login page and try your new credentials',
      5000
    );
    this.resetPasswordForm.reset();
    this.submitting = false;
    this.submitted = false;
    this.authService.redirectToLogin();
  }

  handdleError(errorRes) {
    const error = errorRes.error;
    const message = error.data.errors ? error.data.errors[0] : error.message;
    this.toastrService.notify('error', message);
    this.submitting = false;
  }

  onSubmit() {
    if (this.submitting) {
      return;
    }
    this.submitted = true;
    if (this.resetPasswordForm.valid) {
      this.submitting = true;
      const params = {
        id: this.id,
        password_reset_token: this.passwordReseToken,
        password: this.resetPasswordForm.value.password,
      };
      this.authService.resetPassword(params).subscribe(
        (res) => this.handleSuccess(),
        (err) => this.handdleError(err)
      );
    }
  }
}
