import { EnvironmentService } from '../app/shared/services';

class EnvironmentServiceImpl implements EnvironmentService {
  name = 'staging';
  production = false;
  apiUrls = {
    faxApi: 'https://staging-faxapp.inteliquent.com/fax/v1/',
  };
  s3 = {
    accessKeyId: 'AKIAJGQOOTTPZJHXOWSQ',
    secretAccessKey: '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',
  };
  pusher = {
    key: '67728f751a1c7ce666a6',
    cluster: 'us3',
  };
  DOMAIN = 'voyant-staging.com';
  pricing = {
    work_os_domain_price: 7500,
    work_os_directory_price: 7500,
    whitelabel_fee: 1000,
    price_per_overage_page: 2,

    usage: {
      price_per_page: 3,
      price_per_phone_number: 100,
      price_per_active_user: 1,
    },

    wholesale: {
      basic: {
        number_of_pages: 500,
        price: 500,
      },
      standard: {
        number_of_pages: 2500,
        price: 2200,
      },
      premium: {
        number_of_pages: 5000,
        price: 4000,
      },
      deluxe: {
        number_of_pages: 10000,
        price: 7000,
      },
    },
  };
}

export const environment = new EnvironmentServiceImpl();
