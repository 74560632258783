import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, take, debounceTime } from 'rxjs/operators';

import {
  Log,
  LogResponse,
  OpsResponse,
  UserCsvResponse,
} from '@admin/store/admin.model';
import { EnvironmentService, UtilityService } from '@shared/services';
import { pagingDefault } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private FAX_API = this.env.apiUrls.faxApi;
  private OPS_USERS = `${this.FAX_API}ops/users`;

  constructor(
    private _utilityService: UtilityService,
    private env: EnvironmentService,
    private http: HttpClient
  ) {}

  getLogs(urlParams: any): Observable<LogResponse> {
    const params =
      urlParams && Object.keys(urlParams).length > 0
        ? { ...urlParams }
        : { ...pagingDefault };

    const url = `${this.FAX_API}user_action_logs`;
    return this.http.get(url, { params }).pipe(
      debounceTime(350),
      take(1),
      map((res: LogResponse) => {
        res.data.map((log: Log) => {
          log.created_at = this._utilityService.utcToIsosDate(log.created_at);
          return log;
        });
        return res;
      })
    );
  }

  getOpsUsers(urlParams?: any): Observable<OpsResponse> {
    const params = urlParams ? { ...urlParams } : {};
    return this.http.get(this.OPS_USERS, { params }).pipe(
      debounceTime(350),
      take(1),
      map((res: OpsResponse) => {
        return res;
      })
    );
  }

  importUsersCsv(params: {
    file: File;
    dry_run?: boolean;
  }): Observable<UserCsvResponse> {
    const formData: any = new FormData();
    const url = `${this.OPS_USERS}/import`;
    Object.keys(params).forEach((key) => {
      if (params[key]) formData.append(key, params[key]);
    });
    return this.http
      .post(url, formData)
      .pipe(map((res: UserCsvResponse) => res));
  }
}
