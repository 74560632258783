import { NgModule } from '@angular/core';
import { DepartmentsAllComponent } from './departments-all/departments-all.component';
import { NewDepartmentComponent } from './modals/new-department/new-department.component';
import { DepartmentInfoComponent } from './modals/new-department/department-info/department-info.component';
import { ReviewCreateDepartmentComponent } from './modals/new-department/review-create-department/review-create-department.component';
import { SharedModule } from '../shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditDepartmentComponent } from './modals/edit-department/edit-department.component';
import { BillerSelectionComponent } from './biller-selection/biller-selection.component';

@NgModule({
  declarations: [
    DepartmentsAllComponent,
    NewDepartmentComponent,
    DepartmentInfoComponent,
    ReviewCreateDepartmentComponent,
    EditDepartmentComponent,
    BillerSelectionComponent,
  ],
  imports: [SharedModule, MatTooltipModule],
})
export class DepartmentModule {}
