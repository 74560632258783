import { NgModule } from '@angular/core';
import { OpsAccountManagementComponent } from './ops-account-management/ops-account-management.component';
import { OpsngRoutingModule } from './ops-routing.module';
import { SharedModule } from '@shared/shared.module';
import { UploadUsersCsvComponent } from './modals/upload-users-csv/upload-users-csv.component';

@NgModule({
  declarations: [OpsAccountManagementComponent, UploadUsersCsvComponent],
  imports: [SharedModule, OpsngRoutingModule],
})
export class OpsModule {}
