import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  createFeatureSelector,
  createReducer,
  on,
  createSelector,
} from '@ngrx/store';

import { pagingDefault, SearchParamsSortDir } from '@shared/models';
import { Fax } from './fax.model';

import * as FaxActions from './fax.actions';

export interface State extends EntityState<Fax> {
  searchParams: any;
  loading: boolean;
  sending: boolean;
  error: string;
}

export const adapter: EntityAdapter<Fax> = createEntityAdapter<Fax>({
  selectId: (f: Fax) => f.id,
});

export const initialState: State = adapter.getInitialState({
  searchParams: {
    ...pagingDefault,
    sort_dir: SearchParamsSortDir.DESC,
    sort_on: 'created_at',
    direction: 'received',
  },
  loading: false,
  sending: false,
  error: null,
});

export const faxReducer = createReducer(
  initialState,
  on(FaxActions.loadFaxes, (state, {searchParams}) => {
    searchParams ? searchParams : initialState.searchParams;
    return {
      ...state,
      searchParams : {
        ...state.searchParams,
        ...searchParams
      },
      loading: true,
    };
  }),

  on(FaxActions.loadFaxesSucccess, (state, { atas, paging }) => {
    return adapter.setAll(atas, {
      ...state,
      searchParams: {
        ...state.searchParams,
        ...paging
      },
      loading: false,
    });
  }),

  on(FaxActions.loadFaxSucccess, (state, { fax }) => {
    return adapter.updateOne(
      {
        id: fax.id,
        changes: fax,
      },
      state
    );
  }),

  on(FaxActions.reportError, (state, { error }) => {
    return {
      ...state,
      loading: false,
      sending: false,
      error,
    };
  })
);

export const getState = createFeatureSelector<State>('fax');

export const { selectIds, selectAll, selectEntities, selectTotal } =
  adapter.getSelectors(getState);

export const selectEntity = (id) =>
  createSelector(selectEntities, (entities) => entities[id]);
