import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/user';

@Component({
  selector: 'voyant-members-panel',
  templateUrl: './members-panel.component.html',
  styleUrls: ['./members-panel.component.scss'],
})
export class MembersPanelComponent implements OnInit {
  @Input() header = 'Members';
  @Input() emptyText: string;
  @Input() parentName: string;
  @Input() users: User[];
  @Input() isEdit: boolean;
  @Output() addUser: EventEmitter<any> = new EventEmitter();
  @Output() removeUser: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.parentName) {
      this.emptyText = `Edit ${this.parentName} details to add members.`;
    }
  }

  onRemoveUser(user: User, index: number) {
    this.removeUser.emit({ user, index });
  }

  onAddUsers() {
    this.addUser.emit(true);
  }
}
