import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'voyant-loading-spinner',
  template: `
    <svg class="glyph--spin" width="16px" height="16px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  `,
  styleUrls: ['./loading-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingSpinnerComponent {
  @HostBinding('class') fclass = 'voyant-loading-spinner';
}
