import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Fax, FaxService } from '@fax/store';
import { ToastrService } from '@shared/services/toastr.service';
import { PusherService, UtilityService } from '@shared/services';
import { NumberService } from 'src/app/number';
import { AuthService } from '@auth/auth.service';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';

@Component({
  selector: 'voyant-fax-view',
  templateUrl: './fax-view.component.html',
  styleUrls: ['./fax-view.component.scss'],
})
export class FaxViewComponent implements OnInit {
  isVarImpersontating = false;
  imageLoading = true;
  previewIsReady = false;
  filePreview = null;
  isHipaaCompliant: boolean = null;
  // fax data is appended to this.fax property

  get recipient() {
    return this.fax.recipients[0];
  }

  constructor(
    private _numberService: NumberService,
    private _faxService: FaxService,
    private _toastrService: ToastrService,
    private _pusherService: PusherService,
    private _authService: AuthService,
    private _utilityService: UtilityService,
    public dialogRef: MatDialogRef<FaxViewComponent>,
    private store: Store<fromApp.AppState>,
    @Inject(MAT_DIALOG_DATA) public fax: Fax
  ) {}

  ngOnInit() {
    // pings the API to trigger "read" event
    this._faxService.getFax(this.fax.id).subscribe();

    this._pusherService.channel.bind('update', (data: Fax) => {
      if (data && typeof data === 'object' && data.hasOwnProperty('id')) {
        if (data.id === this.fax.id && data.status !== this.fax.status) {
          data = {
            ...data,
            created_at: this._utilityService.utcToIsosDate(data.created_at)
          }
          this.fax = { ...this.fax, ...data };
        }
      }
    });

    this.store.select('ops').subscribe((s) => {
      this.isVarImpersontating = s.impersonating && this._authService.isVar;
    });

    this.getFaxPreview(this.fax.id);
    this.isFaxHipaa();
  }

  isFaxHipaa() {
    const {caller_id, recipients} = this.fax;
    const prop = this.fax.direction === 'sent' ? caller_id : recipients[0]?.phone_number;
    const numObj = this._numberService.getNumber(prop);

    if (numObj) {
      this.isHipaaCompliant = numObj.is_hipaa;
    }
  }

  reformatPhoneNumber(phoneNum: string) {
    if (phoneNum) {
      const numsOnly = phoneNum.replace(/\D/g, '');

      if (numsOnly.length) {
        return `+1${numsOnly}`;
      }
    }

    return phoneNum;
  }

  getFaxPreview(faxId: number) {
    this._faxService.faxPreview(faxId).subscribe(
      (faxFileBlob: any) => {
        if (faxFileBlob) {
          const reader = new FileReader();
          reader.onload = () => (this.filePreview = reader.result);
          reader.readAsDataURL(faxFileBlob);
        } else {
          this.filePreview = faxFileBlob;
        }
      },
      (err) => console.warn('load preview error', err),
      () => (this.imageLoading = false)
    );
  }

  onDownloadFax(faxId: number) {
    if (this.filePreview && !this.isVarImpersontating) {
      this._faxService.faxDownloadUrl(faxId).subscribe(
        (res: any) => {},
        (err: string) => {
          this._toastrService.notify('error', 'Failed to locate file');
        }
      );
    }
  }

  onResendFax() {
    this._faxService
      .faxDownloadUrl(this.fax.id, false)
      .subscribe((faxBlob: Blob) => {
        const { id, name, caller_id } = this.fax;
        const { type } = faxBlob;

        const fileName = `${name
          .replace(/[^A-Z0-9]+/gi, '_')
          .toLowerCase()}_resent.${type.substr(
          type.lastIndexOf('/') + 1,
          type.length
        )}`;

        const file: File = new File([faxBlob], fileName, {
          type,
        });

        const faxName =
          name.indexOf('|') === -1 ? `${name} | ${id} Resend` : name;

        const faxObj = {
          name: faxName,
          from: caller_id,
          to: [],
          file: [file],
        };

        this.fax.recipients.forEach((r: { phone_number: string }) =>
          faxObj.to.push(r.phone_number)
        );

        this._faxService.sendFax(faxObj).subscribe(
          (res: any) => {
            this._toastrService.notify('success', res.message);
            // delay refresh to let db to update
            setTimeout(() => this._faxService.sentFaxesUpdated.next(), 1500);
            this.dialogRef.close(res);
          },
          (res: any) => this._toastrService.notify('error', res.error.message)
        );
      });
  }

  hasError() {
    return (
      this.recipient.hasOwnProperty('error_message') &&
      this.recipient.error_message !== null
    );
  }
}
