import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared/shared.module';

import {
  VarContactComponent,
  VarDashboardBillingEventsComponent,
  VarDashboardComponent,
  VarInvoiceStatusComponent,
  VarProfileBrandingComponent,
  VarProfileMainComponent,
  VarProfileComponent,
  VarStatisticsComponent,
} from './';

import {
  CompanyInfoComponent,
  CompanyReviewComponent,
  ContactInfoComponent,
  NewCompanyComponent,
} from './modals/new-company';
import { VarOmniSearchComponent } from './var-omni-search/var-omni-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NumberComponent } from './var-omni-search/preview-cards/number/number.component';
import { CsvDateSelectorComponent } from './modals/csv-date-selector/csv-date-selector.component';
import { VarRoutingModule } from './var-routing.module';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as fromAuth from '@auth/store/auth.reducer';
import * as AuthActions from '@auth/store/auth.actions';
import { VarService } from './var.service';
import { take } from 'rxjs/operators';

@NgModule({
  declarations: [
    VarProfileComponent,
    VarDashboardComponent,
    NewCompanyComponent,
    VarProfileMainComponent,
    VarProfileBrandingComponent,
    VarDashboardBillingEventsComponent,
    VarInvoiceStatusComponent,
    CompanyInfoComponent,
    ContactInfoComponent,
    CompanyReviewComponent,
    VarContactComponent,
    VarStatisticsComponent,
    VarOmniSearchComponent,
    NumberComponent,
    CsvDateSelectorComponent,
  ],
  imports: [VarRoutingModule, SharedModule, MatTooltipModule, NgSelectModule],
})
export class VarModule {
  constructor(
    private _varService: VarService,
    private store: Store<fromApp.AppState>
  ) {
    this.store
      .select(fromAuth.getState)
      .pipe(take(1))
      .subscribe((s) => {
        if (s.isVar) {
          this._varService
            .getProfile()
            .subscribe((res) =>
              this.store.dispatch(
                AuthActions.getPublicVarProfileSucces({ varProfile: res.data })
              )
            );
          this.store.dispatch(AuthActions.getPublicVarProfile());
        }
      });
  }
}
