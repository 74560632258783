import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'voyant-ports-page',
  templateUrl: './ports-page.component.html',
  styleUrls: ['./ports-page.component.scss'],
})
export class PortsPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
