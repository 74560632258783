import { Paging } from './paging.model';

export enum SearchParamsSortDir {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SearchParams extends Paging {
  sort_dir?: SearchParamsSortDir;
  sort_on?: string;
  search?: string | null;
}
