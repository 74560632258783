import { AuthLoginComponent, AuthForgotPasswordComponent } from './';
import { AuthActivateComponent } from './auth-activate/auth-activate.component';
import { AuthResetPasswordComponent } from './auth-reset-password/auth-reset-password.component';
import { AuthContainerComponent } from './auth-container/auth-container.component';
import { AuthVerifyEmailComponent } from './auth-verify-email/auth-verify-email.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: AuthContainerComponent,
    children: [
      {
        path: 'login',
        component: AuthLoginComponent,
      },
      {
        path: 'forgot-password',
        component: AuthForgotPasswordComponent,
      },
      {
        path: 'reset-password',
        component: AuthResetPasswordComponent,
      },
      {
        path: 'activate',
        component: AuthActivateComponent,
      },
      {
        path: 'verify_email',
        component: AuthVerifyEmailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
