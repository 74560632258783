import { Component, OnInit, OnDestroy } from '@angular/core';

import { Group } from '../store/group.model';
import { ModalService, SearchService } from '../../shared/services';
import { EditGroupComponent } from '../modals';
import {
  pagingDefault,
  SearchParams,
  UniversalSearchValues,
} from '@shared/models';
import { Store } from '@ngrx/store';
import * as fromGroup from '../store/group.reducer';
import * as GroupActions from '../store/group.actions';
import * as NumberActions from '../../number/store/number.actions';
import * as UserActions from '../../user/store/user.actions';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'voyant-groups-table',
  templateUrl: './groups-table.component.html',
})
export class GroupsTableComponent implements OnInit, OnDestroy {
  groups$: Observable<Group[]>;
  state$: Observable<fromGroup.State>;

  isSearch = false;
  searchText: string = null;
  searchFields = ['name'];

  searchParams: SearchParams = this._searchService.initSearchParams('name');
  searchSubscription: Subscription;
  search: string;

  constructor(
    private _modal: ModalService,
    private _searchService: SearchService,
    private store: Store<fromGroup.State>
  ) {
    this.store.dispatch(
      NumberActions.loadPhoneNumbers({ payload: pagingDefault })
    );
    this.store.dispatch(UserActions.loadUsers({ payload: pagingDefault }));

    this.groups$ = this.store.select(fromGroup.selectAll);
    this.state$ = this.store.select(fromGroup.getState)
  }

  ngOnInit() {
    this.searchSubscription = this._searchService.term.subscribe(
      (params: UniversalSearchValues) => {
        this.search = params.search;
        this.searchParams = { ...this.searchParams, page: 1 };

        if (params.search && params.search.length) {
          this.searchParams.search = params.search;
          this.isSearch = true;
        } else if (this.searchParams.hasOwnProperty('search')) {
          delete this.searchParams.search;
          this.isSearch = false;
        }

        this.store.dispatch(
          GroupActions.loadGroups({ payload: this.searchParams })
        );
      }
    );
  }

  onViewGroup(group: Group): void {
    this._modal.openModal(EditGroupComponent, 'right', group);
  }

  onPageChanged(paginationConfig) {
    this.searchParams = { ...this.searchParams, ...paginationConfig };
    delete this.searchParams.total;

    this.store.dispatch(
      GroupActions.loadGroups({ payload: this.searchParams })
    );
  }

  onSortChanged(sortOn: string) {
    this.searchParams = this._searchService.updateSort(
      sortOn,
      this.searchParams
    );

    this.store.dispatch(
      GroupActions.loadGroups({ payload: this.searchParams })
    );
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
    this._searchService.term.next({
      search: null,
    });
  }
}
