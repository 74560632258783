import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'voyant-fax-activity-loader',
  templateUrl: './fax-activity-loader.component.html',
  styleUrls: ['./fax-activity-loader.component.scss'],
})
export class FaxActivityLoaderComponent implements OnInit {
  count = new Array(4);
  constructor() {}

  ngOnInit(): void {}
}
