import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FaxSendComponent } from '../../modals';
import { ModalService } from '@shared/services';
import { AuthService } from '@auth/auth.service';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';

@Component({
  selector: 'voyant-fax-activity-actions',
  templateUrl: './fax-activity-actions.component.html',
  styleUrls: ['./fax-activity-actions.component.scss'],
})
export class FaxActivityActionsComponent implements OnInit {
  isVarImpersontating = false;
  @Input() view = 'received';
  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  constructor(
    private _modal: ModalService,
    private _authService: AuthService,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    this.store.select('ops').subscribe((s) => {
      this.isVarImpersontating = s.impersonating && this._authService.isVar;
    });
  }

  onViewChange(view) {
    this.viewChange.emit(view);
  }

  openSendFaxModal() {
    this._modal.openModal(FaxSendComponent, 'right');
  }
}
