import { Component, OnInit } from '@angular/core';
import { UserService } from '@user/store/user.service';
import { AdminService } from '@admin/store/admin.service';
import { CsvService } from '@shared/services';
import { Log } from '@admin/store/admin.model';

import {
  UniversalSearchValues,
  Paging,
  pagingDefault,
  SearchParams,
} from '@shared/models';

import * as fromApp from '@app-store/app.reducer';
import { Store } from '@ngrx/store';
import * as UserActions from '@user/store/user.actions';
import * as fromAdmin from '@admin/store/admin.reducer';
import * as AdminActions from '@admin/store/admin.actions';
import { Observable } from 'rxjs';

@Component({
  selector: 'voyant-admin-log',
  templateUrl: './admin-log.component.html',
  styleUrls: ['./admin-log.component.scss'],
})
export class AdminLogComponent implements OnInit {
  state$: Observable<fromAdmin.State>;
  logs$: Observable<Log[]>;
  searchParams$: Observable<SearchParams>;

  constructor(
    private _adminService: AdminService,
    private _userService: UserService,
    private _csvService: CsvService,
    private store: Store<fromApp.AppState>
  ) {
    this.store.dispatch(AdminActions.getLog({ payload: pagingDefault }));
    this.store.dispatch(UserActions.loadUsers({ payload: pagingDefault }));
  }

  ngOnInit() {
    this.state$ = this.store.select(fromAdmin.getState);
    this.logs$ = this.store.select(fromAdmin.selectAll);
    this.searchParams$ = this.store.select(fromAdmin.searchParams);
  }

  onPageChanged(paging: Paging) {
    this.store.dispatch(
      AdminActions.getLog({
        payload: paging,
      })
    );
  }

  exportToCSV() {
    const headers = ['ID', 'User', 'Event', 'Date & Time'];
    const parseInstructions = (headerList, index, array, i) => {
      switch (headerList[index]) {
        case 'ID':
          return `${array[i].id},`;
        case 'User':
          return `${
            this._userService.getUserName(array[i].userId) || array[i].userId
          },`;
        case 'Event':
          return `${array[i].description.replace(/,/g, '')},`;
        case 'Date & Time':
          return `${array[i].created_at},`;
      }
    };

    this._adminService
      .getLogs({ ...pagingDefault, per_page: 9999 })
      .subscribe((response: any) => {
        this._csvService.downloadCSVFile(
          headers,
          response.data,
          parseInstructions,
          'admin-logs'
        );
      });
  }

  getUserName(userId: string): string {
    return '';
  }

  onFilterResults(params: UniversalSearchValues) {
    this.store.dispatch(
      AdminActions.getLog({
        payload: params,
      })
    );
  }

  onSortChanged(sortOn: string) {
    this.store.dispatch(
      AdminActions.getLog({
        payload: {
          sort_on: sortOn,
        },
      })
    );
  }
}
