import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ToastrService } from '../../shared/services';

@Component({
  selector: 'voyant-auth-forgot-password',
  templateUrl: './auth-forgot-password.component.html',
  styleUrls: ['./auth-forgot-password.component.scss'],
})
export class AuthForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  submitting = false;
  submitted = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get email() {
    return this.forgotPasswordForm.get('email');
  }

  onGoToLogin() {
    this.router.navigate(['auth/login']);
  }

  onSubmit() {
    this.submitted = true;
    if (this.submitting) {
      return;
    }
    if (this.forgotPasswordForm.valid) {
      this.submitting = true;
      const { email } = this.forgotPasswordForm.value;
      this.authService.requestPasswordReset(email).subscribe(
        (res) => {
          this.toastrService.notify(
            'success',
            'Your information was successfully sent. You will receive an email with reset instructions at the address you entered shortly.',
            5000
          );
          this.authService.redirectToLogin();
        },
        (errRes) => this.toastrService.setErrorFlash(errRes.error)
      );
    }
  }
}
