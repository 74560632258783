import { CustomerAccountComponent } from './customer-account/customer-account.component';
import { CustomerProfileComponent } from './customer-profile/customer-profile.component';
import { AuthGuard } from '../auth/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        canActivate: [AuthGuard],
        children: [
          { path: 'account', component: CustomerAccountComponent },
          { path: 'profile', component: CustomerProfileComponent },
        ],
        data: {
          expectedRoles: ['user', 'manager', 'admin', 'ops'],
        },
      },
    ]),
  ],
  exports: [RouterModule],
})
export class CustomerRoutingModule {}
