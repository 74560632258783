import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ModalService, UtilityService } from '@shared/services';
import { AddUsersComponent } from '@shared/components/modal/add-users/add-users.component';

import { UserService } from '@user/store/user.service';
import { User } from '@user/store/user.model';

import { Store } from '@ngrx/store';
import { PhoneNumber } from '@number/store/number.model';
import * as fromNumber from '@number/store/number.reducer';

import { Group } from '@group/store/group.model';
import * as GroupActions from '@group/store/group.actions';
import * as fromGroup from '@group/store/group.reducer';

@Component({
  selector: 'voyant-edit-group',
  templateUrl: './edit-group.component.html',
})
export class EditGroupComponent {
  state$: Observable<fromGroup.State>;

  cache: Group;
  group: Group;

  groupForm: FormGroup = this.fb.group({
    name: [null, [Validators.required]],
    phone_number: [null, [Validators.required]],
    compliance: [null, Validators.required],
  });

  complianceOptions = [
    { value: true, label: 'Compliant' },
    { value: false, label: 'Noncompliant' },
  ];

  constructor(
    private _userService: UserService,
    private _modalService: ModalService,
    private _modal: ModalService,
    private _utilityService: UtilityService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditGroupComponent>,
    private store: Store<fromNumber.State>,

    @Inject(MAT_DIALOG_DATA) public data: Group
  ) {
    this.state$ = this.store.select(fromGroup.getState);

    this.store.select(fromGroup.selectGroup(data.id)).subscribe((g: Group) => {
      if (g) {
        const num = g?.phone_numbers[0];
        this.group = { ...g };
        this.cache = { ...g };
        this.groupForm.patchValue({
          name: g.name,
          phone_number: num?.phone_number,
          compliance: num?.is_hipaa,
        });
      }
    });
  }

  get g() {
    return this.groupForm.controls;
  }

  onAddUsers() {
    const dialogRef = this._modalService.openModal(null, 'center', {
      component: AddUsersComponent,
      title: 'Add Members',
    });

    dialogRef.afterClosed().subscribe((res: { users: User[] }) => {
      if (typeof res === 'object' && res.users.length > 0) {
        this.group = {
          ...this.group,
          users: this._utilityService.uniqueArr(
            [...this.group.users, ...res.users],
            'id'
          ),
        };
      }
    });
  }

  onRemoveUser({ user }) {
    this.group = {
      ...this.group,
      users: this.group.users.filter((u) => u.id !== user.id),
    };
  }

  onSaveGroup() {
    this.group = {
      ...this.group,
      name: this.g.name.value,
    };
    this.store.dispatch(GroupActions.updateGroup({ group: this.group }));
  }

  cancel() {
    this.store.dispatch(GroupActions.toggleGroupEdit({ id: this.group.id }));
    this.group = { ...this.cache };
  }

  onDeleteGroup() {
    const dialogRef = this._modal.openConfirmModal({
      modalType: 'danger',
      title: 'Delete Group',
      altTitle: `Are you sure you want to delete [${this.group.name}] ?`,
      message: 'Once deleted, this action cannot be undone.',
      confirmText: 'delete',
    });

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.store.dispatch(GroupActions.deleteGroup({ id: this.group.id }));
      }
    });
  }

  getUserName(userId: string) {
    return this._userService.getUserName(userId);
  }

  onSelectedNumber(n: PhoneNumber) {
    this.group = {
      ...this.group,
      phone_numbers: [n],
    };
  }

  onSelectCompliance(is_hipaa: boolean) {
    this.group = {
      ...this.group,
      phone_numbers: [
        {
          ...this.group.phone_numbers[0],
          is_hipaa,
        },
      ],
    };
  }

  onToggleEdit() {
    this.store.dispatch(GroupActions.toggleGroupEdit({ id: this.group.id }));
  }
}
