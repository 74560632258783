import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PhoneNumber } from '../../../../number';

@Component({
  selector: 'voyant-fax-lines-panel',
  templateUrl: './fax-lines-panel.component.html',
  styleUrls: ['./fax-lines-panel.component.scss'],
})
export class FaxLinesPanelComponent implements OnInit {
  @Input() single = false;
  @Input() header = 'Direct Fax Lines';
  @Input() emptyText: string;
  @Input() parentName: string;
  @Input() faxLines: PhoneNumber[];
  @Input() isEdit: boolean;

  @Output() removeNumber: EventEmitter<any> = new EventEmitter();
  @Output() addNumber: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.parentName) {
      this.emptyText = `Edit ${this.parentName} details to add fax lines.`;
    }
  }

  onRemoveNumber(phoneNum: PhoneNumber, index: number) {
    this.removeNumber.emit({ phoneNum, index });
  }
  onOpenAddFaxLines() {
    this.addNumber.emit(true);
  }
}
