import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '@auth/auth.service';
import { User } from 'src/app/user';
import { VarProfile } from 'src/app/var/var.model';
import { VarService } from 'src/app/var/var.service';
import * as fromApp from '@app-store/app.reducer';
import * as fromAuth from '@auth/store/auth.reducer';
import * as AuthActions from '@auth/store/auth.actions';
import * as fromOps from '@ops/store/ops.reducer';
import { AuthUser } from '@auth/store/auth.model';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'voyant-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  authS$: Observable<fromAuth.State>;
  opsS$: Observable<fromOps.State>;

  showNav = false;
  className = 'header';
  varProfile: VarProfile = null;
  iconColor = 'light';

  user: AuthUser = null;

  @Input() activeLink: string;

  @Output() toggleMobileNav = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth > 992 && this.showNav) {
      this.showNav = false;
    }
  }
  constructor(
    private _authService: AuthService,
    private router: Router,
    private store: Store<fromApp.AppState>
  ) {
    this.authS$ = this.store.select(fromAuth.getState);
    this.opsS$ = this.store.select(fromOps.getState);
  }

  ngOnInit(): void {
    combineLatest([
      this.store.select(fromAuth.getState),
      this.store.select(fromOps.getState),
    ]).subscribe(([authS, opsS]) => {
      if (opsS.impersonating) {
        this.user = opsS.impersonating;
      } else if (!opsS.impersonating && authS.loggedInUser) {
        this.user = authS.loggedInUser;
      }

      this.className = this.isOps(this.user) ? 'nav--ops' : 'nav';

      if (authS.varProfile) {
        this.updateVar(authS.varProfile, authS.isVar);
      }
    });
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.showNav = false;
      }
    });
  }

  onLogout(e): void {
    e.preventDefault();
    this.store.dispatch(AuthActions.logout());
  }

  onToggleMobileNav(): void {
    this.showNav = !this.showNav;
    this.toggleMobileNav.emit(this.showNav);
  }

  private isOps(user: User): boolean {
    return user && user.role === 'ops';
  }

  private setIconColor(): void {
    if (this.varProfile) {
      const theme = this.varProfile.header_text_theme;
      this.iconColor = !theme || theme === 'light' ? 'light' : '';
    } else if (this.user.role === 'ops') {
      this.iconColor = '';
    } else {
      this.iconColor = 'light';
    }
  }

  private updateVar(profile: VarProfile, isVar: boolean): void {
    this.varProfile = profile;
    if (profile && isVar) {
      this.setIconColor();
    }
  }
}
