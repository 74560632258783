import { Pipe, PipeTransform } from '@angular/core';
import { NumberService } from '@number/store/number.service';

@Pipe({
  name: 'packageLabel',
})
export class PackageLabelPipe implements PipeTransform {
  constructor(private _numberService: NumberService) {}
  plans = this._numberService.getWholeSalePriceList();
  transform(value: string, ...args: unknown[]): unknown {
    const plan = this.plans.find((p) => p.type === value);
    if (!plan) {
      return '';
    }
    const plan_type =
      plan.type.charAt(0).toUpperCase() + plan.type.substring(1);
    return `${plan_type} | ${plan.number_of_pages} pages`;
  }
}
