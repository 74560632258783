import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

interface Config {
  type?: 'default'| 'outline',
  size?: 'default' | 'sm' | 'lg',
  btnText: string,
}

const defaults: Config = {
    type: 'default',
    size: 'default',
    btnText: 'Upload File'
  }

@Component({
  selector: 'voyant-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss']
})
export class FileUploadButtonComponent implements OnChanges {
  @Output() fileUploaded = new EventEmitter<File[]>();
  @Input() options: Config = defaults;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('options') && typeof changes.options === 'object') {
      this.options = {
        ...defaults,
        ...changes.options.currentValue
      }
    }
  }

  onFileUploaded(files: File[]){
    this.fileUploaded.emit(files);
  }
}
