import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnDestroy,
} from '@angular/core';

import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { Subscription } from 'rxjs';

export interface ColorPickerValues {
  hex: string;
  r: string;
  g: string;
  b: string;
}

@Component({
  selector: 'voyant-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  @Input() default: string;
  @Input() label: string;
  toggle: false;
  color: any;
  form: FormGroup;
  subscriptions: Subscription[] = [];
  onChange: any = () => {};
  onTouched: any = () => {};

  get value(): string {
    return this.form.get('hex').value;
  }

  set value(value: string) {
    this.onAssignHex(value);
    this.onCorrectHex(value);
    this.onAssignRgb(value);
    this.onChange(this.form.get('hex').value);
    this.onTouched();
  }

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      hex: [''],
      r: [0],
      g: [0],
      b: [0],
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe((value) => {
        this.onChange(value.hex);
        this.onTouched();
      })
    );
  }

  ngAfterViewInit() {
    if (!this.form.get('hex').value && this.default) {
      setTimeout(() => {
        this.onAssignHex(this.default);
        this.onCorrectHex(this.default);
        this.onAssignRgb(this.default);
        this.onChange(this.form.get('hex').value);
        this.onTouched();
      });
    }
  }

  hexToRgb(hex) {
    const result = /^#?([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})$/i.exec(hex);
    const resultConversion = (val) => {
      return val.length > 1 ? val : val + val;
    };
    return result
      ? {
          r: parseInt(resultConversion(result[1]), 16),
          g: parseInt(resultConversion(result[2]), 16),
          b: parseInt(resultConversion(result[3]), 16),
        }
      : null;
  }

  onCorrectHex(hex: string) {
    if (hex && hex.length > 1) {
      if (hex.charAt(0) !== '#') {
        this.form.patchValue({
          hex: `#${hex}`,
        });
      }
    }
  }

  onAssignRgb(hex: string) {
    if ((hex && hex.length === 4) || hex.length === 7) {
      const rgb: { r: number; g: number; b: number } = this.hexToRgb(hex);
      if (rgb && rgb.r !== null && rgb.g !== null && rgb.b !== null) {
        this.form.patchValue(rgb);
      }
    }
  }

  onAssignHex(hex: string) {
    this.color = hex;
    this.form.patchValue({ hex });
  }

  onAssignHexFromRgb() {
    const { r, g, b } = this.form.value;
    const isEmpty = (val: any) => {
      return val === null;
    };
    if (!isEmpty(r) || !isEmpty(g) || !isEmpty(b)) {
      if (!isEmpty(r) && isEmpty(g) && isEmpty(b)) {
        this.form.patchValue({ g: 0, b: 0 });
      }
      if (!isEmpty(g) && isEmpty(r) && isEmpty(b)) {
        this.form.patchValue({ r: 0, b: 0 });
      }
      if (!isEmpty(b) && isEmpty(r) && isEmpty(g)) {
        this.form.patchValue({ r: 0, g: 0 });
      }
      if (!isEmpty(r) && !isEmpty(g) && !isEmpty(b)) {
        this.onSetHex();
      }
    }
  }

  onSetHex() {
    const { r, g, b } = this.form.value;
    const red = r > 255 ? 255 : r;
    const green = g > 255 ? 255 : g;
    const blue = b > 255 ? 255 : b;
    const valToHex = (val) => {
      const hex = val.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    this.form.patchValue({
      hex: '#' + valToHex(red) + valToHex(green) + valToHex(blue),
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { profile: { valid: false } };
  }
}
