import { Injectable, Type } from '@angular/core';
import { NgDialogAnimationService, MatDialogConfig } from 'ng-dialog-animation';
import { ModalComponent } from '../components/modal/modal.component';
import { ConfirmationComponent } from '../components/modal/confirmation/confirmation.component';

interface ConfirmParams {
  modalType: 'primary' | 'success' | 'danger' | 'warning';
  title: string;
  altTitle: string;
  message?: string;
  confirmText?: string;
  withoutCancel?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private centerModalOptions: MatDialogConfig = {
    width: '690px',
    panelClass: ['modal', 'modal-center'],
  };

  private rightModalOptions: MatDialogConfig = {
    width: '610px',
    height: '100%',
    animation: {
      incomingOptions: {
        keyframes: [
          { transform: 'translateX(100%)' },
          { transform: 'translateX(0)' },
        ],
        keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
      },
      outgoingOptions: {
        keyframes: [
          { transform: 'translateX(0)' },
          { transform: 'translateX(100%)' },
        ],
        keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
      },
    },
    position: { right: '0px' },
    panelClass: ['modal', 'modal-right', 'modal--scrollable'],
  };

  constructor(public _dialog: NgDialogAnimationService) {}

  openModal(
    InjectedComponent: Type<any> | null,
    type: string = 'center',
    data: any = {},
    options?: MatDialogConfig
  ) {
    let modalOptions: MatDialogConfig = type === 'center' ?
      this.centerModalOptions : this.rightModalOptions;

    modalOptions.data = {
      type,
      ...data,
    };

    if (options) {
      modalOptions = {
        ...modalOptions,
        ...options,
      };
    }

    const componentToInject: Type<any> =
      data && data.hasOwnProperty('component')
        ? ModalComponent
        : InjectedComponent;

    return this._dialog.open(componentToInject, modalOptions);
  }

  openConfirmModal(data: ConfirmParams) {
    const modalData: any = { ...data };
    modalData.component = ConfirmationComponent;
    return this.openModal(null, 'center', modalData, {
      width: '500px',
      panelClass: ['modal', 'modal-center', 'modal-confirmation'],
    });
  }
}
