import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, throwError } from 'rxjs';
import { map, mergeMap, catchError, take } from 'rxjs/operators';
import * as FaxActions from './fax.actions';
import { FaxTypes } from './fax.types';

import { PhaxioResponse } from '@shared/models';
import { ToastrService } from '@shared/services';
import { MatDialog } from '@angular/material/dialog';
import { FaxService } from './fax.service';
import { Store } from '@ngrx/store';
import * as fromFax from './fax.reducer';

@Injectable()
export class FaxEffects {
  loadFaxes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FaxActions.loadFaxes),
      mergeMap(() =>
        this._faxService.getFaxes(this.getState().searchParams).pipe(
          map((res: PhaxioResponse) => {
            const { paging, data } = res;
            return FaxActions.loadFaxesSucccess({ atas: data, paging });
          }),
          catchError((errRes: any) =>
            of(FaxActions.reportError({ error: errRes.error.message }))
          )
        )
      )
    )
  );

  loadFax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FaxActions.loadFax),
      mergeMap(({ id }) =>
        this._faxService.getFax(id).pipe(
          map((res: PhaxioResponse) => {
            return FaxActions.loadFaxSucccess({ fax: res.data });
          }),
          catchError((errRes: any) =>
            of(FaxActions.reportError({ error: errRes.error.message }))
          )
        )
      )
    )
  );

  sendFax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FaxActions.sendFax),
      mergeMap(({ req }) =>
        this._faxService.sendFax(req).pipe(
          map((res: PhaxioResponse) =>
            FaxActions.sendFaxSuccess({ message: res.message })
          ),
          catchError((errRes: any) =>
            of(FaxActions.reportError({ error: errRes.error.message }))
          )
        )
      )
    )
  );

  doneSending$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FaxTypes.SEND_FAX_SUCCESS),
        map(({ message }) => {
          this._toastrService.notify('success', message);
          this.dialogRef.closeAll();
        })
      ),
    {
      dispatch: false,
    }
  );

  reportError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FaxTypes.REPORT_ERROR),
        map(({ error }) => {
          this._toastrService.notify('error', error);
          return throwError(error);
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private _faxService: FaxService,
    private _toastrService: ToastrService,
    private dialogRef: MatDialog,
    private store: Store<fromFax.State>
  ) {}

  getState() {
    let state: fromFax.State;
    this.store
      .select(fromFax.getState)
      .pipe(take(1))
      .subscribe((s) => (state = s));
    return state;
  }
}
