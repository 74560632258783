import * as UserActions from './user.actions';
import { User } from './user.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Paging, pagingDefault } from '@shared/models';

export interface State extends EntityState<User> {
  loading: boolean;
  selectedUser: User | null;
  current: User[] | null;
  paging: Paging;
  creating: boolean;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (a: User) => a.id,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  selectedUser: null,
  current: [],
  paging: pagingDefault,
  creating: false,
});

export const userReducer = createReducer(
  initialState,

  on(UserActions.loadUsers, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(UserActions.setUsers, (state, { paging, users }) => {
    return adapter.setAll(users, {
      ...state,
      paging,
      loading: false,
    });
  }),

  on(UserActions.addUser, (state, {payload}) => {
    return adapter.addOne(payload, state);
  }),

  on(UserActions.updateUser, (state, {id, payload}) => {
    return adapter.updateOne(
      {
        id: id,
        changes: payload,
      },
      state
    );
  }),

  on(UserActions.updateUserSuccess, (state, {payload}) => {
    return adapter.updateOne(
      {
        id: payload.id,
        changes: payload,
      },
      {
        ...state,
        selectedUser: {
          ...payload,
          isEdit: false,
        },
      }
    );
  }),

  on(UserActions.setSelectedUser, (state, {payload}) => {
    return {
      ...state,
      selectedUser: {
        ...payload,
        isEdit: false,
      },
    };
  }),

  on(UserActions.setEdit, (state, {payload}) => {
    return {
      ...state,
      selectedUser: {
        ...state.selectedUser,
        isEdit: payload,
      },
    };
  }),

  on(UserActions.updateUserDetails, (state, { numbers }) => {
    const updates = Object.keys(numbers).map((id) => ({
      id,
      changes: {
        phone_numbers: numbers[id],
      },
    }));

    return adapter.updateMany(updates, state);
  }),

  on(UserActions.endSelectedUser, (state) => {
    return {
      ...state,
      selectedUser: null,
    };
  }),

  on(UserActions.deleteUserSuccess, (state) => ({
    ...state,
    selectedUser: {
      ...state.selectedUser,
      isEdit: false,
    },
  })),

  on(UserActions.createUser, (state) => ({ ...state, creating: true })),

  on(UserActions.createUserSuccess, (state) => ({ ...state, creating: false })),

  on(UserActions.reportError, (state) => ({
    ...state,
    creating: false,
    loading: false,
  }))
);

export const getState = createFeatureSelector<State>('user');

export const { selectIds, selectAll, selectEntities, selectTotal } =
  adapter.getSelectors(getState);

  export const selectEntity = (id) =>
  createSelector(selectEntities, (entities) => entities[id]);
