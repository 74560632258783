import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PhaxioResponse, Paging, pagingDefault } from '@shared/models';
import {
  ModalService,
  ToastrService,
  UtilityService,
  CsvService,
  MetricsService,
  DateRangeService,
} from '@shared/services';
import { NewCompanyComponent } from '../modals/new-company/new-company.component';
import { VarContact } from '../var-contact/var-contact.model';
import { VarAccount } from '../var.model';
import { VarService } from '../var.service';
import { UserService } from 'src/app/user/store/user.service';
import { User, UserResponse } from 'src/app/user/store/user.model';
import * as OpsActions from '@ops/store/ops.actions';

import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import {
  debounceTime,
  map,
  switchMap,
  catchError,
  shareReplay,
  share,
} from 'rxjs/operators';
import { Observable, Subject, concat, of } from 'rxjs';
import { CsvDateSelectorComponent } from '../modals/csv-date-selector/csv-date-selector.component';
import { AuthUser } from '@auth/store/auth.model';

@Component({
  selector: 'voyant-var-dashboard',
  templateUrl: './var-dashboard.component.html',
  styleUrls: ['./var-dashboard.component.scss'],
})
export class VarDashboardComponent implements OnInit {
  isAllCompanies = true;
  accounts: VarAccount[] = [];
  activeAccount: VarAccount = null;
  isSubmitting = false;
  update = false;
  states: { abbr: string; name: string }[] = [];

  metricsRange: {
    fromDate: Date;
    toDate: Date;
  };

  billingData$: Observable<{
    data: any[];
    paging: Paging;
  }>;

  billingPaging: Paging = pagingDefault;

  metricsData$: Observable<{
    sent_pages_count: number;
    received_pages_count: number;
    users_count: number;
    phone_numbers_count: number;
  }>;

  searchResults$: Observable<User[] | any[]>;
  searchInput$: Subject<string> = new Subject<string>();

  companyInfo: FormGroup;
  searchUser: FormGroup;
  companyConfig = {
    initialValues: {},
    saveDisabled: true,
  };

  get c() {
    return this.companyInfo.controls;
  }

  get s() {
    return this.searchUser.controls;
  }

  constructor(
    private _modal: ModalService,
    private _varService: VarService,
    private _toastrService: ToastrService,
    private _modalService: ModalService,
    private _utilityService: UtilityService,
    private _userService: UserService,
    private _csvService: CsvService,
    private _metricsService: MetricsService,
    private _dateRangeService: DateRangeService,

    private fb: FormBuilder,
    private router: Router,
    private store: Store<fromApp.AppState>
  ) {
    this.companyInfo = this.fb.group({
      name: ['', [Validators.required]],
      street_address: ['', [Validators.required]],
      street_address_2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
    });

    this.searchUser = this.fb.group({
      user: ['', [Validators.required]],
    });
    const { start, end } = this._dateRangeService.getDaysSinceToday(30);
    this.metricsRange = { fromDate: start, toDate: end };
    this.billingPaging = {
      ...pagingDefault,
      per_page: 50,
    };
  }

  ngOnInit(): void {
    this.states = this._utilityService.getStates();

    this._varService.varAccounts.subscribe((acc: VarAccount[]) => {
      if (acc) {
        this.accounts = acc;

        if (this.accounts.length === 1) {
          this.isAllCompanies = false;
          this.activeAccount = { ...this.accounts[0] };
        }

        if (this.update) {
          this.activeAccount = { ...this.accounts[0] };
        }

        if (!this.accounts.length) {
          this.activeAccount = null;
        }

        if (this.activeAccount) {
          this.companyConfig.initialValues = { ...this.activeAccount };
          this.companyInfo.patchValue(this.activeAccount);
        }

        if (this._varService.isNewAccount()) {
          this.router.navigate(['vars/profile']);
        }

        this.fetchAccountData();
        this.fetchBillingData();
      }
    });

    this.companyInfo.valueChanges.subscribe((changes) => {
      for (const prop in changes) {
        if (changes[prop] === this.companyConfig.initialValues[prop]) {
          this.companyInfo.get(prop).markAsPristine();
        }
      }

      this.companyConfig.saveDisabled = !this.companyInfo.dirty;
    });

    this.onLoadUsers();
  }

  isActiveCompany(companyId): boolean {
    if (this.activeAccount) {
      return companyId === this.activeAccount.id;
    } else {
      return false;
    }
  }

  openNewCompanyModal() {
    const dialogRef = this._modal.openModal(NewCompanyComponent, 'center', {
      isStepper: true,
      title: 'Create New Company',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.activeAccount = result;
        this.companyInfo.patchValue(this.activeAccount);
      }
    });
  }

  onUpdateAccount(type: string, data: VarContact) {
    const { email, firstName, lastName } = data;
    switch (type) {
      case 'admin':
        this.activeAccount.admin_contact_email = email;
        this.activeAccount.admin_contact_first_name = firstName;
        this.activeAccount.admin_contact_last_name = lastName;
        break;
      case 'billing':
        this.activeAccount.billing_contact_email = email;
        this.activeAccount.billing_contact_first_name = firstName;
        this.activeAccount.billing_contact_last_name = lastName;
        break;
      case 'company':
        this.activeAccount = {
          ...this.activeAccount,
          ...this.companyInfo.value,
        };
    }

    this.companyConfig.initialValues = {
      ...this.activeAccount,
      ...this.companyInfo.value,
    };

    this._varService
      .updateAccount(this.activeAccount.id, this.activeAccount)
      .subscribe((res: PhaxioResponse) => {
        if (res.success) {
          const msg =
            type.charAt(0).toUpperCase() +
            type.slice(1) +
            ' Information Updated';
          this._toastrService.notify('success', msg);
        }
      });
  }

  onSetActiveAccount(acc: VarAccount) {
    this.isAllCompanies = false;
    this.activeAccount = { ...acc };
    this.companyConfig.initialValues = { ...this.activeAccount };
    this.companyInfo.patchValue(this.activeAccount);
    this.fetchAccountData();
    this.fetchBillingData();
  }

  onSetAllCompanies(): void {
    this.isAllCompanies = true;
    this.activeAccount = null;
    this.fetchAccountData();
    this.fetchBillingData();
  }

  onDeleteCompany() {
    const dialogRef = this._modalService.openConfirmModal({
      modalType: 'danger',
      title: 'Delete Company',
      altTitle: `Are you sure you want to delete [${this.activeAccount.name}] ?`,
      message: 'Once deleted, this action cannot be undone.',
      confirmText: 'delete',
    });

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this._varService
          .deleteAccount(this.activeAccount.id)
          .subscribe((res) => {
            this.update = true;
            this._toastrService.notify(
              'success',
              `[${this.activeAccount.name}] department was successfully deleted`
            );
          });
      }
    });
  }

  onLoadUsers(): void {
    this.searchResults$ = concat(
      of([]),
      this.searchInput$.pipe(
        debounceTime(350),
        switchMap((term) => {
          if (term && term.length && this.activeAccount) {
            const params = {
              account_id: this.activeAccount.id,
              search: term,
              page: 1,
              per_page: 50,
            };

            return this._userService.getUsers(params).pipe(
              map((res: UserResponse) => res.data),
              catchError(() => of([]))
            );
          } else {
            return of([]);
          }
        }),
        share()
      )
    );
  }

  onImpersonate(): void {
    if (this.searchUser.valid) {
      const user: AuthUser = this.s.user.value;
      this.store.dispatch(OpsActions.impersonate({ userId: user.id }));
    }
  }

  onExportBillingEvents(): void {
    const dialogRef = this._modal.openModal(null, 'center', {
      title: 'Export to CSV',
      component: CsvDateSelectorComponent,
    });

    dialogRef
      .afterClosed()
      .subscribe((result: { fromDate: Date; toDate: Date } | null) => {
        if (result) {
          const req = { ...result };
          if (!!this.activeAccount) {
            req['account_id'] = this.activeAccount.id.toString();
          }
          this._varService.getBillingDataCsv(req).subscribe((res: string) => {
            const fileName =
              this.activeAccount === null
                ? 'all'
                : this.activeAccount.name.replace(' ', '_').toLowerCase();
            this._csvService.downloadFile(res, fileName + '_billing_events');
          });
        }
      });
  }

  onRangeChange(obj: { fromDate: Date; toDate: Date }) {
    this.metricsRange = { ...obj };
    this.fetchAccountData();
    this.fetchBillingData();
  }

  search(e: any) {}

  private fetchAccountData(): void {
    const endpoint = this.isAllCompanies
      ? this._metricsService.getVarMetrics(this.metricsRange)
      : this._metricsService.getAccountMetrics(
          this.activeAccount.id,
          this.metricsRange
        );

    this.metricsData$ = endpoint.pipe(
      shareReplay(),
      map((res) => res.data)
    );
  }

  private fetchBillingData() {
    const id = this.isAllCompanies ? null : this.activeAccount.id;

    const billingParams = {
      ...this.metricsRange,
      ...this.billingPaging,
    };

    this.billingData$ = this._varService.getBillingData(id, billingParams).pipe(
      shareReplay(),
      map((res: PhaxioResponse) => {
        this.billingPaging.total = res.paging.total;
        return res;
      })
    );
  }
}
