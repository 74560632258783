import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';

@Pipe({
  name: 'phoneInput',
})
export class PhoneInputPipe implements PipeTransform {
  transform(value: string, args?: string): any {
    if (!value) {
      return value;
    }
    return new AsYouType('US').input(value);
  }
}
