import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subject, of } from 'rxjs';
import {
  debounceTime,
  switchMap,
  map,
  catchError,
  share,
} from 'rxjs/operators';
import { UserService } from '@user/store/user.service';
import { User, UserResponse } from '@user/store/user.model';

@Component({
  selector: 'voyant-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectComponent),
      multi: true,
    },
  ],
})
export class UserSelectComponent implements ControlValueAccessor {
  @Input() multiple = false;
  @Input() clearable = true;
  @Input() disabled = false;
  @Input() name = 'userSelect';
  @Input() selectedUsers: User[] = [];
  @Input() value: string = null;

  searchResults: User[] = [];
  searchInput$: Subject<string> = new Subject<string>();

  loading = false;

  constructor(private _userService: UserService) {
    this.searchInput$
      .pipe(
        debounceTime(400),
        switchMap((term) => {
          this.loading = true;
          if (term && term.length) {
            const params = {
              search: term,
              page: 1,
              per_page: 50,
            };

            return this._userService.getUsers(params).pipe(
              map((res: UserResponse) => res.data),
              catchError(() => of([]))
            );
          } else {
            this.loading = false;
            return of([]);
          }
        }),
        share()
      )
      .subscribe((data) => {
        this.loading = false;
        this.searchResults = data;
      });
  }

  onChange = (_) => {};
  onBlur = (_) => {};

  writeValue(val: string): void {
    this._userService
      .getUser(val)
      .pipe(share())
      .subscribe((res) => (this.searchResults = [res]));

    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChanged($event) {
    this.value = $event;
    this.onChange(this.value);
  }
}
