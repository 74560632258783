import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EnvironmentService } from '../../shared/services';
import { Port } from './port.model';
import { Paging, pagingDefault } from '@shared/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PortService {
  private API_URL = this.env.apiUrls.faxApi;
  private PORT_URL = `${this.API_URL}port_orders`;

  constructor(private env: EnvironmentService, private http: HttpClient) {}

  getPorts(params: Paging = pagingDefault) {
    const queryParams: any = params;
    return this.http.get(this.PORT_URL, { params: queryParams });
  }

  getPort(id: string) {
    const url = `${this.PORT_URL}/${id}`;
    return this.http.get(url);
  }

  getPortsMetrics() {
    const url = `${this.PORT_URL}/metrics`;
    return this.http.get(url);
  }

  createPort(req: Port) {
    return this.http.post(this.PORT_URL, { port_order: req });
  }

  uploadLetterOfAgreement(portId: string, loa: File) {
    const url = `${this.PORT_URL}/${portId}`;
    const form = new FormData();
    form.append('letter_of_agreement', loa);
    return this.http.put(url, form);
  }
}
