import { Injectable } from '@angular/core';
import { NumberService } from '@number/store/number.service';
import { PhoneNumber } from '@number/store/number.model';
import { Observable } from 'rxjs';

import { Group } from './group.model';
import { GroupService } from './group.service';

@Injectable({ providedIn: 'root' })
export class GroupHelper {
  constructor(
    private _groupService: GroupService,
    private _numberService: NumberService
  ) {}

  getUpdateChain(oldGroup: Group, newGroup: Group): Observable<any>[] {
    const calls: Observable<any>[] = [];
    const { id, name } = newGroup;

    // update group name
    if (oldGroup.name !== newGroup.name) {
      calls.push(this._groupService.updateGroup(id, { name }));
    }

    // update numbers
    const addNumbers: PhoneNumber[] = [];
    const cacheNumbers = oldGroup.phone_numbers.map((n) => n.phone_number);

    newGroup.phone_numbers.forEach((n: PhoneNumber) => {
      const currentNum = n;
      const oldNum = oldGroup.phone_numbers.find(
        (n) => currentNum.phone_number === n.phone_number
      );

      if (!cacheNumbers.includes(currentNum.phone_number)) {
        addNumbers.push(n);
      } else if (currentNum.is_hipaa !== oldNum.is_hipaa) {
        calls.push(
          this._numberService.updateNumber(currentNum.phone_number, {
            is_hipaa: n.is_hipaa,
          })
        );
      }
    });

    if (addNumbers?.length > 0) {
      calls.push(this._groupService.addPhoneNumbersToGroup(id, addNumbers));
    }

    const updateNumbers = newGroup.phone_numbers.map((n) => n.phone_number);

    oldGroup.phone_numbers.forEach((n: PhoneNumber) => {
      if (!updateNumbers.includes(n.phone_number)) {
        calls.push(
          this._groupService.removePhoneNumberFromGroup(id, n.phone_number)
        );
      }
    });

    // update users
    const newUsers = newGroup.users.map((u) => u.id);
    const oldUsers = oldGroup.users.map((u) => u.id);

    const addUsers: string[] = newUsers.reduce((acc, curr) => {
      return !oldUsers.includes(curr) ? [...acc, curr] : acc;
    }, []);

    if (addUsers?.length > 0) {
      calls.push(this._groupService.addUsersToGroup(id, addUsers));
    }

    oldUsers.forEach((curr: string) => {
      if (!newUsers.includes(curr)) {
        calls.push(this._groupService.removeUserFromGroup(id, curr));
      }
    });

    return calls;
  }
}
