import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromAuth from '@auth/store/auth.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private store: Store<fromAuth.State>
  ) {}

  canActivate(): Observable<boolean> {
    return this._authService.isAuthenticated().pipe(
      withLatestFrom(this.store.select(fromAuth.authUser)),
      map(([isAuthenticated, authUser]) => {
        if (isAuthenticated && authUser) {
          return true;
        } else {
          this._authService.redirectToLogin();
          return false;
        }
      })
    );
  }
}
