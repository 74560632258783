import { createAction, props } from '@ngrx/store';
import { Fax } from './fax.model';
import { Paging } from '@shared/models';
import { FaxTypes } from './fax.types';

export const loadFaxes = createAction(
  FaxTypes.LOAD_FAXES,
  props<{ searchParams?: any }>()
);

export const loadFaxesSucccess = createAction(
  FaxTypes.LOAD_FAXES_SUCCESS,
  props<{ atas: Fax[]; paging: Paging }>()
);

export const loadFax = createAction(FaxTypes.LOAD_FAX, props<{ id: number }>());

export const loadFaxSucccess = createAction(
  FaxTypes.LOAD_FAX_SUCCESS,
  props<{ fax: Fax }>()
);

export const sendFax = createAction(
  FaxTypes.SEND_FAX,
  props<{ req: { name: string; from: string; to: string[]; file: File[] } }>()
);

export const sendFaxSuccess = createAction(
  FaxTypes.SEND_FAX_SUCCESS,
  props<{ message: string }>()
);

export const reportError = createAction(
  FaxTypes.REPORT_ERROR,
  props<{ error: string }>()
);
