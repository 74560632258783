import { createAction, props } from '@ngrx/store';
import { Port } from './port.model';
import { Paging } from '@shared/models';
import { PortTypes } from './port.types';

export const loadAllPorts = createAction(
  PortTypes.LOAD_ALL_PORTS,
  props<{ payload: Partial<Paging> }>()
);

export const loadAllSucess = createAction(
  PortTypes.LOAD_ALL_SUCCESS,
  props<{ payload: Port[] }>()
);

export const loadPorts = createAction(
  PortTypes.LOAD_PORTS,
  props<{ payload: Partial<Paging> }>()
);

export const setPorts = createAction(
  PortTypes.SET_PORTS,
  props<{ paging: Paging; data: Port[] }>()
);

export const createPort = createAction(
  PortTypes.CREATE_PORT,
  props<{ payload: any; loa?: Blob }>()
);

export const updatePort = createAction(
  PortTypes.UPDATE_PORT,
  props<{ port: any; payload: Blob }>()
);

export const addPort = createAction(
  PortTypes.ADD_PORT,
  props<{ payload: Port }>()
);

export const loadPortsMetrics = createAction(PortTypes.LOAD_PORTS_METRICS);

export const setPortsMetrics = createAction(
  PortTypes.SET_PORTS_METRICS,
  props<{
    payload: {
      cancelled: number;
      completed: number;
      incomplete: number;
      pending: number;
    };
  }>()
);

export const setSelectedPort = createAction(
  PortTypes.SET_SELECTED_PORT,
  props<{ port: Port }>()
);

export const endSelectedPort = createAction(PortTypes.END_SELECTED_PORT);

export const reportError = createAction(
  PortTypes.REPORT_ERROR,
  props<{ error: string }>()
);
