import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromPort from '@port/store/port.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

interface Col {
  label: string;
  count: number;
}
@Component({
  selector: 'voyant-port-statistics',
  templateUrl: './port-statistics.component.html',
  styleUrls: ['./port-statistics.component.scss'],
})
export class PortStatisticsComponent implements OnInit, OnDestroy {
  portCounts: Col[] = [
    {
      label: 'Total Numbers',
      count: 0,
    },
    {
      label: 'Completed',
      count: 0,
    },
    {
      label: 'Pending',
      count: 0,
    },
    {
      label: 'Cancelled',
      count: 0,
    },
    {
      label: 'Incomplete',
      count: 0,
    },
  ];

  portSub: Subscription;

  constructor(private store: Store<fromPort.State>) {}

  ngOnInit(): void {
    this.portSub = this.store
      .select(fromPort.getState)
      .subscribe((s: fromPort.State) => {
        this.portCounts[0].count = s.paging.total;
        this.portCounts[1].count = s.completed;
        this.portCounts[2].count = s.pending;
        this.portCounts[3].count = s.cancelled;
        this.portCounts[4].count = s.incomplete;
        return s;
      });
  }

  ngOnDestroy() {
    this.portSub.unsubscribe();
  }
}
