import { Pipe, PipeTransform } from '@angular/core';
import parsePhoneNumber from 'libphonenumber-js';

@Pipe({
  name: 'phoneType',
})
export class PhoneTypePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (value) {
      const phoneNumber = parsePhoneNumber(value, 'US');
      const type = phoneNumber.getType();
      switch (type) {
        case 'FIXED_LINE_OR_MOBILE':
          return 'Local';
        case 'TOLL_FREE':
          return 'Toll-Free';
        default:
          return type
            .toLowerCase()
            .split('_')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
      }
    }
    return null;
  }
}
