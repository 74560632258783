import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as AuthActions from '@auth/store/auth.actions';
import * as fromAuth from '@auth/store/auth.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'voyant-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent implements OnInit {
  state$: Observable<fromAuth.State>;

  @ViewChild('emailInput', { static: true }) emailInput;
  @ViewChild('passwordInput', { static: true }) passwordInput;

  config: {
    checkSso: boolean;
  } = {
    checkSso: false,
  };

  loginFrom: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required]],
  });

  constructor(private fb: FormBuilder, private store: Store<fromAuth.State>) {
    this.state$ = this.store.select(fromAuth.getState).pipe(
      map((s) => {
        if (s.isVar) {
          this.config.checkSso = true;
        }

        if (s.error) {
          this.loginFrom.patchValue({
            password: null,
          });
        }
        return s;
      })
    );
  }

  ngOnInit() {
    setTimeout(() => this.emailInput.nativeElement.focus(), 300);
  }

  onLogin() {
    const { email, password } = this.loginFrom.value;
    if (!this.config.checkSso) {
      this.config.checkSso = true;
      this.store.dispatch(AuthActions.checkSso({ email }));
    }
    if (!password) {
      this.store.dispatch(AuthActions.setPasswordView({ showPassword: true }));
    }

    if (this.loginFrom.valid) {
      this.store.dispatch(AuthActions.login({ email, password }));
    }
  }

  onResetSSO() {
    this.store.dispatch(AuthActions.setPasswordView({ showPassword: false }));
  }
}
