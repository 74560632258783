import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType, CountryCode } from 'libphonenumber-js';

@Pipe({
  name: 'internationalNum',
})
export class InternationalNumPipe implements PipeTransform {
  transform(value: string, country_code: CountryCode): unknown {
    if (value && country_code) {
      return new AsYouType(country_code).input(value);
    }
    return value;
  }
}
