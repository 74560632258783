import { RoleGuard } from '../auth/role.guard';
import { ManageAllComponent } from './manage-all/manage-all.component';
import { UsersTableComponent } from '../user/users-table/users-table.component';
import { GroupsTableComponent } from '../group/groups-table/groups-table.component';
import { AtasTableComponent } from '../ata/atas-table/atas-table.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        canActivate: [RoleGuard],
        component: ManageAllComponent,
        children: [
          { path: 'users', component: UsersTableComponent },
          { path: 'groups', component: GroupsTableComponent },
          { path: 'devices', component: AtasTableComponent },
        ],
        data: {
          expectedRoles: ['admin', 'manager'],
        },
      },
    ]),
  ],
  exports: [RouterModule],
})
export class ManageRoutingModule {}
