import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'voyant-privacy-popup',
  templateUrl: './privacy-popup.component.html',
  styleUrls: ['./privacy-popup.component.scss'],
})
export class PrivacyPopupComponent implements OnInit {
  privacyCookieIsSet: boolean;
  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {
    this.privacyCookieIsSet = this.cookieService.check('VoyantFaxPrivacy');
  }

  setPrivacyCookie(days) {
    const expireInSeconds = days * 24 * 60 * 60 * 1000;
    this.cookieService.set('VoyantFaxPrivacy', 'true', expireInSeconds);
    this.privacyCookieIsSet = this.cookieService.check('VoyantFaxPrivacy');
  }

  cancelPrivacy() {
    this.privacyCookieIsSet = true;
  }
}
