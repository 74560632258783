import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '../../../admin/store/admin.service';
import { ToastrService, CsvService } from '@shared/services';
import { UserCsvResponse } from '../../../admin/store/admin.model';

interface ErrorChunk {
  errors: string[];
  line: string;
  warning: string;
}

interface CsvDataChunk {
  accountName: string;
  phoneNumber: string;
  email: string;
  invite: string;
  role: string;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'voyant-upload-users-csv',
  templateUrl: './upload-users-csv.component.html',
  styleUrls: ['./upload-users-csv.component.scss'],
})
export class UploadUsersCsvComponent {
  erroredUsers = new Set();
  data: CsvDataChunk[];
  errors: any = null;
  saving = false;
  file: File;

  constructor(
    private _adminService: AdminService,
    private _toastrService: ToastrService,
    public dialogRef: MatDialogRef<File>
  ) {}

  onSubmit(): void {
    this.saving = true;
    this._adminService
      .importUsersCsv({
        file: this.file,
      })
      .subscribe(
        (res: UserCsvResponse) => {
          this._toastrService.notify('success', res.message);
          this.handleCsvUpload(res.data);
        },
        (errRes: any) => this._toastrService.setErrorFlash(errRes.error),
        () => (this.saving = false)
      );
  }

  onFilesReady(e: File[]): void {
    this.file = e[0];

    const reader = new FileReader();
    reader.onload = (e: any) =>
      (this.data = this.data = this.convertToJson(e.target.result));
    reader.readAsText(e[0]);
  }

  onGetKeyArray(obj: any): string[] {
    return Object.keys(obj);
  }

  onGetUserDetails(str: any): string {
    const strArr = str.split(',');
    return `${strArr[5]} ${strArr[6]}`;
  }

  private convertToJson(inputCsv): any {
    const arrayCsv = inputCsv
      .split(',')
      .map((s) => s.replace(/"/gi, '').trim());

    const outputJson = [];
    for (let i = 7; i < arrayCsv.length; i += 7) {
      const [
        accountName,
        phoneNumber,
        email,
        invite,
        role,
        firstName,
        lastName,
      ] = arrayCsv.slice(i, i + 7);

      outputJson.push({
        accountName,
        phoneNumber,
        email,
        invite,
        role,
        firstName,
        lastName,
      });
    }

    return outputJson;
  }

  private handleCsvUpload(data: ErrorChunk[]): void {
    if (data.length) {
      this.errors = {};
      data.forEach((chunk: ErrorChunk) => {
        chunk.errors.forEach((errStr) => {
          if (!this.errors[errStr]) {
            this.errors[errStr] = [];
          }
          this.errors[errStr].push(chunk.line);
          this.erroredUsers.add(chunk.line);
        });
      });
    } else {
      this.dialogRef.close();
    }
  }
}
