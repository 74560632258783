import { ArrayFilterPipe } from './array-filter/array-filter.pipe';
import { ArrayExcludePipe } from './array-exclude/array-exclude.pipe';
import { DashPhonePipe } from './dash-phone/dash-phone.pipe';
import { PropArrayFilterPipe } from './prop-array-filter/prop-array-filter.pipe';
import { PhonePreviewPipe } from './phone-preview/phone-preview.pipe';
import { PhoneInputPipe } from './tel-input.pipe';
import { InternationalNumPipe } from './international-num.pipe';
import { SearchHighlightPipe } from './search-highlight/search-highlight.pipe';
import { IsHipaaPipe } from './is-hipaa/is-hipaa.pipe';
import { CapitalizePipe } from './capitalize/capitalize.pipe';
import { TableDatePipe } from './table-date/table-date.pipe';
import { MacAddressPipe } from './mac-address.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { PhoneTypePipe } from './phone-type.pipe';
import { PackageLabelPipe } from './package-label.pipe';
import { PlanUsagePipe } from './plan-usage.pipe';

export { DashPhonePipe };
export { MacAddressPipe };

export const sharedPipes = [
  PhoneInputPipe,
  DashPhonePipe,
  InternationalNumPipe,
  ArrayFilterPipe,
  PhonePreviewPipe,
  PropArrayFilterPipe,
  SearchHighlightPipe,
  IsHipaaPipe,
  ArrayExcludePipe,
  CapitalizePipe,
  TableDatePipe,
  MacAddressPipe,
  DashPhonePipe,
  TimeAgoPipe,
  PhoneTypePipe,
  PackageLabelPipe,
  PlanUsagePipe,
];
