import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, forkJoin, throwError, of } from 'rxjs';
import { map, mergeMap, catchError, switchMap, delay } from 'rxjs/operators';

import { User } from './user.model';
import { UserService } from './user.service';
import * as UserActions from './user.actions';
import * as fromUser from './user.reducer';

import { PhaxioResponse, Paging, pagingDefault } from '@shared/models';
import { Store } from '@ngrx/store';
import { GroupService, Group } from 'src/app/group';
import { PhoneNumber, NumberService } from 'src/app/number';
import { ToastrService } from '@shared/services';
import { MatDialog } from '@angular/material/dialog';
import { NewUser } from '@user/store/user.model';

interface UpdateParams {
  user: User;
  groups: Group[];
  numbers: PhoneNumber[];
}

@Injectable()
export class UserEffects {
  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUsers),
      map((action) => action.payload),
      mergeMap((params: Paging) =>
        this._userService.getUsers(params).pipe(
          map((res: PhaxioResponse) =>
            UserActions.setUsers({
              paging: res.paging,
              users: res.data,
            })
          ),
          catchError((errRes: any) =>
            of(UserActions.reportError({ message: errRes.error.message }))
          )
        )
      )
    )
  );

  loadUsersDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setUsers),
        switchMap(({ users }) =>
          forkJoin(
            users.map((u) => this._numberService.getNumberFor('User', u.id))
          ).pipe(
            map((res: any[]) => {
              const numbers = [].concat
                .apply(
                  [],
                  res.filter((r) => r.data.length).map((r) => r.data)
                )
                .reduce(
                  (acc, num) => ({
                    ...acc,
                    [num.user_id]: acc[num.user_id]
                      ? [...acc[num.user_id], num]
                      : [num],
                  }),
                  {}
                );

              this.store.dispatch(
                UserActions.updateUserDetails({
                  numbers,
                })
              );
            }),
            catchError((errRes: any) =>
              of(UserActions.reportError({ message: errRes.error.message }))
            )
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createUser),
      map((action) => action.payload),
      mergeMap((req: NewUser) =>
        this._userService.createUser(req).pipe(
          map((res: PhaxioResponse) =>
            UserActions.createUserSuccess({ message: res.message })
          ),
          catchError((errRes: any) => {
            const { message, data } = errRes.error;
            return of(UserActions.reportError({ message, data }));
          })
        )
      )
    )
  );

  sendInviteEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendInviteEmail),
      switchMap(({ id }) =>
        this._userService.sendInviteEmail(id).pipe(
          map((res: PhaxioResponse) =>
            UserActions.reportSuccess({ message: res.message })
          ),
          catchError((errRes: any) => {
            const { message, data } = errRes.error;
            return of(UserActions.reportError({ message, data }));
          })
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserEdit),
      switchMap((action) => {
        const updateCalls = this.getUpdateChain(action.compare, action.payload);
        return forkJoin(updateCalls).pipe(
          map(() =>
            UserActions.updateUserSuccess({
              payload: {
                ...action.payload.user,
                phone_numbers: action.payload.numbers,
              },
            })
          ),
          catchError((errRes: any) =>
            of(UserActions.reportError({ message: errRes.error.message }))
          )
        );
      })
    )
  );

  updateUserStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserStatus),
      switchMap(({ id, status }) =>
        this._userService.updateUserStatus(id, status).pipe(
          map(({ data }) => UserActions.updateUserSuccess({ payload: data })),
          catchError((errRes: any) =>
            of(UserActions.reportError({ message: errRes.error.message }))
          )
        )
      )
    )
  );

  updateUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserSuccess),
      map(({ payload }) => {
        this._toastrService.notify('success', `User updated successfully`);
        return UserActions.loadUsersDetails({ users: [payload] });
      }),
      catchError(() => EMPTY)
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUser),
      switchMap(({ payload }) => {
        return this._userService.deleteUser(payload).pipe(
          map((res) => {
            const { message } = res;
            return UserActions.deleteUserSuccess({ message });
          }),
          catchError((errRes: any) =>
            of(UserActions.reportError({ message: errRes.error.message }))
          )
        );
      })
    )
  );

  reloadAfterModalClose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUserSuccess, UserActions.createUserSuccess),
      delay(1000),
      map(({ message }) => {
        this._toastrService.notify('success', message);
        this.dialogRef.closeAll();
        return UserActions.loadUsers({ payload: pagingDefault });
      })
    )
  );

  reportError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.reportError),
        map(({ message, data }) => {
          const computedMessage = `${message} \r\n ${
            data?.errors?.length ? data.errors.join('\r\n') : ''
          }`;
          this._toastrService.notify('error', computedMessage);
          return throwError(message);
        })
      ),
    {
      dispatch: false,
    }
  );

  reportSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.reportSuccess),
        map(({ message }) => {
          this._toastrService.notify('success', message);
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromUser.State>,
    private _userService: UserService,
    private _groupService: GroupService,
    private _numberService: NumberService,
    private _toastrService: ToastrService,
    private dialogRef: MatDialog
  ) {}

  private getUpdateChain(
    cache: UpdateParams,
    update: UpdateParams
  ): Observable<any>[] {
    const calls: Observable<any>[] = [];
    const { id, first_name, last_name, role, email } = update.user;
    // update user
    if (JSON.stringify(cache.user) !== JSON.stringify(update.user)) {
      calls.push(
        this._userService.updateUser(id, { first_name, last_name, role, email })
      );
    }

    // update numbers
    const addNumbers: PhoneNumber[] = [];
    const cacheNumbers = cache.numbers.map((n) => n.phone_number);

    update.numbers.forEach((n: PhoneNumber) => {
      if (!cacheNumbers.includes(n.phone_number)) {
        addNumbers.push(n);
      }
    });

    if (addNumbers.length) {
      calls.push(
        this._userService.addPhoneNumbersToUser(update.user.id, addNumbers)
      );
    }

    const updateNumbers = update.numbers.map((n) => n.phone_number);

    cache.numbers.forEach((n: PhoneNumber) => {
      if (!updateNumbers.includes(n.phone_number)) {
        calls.push(
          this._userService.removePhoneNumberFromUser(id, n.phone_number)
        );
      }
    });

    // update groups
    const cacheGroups = cache.groups.map((g) => g.id);
    update.groups.forEach((g: Group) => {
      if (!cacheGroups.includes(g.id)) {
        calls.push(this._groupService.addUsersToGroup(g.id, [id]));
      }
    });

    const updateGroups = update.groups.map((g) => g.id);
    cache.groups.forEach((g: Group) => {
      if (!updateGroups.includes(g.id)) {
        calls.push(this._groupService.removeUserFromGroup(g.id, id));
      }
    });

    return calls;
  }
}
