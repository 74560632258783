import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PhoneNumber } from 'src/app/number';
import { Ata } from 'src/app/ata/store';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as AtaActions from '@ata/store/ata.actions';
import * as fromAta from '@ata/store/ata.reducer';
import { Observable } from 'rxjs';
import { StepperData } from './ata-review.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'voyant-ata-review',
  templateUrl: './ata-review.component.html',
  styleUrls: ['./ata-review.component.scss'],
})
export class AtaReviewComponent implements OnInit {
  creating: boolean;
  @Input() stepperData: StepperData;
  @Output() back = new EventEmitter();
  state$: Observable<fromAta.State>;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.state$ = this.store.select(fromAta.getState).pipe(
      map((s) => {
        this.creating = s.creating;
        return s;
      })
    );
  }

  onBack() {
    this.back.emit('true');
  }

  onCreateAta() {
    if (!this.creating) {
      let num: Partial<PhoneNumber> = null;
      const { name, description, domain, mac_address, users, lines } =
        this.stepperData;

      if (lines.numbers.length) {
        num = lines.numbers[0];
      }

      const phoneNumber = num
        ? {
            phone_number: num.phone_number ? num.phone_number : num.area_code,
            is_hipaa: num.is_hipaa,
            plan: num.plan,
          }
        : null;

      const ata: Ata = {
        name,
        description,
        domain,
        mac_address: mac_address ? mac_address : null,
        users: users ? users : null,
        phone_number: phoneNumber,
      };

      this.store.dispatch(AtaActions.createAta({ ata }));
    }
  }
}
