import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as fromNumber from '../store/number.reducer';
import * as numberSelectors from '@number/store/number.selectors';
import { Observable } from 'rxjs';
import { AuthUser } from '@auth/store/auth.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'voyant-number-statistics',
  templateUrl: './number-statistics.component.html',
  styleUrls: ['./number-statistics.component.scss'],
})
export class NumberStatisticsComponent implements OnInit {
  authUser$: Observable<AuthUser>;

  numberCounts: {
    total: { label: string; count: number };
    assigned: { label: string; count: number };
    unassigned: { label: string; count: number };
    local: { label: string; count: number };
    tollFree: { label: string; count: number };
  } = {
    total: {
      label: 'Total Numbers',
      count: 0,
    },
    assigned: {
      label: 'Assigned Numbers',
      count: 0,
    },
    unassigned: {
      label: 'Unassigned Numbers',
      count: 0,
    },
    local: {
      label: 'Local Numbers',
      count: 0,
    },
    tollFree: {
      label: 'Toll Free Numbers',
      count: 0,
    },
  };

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    this.authUser$ = this.store.select('auth').pipe(map((s) => s.loggedInUser));

    this.store
      .select(numberSelectors.getState)
      .subscribe((s: fromNumber.State) => {
        this.numberCounts.total.count = s.paging.total;
        this.numberCounts.unassigned.count =
          s.paging.total - this.numberCounts.assigned.count;

        const { assigned_numbers, local_numbers, toll_free_numbers } =
          s.metrics.data;
        this.numberCounts.assigned.count = assigned_numbers;
        this.numberCounts.local.count = local_numbers;
        this.numberCounts.tollFree.count = toll_free_numbers;
      });
  }
}
