import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayExclude'
})
export class ArrayExcludePipe implements PipeTransform {
  transform(items: any[], exclude: any[], ecludeField: string): unknown {
    if (!exclude || !exclude.length){
      return items;
    }

    items = items.filter((item: any) => {
      let res: boolean;
      exclude.forEach((prop: string) => {
        res = item[ecludeField] !== prop;
      });
      return res;
    });

    return items;
  }
}
