import { Injectable } from '@angular/core';
import {
  SearchParams,
  SearchParamsSortDir,
  Paging,
  pagingDefault,
  UniversalSearchValues,
} from '../models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private paginationConfig: Paging = pagingDefault;

  private searchParams: SearchParams = {
    sort_dir: SearchParamsSortDir.ASC,
    sort_on: null,
    ...this.paginationConfig,
  };

  term: BehaviorSubject<UniversalSearchValues> = new BehaviorSubject({
    search: null,
  });

  constructor() {}

  initSearchParams(sortOn: string): SearchParams {
    this.searchParams.sort_on = sortOn;
    return { ...this.searchParams };
  }

  sanitizeParams(params: any) {
    Object.keys(params).forEach((key) => {
      const val = params[key];
      if ((typeof val === 'string' && val.length === 0) || val == null) {
        delete params[key];
      }
    });
    return params;
  }

  updateSort(sort_on: string, searchParams: SearchParams) {
    let paramsCopy: SearchParams = {
      ...searchParams,
    };
    if (sort_on !== searchParams.sort_on) {
      paramsCopy = {
        ...paramsCopy,
        sort_dir: SearchParamsSortDir.DESC,
        sort_on,
      };
    } else {
      paramsCopy = {
        ...paramsCopy,
        sort_dir:
          searchParams.sort_dir === SearchParamsSortDir.DESC
            ? SearchParamsSortDir.ASC
            : SearchParamsSortDir.DESC,
      };
    }
    return paramsCopy;
  }
}
