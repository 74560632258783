import { Component, Input, ElementRef } from '@angular/core';
import { SearchParamsSortDir } from '../../models';

@Component({
  selector: 'voyant-table-sort',
  templateUrl: './table-sort.component.html',
  styleUrls: ['./table-sort.component.scss'],
})
export class TableSortComponent {
  field = this.ele.nativeElement.getAttribute('field');
  @Input() sortOn: string = null;
  @Input() sortDir: SearchParamsSortDir = null;

  constructor(private ele: ElementRef) {}

  isAsc() {
    return (
      this.field === this.sortOn && this.sortDir === SearchParamsSortDir.ASC
    );
  }
  isDesc() {
    return (
      this.field === this.sortOn && this.sortDir === SearchParamsSortDir.DESC
    );
  }
}
