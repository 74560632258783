import * as PortActions from './port.actions';
import { Port } from './port.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { Paging, pagingDefault } from '@shared/models';

export interface State extends EntityState<Port> {
  loading: boolean;
  isReady: boolean;
  port: Port | null;
  current: Port[] | null;
  paging: Paging;
  cancelled: number;
  completed: number;
  incomplete: number;
  pending: number;
  error: string;
}

export const adapter: EntityAdapter<Port> = createEntityAdapter<Port>({
  selectId: (p: Port) => p.id,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  isReady: false,
  port: null,
  current: [],
  paging: pagingDefault,
  cancelled: 0,
  completed: 0,
  incomplete: 0,
  pending: 0,
  error: null,
});

export const portReducer = createReducer(
  initialState,

  on(PortActions.loadAllSucess, (state, {payload}) => {
    return adapter.addMany(payload, { ...state, isReady: true });
  }),

  on(PortActions.loadPorts, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(PortActions.setPorts, (state, {data, paging}) => {
    return {
      ...state,
      current: data,
      paging: paging,
      loading: false,
    };
  }),

  on(PortActions.addPort, (state, {payload}) => {
    return adapter.addOne(payload, state);
  }),

  on(PortActions.setPortsMetrics, (state, {payload}) => {
    return {
      ...state,
      ...payload,
    };
  }),

  on(PortActions.setSelectedPort, (state, { port }) => {
    return {
      ...state,
      port,
    };
  }),

  on(PortActions.endSelectedPort, (state) => {
    return {
      ...state,
      port: null,
    };
  }),

  on(PortActions.reportError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  })
);

export const getState = createFeatureSelector<State>('port');

export const { selectIds, selectAll, selectEntities, selectTotal } =
  adapter.getSelectors(getState);
