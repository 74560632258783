import { Component, Input } from '@angular/core';
import { FaxViewComponent } from '@fax/modals';
import { Fax, FaxService } from '@fax/store';
import { ModalService, ToastrService } from '@shared/services';

@Component({
  selector: 'voyant-fax-activity-row',
  templateUrl: './fax-activity-row.component.html',
  styleUrls: ['./fax-activity-row.component.scss'],
})
export class FaxActivityRowComponent {
  @Input() type: string;
  @Input() fax: Fax;

  constructor(
    private _modal: ModalService,
    private _faxService: FaxService,
    private _toastrService: ToastrService
  ) {}

  onViewFaxDetails(): void {
    this._modal.openModal(FaxViewComponent, 'right', this.fax);
  }

  reformatPhoneNumber(phoneNum: string) {
    if (phoneNum) {
      const numsOnly = phoneNum.replace(/\D/g, '');

      if (numsOnly.length) {
        return `+1${numsOnly}`;
      }
    }

    return phoneNum;
  }

  onDownloadFax(event, faxId: number) {
    event.stopPropagation();
    this._faxService.faxDownloadUrl(faxId).subscribe(
      (res: any) => {},
      (err: string) => {
        this._toastrService.notify('error', 'Failed to locate file');
      }
    );
  }
}
