import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import Pusher from 'pusher-js';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher: any;
  channel: any;
  constructor(private _env: EnvironmentService) {
    this.pusher = new Pusher(this._env.pusher.key, {
      cluster: this._env.pusher.cluster,
    });

    this.channel = this.pusher.subscribe('faxes');
  }
}
