import { Component, Input } from '@angular/core';

@Component({
  selector: 'voyant-fax-statistics',
  templateUrl: './fax-statistics.component.html',
  styleUrls: ['./fax-statistics.component.scss']
})
export class FaxStatisticsComponent {
  @Input() data = [
      { icon: 'person-team-default', label: 'Active Accounts', value: '--' },
      { icon: 'number', label: 'Active Numbers', value: '--' },
      { icon: 'money', label: 'Amount Spent', value: '--' },
    ];

  @Input() faxesSent = '--';
  constructor() { }
}
