import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as fromAta from '../../../store/ata.reducer';
import * as AtaActions from '../../../store/ata.actions';
import { MacAddressPipe } from '@shared/pipes';

@Component({
  selector: 'voyant-ata-connect',
  templateUrl: './ata-connect.component.html',
  styleUrls: ['./ata-connect.component.scss'],
})
export class AtaConnectComponent implements OnInit {
  @Input() data: {
    mac_address: string;
  };
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();

  ataConnect: FormGroup;
  provisionUrls: any;

  copied = {
    provision_url: false,
  };

  steps = [
    { label: 'Device', active: true, complete: false },
    { label: 'Mac', active: false, complete: false },
  ];

  constructor(
    private fb: FormBuilder,
    private macPipe: MacAddressPipe,
    private store: Store<fromApp.AppState>
  ) {
    this.ataConnect = this.fb.group({
      device: [null],
      provision_url: [null],
      mac_address: [null],
    });

    this.store.dispatch(AtaActions.loadProvisionUrls());
  }

  get c() {
    return this.ataConnect.controls;
  }

  ngOnInit(): void {
    this.ataConnect.patchValue(this.data);
    this.store.select(fromAta.getState).subscribe((s: fromAta.State) => {
      if (s.provision_urls) {
        this.provisionUrls = [...s.provision_urls];
      }
      return s;
    });
  }

  onNext(): void {
    if (!this.allStepsComplete()) {
      this.setNextStep();
    }
  }

  onBack(): void {
    if (!this.allStepsComplete() && this.steps[0].active) {
      this.back.emit('true');
    } else {
      this.steps[1].active = false;
      this.steps[0].active = true;
    }
  }

  onSetDeviceUrl(brandIndex: number) {
    this.ataConnect.patchValue({
      provision_url: this.provisionUrls[brandIndex].url,
    });
  }

  onGetControls(g: FormGroup): string[] {
    return Object.keys(g.controls);
  }

  onCopy(field: string): void {
    this.copied[field] = true;
    setTimeout(() => (this.copied[field] = false), 750);
  }

  onUpdateMac(mac: string) {
    this.ataConnect.patchValue({
      mac_address: this.macPipe.transform(mac),
    });
  }

  private allStepsComplete() {
    return this.steps.every(({ complete }) => {
      return complete;
    });
  }

  private getActiveStep() {
    let index = 0;
    let activeStep = null;
    for (const step of this.steps) {
      if (step.active) {
        activeStep = step;
        break;
      }
      index++;
    }
    return { step: activeStep, index };
  }

  private setNextStep() {
    const activeStepObj = this.getActiveStep();
    activeStepObj.step.active = false;
    activeStepObj.step.complete = true;
    if (activeStepObj.index !== this.steps.length) {
      const nextStepIndex = activeStepObj.index + 1;
      if (this.steps[nextStepIndex]) {
        this.steps[nextStepIndex].active = true;
      }
    }

    if (this.allStepsComplete()) {
      const emitObj: any = {};
      if (this.ataConnect.value.mac_address) {
        emitObj.mac_address = this.ataConnect.value.mac_address;
      }
      this.next.emit(emitObj);
    }
  }
}
