import { Injectable } from '@angular/core';
import { EnvironmentService } from '../shared/services';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Department, DepartmentResponse } from './department.model';
import { debounceTime, map, take } from 'rxjs/operators';
import { Paging } from '../shared/models';
import { User, UserService } from '../user';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  serviceIsReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  depMapBuilding = false;
  departmetnUrl: string = this.env.apiUrls.faxApi + 'departments';
  departmentsUpdated: Subject<DepartmentResponse> = new Subject();
  depMap: BehaviorSubject<any> = new BehaviorSubject(new Map());

  constructor(
    private _userService: UserService,
    private env: EnvironmentService,
    private http: HttpClient
  ) {
    this.get({}, true).subscribe();
  }

  show(depId: string) {
    const url = this.departmetnUrl + `/${depId}`;
    return this.http.get(url);
  }

  get(urlParams?: any, update = false): Observable<DepartmentResponse> {
    let params = {};
    if (urlParams) {
      params = Object.assign({}, params, urlParams);
    }
    return this.http.get(this.departmetnUrl, { params }).pipe(
      debounceTime(350),
      take(1),
      map((res: DepartmentResponse) => {
        if (!this.depMapBuilding && (update || !this.serviceIsReady.value)) {
          this.setDepartments({ ...res.paging });
        }
        this.departmentsUpdated.next(res);
        return res;
      })
    );
  }

  create(data: Department) {
    return this.http.post(this.departmetnUrl, { ...data }).pipe(
      map((res: any) => {
        this.get({}, true);
        return res;
      })
    );
  }

  update(depId: string, data: Department) {
    const url = this.departmetnUrl + `/${depId}`;
    return this.http.put(url, data).pipe(
      map((res: any) => {
        this.get({}, true);
        return res;
      })
    );
  }

  delete(depId: string) {
    const url = this.departmetnUrl + `/${depId}`;
    return this.http.delete(url).pipe(
      map((res: any) => {
        this.get({}, true).subscribe();
        return res;
      })
    );
  }

  addUser(depId: string, userId: string) {
    const url = this.departmetnUrl + `/${depId}/users`;
    return this.http.post(url, { user_id: userId });
  }

  removeUser(depId: string, userId: string) {
    const url = this.departmetnUrl + `/${depId}/users`;
    return this.http.delete(url, { params: { user_id: userId } });
  }

  getNonAssignableUsers(): string[] {
    // remove admins and ops
    const adminStaff: string[] = Array.from(
      this._userService.fetchAll().values()
    )
      .filter((user: User) => user.role === 'admin' || user.role === 'ops')
      .map((user: User) => user.id);

    // remove assigned users that includes managers assigned to a department
    const usersAssignedToDepartment: any = Array.from(
      this.depMap.value.values()
    ).map((department: Department) => {
      return department.users.map((user: User) => user.id);
    });

    const users = [...adminStaff, ...usersAssignedToDepartment.flat(2)];
    return users.filter((value, index, self) => self.indexOf(value) === index);
  }

  getUserDepartments(userid: string): Department[] {
    const departments: Department[] = Array.from(this.depMap.value.values());
    const userDepartments: Department[] = departments.filter(
      (department: Department) => {
        const userIds = department.users.map((user: User) => user.id);
        return userIds.includes(userid);
      }
    );
    return userDepartments;
  }

  private setDepartments(paging?: Paging) {
    this.depMapBuilding = true;
    paging.per_page = paging.total;
    this.get(paging).subscribe((res: DepartmentResponse) =>
      this.constructDepMap(res.data)
    );
  }

  private constructDepMap(logs: Department[]) {
    const depMap = logs.reduce((accumulator: any, dep: Department) => {
      accumulator.set(dep.id, dep);
      return accumulator;
    }, new Map());
    this.depMap.next(depMap);
    this.depMapBuilding = false;
    this.serviceIsReady.next(true);
  }
}
