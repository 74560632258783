import { Component, OnInit } from '@angular/core';
import { ModalService, PusherService } from '../../shared/services';
import { FaxSendComponent } from '../modals';
import { AuthService } from '../../auth/auth.service';
import { PhoneNumber } from '../../number';
import { Fax } from '@fax/store';
import { AuthUser } from '@auth/store/auth.model';
import { Store } from '@ngrx/store';
import * as fromFax from '@fax/store/fax.reducer';
import * as FaxActions from '@fax/store/fax.actions';
import { Observable } from 'rxjs';
import { pagingDefault } from '@shared/models';

@Component({
  selector: 'voyant-fax-activity',
  templateUrl: './fax-activity.component.html',
  styleUrls: ['./fax-activity.component.scss'],
})
export class FaxActivityComponent implements OnInit {
  userAssignedNumbers: PhoneNumber[];

  searchControls = {
    sort_dir: true,
    search: true,
  };

  showUnreadOnly = false;
  showSuccessfulOnly = false;

  state$: Observable<fromFax.State>;
  faxes$: Observable<Fax[]>;

  constructor(
    private _modalService: ModalService,
    private _authService: AuthService,
    private _pusherService: PusherService,
    private store: Store<fromFax.State>
  ) {
    this.store.dispatch(FaxActions.loadFaxes({ searchParams: {
      ...pagingDefault,
      direction: 'received'
    }}));
  }

  ngOnInit() {
    const user: AuthUser = this._authService.getLoggedInUser();
    if (user) {
      this.userAssignedNumbers = user.phone_numbers;
    }

    this.state$ = this.store.select(fromFax.getState);
    this.faxes$ = this.store.select(fromFax.selectAll);

    this._pusherService.channel.bind('update', (fax: Fax) => {
      if (fax && typeof fax === 'object' && fax.hasOwnProperty('id')) {
        this.store.dispatch(FaxActions.loadFaxSucccess({ fax }));
      }
    });
  }

  // swtiches view between 'sent' and 'received'
  onViewUpdated(direction: string) {
    this.store.dispatch(
      FaxActions.loadFaxes({
        searchParams: {
          page: 1,
          direction,
        },
      })
    );
  }

  // fetches new results when the view changes
  updateResults(searchParams: any) {
    this.store.dispatch(FaxActions.loadFaxes({ searchParams }));
  }

  onFilterResults(searchParams: any) {
    searchParams.page = 1;
    this.store.dispatch(FaxActions.loadFaxes({ searchParams }));
  }

  onToggleUnread() {
    this.store.dispatch(
      FaxActions.loadFaxes({
        searchParams: {
          is_read: this.showUnreadOnly ? false : null,
          page: 1,
        },
      })
    );
  }

  onToggleSuccessful() {
    this.store.dispatch(
      FaxActions.loadFaxes({
        searchParams: {
          status: this.showSuccessfulOnly ? 'success' : null,
          page: 1,
        },
      })
    );
  }

  openSendFaxModal() {
    this._modalService.openModal(FaxSendComponent, 'right');
  }
}
