import { Component, OnInit } from '@angular/core';
import {
  UniversalSearchControlls,
  UniversalSearchValues,
  pagingDefault,
} from '@shared/models';
import { SearchService, ModalService } from '@shared/services';
import { BuyNumberComponent } from '../modals/buy-number/buy-number.component';

import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as NumberActions from '../store/number.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'voyant-numbers-all',
  templateUrl: './numbers-all.component.html',
  styleUrls: ['./numbers-all.component.scss'],
})
export class NumbersAllComponent implements OnInit {
  searchControls: UniversalSearchControlls = {
    search: true,
  };

  constructor(
    private _searchService: SearchService,
    private _modalService: ModalService,
    private router: Router,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {}

  onFilterResults(params: UniversalSearchValues) {
    this._searchService.term.next(params);
  }

  onOpenBuyNumberModal() {
    const dialogRef = this._modalService.openModal(
      null,
      'center',
      {
        component: BuyNumberComponent,
        title: 'Buy a Number',
      },
      { width: '690px' }
    );

    dialogRef
      .afterClosed()
      .subscribe(
        (result: { phone_number: string; is_hipaa: boolean }[] | boolean) => {
          if (result) {
            this.store.dispatch(
              NumberActions.loadPhoneNumbers({ payload: pagingDefault })
            );
          }
        }
      );
  }

  onCreatePortRequest() {
    this.router.navigate(['/numbers/ports/new']);
  }
}
