export enum FaxTypes {
  LOAD_FAXES = '[FAX] Load Faxes',
  LOAD_FAXES_SUCCESS = '[FAX] Load Faxes Success',

  LOAD_FAX = '[FAX] Load Fax',
  LOAD_FAX_SUCCESS = '[FAX] Load Fax Success',

  SEND_FAX = '[FAX] Send Fax',
  SEND_FAX_SUCCESS = '[FAX] Send Fax Success',

  REPORT_ERROR = '[FAX] Report Error',
}
