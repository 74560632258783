import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { PhoneNumber } from '@number/store/number.model';
import { AuthService } from '../../../auth/auth.service';

import { CountryCodeService, Country } from '@shared/services';

import { PdfService } from '@shared/services/pdf.service';
import { DashPhonePipe } from '@shared/pipes/dash-phone/dash-phone.pipe';
import { DatePipe } from '@angular/common';
import { AuthUser } from '@auth/store/auth.model';
import { Group } from 'src/app/group';
import { SendFromNumbers } from './fax-send.model';
import { Ata } from '@ata/store';

import { Store } from '@ngrx/store';
import * as fromFax from '@fax/store/fax.reducer';
import * as FaxActions from '@fax/store/fax.actions';

import { Observable } from 'rxjs';

interface Config {
  files: File[];
  countryLookUp: Country;
}

@Component({
  selector: 'voyant-fax-send',
  templateUrl: './fax-send.component.html',
  styleUrls: ['./fax-send.component.scss'],
})
export class FaxSendComponent implements OnInit {
  sendFaxForm: FormGroup;
  loggedInUser: AuthUser = this._authService.getLoggedInUser();
  config: Config;

  state$: Observable<fromFax.State>;

  sendFromNumbers: SendFromNumbers[] = [
    { type: 'direct line', numbers: [] },
    { type: 'group line', numbers: [] },
    { type: 'ata line', numbers: [] },
  ];

  constructor(
    private _authService: AuthService,
    private _pdfService: PdfService,
    private _countryCodesService: CountryCodeService,
    private datePipe: DatePipe,
    private dashPhonePipe: DashPhonePipe,
    public dialogRef: MatDialogRef<FaxSendComponent>,
    private fb: FormBuilder,
    private store: Store<fromFax.State>
  ) {
    this.config = {
      files: [],
      countryLookUp: null,
    };

    this.sendFaxForm = this.fb.group({
      fax_name: ['', [Validators.required]],
      send_to: ['', [Validators.required]],
      send_from: ['', [Validators.required]],
      fax_file: [''],
      cover_page: ['off'],
      cover_page_message: [''],
    });
  }

  get f() {
    return this.sendFaxForm.controls;
  }

  ngOnInit(): void {
    this.state$ = this.store.select(fromFax.getState);

    if (this.loggedInUser) {
      const { phone_numbers, groups, atas } = this.loggedInUser;

      this.sendFromNumbers[0].numbers = phone_numbers.map(
        (n: PhoneNumber, i: number) => ({
          name: 'Direct Line ' + (i + 1),
          phone_number: n.phone_number,
        })
      );

      let groups_with_numbers = groups.filter((g) => g.phone_numbers.length);
      this.sendFromNumbers[1].numbers = groups_with_numbers.map((g: Group) => ({
        phone_number: g.phone_numbers[0].phone_number,
        name: g.name,
      }));

      this.sendFromNumbers[2].numbers = atas.map((a: Ata) => ({
        phone_number: a.phone_number.phone_number,
        name: a.name,
      }));
    }
  }

  getPdfGenerator(): any {
    if (this.sendFaxForm.valid) {
      const { fax_name, send_from, send_to, cover_page_message } =
        this.sendFaxForm.value;

      const { first_name, last_name, account } = this.loggedInUser;

      const search = this.sendFromNumbers.find((collection) =>
        collection.numbers.find((set) => set.phone_number === send_from)
      );

      const send_from_label = search.numbers.find(
        (num) => num.phone_number === send_from
      ).name;

      const req = {
        fax_name,
        first_name,
        last_name,
        account_name: account.name,
        send_from_label,
        send_to: this.dashPhonePipe.transform(send_to),
        send_from: this.dashPhonePipe.transform(send_from),
        date: this.datePipe.transform(new Date(), 'fullDate'),
        message: cover_page_message.replace(/\n/g, '\\n').replace(/\r/g, '\\r'),
      };

      return this._pdfService.generateCoverSheet(req);
    }
  }

  onPreview(): void {
    const pdfDocGenerator = this.getPdfGenerator();
    if (pdfDocGenerator) {
      pdfDocGenerator.open();
    }
  }

  onFilesReady(e: File[]): void {
    this.config.files = [...e];
  }

  onSendFax(): void {
    if (this.sendFaxForm.valid && this.config.files.length > 0) {
      const { fax_name, send_from, send_to, cover_page } =
        this.sendFaxForm.value;
      let toNumber = send_to.match(/(\d+)/g, '').join('');

      if (toNumber.length === 8) {
        toNumber = '+1' + toNumber;
      }

      const req = {
        name: fax_name,
        from: send_from,
        to: [toNumber],
        file: this.config.files,
      };

      if (cover_page === 'on') {
        const pdfDocGenerator = this.getPdfGenerator();
        if (pdfDocGenerator) {
          pdfDocGenerator.getBlob((blob: any) => {
            const fileName = fax_name.replace(/\s/g, '').toLowerCase() + '.pdf';
            const file = new File([blob], fileName);
            req.file.unshift(file);
            this.store.dispatch(FaxActions.sendFax({ req }));
          });
        }
        return;
      }

      this.store.dispatch(FaxActions.sendFax({ req }));
    }
  }

  // sendFaxRequest(faxObj: any): void {
  //   this._faxService.sendFax(faxObj).subscribe(
  //     (res: any) => {
  //       this._toastrService.notify('success', res.message);
  //       // delay refresh to let db to update
  //       setTimeout(() => this._faxService.sentFaxesUpdated.next(), 1500);
  //       this.dialogRef.close(res);
  //     },
  //     (res: any) => this._toastrService.notify('error', res.error.message),
  //     () => (this.config.sending = false)
  //   );
  // }

  resetForm(): void {
    this.config.files = [];
    this.sendFaxForm.reset();
    this.sendFaxForm.clearValidators();
  }

  onLookUpCountry(): void {
    if (this.f.send_to && this.f.send_to.value) {
      const val = this.f.send_to.value;
      const list = this._countryCodesService.getCountryByCode(val);
      this.config.countryLookUp = list && list.length ? { ...list[0] } : null;
    }
  }
}
