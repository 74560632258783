import { Component, Input } from '@angular/core';

export interface StepperStep {
  label: string;
  complete: boolean;
  valid: boolean;
  active: boolean;
}

@Component({
  selector: 'voyant-stepper-nav',
  templateUrl: './stepper-nav.component.html',
  styleUrls: ['./stepper-nav.component.scss'],
})
export class StepperNavComponent {
  @Input() steps: StepperStep[];
}
