import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propArrayFilter',
})
export class PropArrayFilterPipe implements PipeTransform {
  transform(originalArr: any[], comapreToArr: any[], prop: string): any[] {
    if (!!comapreToArr && comapreToArr.length) {
      originalArr = originalArr.filter((item: any) => {
        let keep = true;
        for (const compareItem of comapreToArr) {
          if (item[prop] === compareItem[prop]) {
            keep = false;
            break;
          }
        }
        return keep;
      });
    }
    return originalArr || [];
  }
}
