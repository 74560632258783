export enum PortTypes {
  LOAD_ALL_PORTS = '[PORT] Load All Ports',
  LOAD_ALL_SUCCESS = '[PORT] Load All Sucess',

  LOAD_PORTS = '[PORT] Load Ports',
  SET_PORTS = '[PORT] Set Ports',

  CREATE_PORT = '[PORT] Create Port',
  UPDATE_PORT = '[PORT] Update Port',
  ADD_PORT = '[PORT] Add Port',

  LOAD_PORTS_METRICS = '[PORT] Load Ports Metrics',
  SET_PORTS_METRICS = '[PORT] Set Ports Metrics',

  SET_SELECTED_PORT = '[PORT] Set Selected Port',
  END_SELECTED_PORT = '[PORT] End Selected Port',
  REPORT_ERROR = '[PORT] Report Error',
}
