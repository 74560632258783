import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@shared/services';
import { FaxViewComponent } from '../modals';
import { FaxService } from '@fax/store';

@Component({
  selector: 'voyant-fax-preview',
  template: ``,
  styles: [],
})
export class FaxPreviewComponent implements OnInit {
  private faxId: string;
  constructor(
    private _faxService: FaxService,
    private _modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.faxId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.faxId && this.faxId.length > 0) {
      this._faxService.getFax(this.faxId).subscribe((res: any) => {
        const fax = res.data;
        this.onViewFaxDetails(fax);
      });
    } else {
      this.router.navigate(['dashboard']);
    }
  }

  onViewFaxDetails(fax): void {
    const dialogRef = this._modalService.openModal(
      FaxViewComponent,
      'right',
      fax
    );
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
