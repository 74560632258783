import { createAction, props } from '@ngrx/store';
import { VarProfile } from '@var/var.model';
import { AuthUser } from './auth.model';

const label = '[AUTH]';

export const login = createAction(
  `${label} Login`,
  props<{ email: string; password: string }>()
);

export const checkSso = createAction(
  `${label} Check SSO`,
  props<{ email: string }>()
);

export const setPasswordView = createAction(
  `${label} Set Password View`,
  props<{ showPassword: boolean }>()
);

export const authenticate = createAction(
  `${label} Authenticate`,
  props<{ payload: AuthUser }>()
);

export const checkAuthentication = createAction(
  `${label} Check Authentication`
);

export const updateAuthUser = createAction(
  `${label} Update Auth User`,
  props<{ user: Partial<AuthUser> | null }>()
);

export const getPublicVarProfile = createAction(
  `${label} Get Public Var Profile`
);

export const getPublicVarProfileSucces = createAction(
  `${label} Get Public Var Profile Success`,
  props<{ varProfile: VarProfile }>()
);

export const authenticateFailed = createAction(
  `${label} Authenticate Failed`,
  props<{ error: string }>()
);

export const logout = createAction(`${label} Logout`);
