import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Port } from '@port/store/port.model';
import { Store } from '@ngrx/store';
import * as fromPort from '@port/store/port.reducer';
import * as PortActions from '@port/store/port.actions';

@Component({
  selector: 'voyant-port-edit',
  templateUrl: './port-edit.component.html',
  styleUrls: ['./port-edit.component.scss'],
})
export class PortEditComponent implements OnInit {
  constructor(
    private store: Store<fromPort.State>,
    @Inject(MAT_DIALOG_DATA) public port: Port
  ) {
    this.store.dispatch(PortActions.setSelectedPort({ port }));
  }

  ngOnInit(): void {}

  onDownloadLoa(loaUrl: string) {
    // this._utilityService.getFile(loaUrl).subscribe((data: any) => {
    //   console.log('data', data);
    // });
  }
}
