import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  UrlTree,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as fromAuth from '@auth/store/auth.reducer';
import * as fromOps from '@ops/store/ops.reducer';
import { AuthUser } from './store/auth.model';
import { ToastrService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _toastrService: ToastrService,
    private store: Store<fromAuth.State>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return combineLatest([
      this.store.select(fromOps.getState),
      this.store.select(fromAuth.authUser),
    ]).pipe(
      map(([s, authUser]) => {
        const user = s.impersonating ? s.impersonating : authUser;
        if (user) {
          return this.canAccessPath(user, next, state);
        }

        this._authService.redirectToLogin();
        return false;
      })
    );
  }

  private canAccessPath(
    user: AuthUser,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!user) {
      return false;
    }

    const canAccess = next?.data
      ? next?.data?.expectedRoles.includes(user.role)
      : true;

    if (!canAccess) {
      if ((state.url = '/dashboard')) {
        this._authService.userRoleRedirect(user);
      } else {
        this._toastrService.notify(
          'error',
          'You Are Not Allowed to Access This Page'
        );
      }
    }

    return canAccess;
  }
}
