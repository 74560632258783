import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GroupModule } from '../group/group.module';
import { SharedModule } from '../shared/shared.module';
import { UsersTableComponent } from './users-table/users-table.component';

import {
  EditUserComponent,
  NewUserComponent,
  ProfileInfoComponent,
  ReviewCreateAccountComponent,
} from './modals';
import { AddFaxLinePersonalComponent } from './modals/add-fax-line-personal/add-fax-line-personal.component';
import { AtaModule } from '../ata/ata.module';
import { PortModule } from '../port/port.module';

@NgModule({
    declarations: [
        UsersTableComponent,
        NewUserComponent,
        ProfileInfoComponent,
        ReviewCreateAccountComponent,
        EditUserComponent,
        AddFaxLinePersonalComponent,
    ],
    imports: [
        SharedModule,
        GroupModule,
        AtaModule,
        PortModule,
        MatRadioModule,
        MatTooltipModule,
    ]
})
export class UsersModule {}
