import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as fromAuth from '@auth/store/auth.reducer';
import * as AuthActions from '@auth/store/auth.actions';

import { AuthService } from '@auth/auth.service';
import { catchError, exhaustMap, map, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { PhaxioResponse } from '@shared/models';
import { ToastrService } from '@shared/services';
import { VarService } from '@var/var.service';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      exhaustMap(({ email, password }) =>
        this._authService.login(email, password).pipe(
          map((res) => {
            this._authService.userRoleRedirect(res.data);
            return AuthActions.updateAuthUser({ user: res.data });
          }),
          catchError((errRes) => {
            this._toastrService.setErrorFlash(errRes.error);
            return of(
              AuthActions.authenticateFailed({ error: errRes.error.message })
            );
          })
        )
      )
    )
  );

  checkAuthentication = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.checkAuthentication),
      exhaustMap(() =>
        this._authService.checkAuthentication().pipe(
          map((res) => AuthActions.updateAuthUser({ user: res.data })),
          catchError((errRes) =>
            of(AuthActions.authenticateFailed({ error: errRes.error.message }))
          )
        )
      )
    )
  );
  checkSso$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.checkSso),
      exhaustMap(({ email }) =>
        this._authService.checkSSO(email).pipe(
          map((res: PhaxioResponse) => {
            if (res.success) {
              window.location.href = res.data;
              return AuthActions.updateAuthUser({ user: res.data });
            } else {
              return AuthActions.setPasswordView({ showPassword: true });
            }
          })
        )
      )
    )
  );

  getPublicVarProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getPublicVarProfile),
      exhaustMap(() =>
        this._varService.getPublicProfile(window.location.hostname).pipe(
          take(1),
          map((res) =>
            AuthActions.getPublicVarProfileSucces({ varProfile: res.data })
          )
        )
      )
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        exhaustMap(() => this._authService.logout())
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromAuth.State>,
    private _authService: AuthService,
    private _toastrService: ToastrService,
    private _varService: VarService
  ) {}
}
