import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchHighlight',
})
export class SearchHighlightPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (typeof value !== 'string'){
      return value;
    }
    const sanitized = value.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    if (args){
      const re = new RegExp(args.trim(), 'gi');
      return sanitized.replace(re, '<strong>$&</strong>');
    } else {
      return sanitized;
    }
  }
}
