import { Pipe, PipeTransform } from '@angular/core';
import { NumberService } from '@number/store/number.service';
interface PlanDetails {
  type: string;
  number_of_pages: number;
  price: number;
}

@Pipe({
  name: 'planUsage',
})
export class PlanUsagePipe implements PipeTransform {
  packages = this._numberService.getWholeSalePriceList();

  constructor(private _numberService: NumberService) {}

  transform(value: string, currentCount: number): string {
    if (value) {
      const packageDetails: PlanDetails = this.getPackageDetails(value);
      return `${currentCount} / ${packageDetails.number_of_pages}`;
    } else {
      return '';
    }
  }

  private getPackageDetails(plan: string): PlanDetails {
    return this.packages.find((p) => p.type === plan);
  }
}
