import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SignaturePad } from 'angular2-signaturepad';
import * as fromPort from '@port/store/port.reducer';
import { Config, portDefaults } from './port-new.model';
import { DatePipe } from '@angular/common';
import { PdfService } from '@shared/services/pdf.service';
import * as PortActions from '@port/store/port.actions';
import { UtilityService, ToastrService } from '@shared/services';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Router } from '@angular/router';

@Component({
  selector: 'voyant-port-new',
  templateUrl: './port-new.component.html',
  styleUrls: ['./port-new.component.scss'],
})
export class PortNewComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  datePickerOpen = false;
  minDate: Date;

  signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 442,
    canvasHeight: 131,
  };

  portRequest: FormGroup;

  portConfig: Config = {
    loading: false,
    submitted: false,
    portType: 'residential',
    signature: null,
    initialValues: { ...portDefaults },
  };

  portTypes = ['residential', 'business'];
  states = this._utility.getStates();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private store: Store<fromPort.State>,
    private _utility: UtilityService,
    private _toastrService: ToastrService,
    private _pdfService: PdfService
  ) {
    this.minDate = this._utility.addWorkDays(new Date(), 5);
    this.portRequest = this.fb.group({
      business: this.fb.group({
        name_of_business: [null, [Validators.required]],
        account_identifier: [null, [Validators.required]],
        port_out_pin: [null, [Validators.required]],
        billing_address1: [null, [Validators.required]],
        billing_address2: [null],
        billing_city: [null, [Validators.required]],
        billing_state: [null, [Validators.required]],
        billing_zip: [null, [Validators.required]],
        billing_number: [null, [Validators.required]],
      }),
      contact: this.fb.group({
        name_on_account: [null, [Validators.required]],
        contact_email: [null, [Validators.required, Validators.email]],
        contact_number: [null, [Validators.required]],
      }),
      port: this.fb.group({
        provider_name: [null],
        requested_for: [this.minDate, [Validators.required]],
        port_type: ['residential', [Validators.required]],
        port_numbers: [null, [Validators.required]],
        legal_agreement: [true, [Validators.required]],
        authorized_signer: [null, [Validators.required]],
        bill_received_at: [new Date(), [Validators.required]],
        esig: [false, [Validators.required]],
        loa: [false],
      }),
    });
  }

  get p() {
    return this.portRequest.controls;
  }

  get business() {
    const b: FormGroup = this.p.business as FormGroup;
    return b.controls;
  }

  get contact() {
    const c: FormGroup = this.p.contact as FormGroup;
    return c.controls;
  }

  get port() {
    const p: FormGroup = this.p.port as FormGroup;
    return p.controls;
  }

  ngOnInit(): void {}

  drawComplete() {
    this.port.esig.setValue(true);
    this.portConfig.signature = this.signaturePad.toDataURL();
  }

  onSubmit() {
    this.router.navigate(['/numbers']);
    // window.location.href = '/numbers';
    // this.portConfig.submitted = true;
    // if (this.portRequest.valid) {
    //   const req: any = this.flattenObject(this.portRequest.value);
    //
    //   const port_numbers = this.validatePhoneNumbers(req.port_numbers);
    //   if (!port_numbers) {
    //     return;
    //   }
    //
    //   req.port_numbers = port_numbers;
    //
    //   if (req.loa) {
    //     const loaGenerator = this.onGetLoaGenerator();
    //     if (loaGenerator) {
    //       loaGenerator.getBlob((blob: any) => {
    //         this.store.dispatch(
    //           PortActions.createPort({
    //             payload: req,
    //             loa: blob,
    //           })
    //         );
    //       });
    //     }
    //   } else {
    //     this.store.dispatch(
    //       PortActions.createPort({
    //         payload: req,
    //       })
    //     );
    //   }
    // } else {
    //   window.scroll(0, 0);
    // }
  }

  onSetPortType(type: 'residential' | 'business') {
    this.p.port.patchValue({
      port_type: type,
    });
  }

  onGetLoaGenerator() {
    const loaParams: any = {
      name_of_business: this.business.name_of_business.value,
      name_on_account: this.contact.name_on_account.value,
      billing_address1: this.business.billing_address1.value,
      billing_address2: this.business.billing_address2.value,
      contact_email: this.contact.contact_email.value,
      billing_city: this.business.billing_city.value,
      billing_state: this.business.billing_state.value,
      billing_zip: this.business.billing_zip.value,
      contact_number: this.contact.contact_number.value,
      requested_for: this.datePipe.transform(
        this.port.requested_for.value,
        'MM/dd/yy h:mm a'
      ),
      port_numbers: this.validatePhoneNumbers(this.port.port_numbers.value),
      signature: this.portConfig.signature,
      authorized_signer: this.port.authorized_signer.value,
      bill_received_at: this.datePipe.transform(
        this.port.bill_received_at.value,
        'MM/dd/yy h:mm a'
      ),
    };

    const pdfDocGenerator = this._pdfService.generateLoa(loaParams);
    return pdfDocGenerator;
  }

  onToggleDatePicker() {
    setTimeout(() => {
      this.datePickerOpen = !this.datePickerOpen;
    }, 50);
  }

  private validatePhoneNumbers(numsStr: string): string[] | boolean {
    const arr = numsStr.split(/[\n,\s]/).filter((n) => n);
    let valid = true;
    const wrongNums = [];
    arr.forEach((num) => {
      const phoneNumber = parsePhoneNumber(num, 'US');
      if (!phoneNumber.isValid()) {
        wrongNums.push(num);
        valid = false;
      }
    });

    if (!valid) {
      this._toastrService.notify(
        'error',
        `The following phone number${
          wrongNums.length > 1 ? 's' : ''
        } are invalid ${wrongNums.join(',  ')}. Please fix ${
          wrongNums.length > 1 ? 'them' : 'it'
        } or remove ${wrongNums.length > 1 ? 'them' : 'it'} from the list`,
        5000
      );
    }

    return valid ? arr : false;
  }

  private flattenObject = (obj) => {
    const flattened = {};

    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(flattened, obj[key]);
      } else {
        flattened[key] = obj[key];
      }
    });

    return flattened;
  };
}
