import * as AdminActions from './admin.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';

import {
  pagingDefault,
  SearchParams,
  SearchParamsSortDir,
} from '@shared/models';

import { Log } from './admin.model';

export interface State extends EntityState<Log> {
  loading: boolean;
  error: string;
  searchParams: SearchParams;
}

export const adapter: EntityAdapter<Log> = createEntityAdapter<Log>({
  selectId: (a: Log) => a.id,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
  searchParams: {
    ...pagingDefault,
    sort_dir: SearchParamsSortDir.DESC,
    sort_on: 'created_at',
  },
});

export const adminReducer = createReducer(
  initialState,

  on(AdminActions.getLog, (state, { payload }) => {
    return {
      ...state,
      loading: true,
      searchParams: {
        ...state.searchParams,
        ...payload,
      },
    };
  }),

  on(AdminActions.getLogSuccess, (state, { logs, paging }) => {
    return adapter.setAll(logs, {
      ...state,
      loading: false,
      searchParams: {
        ...state.searchParams,
        ...paging,
      },
    });
  }),

  on(AdminActions.getUsersSuccess, (state, { logUsers }) => {
    const changes = [];
    logUsers.forEach((user, id) =>
      changes.push({
        id,
        changes: {
          user,
        },
      })
    );
    return adapter.updateMany(changes, state);
  }),

  on(AdminActions.reportError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  })
);

export const getState = createFeatureSelector<State>('admin');

export const { selectIds, selectAll, selectEntities, selectTotal } =
  adapter.getSelectors(getState);

export const searchParams = createSelector(getState, (s) => s.searchParams);
