import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Department } from '../../department.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { UserService, User } from 'src/app/user';
import { ModalService, ToastrService } from '@shared/services';
import { DepartmentService } from '../../department.service';
import { pagingDefault } from '@shared/models';
import { AddUsersComponent } from '@shared/components/modal';
import { AuthService } from '@auth/auth.service';

@Component({
  selector: 'voyant-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.scss'],
})
export class EditDepartmentComponent implements OnInit {
  loggedInUser: User;
  isEdit = false;
  departmentForm: FormGroup;
  users: User[];
  depManager: any = null;

  config = {
    initialValues: { ...this.department },
    saveDisabled: true,
  };

  constructor(
    private _userService: UserService,
    private _modalService: ModalService,
    private _departmentService: DepartmentService,
    private _toastrService: ToastrService,
    private _authService: AuthService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditDepartmentComponent>,
    @Inject(MAT_DIALOG_DATA) public department: Department
  ) {
    this.departmentForm = this.fb.group({
      name: ['', [Validators.required]],
      manager_id: ['', [Validators.required]],
      billing_email: ['', [Validators.required, Validators.email]],
      billing_name: ['', [Validators.required]],
      billing_id: [''],
      selectBiller: [false],
    });

    if (!this._userService.fetchAll().length) {
      this._userService.getUsers().subscribe();
    }
  }

  get c() {
    return this.departmentForm.controls;
  }

  ngOnInit(): void {
    this.loggedInUser = this._authService.getLoggedInUser();
    this.onInit();

    this.departmentForm.valueChanges.subscribe({
      next: (changes: any) => {
        for (const prop in changes) {
          if (changes[prop] === this.config.initialValues[prop]) {
            this.departmentForm.get(prop).markAsPristine();
          }
        }
        this.config.saveDisabled = !this.departmentForm.dirty;
      },
    });
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  onInit() {
    const users: User[] = Array.from(this._userService.fetchAll().values());
    this.users = users.sort(this._userService.sortUsers);

    const { name, managers, billing_name, billing_email } = this.department;
    this.depManager = managers && managers.length ? managers[0] : null;

    const biller: User[] = users.filter(
      (user: User) => user.email === billing_email
    );

    this.departmentForm.patchValue({
      name,
      manager_id: managers && managers.length ? managers[0].id : null,
      billing_email,
      billing_name,
      billing_id: biller && biller.length ? biller[0].id : null,
      selectBiller: false,
    });
  }

  userFilter(users: User[]) {
    const notAssignable: string[] =
      this._departmentService.getNonAssignableUsers();
    const managerId =
      this.department.managers && this.department.managers.length
        ? this.department.managers[0].id
        : null;
    if (managerId) {
      notAssignable.splice(notAssignable.indexOf(managerId), 1);
    }
    return users.filter((user: User) => !notAssignable.includes(user.id));
  }

  onSaveDepartment() {
    const depUpdate = this.updateDepartmentInfo();
    const usersUpdate = this.updateDepartmentUsers();

    forkJoin([...depUpdate, ...usersUpdate]).subscribe(() => {
      this._toastrService.notify(
        'success',
        `[${this.c.name.value}] department was successfully updated`
      );

      this._departmentService.get(pagingDefault).subscribe(() => {
        this.isEdit = false;
        this.dialogRef.close();
      });
    });
  }

  onRemoveUser({ user, index }) {
    this.department.users.splice(index, 1);
  }

  onAddUsers() {
    const dialogRef = this._modalService.openModal(null, 'center', {
      component: AddUsersComponent,
      title: 'Add Members',
      removeFromList: this._departmentService.getNonAssignableUsers(),
    });

    dialogRef.afterClosed().subscribe((res: { users: User[] }) => {
      if (typeof res === 'object' && res.users.length > 0) {
        const newUserList: any = [...this.department.users, ...res.users].sort(
          this._userService.sortUsers
        );
        this.department.users = newUserList;
      }
    });
  }

  onDeleteDepartment() {
    const dialogRef = this._modalService.openConfirmModal({
      modalType: 'danger',
      title: 'Delete Department',
      altTitle: `Are you sure you want to delete [${this.department.name}] ?`,
      message: 'Once deleted, this action cannot be undone.',
      confirmText: 'delete',
    });
    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this._departmentService.delete(this.department.id).subscribe(() => {
          this._toastrService.notify(
            'success',
            `[${this.c.name.value}] department was successfully deleted`
          );

          forkJoin([
            this._departmentService.get({ ...pagingDefault }, true),
          ]).subscribe(() => {
            this.isEdit = false;
            this.dialogRef.close();
          });
        });
      }
    });
  }

  onCancel() {
    const userList: User[] = Array.from(this._userService.fetchAll().values());
    const { name, managers, billing_name, billing_email, users } =
      this.config.initialValues;

    const biller: User[] = userList.filter(
      (user: User) => user.email === billing_email
    );

    this.departmentForm.patchValue({
      name,
      billing_name,
      billing_email,
      manager_id: managers && managers.length ? managers[0].id : '',
      billing_id: biller && biller.length ? biller[0].id : '',
    });

    this.depManager = managers && managers.length ? managers[0] : null;
    this.department.users = [...users];
    this.isEdit = false;
  }

  onSetBiller(u: User) {
    const { first_name, last_name, email } = u;
    this.departmentForm.patchValue({
      billing_name: `${first_name} ${last_name}`,
      billing_email: email,
    });
  }

  canEdit() {
    if (this.loggedInUser.role === 'admin') {
      return true;
    }

    if (this.loggedInUser.role === 'manager') {
      const managerIds = this.department.managers.map((user: User) => user.id);
      return managerIds.includes(this.loggedInUser.id);
    }
  }

  private updateDepartmentInfo() {
    const reqArr = [];
    if (!this.config.saveDisabled) {
      // general info update
      const { name, billing_name, billing_email, manager_id } =
        this.departmentForm.value;

      reqArr.push(
        this._departmentService.update(this.department.id, {
          name,
          billing_email,
          billing_name,
        })
      );

      // manager update
      const d: Department = this.config.initialValues;
      if (!d.managers.length || d.managers[0].id !== manager_id) {
        const user = this._userService.fetchAll()[manager_id];
        if (user.role !== 'manager') {
          reqArr.push(
            this._userService.updateUser(user.id, { ...user, role: 'manager' })
          );
        }
        // remove old manager
        if (d.managers.length) {
          reqArr.push(
            this._departmentService.removeUser(
              this.department.id,
              d.managers[0].id
            )
          );
        }
        // add new manager
        reqArr.push(
          this._departmentService.addUser(this.department.id, user.id)
        );
      }
    }
    return reqArr;
  }

  private updateDepartmentUsers() {
    let reqArr = [];

    const deleteUsers = [];
    const addUsers = [];

    const original = this.config.initialValues.users.map(
      (user: User) => user.id
    );

    const change = this.department.users.map((user: User) => user.id);

    if (JSON.stringify(original) !== JSON.stringify(change)) {
      original.forEach((userId: string) => {
        if (!change.includes(userId)) {
          deleteUsers.push(
            this._departmentService.removeUser(this.department.id, userId)
          );
        }
      });

      change.forEach((userId: string) => {
        if (!original.includes(userId)) {
          addUsers.push(
            this._departmentService.addUser(this.department.id, userId)
          );
        }
      });
      reqArr = [...deleteUsers, ...addUsers];
    }
    return reqArr;
  }
}
