import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../store/user.model';
import { GroupService } from '../../group/store/group.service';
import { Group } from '../../group';
import { ModalService, SearchService } from '../../shared/services';
import { EditUserComponent } from '../modals/edit-user/edit-user.component';
import { PhoneNumber } from '../../number';
import { DashPhonePipe } from '../../shared/pipes/dash-phone/dash-phone.pipe';
import {
  SearchParams,
  UniversalSearchValues,
  pagingDefault,
} from '../../shared/models';
import { DepartmentService } from 'src/app/department/department.service';
import { Department } from 'src/app/department/department.model';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';

import * as fromGroup from '../../group/store/group.reducer';
import * as fromUser from '../store/user.reducer';
import * as UserActions from '../store/user.actions';
import * as GroupActions from '../../group/store/group.actions';
import { Subscription, Observable, combineLatest } from 'rxjs';

@Component({
  selector: 'voyant-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit, OnDestroy {
  userGroups: Map<string, Group[]> = new Map();

  isSearch = false;

  state$: Observable<fromUser.State>;
  users$: Observable<User[]>;

  searchParams: SearchParams =
    this._searchService.initSearchParams('first_name');
  searchSubscription: Subscription;

  search: string;

  constructor(
    private _modal: ModalService,
    private _groupService: GroupService,
    private _searchService: SearchService,
    private _departmentService: DepartmentService,
    private dashPhone: DashPhonePipe,
    private store: Store<fromApp.AppState>
  ) {
    this.store.dispatch(GroupActions.loadGroups({ payload: pagingDefault }));
  }

  ngOnInit(): void {
    this.searchSubscription = this._searchService.term.subscribe(
      (params: UniversalSearchValues) => {
        this.search = params.search;
        this.searchParams = { ...this.searchParams, page: 1 };

        if (params.search && params.search.length) {
          this.searchParams.search = params.search;
          this.isSearch = true;
        } else if (this.searchParams.hasOwnProperty('search')) {
          delete this.searchParams.search;
          this.isSearch = false;
        }

        this.store.dispatch(
          UserActions.loadUsers({ payload: this.searchParams })
        );
      }
    );

    this.users$ = this.store.select(fromUser.selectAll);
    this.state$ = this.store.select(fromUser.getState);

    combineLatest([
      this.store.select(fromUser.selectAll),
      this.store.select(fromGroup.selectAll),
    ]).subscribe(([users, groups]) => {
      if (users.length) {
        users.forEach((u: User) => {
          this.userGroups.set(u.id, this._groupService.getUserGroups(u.id));
        });
      }
    });
  }

  onGetUserDepartment(userid: string): Department[] {
    return this._departmentService.getUserDepartments(userid);
  }

  getGroupNamesString(groups: Group[]): string {
    return groups
      .map((group: Group) => group.name)
      .join(', ')
      .trim();
  }

  getNumbersString(numbers: PhoneNumber[]): string {
    return numbers
      .map((phoneNumObj: PhoneNumber, index: number) =>
        this.dashPhone.transform(phoneNumObj.phone_number)
      )
      .join(', ')
      .trim();
  }

  onViewUser(user: User): void {
    this.store.dispatch(UserActions.setSelectedUser({ payload: user }));

    const dialogRef = this._modal.openModal(EditUserComponent, 'right', user);

    dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(UserActions.endSelectedUser());
    });
  }

  onPageChanged(paginationConfig): void {
    this.searchParams = { ...this.searchParams, ...paginationConfig };
    delete this.searchParams.total;

    this.store.dispatch(UserActions.loadUsers({ payload: this.searchParams }));
  }

  onSortChanged(sortOn: string): void {
    this.searchParams = this._searchService.updateSort(
      sortOn,
      this.searchParams
    );

    this.store.dispatch(UserActions.loadUsers({ payload: this.searchParams }));
  }

  identify(index, item: User) {
    return item.id;
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
    this._searchService.term.next({
      search: null,
    });
  }
}
