import { NgModule } from '@angular/core';
import { AdminLogComponent } from './admin-log/admin-log.component';
import { SharedModule } from '../shared/shared.module';

import { AdminRoutingModule } from './admin-routing.module';

@NgModule({
  declarations: [AdminLogComponent],
  imports: [AdminRoutingModule, SharedModule],
})
export class AdminModule {}
