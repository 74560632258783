import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { Observable } from 'rxjs';
import { PhaxioResponse } from '../models';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  private FAX_API_URL = this.env.apiUrls.faxApi;
  private METRICS_URL = `${this.FAX_API_URL}metrics`;

  constructor(private env: EnvironmentService, private http: HttpClient) {}

  getAccountMetrics(
    accId: number,
    d: { fromDate: Date; toDate: Date }
  ): Observable<PhaxioResponse> {
    const params = {
      created_before: d.toDate.toUTCString(),
      created_after: d.fromDate.toDateString(),
    };

    let url = `${this.METRICS_URL}/accounts/${accId}`;
    return this.http
      .get(url, { params })
      .pipe(map((res: PhaxioResponse) => res));
  }

  getVarMetrics(d: {
    fromDate: Date;
    toDate: Date;
  }): Observable<PhaxioResponse> {
    const params = {
      created_before: `${d.toDate.getFullYear()}-${
        d.toDate.getMonth() + 1
      }-${d.toDate.getDate()}`,
      created_after: `${d.fromDate.getFullYear()}-${
        d.fromDate.getMonth() + 1
      }-${d.fromDate.getDate()}`,
    };

    let url = `${this.METRICS_URL}/vars`;
    return this.http
      .get(url, { params })
      .pipe(map((res: PhaxioResponse) => res));
  }
}
