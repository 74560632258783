import * as fromNumber from './number.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PhoneNumber } from './number.model';

export const getState = createFeatureSelector<fromNumber.State>('number');

export const { selectIds, selectAll, selectEntities, selectTotal } =
  fromNumber.adapter.getSelectors(getState);

export const selectAvailable = createSelector(
  selectAll,
  (all: PhoneNumber[]) => {
    return all
      .filter((n: PhoneNumber) => {
        return (
          !n.hasOwnProperty('user_id') &&
          !n.hasOwnProperty('group_id') &&
          !n.hasOwnProperty('ata_id')
        );
      })
      .sort((a: PhoneNumber, b: PhoneNumber) =>
        a.phone_number > b.phone_number ? 1 : -1
      );
  }
);
