import { NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';

import { AuthLoginComponent } from './auth-login/auth-login.component';
import { SharedModule } from '../shared/shared.module';
import { AuthForgotPasswordComponent } from './auth-forgot-password/auth-forgot-password.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';

import { AuthActivateComponent } from './auth-activate/auth-activate.component';
import { CallInterceptorService } from './call-interceptor.service';
import { RoleGuard } from './role.guard';
import { AuthResetPasswordComponent } from './auth-reset-password/auth-reset-password.component';
import { AuthContainerComponent } from './auth-container/auth-container.component';
import { AuthVerifyEmailComponent } from './auth-verify-email/auth-verify-email.component';
import { AuthRoutingModule } from './auth-routing.module';
import { Store } from '@ngrx/store';
import * as fromAuth from '@auth/store/auth.reducer';
import * as AuthActions from '@auth/store/auth.actions';

@NgModule({
  declarations: [
    AuthLoginComponent,
    AuthForgotPasswordComponent,
    AuthLayoutComponent,
    AuthActivateComponent,
    AuthResetPasswordComponent,
    AuthContainerComponent,
    AuthVerifyEmailComponent,
  ],
  providers: [
    AuthService,
    AuthGuard,
    RoleGuard,
    CookieService,
    CallInterceptorService,
  ],
  imports: [SharedModule, AuthRoutingModule],
  exports: [AuthLoginComponent],
})
export class AuthModule {
  constructor(private store: Store<fromAuth.State>) {
    this.store.select(fromAuth.isVar).subscribe((isVar) => {
      if (isVar) {
        this.store.dispatch(AuthActions.getPublicVarProfile());
      }
    });
  }
}
