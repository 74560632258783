import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'voyant-fax-status',
  templateUrl: './fax-status.component.html',
  styleUrls: ['./fax-status.component.scss'],
})
export class FaxStatusComponent implements OnChanges {
  @ViewChild('statusContainer', { static: true }) statusContainer;
  @Input() status: string;
  applyClass = '';
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const status = changes.status.currentValue;
    const applyClass = this.getStatusClass(status);
    this.applyClass = applyClass;
  }

  getStatusClass(status: string): string {
    let applyClass = 'status__';
    switch (status) {
      case 'success':
        applyClass += 'success';
        break;
      case 'retrying':
        applyClass += 'primary';
        break;
      case 'unread':
        applyClass += 'blue';
        break;
      case 'partialsuccess':
      case 'inprogress':
      case 'queued':
        applyClass += 'warning';
        break;
      case 'error':
      case 'failure':
        applyClass += 'danger';
        break;
    }
    return applyClass;
  }
}
