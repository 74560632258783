import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class UploadService {
  apiUrl: string;

  constructor(private http: HttpClient) {
    // this.apiUrl = this.env.apiUrls.ossApi;
  }

  getUploadUrls(): Promise<any> {
    return this.http
      .post(`${this.apiUrl}fax/presigned_urls`, {})
      .toPromise()
      .then((data) => data)
      .catch((err) => console.log(err));
  }
}
