import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { VarProfile } from '@var/var.model';

import * as AuthActions from './auth.actions';
import { AuthUser } from './auth.model';

export interface State {
  isVar: boolean;
  loggedInUser: AuthUser | null;
  showPassword: boolean;
  error: string | null;
  varProfile: VarProfile;
}

const notVarHosts: string[] = [
  'fax.voyant-staging.com',
  'fax.voyant.com',
  'fax.inteliquent.com',
  'staging-fax.inteliquent.com',
  'localhost',
];
const def: State = {
  isVar: true,
  loggedInUser: null,
  showPassword: false,
  error: null,
  varProfile: null,
};

const initialState: State = {
  isVar: !notVarHosts.includes(window.location.hostname),
  loggedInUser: null,
  showPassword: !notVarHosts.includes(window.location.hostname),
  error: null,
  varProfile: null,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.setPasswordView, (state, { showPassword }) => {
    return {
      ...state,
      showPassword,
    };
  }),

  on(AuthActions.updateAuthUser, (state, { user }) => {
    return {
      ...state,
      loggedInUser: {
        ...state.loggedInUser,
        ...user,
      },
    };
  }),

  on(AuthActions.logout, (state) => {
    return {
      ...initialState,
      showPassword: state.showPassword,
    };
  }),

  on(AuthActions.authenticateFailed, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),

  on(AuthActions.getPublicVarProfileSucces, (state, { varProfile }) => ({
    ...state,
    varProfile,
  }))
);

export const getState = createFeatureSelector<State>('auth');
export const authUser = createSelector(getState, (s) => s.loggedInUser);
export const isVar = createSelector(getState, (s) => s.isVar);
export const varProfile = createSelector(getState, (s) => s.varProfile);
