import { Component, OnInit } from '@angular/core';
import {
  UniversalSearchControlls,
  UniversalSearchValues,
  Paging,
  pagingDefault,
  SearchParams,
} from '@shared/models';
import { Department, DepartmentResponse } from '../department.model';
import { SearchService, ModalService } from '@shared/services';
import { NewDepartmentComponent } from '../modals/new-department/new-department.component';
import { DepartmentService } from '../department.service';
import { EditDepartmentComponent } from '../modals/edit-department/edit-department.component';
import { User, UserService } from 'src/app/user';
import { AuthService } from '@auth/auth.service';
import { debounceTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';

@Component({
  selector: 'voyant-departments-all',
  templateUrl: './departments-all.component.html',
  styleUrls: ['./departments-all.component.scss'],
})
export class DepartmentsAllComponent implements OnInit {
  loading = true;
  loggedInUser: User;
  isSearch = false;
  departments: Department[];

  searchControls: UniversalSearchControlls = {
    search: true,
  };

  paginationConfig: Paging = pagingDefault;
  searchParams: SearchParams = this._searchService.initSearchParams('name');

  constructor(
    private _searchService: SearchService,
    private _modalService: ModalService,
    private _userService: UserService,
    private _departmentService: DepartmentService,
    private _authServhice: AuthService,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit(): void {
    this.loggedInUser = this._authServhice.getLoggedInUser();
    this._departmentService
      .get(this.searchParams)
      .subscribe(this.handleDepartmentResponse());

    this._departmentService.departmentsUpdated.subscribe(
      (res: DepartmentResponse) => {
        this.departments = [...res.data];
        this.paginationConfig = { ...res.paging };
      }
    );

    if (!this._userService.fetchAll().length) {
      this._userService.getUsers().subscribe();
    }
  }

  onFilterResults(params: UniversalSearchValues) {
    this.loading = true;
    this.searchParams = { ...this.searchParams, ...params, page: 1 };
    this.searchParams = this._searchService.sanitizeParams(this.searchParams);

    this._departmentService
      .get(this.searchParams)
      .pipe(debounceTime(350))
      .subscribe(this.handleDepartmentResponse());
  }

  onSortChanged(sortOn: string) {
    this.loading = true;
    this.searchParams = this._searchService.updateSort(
      sortOn,
      this.searchParams
    );

    this._departmentService
      .get(this.searchParams)
      .subscribe(this.handleDepartmentResponse());
  }

  onPageChanged(paginationConfig) {
    this.searchParams = { ...this.searchParams, ...paginationConfig };

    this._departmentService
      .get(this.searchParams)
      .subscribe(this.handleDepartmentResponse());
  }

  openNewDepartmentModal() {
    const dialogRef = this._modalService.openModal(
      NewDepartmentComponent,
      'center',
      {
        isStepper: true,
        title: 'Create New Department',
      }
    );

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openViewDepartmentModal(dep: Department) {
    const dialogRef = this._modalService.openModal(
      EditDepartmentComponent,
      'right',
      dep
    );

    dialogRef.afterClosed().subscribe(() => {
      this._departmentService.get().subscribe(this.handleDepartmentResponse());
    });
  }

  private handleDepartmentResponse() {
    return {
      next: (res: DepartmentResponse) => {
        this.departments = res.data;
        this.paginationConfig = { ...res.paging };
      },
      error: (errRes) => (this.departments = []),
      complete: () => (this.loading = false),
    };
  }
}
