import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { GroupService } from '../../../store/group.service';
import { NumberService } from '../../../../number';
import { User } from '../../../../user';
import { ToastrService } from '@shared/services';
import { forkJoin } from 'rxjs';
import * as fromApp from '@app-store/app.reducer';
import * as GroupActions from '@group/store/group.actions';
import { Store } from '@ngrx/store';
import { pagingDefault } from '@shared/models';

@Component({
  selector: 'voyant-review-create-group',
  templateUrl: './review-create-group.component.html',
  styleUrls: ['./review-create-group.component.scss'],
})
export class ReviewCreateGroupComponent implements OnInit {
  @Input() stepperData: {
    groupInfo: {
      name: string;
      phoneNumber: any;
    };
    users: User[];
  };

  displayUsers: any[] = [];

  @Output() back = new EventEmitter();
  isSubmitting = false;

  constructor(
    private _groupService: GroupService,
    private _numberService: NumberService,
    private _toastrService: ToastrService,
    private store: Store<fromApp.AppState>,
    public dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit() {
    if (this.stepperData && this.stepperData.users) {
      this.stepperData.users.forEach((user) => this.displayUsers.push(user));
    }
  }

  onBack() {
    this.back.emit('true');
  }

  onCreateGroup() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    const { groupInfo, users } = this.stepperData;
    const { name, phoneNumber } = groupInfo;

    const payload = {
      name,
      phone_numbers: [phoneNumber],
      users: users.map((user) => user.id),
    };

    if (phoneNumber.hasOwnProperty('area_code')) {
      const { area_code, is_hipaa, plan } = phoneNumber;
      this._numberService
        .provisionNumber({
          plan,
          area_code,
          is_hipaa,
        })
        .subscribe((res: any) => {
          payload.phone_numbers = [res.data];
          this._groupService.createGroup(payload).subscribe(
            (res: any) => this.handleSuccess(res),
            (errRes: any) => this._toastrService.setErrorFlash(errRes.error)
          );
        });
    } else {
      this._groupService.createGroup(payload).subscribe(
        (res: any) => this.handleSuccess(res),
        (errRes: any) => this._toastrService.setErrorFlash(errRes.error)
      );
    }
  }

  handleSuccess(res) {
    // timeout is needed to let db to update
    setTimeout(() => {
      forkJoin([
        this._groupService.getGroups(null),
        this._numberService.getNumbers(),
      ]).subscribe(() => {
        this.store.dispatch(
          GroupActions.loadGroups({ payload: pagingDefault })
        );
        this._toastrService.notify(
          'success',
          `${res.data.name} group successfully created.`
        );
        this.dialogRef.close(res);
      });
    }, 600);
  }
}
