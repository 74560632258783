import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
  FormControl,
} from '@angular/forms';

import { ModalService } from '@shared/services';
import { NewGroupComponent } from 'src/app/group/modals';
import { NewUserComponent } from 'src/app/user/modals';
import { Store } from '@ngrx/store';

import * as fromApp from '@app-store/app.reducer';
import * as NumberActions from '@number/store/number.actions';
import * as numberSelectors from '@number/store/number.selectors';
import { NumberService } from '@number/store/number.service';
import { Observable } from 'rxjs';
import { AuthUser } from '@auth/store/auth.model';
import { map } from 'rxjs/operators';
import { PhoneNumber } from 'libphonenumber-js';

@Component({
  selector: 'voyant-buy-number',
  templateUrl: './buy-number.component.html',
  styleUrls: ['./buy-number.component.scss'],
})
export class BuyNumberComponent implements OnInit {
  buyForm: FormGroup;
  processing = false;
  purchaseWasMade = false;
  purchasedNumbers: { phone_number: string; is_hipaa: boolean }[] = [];
  failedNumbers = 0;
  packages = this._numberService.getWholeSalePriceList();
  auth$: Observable<AuthUser>;

  @ViewChild('buyFormRef', { static: true }) buyFormRef: NgForm;

  constructor(
    private fb: FormBuilder,
    private _numberService: NumberService,
    private _modalService: ModalService,
    private dialogRef: MatDialogRef<BuyNumberComponent>,
    private store: Store<fromApp.AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.auth$ = this.store.select('auth').pipe(map((s) => s.loggedInUser));
  }

  get numberProvisioning() {
    return this.buyForm.get('numberProvisioning');
  }
  set numberProvisioning(val: any) {
    this.numberProvisioning.setValue(val);
  }

  get areaCode() {
    return this.buyForm.get('areaCode');
  }
  set areaCode(val: any) {
    this.areaCode.setValue(val);
  }

  get quantity() {
    return this.buyForm.get('quantity');
  }
  set quantity(val: any) {
    this.quantity.setValue(val);
  }

  get hipaa() {
    return this.buyForm.get('hipaa');
  }
  set hipaa(val: any) {
    this.hipaa.setValue(val);
  }

  get selectedPlan() {
    return this.buyForm.get('selectedPlan');
  }
  set selectedPlan(val: any) {
    this.selectedPlan.setValue(val);
  }

  get buyControls() {
    return this.buyForm.controls;
  }

  ngOnInit(): void {
    this.store.select(numberSelectors.getState).subscribe((s) => {
      this.purchasedNumbers = s.numbersBought;
      this.failedNumbers = s.numbersFailed;
      this.processing = s.numbersBuyLoading;
    });

    this.buyForm = this.fb.group({
      numberProvisioning: ['single-number'],
      areaCode: [null, [Validators.required]],
      quantity: [
        1,
        [Validators.required, Validators.min(1), Validators.max(20)],
      ],
      selectedPlan: [null],
      hipaa: [null, [Validators.required]],
    });

    this.areaCode.setAsyncValidators(
      this._numberService.areaCodeIsAvailableValidator()
    );
  }

  setNumberProvisioningType(type: string) {
    this.numberProvisioning = type;
    this.quantity = type === 'single-number' ? 1 : null;
  }

  onBuyNumbers() {
    if (this.buyForm.valid) {
      const req = {
        area_code: this.areaCode.value,
        plan: this.selectedPlan.value,
        is_hipaa: this.hipaa.value === 'yes' ? true : false,
      };

      this.store.dispatch(
        NumberActions.provisionManyPhoneNumbers({
          quantity: this.quantity.value,
          req,
        })
      );
      this.purchaseWasMade = true;
    }
  }

  onFinish() {
    this.store.dispatch(NumberActions.provisionManyPhoneNumbersDone());
    this.dialogRef.close({
      numbers: this.purchasedNumbers,
    });
  }

  openNewGroupModal() {
    this._modalService.openModal(NewGroupComponent, 'center', {
      title: 'Create New Group',
    });
    this.dialogRef.close();
  }

  openNewUserModal() {
    this._modalService.openModal(NewUserComponent, 'center', {
      title: 'Create New Account',
    });
    this.dialogRef.close();
  }

  hasError(val: FormControl) {
    if (this.buyFormRef && this.buyFormRef.submitted) {
      return val.errors;
    } else {
      return val.errors && (val.touched || val.dirty);
    }
  }
}
