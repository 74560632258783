import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as fromAuth from '@auth/store/auth.reducer';
import { PhoneNumber } from '@number/store/number.model';
import { NumberService } from '@number/store/number.service';
import * as NumberActions from '@number/store/number.actions';
import { ModalService } from '@shared/services';
import { DashPhonePipe } from '@shared/pipes';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'voyant-number-edit',
  templateUrl: './number-edit.component.html',
  styleUrls: ['./number-edit.component.scss'],
})
export class NumberEditComponent implements OnInit {
  isEdit = false;
  editNumber: FormGroup;

  config = {
    saving: false,
    initialValues: { ...this.data.number },
    saveDisabled: true,
  };

  complianceOptions = [
    { value: true, label: 'Compliant' },
    { value: false, label: 'Noncompliant' },
  ];

  packages = this._numberService.getWholeSalePriceList();

  packageDetails: {
    type: string;
    number_of_pages: number;
    price: number;
  } = null;

  auth$: Observable<fromAuth.State>;

  get a() {
    return this.editNumber.controls;
  }

  constructor(
    private _numberService: NumberService,
    private _modalService: ModalService,
    public dialogRef: MatDialogRef<NumberEditComponent>,
    private datePipe: DatePipe,
    private dashPhone: DashPhonePipe,
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      number: PhoneNumber;
      assignee: { type: string; name: string };
    }
  ) {
    this.auth$ = this.store.select('auth');

    const { is_hipaa, plan } = this.data.number;

    if (plan) {
      this.packageDetails = this.getPackageDetails(plan);
    }

    this.config.initialValues = { ...data.number };

    this.editNumber = this.fb.group({
      is_hipaa: [is_hipaa, [Validators.required]],
      revised_plan: [plan ? plan : null],
    });
  }

  ngOnInit(): void {}

  onTypeToString(type) {
    if (type) {
      return type
        .replace('_id', '')
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    } else {
      return null;
    }
  }

  onSaveNumber() {
    const { is_hipaa, revised_plan } = this.editNumber.value;

    this.store.dispatch(
      NumberActions.updatePhoneNumber({
        payload: {
          phone_number: this.data.number.phone_number,
          is_hipaa,
          revised_plan,
        },
      })
    );
  }

  onDeleteNumber() {
    const dialogRef = this._modalService.openConfirmModal({
      modalType: 'danger',
      title: `Release Number`,
      altTitle: `Are you sure you want to release [${this.dashPhone.transform(
        this.data.number.phone_number
      )} ] ?`,
      message: 'Once released, this action cannot be undone.',
      confirmText: 'delete',
    });

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.store.dispatch(
          NumberActions.deletePhoneNumber({
            number: this.data.number.phone_number,
          })
        );
      }
    });
  }

  onCancel() {
    this.editNumber.patchValue({
      ...this.config.initialValues,
    });
    this.isEdit = false;
  }

  onGetLastDayOfMonth() {
    const today = new Date();
    const lastDay = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).getDate();
    today.setDate(lastDay);
    return this.datePipe.transform(today, 'MM/dd/yyyy');
  }

  private getPackageDetails(plan: string) {
    return this.packages.find((p) => p.type === plan);
  }
}
