import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromAta from '@ata/store/ata.reducer';
import * as AtaActions from '@ata/store/ata.actions';
@Component({
  selector: 'voyant-ata-provision-url',
  templateUrl: './ata-provision-url.component.html',
  styleUrls: ['./ata-provision-url.component.scss'],
})
export class AtaProvisionUrlComponent implements OnInit {
  provision: FormGroup;
  copied: any = {};

  constructor(private fb: FormBuilder, private store: Store<fromAta.State>) {
    this.provision = this.fb.group({});
  }

  ngOnInit(): void {
    this.store
      .select(fromAta.selectProvisionUrls)
      .subscribe((provision_urls: { brand: string; url: string }[]) => {
        if (!provision_urls) {
          this.store.dispatch(AtaActions.loadProvisionUrls());
          return;
        }

        provision_urls.forEach(({ brand, url }) => {
          this.provision.addControl(brand, new FormControl(url));
          this.copied[brand] = false;
        });
      });
  }

  onGetControls(g: FormGroup): string[] {
    return Object.keys(g.controls);
  }

  onCopied(fieldName: string): void {
    this.copied[fieldName] = true;
    setTimeout(() => (this.copied[fieldName] = false), 1000);
  }
}
