import * as GroupActions from './group.actions';
import { Group } from './group.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { Paging, pagingDefault } from '@shared/models';

export interface State extends EntityState<Group> {
  loading: boolean;
  isReady: boolean;
  paging: Paging;
  saving: boolean;
}

export const adapter: EntityAdapter<Group> = createEntityAdapter<Group>({
  selectId: (a: Group) => a.id,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  isReady: false,
  current: [],
  paging: pagingDefault,
  saving: false,
});

export const groupReducer = createReducer(
  initialState,

  on(GroupActions.loadAllSucess, (state, { payload, paging }) => {
    return adapter.addMany(
      payload.map((g) => ({ ...g, isEdit: false, selected: false })),
      { ...state, paging, isReady: true, loading: false }
    );
  }),

  on(GroupActions.loadGroups, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(GroupActions.addGroup, (state, { payload }) => {
    return adapter.upsertOne(payload, state);
  }),

  on(GroupActions.updateGroup, (state) => {
    return {
      ...state,
      saving: true,
    };
  }),

  on(GroupActions.updateGroupSuccess, (state, { payload }) => {
    return adapter.updateOne(
      {
        id: payload.id,
        changes: {
          ...payload,
          isEdit: false,
        },
      },
      {
        ...state,
        saving: false,
      }
    );
  }),

  on(GroupActions.deleteGroupSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      selectedGroup: null,
    });
  }),

  on(GroupActions.addPhoneNumbers, (state, { id, payload }) => {
    const g = state.entities[id];
    return adapter.updateOne(
      {
        id: g.id,
        changes: {
          phone_numbers: [...g.phone_numbers, ...payload],
        },
      },
      state
    );
  }),

  on(GroupActions.removePhoneNumber, (state, { id, payload }) => {
    const g = state.entities[id];
    return adapter.updateOne(
      {
        id: g.id,
        changes: {
          ...g,
          phone_numbers: g.phone_numbers.filter(
            (n) => n.phone_number !== payload
          ),
        },
      },
      state
    );
  }),

  on(GroupActions.setSelectedGroup, (state, { payload }) => {
    return {
      ...state,
      selectedGroup: payload,
    };
  }),

  on(GroupActions.endSelectedGroup, (state) => {
    return {
      ...state,
      selectedGroup: null,
    };
  }),

  on(GroupActions.toggleGroupEdit, (state, { id }) => {
    return adapter.updateOne(
      {
        id,
        changes: {
          isEdit: !state.entities[id].isEdit,
        },
      },
      state
    );
  }),

  on(GroupActions.reportError, (state) => {
    return {
      ...state,
      saving: false,
      loading: false,
    };
  })
);

export const getState = createFeatureSelector<State>('group');

export const { selectIds, selectAll, selectEntities, selectTotal } =
  adapter.getSelectors(getState);

export const selectGroup = (id) =>
  createSelector(selectEntities, (entities) => {
    return entities[id];
  });
