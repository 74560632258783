import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  Input,
} from '@angular/core';
import { UserService } from '../../../../user/store/user.service';
import { User, UserResponse } from '../../../../user/store/user.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { concat, of, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  switchMap,
  map,
  catchError,
  share,
} from 'rxjs/operators';

@Component({
  selector: 'voyant-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss'],
})
export class AddUsersComponent implements OnInit {
  @Input() assignedUsers: User[];
  @Input() removeFromList: string[] = [];
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();

  selectedUsers: string[] = [];

  searchResults$: Observable<User[] | any[]>;
  searchInput$: Subject<string> = new Subject<string>();

  constructor(
    private _userService: UserService,
    public dialogRef: MatDialogRef<AddUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.hasOwnProperty('removeFromList')) {
      this.removeFromList = this.data.removeFromList;
    }
  }

  ngOnInit() {
    if (this.assignedUsers && this.assignedUsers.length) {
      this.selectedUsers = this.assignedUsers.map((user: User) => user.id);
    }

    this.onLoadUsers();
  }

  onNext() {
    !this.data.hasOwnProperty('component')
      ? this.next.emit({ users: this.selectedUsers })
      : this.dialogRef.close({ users: this.selectedUsers });
  }

  onBack() {
    !this.data.hasOwnProperty('component')
      ? this.back.emit({ users: this.selectedUsers })
      : this.dialogRef.close({ users: [] });
  }

  onLoadUsers(): void {
    this.searchResults$ = concat(
      of([]),
      this.searchInput$.pipe(
        debounceTime(400),
        switchMap((term) => {
          if (term && term.length) {
            const params = {
              search: term,
              page: 1,
              per_page: 50,
            };

            return this._userService.getUsers(params).pipe(
              map((res: UserResponse) =>
                res.data.filter((user: User) => {
                  return !this.removeFromList.includes(user.id);
                })
              ),
              catchError(() => of([]))
            );
          } else {
            return of([]);
          }
        }),
        share()
      )
    );
  }

  onSkip(): void {
    this.next.emit({});
  }

  identify(index, item: User) {
    return item.id;
  }
}
