import { Injectable } from '@angular/core';
import { timezones } from '../static/timezones';
import { Timezone } from '../models/timezone';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor() {
    this.getList();
  }

  getList(): Timezone[]{
    const zones =  timezones.sort((a: Timezone, b: Timezone) => a.offset > b.offset ? 1 : -1);
    return zones;
  }
}
