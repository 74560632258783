import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';

import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import { AuthUser } from '@auth/store/auth.model';

@Component({
  selector: 'voyant-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
})
export class MobileNavigationComponent implements OnInit, OnChanges {
  @Input() activeLink: string;
  @Input() showNav: any;
  @Input() loggedInUser: AuthUser;
  show = false;
  isOps = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth > 992 && this.show) {
      this.show = false;
    }
  }
  constructor(
    private _authService: AuthService,
    private router: Router,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.show = false;
      }
    });
    const loggedInUser: AuthUser = this._authService.getLoggedInUser();
    this.isOps = loggedInUser && loggedInUser.role === 'ops';

    this.store.select('auth').subscribe((authState) => {
      if (!this._authService.getImpersonatingUser()) {
        this.isOps =
          authState.loggedInUser && authState.loggedInUser.role === 'ops';
      }
    });
  }

  ngOnChanges(changes) {
    if (changes.hasOwnProperty('showNav')) {
      this.show = changes.showNav.currentValue.navIsVisible;
    }
  }

  onLogout(e) {
    e.preventDefault();
    this._authService.logout().subscribe();
  }
}
