import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of, throwError } from 'rxjs';
import { map, catchError, withLatestFrom, exhaustMap } from 'rxjs/operators';

import * as AdminActions from './admin.actions';
import { AdminService } from './admin.service';

import { PhaxioResponse } from '@shared/models';
import { ToastrService } from '@shared/services';
import { UserService } from '@user/store/user.service';
import { User } from '@user/store/user.model';
import * as fromUser from '@user/store/user.reducer';
import { Log } from './admin.model';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';

@Injectable()
export class AdminEffects {
  constructor(
    private actions$: Actions,
    private _adminService: AdminService,
    private _userService: UserService,
    private _toastrService: ToastrService,
    private store: Store<fromApp.AppState>
  ) {}

  getLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.getLog),
      exhaustMap(({ payload }) =>
        this._adminService.getLogs(payload).pipe(
          map((res: PhaxioResponse) => {
            const logUserMap = new Map();
            res.data.forEach((l: Log) => logUserMap.set(l.id, l.user_id));
            this.store.dispatch(AdminActions.getUsers({ logUserMap }));
            return AdminActions.getLogSuccess({
              paging: res.paging,
              logs: res.data,
            });
          }),
          catchError((errRes: any) =>
            of(AdminActions.reportError({ error: errRes.error.message }))
          )
        )
      )
    )
  );

  getAdminUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.getUsers),
      withLatestFrom(this.store.select(fromUser.selectEntities)),
      exhaustMap(([action, users]) => {
        const { logUserMap } = action;
        const fetchUsers = [];
        const logUsers = new Map<string, User>();
        logUserMap.forEach((userId, logId) => {
          if (users[userId]) {
            logUsers.set(logId, users[userId]);
            return;
          }

          if (fetchUsers.indexOf(userId) == -1) {
            fetchUsers.push(userId);
          }
        });

        if (!fetchUsers.length) {
          return of(AdminActions.getUsersSuccess({ logUsers }));
        }

        return forkJoin(
          fetchUsers.map((id) => this._userService.getUser(id))
        ).pipe(
          map((res: User[]) => {
            const userMap = new Map();
            res.forEach((u) => userMap.set(u.id, u));
            return userMap;
          }),
          exhaustMap((userMap) => {
            logUserMap.forEach((userId, logId) =>
              logUsers.set(logId, userMap.get(userId))
            );
            return of(AdminActions.getUsersSuccess({ logUsers }));
          })
        );
      }),
      catchError((errRes: any) =>
        of(AdminActions.reportError({ error: errRes.error.message }))
      )
    )
  );

  reportError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminActions.reportError),
        map(({ error }) => {
          this._toastrService.notify('error', error);
          return throwError(error);
        })
      ),
    {
      dispatch: false,
    }
  );
}
