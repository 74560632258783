export const mockEnvironment = {
  name: 'staging',
  production: false,
  apiUrls: {
    faxApi: 'https://api.fax-staging.inteliquent.com/fax/v1/',
  },
  s3: {
    accessKeyId: 'AKIAJGQOOTTPZJHXOWSQ',
    secretAccessKey: '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',
  },
  DOMAIN: 'voyant-staging.com',
  bugsnagConfig: {
    apiKey: '75cc6226e7a0ce67635098601cdbadd9',
    releaseStage: 'staging',
  },
};
