import { ColorPickerComponent } from './color-picker/color-picker.component';
import { DatePickerCalendarComponent } from './date-picker/date-picker-calendar/date-picker-calendar.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FaxStatisticsComponent } from './fax-statistics/fax-statistics.component';
import { HasErrorComponent } from './has-error/has-error.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StepperNavComponent } from './stepper-nav/stepper-nav.component';
import { TableLoaderComponent } from './table-loader/table-loader.component';
import { TableSortComponent } from './table-sort/table-sort.component';
import { UniversalSearchComponent } from './universal-search/universal-search.component';
import { FileUploadButtonComponent } from './file-upload-button/file-upload-button.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MembersTooltipComponent } from './members-tooltip/members-tooltip.component';
import { CopyStringComponent } from './copy-string/copy-string.component';
import { NoDataTableComponent } from './no-data-table/no-data-table.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { PhoneNumberSelectComponent } from './phone-number-select/phone-number-select.component';
import { UserSelectComponent } from './user-select/user-select.component';
import { FaxLinesListComponent } from './fax-lines-list/fax-lines-list.component';
import { PlanSelectComponent } from './plan-select/plan-select.component';

export { StepperStep } from './stepper-nav/stepper-nav.component';

export const sharedComponents = [
  ColorPickerComponent,
  DatePickerCalendarComponent,
  DatePickerComponent,
  FaxStatisticsComponent,
  HasErrorComponent,
  ImageUploadComponent,
  LoadingButtonComponent,
  LoadingSpinnerComponent,
  PaginationComponent,
  SpinnerComponent,
  StepperNavComponent,
  TableLoaderComponent,
  TableSortComponent,
  UniversalSearchComponent,
  FileUploadButtonComponent,
  FileUploadComponent,
  MembersTooltipComponent,
  CopyStringComponent,
  NoDataTableComponent,
  ComplianceComponent,
  CheckboxComponent,
  PhoneNumberSelectComponent,
  UserSelectComponent,
  FaxLinesListComponent,
  PlanSelectComponent,
];
