import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of, combineLatest, forkJoin } from 'rxjs';
import { map, mergeMap, catchError, switchMap, tap } from 'rxjs/operators';

import { PhoneNumber, PhoneNumberMetrics } from './number.model';
import { NumberService } from './number.service';
import * as NumberActions from './number.actions';
import * as fromNumber from './number.reducer';
import * as numberSelectors from './number.selectors';

import { PhaxioResponse, Paging, pagingDefault } from '@shared/models';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from '@shared/services';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class NumberEffects {
  loadPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NumberActions.loadPhoneNumbers),
      map((action) => action.payload),
      mergeMap((params: Paging) =>
        this._numberService.getNumbers(params).pipe(
          map((res: PhaxioResponse) => {
            return NumberActions.setPhoneNumbers({
              paging: res.paging,
              numbers: res.data,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  proovisionPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NumberActions.provisionPhoneNumber),
      map((action) => action.payload),
      mergeMap((req: Partial<PhoneNumber>) =>
        this._numberService.provisionNumber(req).pipe(
          map((res: PhaxioResponse) =>
            NumberActions.addPhoneNumberSuccess({
              payload: res.data,
              gettingMany: false,
            })
          ),
          catchError((res: HttpErrorResponse) => {
            return of(NumberActions.addPhoneNumberFail());
          })
        )
      )
    )
  );

  proovisionManyPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NumberActions.provisionManyPhoneNumbers),
      mergeMap((action) => {
        const calls = Array(action.quantity).fill(
          this._numberService.provisionNumber(action.req).pipe(
            map((res) => res),
            catchError((errRes: any) => of(errRes.error))
          )
        );

        return combineLatest(calls).pipe(
          map((res: any[]) => {
            res.forEach((resObj: PhaxioResponse) => {
              let action: any = NumberActions.addPhoneNumberFail();
              if (resObj.success) {
                action = NumberActions.addPhoneNumberSuccess({
                  payload: resObj.data,
                  gettingMany: true,
                });
              }
              this.store.dispatch(action);
            });

            return NumberActions.provisionManyPhoneNumbersFinished();
          })
        );
      })
    )
  );

  updatePhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NumberActions.updatePhoneNumber),
      map((action) => action.payload),
      mergeMap((req: PhoneNumber) => {
        const { phone_number, is_hipaa, revised_plan } = req;
        return this._numberService
          .updateNumber(phone_number, { is_hipaa, revised_plan })
          .pipe(
            map(() => NumberActions.updatePhoneNumberSuccess({ payload: req })),
            catchError((res: HttpErrorResponse) => EMPTY)
          );
      })
    )
  );

  updatePhoneNumberSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NumberActions.updatePhoneNumberSuccess),
        tap(() => {
          this._toastrService.notify('success', 'Number updated successfully');
          this.dialogRef.closeAll();
        })
      ),
    { dispatch: false }
  );

  deletePhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NumberActions.deletePhoneNumbers),
      switchMap(() => this.store.select(numberSelectors.getState)),
      switchMap((state) => {
        const selected = Array.from(state.selected.values());
        const numbers = selected.map((n) => n.phone_number);
        return forkJoin([
          ...numbers.map((n) => this._numberService.deleteNumber(n)),
        ]).pipe(map((res) => NumberActions.deleteSuccess({ numbers })));
      })
    )
  );

  deletePhoneNumbes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NumberActions.deletePhoneNumber),
      switchMap(({ number }) => {
        return this._numberService
          .deleteNumber(number)
          .pipe(map(() => NumberActions.deleteSuccess({ numbers: [number] })));
      })
    )
  );

  deletePhoneNumbersSuccesss$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NumberActions.deleteSuccess),
      map(({ numbers }) => {
        this._toastrService.notify(
          'success',
          `${numbers.length} numbers successfully deleted.`
        );
        this.dialogRef.closeAll();
        return NumberActions.loadPhoneNumbers({ payload: pagingDefault });
      })
    )
  );

  getPhoneNumbersMetrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NumberActions.getNumbersMetrics),
      switchMap(() =>
        this._numberService
          .getPhoneNumbersMetrics()
          .pipe(
            map((res: { data: PhoneNumberMetrics }) =>
              NumberActions.getNumbersMetricsSuccess({ data: res.data })
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromNumber.State>,
    private dialogRef: MatDialog,
    private _numberService: NumberService,
    private _toastrService: ToastrService
  ) {}
}
