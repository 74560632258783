import { NgModule } from '@angular/core';
import { PortsTableComponent } from './ports-table/ports-table.component';
import { SharedModule } from '../shared/shared.module';
import { PortNewComponent } from './port-new/port-new.component';
import { PortStatisticsComponent } from './port-statistics/port-statistics.component';
import { PortsPageComponent } from './ports-page/ports-page.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PortEditComponent } from './modals/port-edit/port-edit.component';
@NgModule({
  declarations: [
    PortsTableComponent,
    PortNewComponent,
    PortStatisticsComponent,
    PortsPageComponent,
    PortEditComponent,
  ],
  imports: [
    SharedModule,
    SignaturePadModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [PortsTableComponent, PortStatisticsComponent],
})
export class PortModule {}
