import { RoleGuard } from '../auth/role.guard';
import { NumbersAllComponent } from './numbers-all/numbers-all.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NumbersPageComponent } from './numbers-page/numbers-page.component';
import { PortsPageComponent } from '../port/ports-page/ports-page.component';
import { PortNewComponent } from '../port/port-new/port-new.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        canActivate: [RoleGuard],
        component: NumbersAllComponent,
        children: [
          {
            path: 'inventory',
            component: NumbersPageComponent,
          },
          { path: 'ports', component: PortsPageComponent },
          // { path: 'ports/new', component: PortNewComponent },
          { path: '', redirectTo: '/numbers/inventory', pathMatch: 'full' },
        ],
        data: {
          expectedRoles: ['admin', 'manager'],
        },
      },
      {
        path: 'ports/new',
        canActivate: [RoleGuard],
        component: PortNewComponent,
        data: {
          expectedRoles: ['admin', 'manager'],
        },
      },
    ]),
  ],
  exports: [RouterModule],
})
export class NumberRoutingModule {}
