import { Injectable } from '@angular/core';
import { EnvironmentService, SearchService } from '../../shared/services';
import { HttpClient } from '@angular/common/http';
import { Ata } from './ata.model';
import { PhaxioResponse, SearchParams } from '@shared/models';
import { Store } from '@ngrx/store';
import * as fromApp from '@app-store/app.reducer';
import * as fromAta from '../store/ata.reducer';
import { Dictionary } from '@ngrx/entity';
import { take, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AtaService {
  private API_URL = this.env.apiUrls.faxApi;
  private ATA_URL = `${this.API_URL}atas`;
  defaultSearch: SearchParams = this._searchService.initSearchParams('id');

  constructor(
    private env: EnvironmentService,
    private http: HttpClient,
    private _searchService: SearchService,
    private store: Store<fromApp.AppState>
  ) {}

  getMap(): Dictionary<Ata> {
    let atas: Dictionary<Ata>;
    this.store
      .select(fromAta.selectEntities)
      .pipe(take(1))
      .subscribe((s) => (atas = s));
    return atas;
  }

  getAtas(urlParams: Partial<SearchParams> = this.defaultSearch) {
    const params: any =
      urlParams && Object.keys(urlParams).length > 0
        ? { ...urlParams }
        : { ...this.defaultSearch };
    return this.http.get(this.ATA_URL, { params });
  }

  getAta(id: string) {
    const url = `${this.ATA_URL}/${id}`;
    return this.http.get(url);
  }

  createAta(req: Ata) {
    return this.http.post(this.ATA_URL, req).pipe(take(1));
  }

  updateAta(id: string, ata: Partial<Ata>) {
    const url = `${this.ATA_URL}/${id}`;
    return this.http.put(url, ata);
  }

  addNumberToAta(id: string, phone_number: string): Observable<PhaxioResponse> {
    const url = `${this.ATA_URL}/${id}/atas_phone_numbers`;
    let cachedNumbers;
    this.store
      .select('number')
      .subscribe((s) => (cachedNumbers = s.searchCache));

    return this.http
      .post(url, {
        phone_number,
        plan: cachedNumbers.find((n) => n.phone_number).plan,
      })
      .pipe(map((res: PhaxioResponse) => res));
  }

  removeNumberFromAta(id: string, phone_number: string) {
    const url = `${this.ATA_URL}/${id}/atas_phone_numbers/${phone_number}`;
    return this.http.delete(url).pipe(
      map((res: PhaxioResponse) => {
        return res;
      })
    );
  }

  addUserToAta(ata_id: string, id: string) {
    const url = `${this.ATA_URL}/${ata_id}/atas_users`;
    return this.http.post(url, { id }).pipe(
      map((res: PhaxioResponse) => {
        return res;
      })
    );
  }

  removeUserToAta(ata_id: string, id: string) {
    const url = `${this.ATA_URL}/${ata_id}/atas_users/${id}`;
    return this.http.delete(url).pipe(
      map((res: PhaxioResponse) => {
        return res;
      })
    );
  }

  deleteAta(ata_id: string) {
    const url = `${this.ATA_URL}/${ata_id}`;
    return this.http.delete(url).pipe(
      map((res: PhaxioResponse) => {
        return res;
      })
    );
  }

  deactivateAta(ata_id: string) {
    const url = `${this.ATA_URL}/${ata_id}/deactivate`;
    return this.http.put(url, {}).pipe(
      map((res: PhaxioResponse) => {
        return res;
      })
    );
  }

  getProvisioningUrls() {
    const url = `${this.ATA_URL}/provisioning_urls`;
    return this.http.get(url);
  }

  getMacMddresses() {
    const url = `${this.ATA_URL}/autodetected`;
    return this.http.get(url);
  }

  getAtaName(ataId: string): string {
    const ata = this.getMap()[ataId];
    return ata ? ata.name : '';
  }
}
