import { createAction, props } from '@ngrx/store';
import { Group } from '@group/store/group.model';
import { Paging, SearchParams } from '@shared/models';
import { PhoneNumber } from '@number/store/number.model';

const LABEL = '[GROUP]';

export const loadAllGroups = createAction(
  `${LABEL}  Load All Groups`,
  props<{ payload: Partial<SearchParams> }>()
);

export const loadAllSucess = createAction(
  `${LABEL}  Load All Groups Success`,
  props<{ payload: Group[]; paging: Paging }>()
);

export const loadGroups = createAction(
  `${LABEL}  Load Groups`,
  props<{ payload: Partial<SearchParams> }>()
);

export const createGroup = createAction(
  `${LABEL}  Create Group`,
  props<{ payload: Group }>()
);

export const addGroup = createAction(
  `${LABEL}  Add Group`,
  props<{ payload: Group }>()
);

export const updateGroup = createAction(
  `${LABEL}  Update Group`,
  props<{ group: Group }>()
);

export const updateGroupSuccess = createAction(
  `${LABEL}  Update Group Success`,
  props<{ id: string; payload: Partial<Group> }>()
);

export const deleteGroup = createAction(
  `${LABEL}  Delete Group`,
  props<{ id: string }>()
);

export const deleteGroupSuccess = createAction(
  `${LABEL}  Delete Group Success`,
  props<{ id: string; message: string }>()
);

export const addPhoneNumbers = createAction(
  `${LABEL}  Add Phone Number`,
  props<{ id: string; payload: PhoneNumber[] }>()
);

export const removePhoneNumber = createAction(
  `${LABEL}  Remove Phone Number`,
  props<{ id: string; payload: string }>()
);

export const setSelectedGroup = createAction(
  `${LABEL}  Set Selected Group`,
  props<{ payload: Group }>()
);

export const endSelectedGroup = createAction(`${LABEL}  End Selected Group`);

export const toggleGroupEdit = createAction(
  `${LABEL}  Toggle Group Edit`,
  props<{ id: string }>()
);

export const reportError = createAction(
  `${LABEL}  Report Error`,
  props<{ message: string; data?: any }>()
);

export const reportSuccess = createAction(
  `${LABEL}  Report Success`,
  props<{ message: string }>()
);
