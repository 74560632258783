import { Component, Input, OnChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'voyant-var-invoice-status',
  templateUrl: './var-invoice-status.component.html',
  styleUrls: ['./var-invoice-status.component.scss']
})
export class VarInvoiceStatusComponent implements OnChanges {
  @Input() status: string;
  applyClass = '';

  constructor() { }

  ngOnChanges(changes: any): void {
    const status = changes.status.currentValue;
    const applyClass = this.getStatusClass(status);
    this.applyClass = applyClass;
  }

  getStatusClass(status: string): string {
    let applyClass = 'status__';
    switch (status) {
      case 'paid':
        applyClass += 'success';
        break;
      // case 'retrying':
      //   applyClass += 'primary';
      //   break;
      // case 'unread':
      //   applyClass += 'blue';
      //   break;
      case 'outstanding':
        applyClass += 'warning';
        break;
      case 'failed':
        applyClass += 'danger';
        break;
    }
    return applyClass;
  }

}
