import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'voyant-date-picker-calendar',
  templateUrl: './date-picker-calendar.component.html',
  styleUrls: ['./date-picker-calendar.component.scss'],
})
export class DatePickerCalendarComponent implements OnInit, OnChanges {
  @Input() next: boolean;
  @Input() date: Date;
  @Input() fromDate: Date;
  @Input() toDate: Date;

  @Output() prevMonth = new EventEmitter();
  @Output() nextMonth = new EventEmitter();
  @Output() selectedDate = new EventEmitter();
  @Output() today = new Date();

  locDate: Date = null;
  fromD: Date = null;
  toD: Date = null;

  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  weekDayLabels = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  month: any[] = null;

  constructor() {}

  ngOnInit(): void {
    if (this.fromDate) {
      this.fromD = this.fromDate;
    }
    if (this.toDate) {
      this.toD = this.toDate;
    }
    if (this.date) {
      this.locDate = this.date;
      this.month = this.buildCallendar(this.locDate);
    }
  }

  ngOnChanges(changes): void {
    if (changes.fromDate) {
      this.fromD = changes.fromDate.currentValue;
    }
    if (changes.toDate) {
      this.toD = changes.toDate.currentValue;
    }
    if (changes.date && this.locDate !== changes.date.currentValue) {
      this.locDate = changes.date.currentValue;
      this.month = this.buildCallendar(this.locDate);
    }
  }

  buildCallendar(date): any[] {
    if (date) {
      const month = date.getMonth();
      const year = date.getFullYear();

      const daysInCurrentMonth = this.getDaysInMonth(month, year);
      const monthArr = [];
      let weekIndex = 0;

      for (let i = 1; i <= daysInCurrentMonth; i++) {
        const locDate = new Date(year, month, i);
        const weekDay = locDate.getDay();
        if (weekDay === 0) {
          weekIndex += 1;
        }
        if (!monthArr[weekIndex]) {
          monthArr[weekIndex] = [];
        }
        const dayObj = {
          date: i,
          weekDay,
          month,
          year,
        };
        monthArr[weekIndex].push(dayObj);
      }

      monthArr.filter((n) => n);

      let missingDays: number;

      const firstWeek = monthArr[0];
      const lastWeek = monthArr[monthArr.length - 1];

      // look for missing days
      if (firstWeek && firstWeek.length !== 7) {
        missingDays = 7 - firstWeek.length;
        const prevMonthDays = this.getDaysInMonth(month - 1, year);

        for (let i = prevMonthDays; i > prevMonthDays - missingDays; i--) {
          const locDate = new Date(year, month - 1, i);
          const weekDay = locDate.getDay();
          const dayObj = {
            date: i,
            weekDay,
            month: month - 1,
            year,
          };

          monthArr[0].unshift(dayObj);
        }
      }

      if (lastWeek && lastWeek.length !== 7) {
        missingDays = 7 - lastWeek.length;
        for (let i = 1; i <= missingDays; i++) {
          const locDate = new Date(year, month + 1, i);
          const weekDay = locDate.getDay();
          const dayObj = {
            date: i,
            weekDay,
            month: month + 1,
            year,
          };
          monthArr[monthArr.length - 1].push(dayObj);
        }
      }

      const final = monthArr.filter((el) => el != null);
      return final;
    } else {
      return [];
    }
  }

  onDaySelected(day: {
    date: number;
    weekDay: number;
    month: number;
    year: number;
  }) {
    this.selectedDate.emit(new Date(day.year, day.month, day.date));
  }

  onPrevMonth() {
    this.prevMonth.emit(true);
  }

  onNextMonth() {
    this.nextMonth.emit(true);
  }

  getDaysInMonth(month: number, year: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  isFilled(day: any): boolean {
    return day.month === this.locDate.getMonth();
  }

  isToday(day: any): boolean {
    return (
      day.month === this.today.getMonth() &&
      day.year === this.today.getFullYear() &&
      day.date === this.today.getDate()
    );
  }

  isActive(day: any): boolean {
    if (this.isFilled(day)) {
      return (
        (this.toD &&
          day.month === this.toD.getMonth() &&
          day.year === this.toD.getFullYear() &&
          day.date === this.toD.getDate()) ||
        (this.fromD &&
          day.month === this.fromD.getMonth() &&
          day.year === this.fromD.getFullYear() &&
          day.date === this.fromD.getDate())
      );
    } else {
      return false;
    }
  }

  isInRange(day: any): boolean {
    if (this.isFilled(day) && this.fromD && this.toD) {
      const date = new Date(day.year, day.month, day.date);
      const from = new Date(this.fromD);
      const to = new Date(this.toD);
      return date > from && date < to;
    } else {
      return false;
    }
  }
}
